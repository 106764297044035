import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import {
  Grid,
  IconButton,
  /* Link, */
  /* Breadcrumbs as MuiBreadcrumbs, */
  /* Divider as MuiDivider, */
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Button,
  /* Tooltip, */
  Typography,
  FormGroup,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  MenuItem,
  FormControl,
  InputLabel as MuiInputLabel,
  FormControlLabel,
  Select,
  Switch,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  FormLabel,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import {
  /* Add as AddIcon,
  Visibility as VisibilityIcon, */
  Settings as SettingsIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import axios from "axios";
import Floorrow from "./components/Floorrow";
import SnackToolbar from "./components/SnackToolbar";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "./components/Textfield";
import {
  AddFloorList,
  GetFloorList,
  DeleteFloor,
  ViewFloor,
  GetFloorColumnList,
} from "../../api";
import AddEditFloor from "./components/AddEditFloor";
import Toaster from "../tasks/Toaster";
import { getSnackClose } from "../../redux/slices/propertySlice";
import { getChecked, setFloorListing } from "../../redux/slices/floorPlanSlice";

import { useDispatch, useSelector } from "react-redux";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";

const CloudUpload = styled(MuiCloudUpload)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;
// Image
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 15%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "floor_no",
    numeric: false,
    disablePadding: true,
    label: "Floor No.",
  },
  { id: "fname", numeric: true, disablePadding: false, label: "Floor Name" },
  { id: "rname", numeric: true, disablePadding: false, label: "Region Name" },
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const EnhancedTableHead = (props) => {
  const checkedValue = useSelector((state) => state.floorPlan.checked);
  const [headColumn, setHeadColumn] = useState([]); //My header...

  const {
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((colResult) => {
        if (colResult.key == obj) {
          result.push(colResult);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });

    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all property" }}
          />
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.key}
            align="left"
            sortDirection={orderBy === headCell.key ? order : false}
            padding={headCell.key === "floor" ? "none" : "normal"}

            // sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              active={
                headCell.key === "floor" ? orderBy === headCell.key : false
              }
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "floor"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "floor" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={
                headCell.id === "floor_no" ? orderBy === headCell.id : false
              }
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "floor_no"
                  ? createSortHandler(headCell.id)
                  : null
              }
              hideSortIcon={headCell.id === "floor_no" ? false : true}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))} */}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const [actions, setActions] = useState("");
  const dispatch = useDispatch();
  const {
    handleDeleteOpen,
    totalRecord,
    showFloorList,
    open,
    setOpen,
    msg,
    setMsg,
    isDisabled,
    setIsDisabled,
    addFloorOpen,
    colArray,
    getColumnList,
    getColumnChecked,
  } = props;

  const checkedValue = useSelector((state) => state.floorPlan.checked);

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { id } = useParams();
  //Handler for snackbar for adding
  const [snackopen, setSnackopen] = useState(false);
  const handleSnackClick = () => {
    setSnackopen(true);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getChecked([...checkedValue, value]));
    } else {
      dispatch(getChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  //Initial values Formik
  // const initialValues = {
  //   floor: "",
  //   floorName: "",
  //   regionName: "",
  //   floorImage: null,
  //   floorBound: "",
  // };

  // const onLoad = (fileString) => {
  //   console.log(fileString);
  //   // console.log(initialValues);
  //   // initialValues.logoImage = fileString;
  //   // setImage(fileString);
  // };

  // const getBase64 = (file) => {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     onLoad(reader.result);
  //     setUploadMedia(reader.result);
  //   };
  // };
  // const [uploadMedia, setUploadMedia] = useState(null);
  // const uploadMediaFiles = (e) => {
  //   if (e.target.files) {
  //     console.log(e.target.files);
  //     const files = e.target.files;
  //     const file = files[0];
  //     getBase64(file);
  //     // setUploadMedia(URL.createObjectURL(e.target.files[0]));
  //   }
  // };
  return (
    <Toolbar>
      <Snackbar
        open={snackopen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          variant="filled"
          sx={{ maxWidth: 600 }}
        >
          {msg}
        </Alert>
      </Snackbar>

      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
            >
              <MenuItem value={10} onClick={handleDeleteOpen}>
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} record found.
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        <Box mt={3}>
          <Item>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* <MenuItem
              onClick={() => {
                // let data = {
                //   floor: "",
                //   floorName: "Anees",
                //   regionName: "Hashmi",
                //   floorImage: null,
                //   floorBound: "[4523698712]",
                // };
                // setUpdateValue(data);
                setOpen(true);
                setMsg("Floor has been successfully added.");
              }}
            > */}
              <WithPermissionHide controller="Property" action="addFloor">
                <MenuItem
                  // onClick={() => {
                  //   // let data = {
                  //   //   floor: "",
                  //   //   floorName: "Anees",
                  //   //   regionName: "Hashmi",
                  //   //   floorImage: null,
                  //   //   floorBound: "[4523698712]",
                  //   // };
                  //   // setUpdateValue(data);
                  //   setOpen(true);
                  //   setMsg("Floor has been successfully added.");
                  // }}
                  onClick={() => {
                    handleClose();
                    addFloorOpen();
                  }}
                >
                  <ListItemIcon>
                    <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText size="large" primary="New Floor" pl={0} />
                </MenuItem>
              </WithPermissionHide>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      5 out of 5 visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked disableRipple />}
                          label="Floor No."
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Floor Name"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Region Name"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Image"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Action"
                        />
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button color="primary" variant="contained">
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem> */}
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {checkedValue.length} out of {colArray.length} visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        {colArray.map((col, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  disableRipple
                                  checked={checkedValue.includes(col.key)}
                                  onChange={(e) => handleChecked(e)}
                                  value={col.key}
                                />
                              }
                              label={col.value}
                            />
                          );
                        })}
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          getColumnList();
                          getColumnChecked();
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Item>
        </Box>

        {/* <Dialog
          open={open}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "0px",
            }}
          >
            <Typography variant="h4">Add/Edit Floor</Typography>
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={() => setOpen(false)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent
            repositionOnUpdate={false}
            style={{ paddingTop: "20px" }}
          >
            <DialogContentText>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  status,
                  setFieldValue,
                }) => (
                  <Card mb={6}>
                    <CardContent>
                      {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  fullWidth
                                  shrink
                                  id="floor-number-label"
                                  sx={{
                                    paddingLeft: "6px",
                                    paddingRight: "6px",
                                    marginLeft: "-4px",
                                  }}
                                >
                                  Floor
                                </InputLabel>
                                <Select
                                  name="floor"
                                  labelId="floor-number-label"
                                  label="Floor"
                                  id="floor-number"
                                  value={values.floor}
                                  displayEmpty
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                >
                                  {number.map((n, index) => (
                                    <MenuItem key={index} value={n}>
                                      {n}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <Textfield
                                  name="floorName"
                                  label="Floor name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display:
                                  uploadMedia === null ? "none" : "block",
                                height: "100%",
                              }}
                            >
                              <Card variant="outlined" sx={{ height: "150px" }}>
                                <Image
                                  sx={{
                                    width: "100%",
                                  }}
                                  src={uploadMedia}
                                />
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth sx={{ mt: "10px" }}>
                                <Textfield
                                  name="floorImage"
                                  label="Floor image *"
                                  type="file"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={uploadMediaFiles}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth sx={{ mt: "10px" }}>
                                <Textfield
                                  id="region-name"
                                  name="regionName"
                                  label="Region name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl fullWidth sx={{ marginTop: "25px" }}>
                                <Textfield
                                  name="floorBound"
                                  label="Floor bounds (include brackets)"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="[4747.3444.2.3343444444.233.22222]"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <DialogActions
                            sx={{ paddingRight: "0px", paddingTop: "20px" }}
                          >
                            <Button
                              onClick={() => setOpen(false)}
                              variant="outlined"
                            >
                              Close
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              autoFocus
                              disabled={isDisabled}
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </form>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Formik>
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("corporate");
  const [selected, setSelected] = React.useState([]); //State for Selecting the records
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [floorLists, setfloorLists] = useState([]);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [floorId, setfloorId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false); //State for loader
  const [snackToolbarOpen, setsnackToolbarOpen] = useState(false);
  const [recordLength, setrecordLength] = useState(null);
  const [open, setOpen] = React.useState(false); // state for opening the dialogbox form opening in the enhancetabletoolbar, managing its state from parent
  const [updateValue, setUpdateValue] = useState({}); //state for managing form initial  values
  const [editButtonStatus, setEditButtonStatus] = useState(false); //State for edit button
  const [msg, setMsg] = useState(""); //state for Snackbar msg
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const [editFloorData, setEditFloorData] = useState({});
  const dispatch = useDispatch();
  const [pageLoader, setPageLoader] = useState(false);

  const checkedValue = useSelector((state) => state.floorPlan.checked);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  //for snackbar
  const snackbarStatus = useSelector((state) => state.property.snackStatus);
  const snackbarMsg = useSelector((state) => state.property.snackMsg);
  const snackbarAlert = useSelector((state) => state.property.snackAlert);
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  // State for add floor modal
  const [addFloorDialog, setAddFloorDialog] = useState(false);
  const addFloorOpen = () => {
    setAddFloorDialog(true);
  };
  const addFloorClose = () => {
    setAddFloorDialog(false);
    setEditFloorData({});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //handlers for snackbar...
  const handleSnackToolbarClick = () => {
    setsnackToolbarOpen(true);
  };
  const handleSnackToolbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackToolbarOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = floorLists.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, fname) => {
    const selectedIndex = selected.indexOf(fname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, fname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setrecordLength(newSelected.length);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setSelected([]);
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleActionClick = (event, id) => {
    console.log("From handle action", id);
    setAnchorEl(event.currentTarget);
    setfloorId(id);
    setrecordLength(1);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
    setEditButtonStatus(false);
  };

  //function for calling API for Listing floors
  const showFloorList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
    };
    GetFloorList(id, currentPage + 1, { params: payload })
      // axios
      //   .get(`get-floors/${id}?page=${currentPage + 1}`, {
      //     params: { limit: rowsPerPage },
      //   })
      .then((res) => {
        setLoading(false);
        setfloorLists(res.data);
        setTotalRecord(res.meta.total);
      })
      .catch((err) => {
        setTotalRecord(0);
        console.log(err);
      });
  };

  useEffect(() => {
    showFloorList();
  }, [rowsPerPage, currentPage]);

  //Handler for Single or multiple property delete
  const deleteList = () => {
    setAnchorEl(null);
    let delId = selected.length > 0 ? selected : floorId;
    DeleteFloor(delId)
      // axios
      //   .post(`delete-floor/${url}`)
      .then((response) => {
        // getUserData();
        showFloorList();
        handleDeleteClose();
        handleSnackToolbarClick();
        setSelected([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setfloorId(null);
  };

  const getFloorDetails = () => {
    setPageLoader(true);
    ViewFloor(floorId)
      .then((res) => {
        setPageLoader(false);
        setEditFloorData(res.data);
        console.log("++", res);
      })
      .catch((err) => {
        console.log(err);
        setPageLoader(false);
      });
  };

  //Handler for editing vehicle involved
  const editFloor = () => {
    getFloorDetails();
    addFloorOpen();
  };

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
    setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
  }, []);

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    GetFloorColumnList()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getColumnChecked = () => {
    GetFloorColumnList()
      .then((res) => {
        dispatch(getChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showFloorList();
    getColumnList();
  }, [rowsPerPage, currentPage, order, orderBy]);

  const isSelected = (fname) => selected.indexOf(fname) !== -1;
  return (
    <>
      <Paper mt={3}>
        <SnackToolbar
          handleSnackToolbarClose={handleSnackToolbarClose}
          snackToolbarOpen={snackToolbarOpen}
          recordLength={recordLength}
        />
        <>
          {selected.length || floorId > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete floor?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button onClick={(handleDeleteClose, deleteList)} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setfloorLists={setfloorLists}
          handleDeleteOpen={handleDeleteOpen}
          totalRecord={totalRecord}
          setTotalRecord={setTotalRecord}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          showFloorList={showFloorList}
          open={open}
          setOpen={setOpen}
          formdata={updateValue}
          updateValue={updateValue}
          setUpdateValue={setUpdateValue}
          editButtonStatus={editButtonStatus}
          setEditButtonStatus={setEditButtonStatus}
          msg={msg}
          setMsg={setMsg}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          addFloorOpen={addFloorOpen}
          colArray={colArray}
          setColArray={setColArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            /* maxWidth: { md: "900px", lg: "992px", xl: "100%" }, */
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={floorLists?.length ? floorLists?.length : 0}
              colArray={colArray}
            />
            {loading ? (
              <TableCell colSpan={9} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(floorLists, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((floorList) => {
                    const isItemSelected = isSelected(floorList.id);
                    const labelId = `enhanced-table-checkbox-${floorList.id}`;
                    return (
                      <Floorrow
                        key={floorList.id}
                        floorList={floorList}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        floorId={floorId}
                        // floorId={floorList.id}
                        handleActionClick={handleActionClick}
                        handleActionClose={handleActionClose}
                        anchorEl={anchorEl}
                        StyledMenu={StyledMenu}
                        handleDeleteOpen={handleDeleteOpen}
                        setUpdateValue={setUpdateValue}
                        editButtonStatus={editButtonStatus}
                        setEditButtonStatus={setEditButtonStatus}
                        msg={msg}
                        setMsg={setMsg}
                        showFloorList={showFloorList}
                        editFloor={editFloor}
                      />
                    );
                  })}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                {totalRecord === 0 && (
                  <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                    <TableCell colSpan={9} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {/* Component for Add/Edit dialog */}
      {addFloorDialog && (
        <AddEditFloor
          addFloorDialog={addFloorDialog}
          setAddFloorDialog={setAddFloorDialog}
          addFloorOpen={addFloorOpen}
          addFloorClose={addFloorClose}
          showFloorList={showFloorList}
          editFloorData={editFloorData}
          editButtonStatus={editButtonStatus}
          floorId={floorId}
          pageLoader={pageLoader}
          setPageLoader={setPageLoader}
        />
      )}
    </>
  );
}

function FloorPlan() {
  return (
    <React.Fragment>
      <Helmet title="Floor Plans" />
      <WithPermissionFallback controller="Property" action="getFloors">
        <Typography variant="h3" gutterBottom display="inline">
          Floor Plans
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default FloorPlan;
