import { Button, IconButton, Typography, Grid, Avatar } from "@mui/material";
import React from "react";
import TaskListDialog from "./TaskListDialog";

import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components/macro";
import {
  Help as HelpIcon,
  Error as ErrorIcon,
  Whatshot as WhatshotIcon,
  ChatOutlined as ChatOutlinedIcon,
} from "@mui/icons-material";
import hasPermission from "../../../utils/hasPermission";

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 5px;
  min-height: 115px;
  border-radius: 5px;
  border-top: 5px solid green;
  max-width: 100%;
  /* background: ${({ isDragging }) =>
    isDragging ? "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: white;
  margin-top: 15px;
  margin: 15px;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

const TaskCard = ({ item, index, module }) => {
  const [taskListDilaog, setTaskListDilaog] = React.useState(false);
  const taskListDilaogOpen = () => {
    setTaskListDilaog(true);
  };
  const taskListDilaogClose = () => {
    setTaskListDilaog(false);
  };
  return (
    <>
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={!hasPermission("ModuleTask", "moveKanbanItem")}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TaskInformation onClick={taskListDilaogOpen}>
              <Typography variant="body2">{item.title}</Typography>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  {item.status === 1 && (
                    <>
                      {item.urgent && (
                        <IconButton title="Urgent">
                          <WhatshotIcon fontSize="small" />
                        </IconButton>
                      )}
                      {item.problem && (
                        <IconButton title="Problem">
                          <ErrorIcon fontSize="small" />
                        </IconButton>
                      )}
                      {item.question && (
                        <IconButton title="Question">
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  )}

                  <IconButton title={"Comments (" + item.comments_count + ")"}>
                    <ChatOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 0,
                      padding: 0,
                      marginLeft: "50px",
                      marginTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {item.tags}
                  </Button>
                  <Typography
                    variant="caption"
                    sx={{
                      marginLeft: "18px",
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    {item.due_date}
                    <Avatar
                      sx={{
                        width: "25px",
                        height: "25px",
                        marginLeft: "5px",
                      }}
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </TaskInformation>
          </div>
        )}
      </Draggable>
      {taskListDilaog && (
        <TaskListDialog
          taskListDilaog={taskListDilaog}
          setTaskListDilaog={setTaskListDilaog}
          taskListDilaogOpen={taskListDilaogOpen}
          taskListDilaogClose={taskListDilaogClose}
          itemData={item}
          module={module}
        />
      )}
    </>
  );
};

export default TaskCard;
