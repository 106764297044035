import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import BadgePrint from "./inner-component/BadgePrint";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import axios from "axios";
import {
  ViewVisitor,
  VisitorCompanyListDropdown,
  VisitorTypeListDropdown,
  VisitorDepartmentList,
  CheckInVisitor,
  CheckOutVisitor,
  SaveVisitorDetails,
  AutoCompleteHostName,
} from "../../api";
import * as Yup from "yup";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  // Autocomplete,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItem,
  List,
  ListItemText,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Radio,
  Paper,
  CircularProgress,
  Chip,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  ListItemIcon,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Search as SearchIcon,
  SettingsOverscan as SettingsOverscanIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

import { Formik } from "formik";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import CheckboxWrapper from "../accounts/components/Checkbox";
import { addYears } from "date-fns";
import { TimePicker } from "@mui/lab";
import moment from "moment";
import CameraFeeding from "../../helper/camera-feeding";
import hasPermission from "../../utils/hasPermission";

const filter = createFilterOptions();
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiButtonBase-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

function AddVisitorForm(props) {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [checkInOutStatus, setCheckInOutStatus] = useState(false);
  //States related to image upload
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();
  const [hostNameList, setHostNameList] = useState([]);
  const [hostName, setHostName] = useState("");

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const classes = useStyles();

  const [editResponseStatus, setEditResponseStatus] = useState(false);
  const [barCodeId, setBarCodeId] = useState("");
  const [viewData, setViewData] = useState({});
  const { id } = useParams();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const viewVisitorDetails = (barId) => {
    setLoading(true);
    let payload = {
      visitor_id: barId,
    };
    ViewVisitor(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          setUploadedImage(res.data.image);
          setCheckInOutStatus(
            res?.data.status == "New Visitor" ||
              res?.data.status == "Pre-book" ||
              res?.data.status == "CHECKED-OUT"
              ? true
              : false
          );
          setLoading(false);
          setEditResponseStatus(true);
          setBarCodeId(res.data.id);
          setHostName(res.data.host_name);
        } else {
          setViewData({});
          setLoading(false);
          setEditResponseStatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  //API call for Company dropdown
  const [companyList, setCompanyList] = useState([]);
  const getCompanyDropdown = () => {
    setLoading(true);
    VisitorCompanyListDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setCompanyList(res.data);
          setLoading(false);
        } else {
          setCompanyList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  //API call for company dropdown
  const [visitorTypeList, setVisitorTypeList] = useState([]);
  const getVisitorTypeDropdown = () => {
    setLoading(true);
    VisitorTypeListDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setVisitorTypeList(res.data);
          setLoading(false);
        } else {
          setVisitorTypeList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  //API for visitor department List
  const [visitorDeptList, setVisitorDeptList] = useState([]);
  const getVisitorDeptDropdown = () => {
    setLoading(true);
    VisitorDepartmentList()
      .then((res) => {
        if (res.statusCode === 200) {
          setVisitorDeptList(res.data);
          setLoading(false);
        } else {
          setVisitorDeptList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  //Method for CheckIn visitor
  const getVisitorCheckIn = () => {
    setLoading(true);
    let payload = {
      visitorId: viewData?.id,
    };

    CheckInVisitor(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setCheckInOutStatus(!checkInOutStatus);
          dispatch(getMsg("Visitor successfully checked in"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setLoading(false);
        } else {
          // setVisitorDeptList([]);
          setLoading(false);
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  //Method for CheckOut visitor
  const getVisitorCheckOut = () => {
    setLoading(true);
    let payload = {
      visitorId: viewData?.id,
    };
    setCheckInOutStatus(!checkInOutStatus);
    CheckOutVisitor(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getMsg("Visitor successfully checked out"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setLoading(false);
        } else {
          // setVisitorDeptList([]);
          setLoading(false);
          dispatch(getMsg(res.message));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  const initialValues = {
    id: viewData ? (viewData.id ? viewData.id : "") : "",
    name: viewData ? (viewData.name ? viewData.name : "") : "",
    email: viewData ? (viewData.email ? viewData.email : "") : "",
    visitor_company_id: viewData
      ? viewData.visitor_company_id
        ? viewData.visitor_company_id
        : ""
      : "",
    address1: viewData ? (viewData.address1 ? viewData.address1 : "") : "",
    address2: viewData ? (viewData.address2 ? viewData.address2 : "") : "",
    city: viewData ? (viewData.city ? viewData.city : "") : "",
    state: viewData ? (viewData.state ? viewData.state : "") : "",
    zip: viewData ? (viewData.zip ? viewData.zip : "") : "",
    phone: viewData ? (viewData.phone ? viewData.phone : "") : "",
    fax: viewData ? (viewData.fax ? viewData.fax : "") : "",
    visitor_type_id: viewData
      ? viewData.visitor_type_id
        ? viewData.visitor_type_id
        : ""
      : "",
    visitor_escort: viewData
      ? viewData.visitor_escort
        ? viewData.visitor_escort
        : ""
      : "",
    vehicle_make: viewData
      ? viewData.vehicle_make
        ? viewData.vehicle_make
        : ""
      : "",
    vehicle_model: viewData
      ? viewData.vehicle_model
        ? viewData.vehicle_model
        : ""
      : "",
    vehicle_license_number: viewData
      ? viewData.vehicle_license_number
        ? viewData.vehicle_license_number
        : ""
      : "",
    host_name: viewData
      ? viewData.host_name
        ? viewData.host_name
        : null
      : null,
    department_id: viewData
      ? viewData.host_department_id
        ? viewData.host_department_id
        : ""
      : "",
    contact_host_upon_arrival: viewData
      ? viewData.contact_host_upon_arrival == "Yes"
        ? true
        : false
      : false,
    host_email: viewData
      ? viewData.host_email
        ? viewData.host_email
        : ""
      : "",
    mobile_no: viewData
      ? viewData.host_mobile_no
        ? viewData.host_mobile_no
        : ""
      : "",
    purpose_of_visit: viewData
      ? viewData.purpose_of_visit
        ? viewData.purpose_of_visit
        : ""
      : "",
    secured_by: viewData
      ? viewData.secured_by
        ? viewData.secured_by
        : null
      : null,
    storage_location: viewData
      ? viewData.storage_location
        ? viewData.storage_location
        : ""
      : "",
    instruction_upon_arrival: viewData
      ? viewData.instruction_upon_arrival
        ? viewData.instruction_upon_arrival
        : ""
      : "",
    note: viewData ? (viewData.note ? viewData.note : "") : "",
    visitor_auth_start_date: viewData
      ? viewData.visitor_auth_start_date
        ? viewData.visitor_auth_start_date
        : new Date()
      : new Date(),
    visitor_auth_start_time: viewData
      ? viewData.visitor_auth_start_date
        ? viewData.visitor_auth_start_date
        : new Date()
      : new Date(),
    visitor_auth_end_date: viewData
      ? viewData.visitor_auth_end_date
        ? viewData.visitor_auth_end_date
        : addYears(new Date(), 1)
      : addYears(new Date(), 1),
    visitor_auth_end_time: viewData
      ? viewData.visitor_auth_end_date
        ? viewData.visitor_auth_end_date
        : new Date()
      : new Date(),
    email_management: viewData
      ? viewData.email_management
        ? viewData.email_management
        : false
      : false,
  };

  const validationSchema = Yup.object().shape({
    // pad_expiry: Yup.string().nullable().required("Pad expiry date is required"),
    // battery_expiry: Yup.string()
    //   .nullable()
    //   .required("Battery expiry date is required"),
  });
  const getAutoCompleteHostName = (event, value) => {
    // Cancel the previous request (if it exists)
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled by the user.");
    }

    // Create a new cancel token source
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    let payload = {
      term: value,
    };
    AutoCompleteHostName(payload, {
      cancelToken: newCancelTokenSource.token,
    })
      .then((response) => {
        setHostNameList(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was canceled, no need to handle errors here
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  function isValidURL(url) {
    try {
      new URL(url);
      return !url.startsWith("data:");
    } catch (_) {
      return false;
    }
  }

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let host_name = "";
    if (values.host_name?.id) {
      host_name = values.host_name?.name;
    } else if (values.host_name?.inputValue) {
      host_name = values.host_name?.inputValue;
    } else {
      host_name = values?.host_name;
    }
    setLoading(true);
    let formatVisitorStartDate = values.visitor_auth_start_date
      ? moment(values.visitor_auth_start_date).format("YYYY-MM-DD")
      : "";
    let formatVisitorStartTime = values.visitor_auth_start_time
      ? moment(values.visitor_auth_start_time).format("HH:mm:ss")
      : "";
    let formatVisitorEndDate = values.visitor_auth_end_date
      ? moment(values.visitor_auth_end_date).format("YYYY-MM-DD")
      : "";
    let formatVisitorEndTime = values.visitor_auth_end_time
      ? moment(values.visitor_auth_end_time).format("HH:mm:ss")
      : "";
    let payload = {
      id: viewData.id,
      name: values.name,
      email: values.email,
      visitor_company_id: values.visitor_company_id,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      fax: values.fax,
      visitor_type_id: values.visitor_type_id,
      visitor_escort: values.visitor_escort,
      vehicle_make: values.vehicle_make,
      vehicle_model: values.vehicle_model,
      vehicle_license_number: values.vehicle_license_number,
      host_name: host_name,
      department_id: values.department_id,
      contact_host_upon_arrival: values.contact_host_upon_arrival,
      host_email: values.host_email,
      mobile_no: values.mobile_no,
      purpose_of_visit: values.purpose_of_visit,
      secured_by: values.secured_by,
      storage_location: values.storage_location,
      instruction_upon_arrival: values.instruction_upon_arrival,
      note: values.note,
      visitor_auth_start_date: formatVisitorStartDate,
      visitor_auth_start_time: formatVisitorStartTime,
      visitor_auth_end_date: formatVisitorEndDate,
      visitor_auth_end_time: formatVisitorEndTime,
      email_management: values.email_management,
      image: isValidURL(uploadedImage) ? null : uploadedImage,
    };
    console.log("uploadedImage", uploadedImage);
    SaveVisitorDetails(payload)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          dispatch(getMsg("Visitor Updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getCompanyDropdown();
    getVisitorTypeDropdown();
    getVisitorDeptDropdown();
    if (id) {
      viewVisitorDetails(id);
    }
  }, []);

  useEffect(() => {
    // Cleanup the cancel token source when the component unmounts
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted.");
      }
    };
  }, []);

  const today = new Date().toLocaleDateString();

  // Badge print Dialog
  const [badgePrintDialog, setBadgePrintDialog] = useState(false);
  const badgePrintDialogOpen = () => {
    setBadgePrintDialog(true);
  };
  const badgePrintDialogClose = () => {
    setBadgePrintDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="span" component="p" color="primary">
                      Visitor Information
                    </Typography>
                    {/* <Button
                  variant="outlined"
                  color="warning"
                  component="span"
                  onClick={scanBarcodeOpen}
                >
                  <SettingsOverscanIcon sx={{ marginRight: "7px" }} /> Scan 2D
                  barcode
                </Button> */}
                  </Box>
                  {/* <Dialog
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "600px",
                    },
                  },
                }}
                open={scanbarcode}
                onClose={scanBarcodeClose}
                aria-labelledby="scan-barcode-modal"
                aria-describedby="scan-barcode-modal"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "0px",
                  }}
                >
                  <Typography variant="h4" component="div">
                    Scan 2D Barcode
                  </Typography>
                  <DialogActions sx={{ padding: "0px" }}>
                    <IconButton
                      sx={{ marginRight: "-10px" }}
                      onClick={() => setScanBarcode(false)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </DialogActions>
                </DialogTitle>
                <DialogContent sx={{ padding: "0px" }}>
                  <DialogContentText
                    id="search-modal"
                    sx={{ padding: "20px 25px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Item sx={{ flexGrow: 1, width: "100%" }}>
                        <FormControl>
                          <TextField
                            id="scan-barcode"
                            placeholder="Scan 2D barcode"
                          />
                        </FormControl>
                      </Item>
                      <Item>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            height: "100%",
                            marginLeft: "-3px",
                          }}
                        >
                          <SearchIcon />
                        </Button>
                      </Item>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog> */}
                  {/* <FormControl mt={4}>
                <InputLabel
                  shrink
                  id="v-status"
                  sx={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    marginLeft: "-4px",
                  }}
                >
                  Visitor status
                </InputLabel>
                <Select
                  labelId="v-status"
                  id="v-status"
                  value={visitorstatus}
                  label="Visitor status"
                  onChange={selectVisitorStatus}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">Please select</MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl> */}

                  <FormControl mt={6}>
                    <CustomTextField
                      name="id"
                      id="barcode-id"
                      label="Barcode ID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          viewVisitorDetails(values.id);
                        }
                      }}
                      placeholder="Scan visitor ID"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="name"
                      id="v-name"
                      label="Visitor name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="email"
                      id="email"
                      label="Email address"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <FormControl
                    mt={6}
                    sx={{
                      ".Mui-disabled": {
                        cursor: "not-allowed !important",
                      },
                    }}
                  >
                    <CustomSelect
                      name="visitor_company_id"
                      fieldLabel="Company (edit visitor to change) *"
                      firstOptionTitle="Enter visitor ID above"
                      options={companyList}
                      displayEmpty
                      disabled
                    />
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {touched.visitor_company_id && errors.visitor_company_id}
                    </FormHelperText>
                  </FormControl>
                  <Box className={classes.accordion}>
                    <Accordion defaultExpanded="true">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="visitor-other-info"
                        id="panel-header"
                      >
                        <Typography>Visitor Other Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl mt={3}>
                          <CustomTextField
                            name="address1"
                            id="address-one"
                            label="Visitor address1"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Edit visitor record to change these values"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="address2"
                            id="address-2"
                            label="Visitor address2"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="city"
                            id="city"
                            label="City"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="state"
                            id="state"
                            label="State"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        {/* <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="country"
                        label="Country"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="USA"
                      />
                    </FormControl> */}
                        <FormControl mt={6}>
                          <CustomTextField
                            name="zip"
                            id="zip"
                            label="Zip"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="phone"
                            id="phone"
                            label="Phone"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="fax"
                            id="fax"
                            label="Fax"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="visitor_type_id"
                            fieldLabel="Visitor type"
                            firstOptionTitle="Please select"
                            options={visitorTypeList}
                            displayEmpty
                          />
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {touched.visitor_type_id && errors.visitor_type_id}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <InputLabel
                            shrink
                            id="escort"
                            sx={{
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              marginLeft: "-4px",
                            }}
                          >
                            Visitor requires escort
                          </InputLabel>
                          <Select
                            labelId="escort"
                            id="escort"
                            name="visitor_escort"
                            value={values.visitor_escort}
                            label="Visitor requires escort"
                            onChange={(e) =>
                              setFieldValue("visitor_escort", e.target.value)
                            }
                            error={Boolean(
                              touched.visitor_escort && errors.visitor_escort
                            )}
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="">Please select</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          <FormHelperText error>
                            {touched.visitor_escort && errors.visitor_escort}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="vehicle_make"
                            id="v-make"
                            label="Vehicle make"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="vehicle_model"
                            id="v-model"
                            label="Vehicle model"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="vehicle_license_number"
                            id="v-license-plate"
                            label="Vehicle license plate"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Typography
                    variant="span"
                    component="p"
                    color="primary"
                    sx={{ marginTop: "20px" }}
                  >
                    Visit Details
                  </Typography>
                  <FormControl mt={4}>
                    <Autocomplete
                      value={hostName}
                      onChange={(event, newValue) => {
                        setFieldValue("host_name", newValue);
                        setHostName(newValue);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      onInputChange={(e, v) => {
                        setFieldValue("host_name", v);
                        setHostName(v);
                        getAutoCompleteHostName(e, v);
                      }}
                      selectOnFocus
                      handleHomeEndKeys
                      id="h-name"
                      name="host_name"
                      options={hostNameList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }

                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label="Host name" />
                      )}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomSelect
                      name="department_id"
                      fieldLabel="Host department"
                      firstOptionTitle="Please select"
                      options={visitorDeptList}
                      displayEmpty
                    />
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {touched.department_id && errors.department_id}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    mt={6}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        margin: "0 0 5px",
                      },
                    }}
                  >
                    <FormHelperText>
                      <CheckboxWrapper
                        name="contact_host_upon_arrival"
                        checked={values.contact_host_upon_arrival}
                        disableRipple
                        label="Contact host upon arrival"
                        // returnOnChange={(evt) => {
                        //   if (evt.target.checked) {
                        //     setFieldValue("threat_level", "");
                        //     setFieldValue("poi_alias", "");
                        //   }
                        // }}
                        // disabled={personActionStatus}
                      />
                      <StyledTooltip
                        sx={{
                          verticalAlign: "middle",
                          marginLeft: "6px",
                          marginRight: "6px",
                          marginBottom: "-20px",
                          cursor: "pointer",
                        }}
                        placement="top-end"
                        title={
                          <Typography>
                            If box is checked, host will receive an email when
                            visitor checks in
                          </Typography>
                        }
                      >
                        <ErrorIcon color="primary" />
                      </StyledTooltip>
                    </FormHelperText>
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="host_email"
                      id="h-email"
                      label="Host email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com - required if box above is checked"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="mobile_no"
                      id="h-mobile"
                      label="Host mobile number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="XXX-XXX-XXXX"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="purpose_of_visit"
                      id="visit-purpose"
                      label="Purpose of visit"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Bldg repair, job interview, vendor delivery, etc."
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Storing Equipment</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="storing-equipment"
                      name="secured_by"
                      value={values.secured_by}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Visitor"
                        control={<Radio />}
                        label="Secured by visitor"
                      />
                      <FormControlLabel
                        value="Host"
                        control={<Radio />}
                        label="Secured by host"
                      />
                    </RadioGroup>
                    {/* <Grid container spacing={4}>
                      <Grid item xs={6} lg={6}>
                        <CheckboxWrapper
                          name="secured_by"
                          checked={values.secured_by}
                          disableRipple
                          label="Secured by visitor"
                          // returnOnChange={(evt) => {
                          //   if (evt.target.checked) {
                          //     setFieldValue("threat_level", "");
                          //     setFieldValue("poi_alias", "");
                          //   }
                          // }}
                          // disabled={personActionStatus}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <CheckboxWrapper
                          name="storage_location"
                          checked={values.storage_location}
                          disableRipple
                          label="Secured by host"
                          // returnOnChange={(evt) => {
                          //   if (evt.target.checked) {
                          //     setFieldValue("threat_level", "");
                          //     setFieldValue("poi_alias", "");
                          //   }
                          // }}
                          // disabled={personActionStatus}
                        />
                      </Grid>
                    </Grid> */}
                  </FormControl>
                  {values.secured_by == "Host" && (
                    <FormControl mt={6}>
                      <CustomTextField
                        label="Storage Location"
                        id="notify-upon-arrival"
                        name="storage_location"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                      />
                    </FormControl>
                  )}
                  <FormControl mt={6}>
                    <CustomTextField
                      name="instruction_upon_arrival"
                      id="arrival-instruction"
                      label="Instruction upon arrival"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Call host, send to waiting area, etc."
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {today >=
                    new Date(
                      viewData?.visitor_auth_start_date
                    ).toLocaleDateString() &&
                  today <
                    new Date(
                      viewData?.visitor_auth_end_date
                    ).toLocaleDateString() ? (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      alignSelf="center"
                      // order={{ xs: 1, md: 3 }}
                    >
                      {viewData?.visitor_company_status == "D" ? (
                        <Tooltip
                          title={`Visitor company is blacklisted!`}
                          followCursor
                        >
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            ACCESS DENIED
                          </Typography>
                        </Tooltip>
                      ) : viewData?.active == "I" ? (
                        <Tooltip title={`Visitor is blacklisted!`} followCursor>
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            ACCESS DENIED
                          </Typography>
                        </Tooltip>
                      ) : viewData?.in_watch_list?.length > 0 ? (
                        <Typography
                          sx={{ color: "red" }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          ACCESS DENIED
                        </Typography>
                      ) : viewData.active == "A" ? (
                        <Typography
                          sx={{ color: "lightgreen" }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          ACCESS APPROVED
                        </Typography>
                      ) : viewData.visitor_company_status == "A" ? (
                        <Typography
                          sx={{ color: "lightgreen" }}
                          variant="h3"
                          component="div"
                          gutterBottom
                        >
                          ACCESS APPROVED
                        </Typography>
                      ) : today <
                          new Date(
                            viewData?.visitor_auth_start_date
                          ).toLocaleDateString() ==
                        true ? (
                        <Tooltip
                          title={`Pass issued for future date!`}
                          followCursor
                        >
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            ACCESS DENIED
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={`Visitor pass has expired!`}
                          followCursor
                        >
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            ACCESS DENIED
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      alignSelf="center"
                      // order={{ xs: 1, md: 3 }}
                    >
                      {today <
                        new Date(
                          viewData?.visitor_auth_start_date
                        ).toLocaleDateString() ==
                      true ? (
                        <Tooltip
                          title={`Pass issued for future date!`}
                          followCursor
                        >
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            {viewData.id ? "ACCESS DENIED" : ""}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={`Visitor pass has expired!`}
                          followCursor
                        >
                          <Typography
                            sx={{ color: "red" }}
                            variant="h3"
                            component="div"
                            gutterBottom
                          >
                            {viewData.id ? "ACCESS DENIED" : ""}
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                  )}

                  <List
                    sx={{
                      padding: "0",
                      "& .MuiListItem-root": {
                        padding: "0",
                        /* "& .MuiListItemText-root": {
                      marginTop: "0",
                      marginBottom: "0",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11.5px",
                    }, */
                      },
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormLabel>
                            Status:{" "}
                            {viewData?.status == "CHECKED-IN" ? (
                              <Chip label="CHECKED IN" color="success" />
                            ) : viewData?.status == "CHECKED-OUT" ? (
                              <Chip label="CHECKED OUT" color="primary" />
                            ) : viewData?.status == "EXPECTED" ? (
                              <Chip label="EXPECTED" color="warning" />
                            ) : viewData?.status == "IN-PROCESS" ? (
                              <Chip label="IN-PROCESS" color="secondary" />
                            ) : null}
                          </FormLabel>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormLabel>
                            Last processed:{" "}
                            {viewData?.created
                              ? viewData?.created
                              : viewData?.checked_in_time !=
                                "0000-00-00 00:00:00"
                              ? viewData?.checked_in_time
                              : viewData?.checked_out_time !=
                                "0000-00-00 00:00:00"
                              ? viewData?.checked_out_time
                              : viewData?.createdAt}
                          </FormLabel>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormLabel>
                            Requires escort: {viewData?.visitor_escort}{" "}
                          </FormLabel>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <FormLabel>
                            Equipment permissions:{" "}
                            {viewData?.equipment_type_id !== null
                              ? "YES"
                              : "NO"}{" "}
                          </FormLabel>
                        }
                      />
                    </ListItem>
                  </List>
                  <FormControl mt={6}>
                    <CustomTextField
                      fullWidth
                      id="note"
                      label="Notes"
                      name="note"
                      multiline
                      rows={4}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Comments about the visit, vendor or company, etc. or any other information can go here"
                    />
                  </FormControl>
                  <FormControl>
                    {uploadedImage ? (
                      <BigAvatar alt="Remy Sharp" src={uploadedImage} />
                    ) : (
                      <BigAvatar
                        alt="Remy Sharp"
                        src="/static/img/avatars/profile-upload.png"
                      />
                    )}

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        JustifyContent: "space-between",
                        margin: "10px 0 15px",
                        gap: "15px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        onClick={(e) => clearSelectedImage(e)}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="outlined"
                        color="warning"
                        component="span"
                        onClick={() => handleShowWebCam()}
                      >
                        {showWebCam ? "Close WebCam" : "WebCam"}
                      </Button>
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                      >
                        Capture
                      </Button> */}
                    </Box>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      ref={uploadedFileRef}
                      onChange={(e) => handleFileChange(e)}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Choose image
                      </Button>
                    </label>
                  </FormControl>

                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                      <FormControl mt={6}>
                        <DatePicker
                          id="pass-issue-date"
                          label="Pass issue date"
                          value={values.visitor_auth_start_date}
                          onChange={(newValue) => {
                            setFieldValue("visitor_auth_start_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="visitor_auth_start_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sx={12} lg={6}>
                      <FormControl mt={6}>
                        <TimePicker
                          label="Pass issue time"
                          id="issue-time"
                          value={values.visitor_auth_start_time}
                          onChange={(newValue) => {
                            setFieldValue("visitor_auth_start_time", newValue);
                          }}
                          inputFormat="HH:mm"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="visitor_auth_start_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl mt={1}>
                        <DatePicker
                          id="pass-issue-date"
                          label="Pass expiry date"
                          value={values.visitor_auth_end_date}
                          onChange={(newValue) => {
                            setFieldValue("visitor_auth_end_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="visitor_auth_end_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sx={12} lg={6}>
                      <FormControl mt={1}>
                        <TimePicker
                          label="Pass expiry time"
                          id="expiry-time"
                          value={values.visitor_auth_end_time}
                          onChange={(newValue) => {
                            setFieldValue("visitor_auth_end_time", newValue);
                          }}
                          inputFormat="HH:mm"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="visitor_auth_end_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item sx={12} lg={12}>
                      <FormControl mt={3}>
                        <CheckboxWrapper
                          name="email_management"
                          checked={values.email_management}
                          disableRipple
                          label="Send email if visitor is still checked in after pass expiry date"
                          // returnOnChange={(evt) => {
                          //   if (evt.target.checked) {
                          //     setFieldValue("comment_about_food_item", "");
                          //   }
                          // }}
                        />
                      </FormControl>

                      {/* <Card
                        variant="outlined"
                        sx={{
                          borderBottom: 0,
                        }}
                      >
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell sx={{ py: 0 }}>processed</TableCell>
                                <TableCell sx={{ py: 0 }} align="right">
                                  fvgbfb
                                </TableCell>
                                <TableCell sx={{ py: 0 }} align="right">
                                  fb fg b
                                </TableCell>
                                <TableCell sx={{ py: 0 }} align="right">
                                  <IconButton
                                    aria-label="Settings"
                                    size="large"
                                    aria-owns={
                                      anchorEl ? "icon-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleActionClick}
                                    style={{ color: "#1374C5" }}
                                  >
                                    <SettingsIcon />
                                  </IconButton>
                                  <StyledMenu
                                    id="icon-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleActionClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuItem>
                                      <ListItemIcon>
                                        <VisibilityIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="View" pl={1} />
                                    </MenuItem>
                                    <MenuItem>
                                      <ListItemIcon>
                                        <EditIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Check-In" pl={1} />
                                    </MenuItem>
                                  </StyledMenu>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card> */}
                    </Grid>
                  </Grid>

                  {viewData?.id && (
                    <Box sx={{ display: "flex" }}>
                      {checkInOutStatus ? (
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mr: 3 }}
                          onClick={getVisitorCheckIn}
                          disabled={
                            !hasPermission("Visitor", "changeStatusToCheckIn")
                          }
                        >
                          <LoginIcon sx={{ mr: 2 }} /> Check-In
                        </Button>
                      ) : (
                        <Button
                          color="success"
                          variant="outlined"
                          onClick={getVisitorCheckOut}
                          disabled={
                            !hasPermission("Visitor", "changeStatusToCheckOut")
                          }
                        >
                          <LogoutIcon sx={{ mr: 2 }} /> Check Visitor Out
                        </Button>
                      )}
                    </Box>
                  )}
                </Grid>
                {viewData?.id ? (
                  <Grid item xs={12} md={12}>
                    <Box sx={{ diplay: "flex" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        mt={6}
                        disabled={
                          !hasPermission("Visitor", "saveVisitorDetail")
                        }
                      >
                        Update Visitor
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        mt={6}
                        sx={{ marginLeft: "10px" }}
                        onClick={badgePrintDialogOpen}
                      >
                        Print
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          )}
        </Formik>
        {showWebCam && (
          <CameraFeeding
            showWebCam={showWebCam}
            closeWabCam={() => setShowWebCam(false)}
          />
        )}
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
        {badgePrintDialog && (
          <BadgePrint
            badgePrintDialog={badgePrintDialog}
            setBadgePrintDialog={setBadgePrintDialog}
            badgePrintDialogOpen={badgePrintDialogOpen}
            badgePrintDialogClose={badgePrintDialogClose}
            barCodeId={barCodeId}
            viewData={viewData}
            addVisitorKey={false}
          />
        )}
      </CardContent>
    </Card>
  );
}

function ProcessVisitor() {
  return (
    <React.Fragment>
      <Helmet title="Process Visitor" />
      <Typography variant="h3" gutterBottom display="inline">
        Process Visitor
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/visitors/dashboard">
          Dashboard
        </Link>
        <Link component={NavLink} to="/visitors/list-visitors">
          Visitor List
        </Link>
        <Typography>Process Visitor</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <AddVisitorForm />
    </React.Fragment>
  );
}

export default ProcessVisitor;
