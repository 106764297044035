import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import { handleApiResonseErrors } from "../../helper/helper";
import { useDispatch } from "react-redux";
import {
  DepartmentList,
  AddUpdateVisitorWatch,
  ViewVisitorWatch,
} from "../../api";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  Box,
  Menu,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItem,
  List,
  ListItemText,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  Paper,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, DatePicker } from "@mui/lab";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Search as SearchIcon,
  SettingsOverscan as SettingsOverscanIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiButtonBase-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "235px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "460px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddVisitorForm(props) {
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // Visitor department
  const [department, setDepartment] = React.useState("");
  const selectDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const classes = useStyles();

  const [viewWatch, setViewWatch] = useState({});
  const viewVisitorWatch = () => {
    ViewVisitorWatch(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewWatch(res.data);
          setLoading(false);
        } else {
          setViewWatch({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  useEffect(() => {
  //    viewVisitorCompany();
  //    setLoading(true);
  //  }, []);

  const getDepartmentList = () => {
    DepartmentList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getDepartmentList();
    viewVisitorWatch();
    setLoading(true);
  }, []);

  const { visitor_name, expiry_date, department_id, added_by, reason } =
    viewWatch;
  //Add Company Select Control
  const initialValues = {
    visitor_name: visitor_name ? visitor_name : "",
    person_requesting: added_by ? added_by : "",
    department_id: department_id ? department_id : "",
    expiry_date: Date(expiry_date) ? new Date(expiry_date) : null,
    reason: reason ? reason : "",
  };
  const validationSchema = Yup.object().shape({
    visitor_name: Yup.string().required("Please enter visitor name"),
    person_requesting: Yup.string().required("Please enter person name"),
    reason: Yup.string().required("Please enter reason"),
    expiry_date: Yup.string()
      .nullable()
      .required("Please select the expiry date"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        visitor_name: values.visitor_name,
        department_id: values.department_id,
        reason: values.reason,
        expiry_date:
          values.expiry_date !== null
            ? format(values.expiry_date, "yyyy-MM-dd HH:mm:ss")
            : expiry_date,
        added_by: values.person_requesting,
      };
      AddUpdateVisitorWatch(payload)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getMsg("Record udpated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
            navigate({
              pathname: "/visitors/visitor-watch-list",
            });
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setLoading(false);
          }
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setLoading(false);
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <CustomTextField
                      fullWidth
                      id="visitor-name"
                      label="Visitor name"
                      name="visitor_name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Hillari Cliton"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      fullWidth
                      id="person-requesting"
                      label="Person requesting"
                      name="person_requesting"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Darin R"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <DateTimePicker
                      id="date-range"
                      name="expiry_date"
                      label="Expiry Date"
                      ampm={false}
                      value={values.expiry_date}
                      onChange={(newValue) => {
                        setFieldValue("expiry_date", newValue);
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          fullWidth
                          name="expiry_date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm/dd/yyyy H:i:s",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomSelect
                      name="department_id"
                      fieldLabel="Department"
                      options={departmentList}
                      displayEmpty
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomTextField
                    fullWidth
                    id="reason"
                    label="Reason"
                    name="reason"
                    multiline
                    rows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="This is a test. Hillery is now added to the watch list. if you create visitor record by this name, a warning will appear with this message."
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    mt={6}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
        </Card>
      )}
    </Formik>
  );
}

function EditVisitorWatch() {
  return (
    <React.Fragment>
      <Helmet title="Edit Visitor Watch" />
      <WithPermissionFallback
        controller="Visitor"
        action="addUpdateVisitorWatch"
      >
        <Typography variant="h3" gutterBottom display="inline">
          Edit Visitor Watch
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/visitors/visitor-watch-list">
            Watch List
          </Link>
          <Typography>Edit Visitor Watch</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddVisitorForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditVisitorWatch;
