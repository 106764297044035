import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CustomAlert from "../../components/form-components/CustomAlert";

import {
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TextField as MuiTextField,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Button,
  Typography,
  FormGroup,
  ListItemIcon,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Select,
  Switch,
  Checkbox,
  DialogActions,
  Dialog,
  DialogTitle,
  CircularProgress,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  ViewList as ViewListIcon,
  AddBox as AddBoxIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { spacing } from "@mui/system";
import Tablerow from "./components/Tablerow";
import SnackToolbar from "./components/SnackToolbar";
import { useDispatch, useSelector } from "react-redux";
// import { hideLoader } from "../../redux/slices/counter";
import { getChecked, getSnackClose } from "../../redux/slices/propertySlice";
import {
  GetPropertyList,
  DeleteProperty,
  PropertyListColumns,
} from "../../api";
import { handleApiResonseErrors } from "../../helper/helper";
import Toaster from "../tasks/Toaster";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import hasPermission from "../../utils/hasPermission";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 100%;
`;

// const TextField = styled(TextFieldSpacing)`
//   width: 100%;
// `;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 2%) 0px 0px 6px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
                whiteSpace: "initial",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.property.checked);

  const {
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all property" }}
          />
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            padding={headCell.key === "property_name" ? "none" : "normal"}
            //sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={
                headCell.key === "property_name"
                  ? orderBy === headCell.key
                  : false
              }
              // active={orderBy === headCell.id}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "property_name"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "property_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const [actions, setActions] = useState("");
  const [searchData, setSearchData] = useState("");

  const checkedValue = useSelector((state) => state.property.checked);
  const dispatch = useDispatch();
  const {
    handleDeleteOpen,
    totalRecord,
    setSearchInput,
    handleFirstPage,
    colArray,
    getColumnList,
    getColumnChecked,
    setCurrentPage,
  } = props;
  const handleChange = (event) => {
    setActions(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enterPress = () => {
    setSearchInput(searchData);
    // handleFirstPage();
    setCurrentPage(1);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getChecked([...checkedValue, value]));
    } else {
      dispatch(getChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
              onChange={handleChange}
            >
              <MenuItem
                disabled={!hasPermission("Property", "deleteProperties")}
                value={10}
                onClick={handleDeleteOpen}
              >
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} record found
            {/* {numSelected} */}
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="standard-search"
              placeholder="Search by anything."
              type="text"
              variant="outlined"
              fullWidth
              // size="small"
              onChange={(e) => setSearchData(e.target.value)}
              value={searchData}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  enterPress();
                }
              }}
              InputProps={{
                sx: {
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                },

                endAdornment: searchData && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setSearchInput("");
                      setSearchData("");
                      setCurrentPage(1);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                ),
              }}
            />
          </Item>
          <Item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSearchInput(searchData);
                // handleFirstPage();
                setCurrentPage(1);
              }}
              sx={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                height: "100%",
                marginLeft: "-10px",
              }}
            >
              <SearchIcon />
              {/* Search */}
            </Button>
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-property"
              component={NavLink}
              to="/accounts/add-property"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Property" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="list-corporate"
              component={NavLink}
              to="/accounts/list-corporate"
            >
              <ListItemIcon>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="List Corporate" pl={0} />
            </MenuItem>
            <MenuItem
              aria-label="add-corporate"
              component={NavLink}
              to="/accounts/add-corporate"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Corporate" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("corporate");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lists, setLists] = useState([]); //state for storing list items
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchInput, setSearchInput] = useState(""); //state for search in tableheadtoolbar
  const [loading, setLoading] = useState(true); //State for loader
  const [snackToolbarOpen, setsnackToolbarOpen] = useState(false);
  const [recordLength, setrecordLength] = useState(null);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const checkedValue = useSelector((state) => state.property.checked);
  const dispatch = useDispatch();
  //for snackbar
  const snackbarStatus = useSelector((state) => state.property.snackStatus);
  const snackbarMsg = useSelector((state) => state.property.snackMsg);
  const snackbarAlert = useSelector((state) => state.property.snackAlert);

  // Alert Message
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //handlers for snackbar...
  const handleSnackToolbarClick = () => {
    setsnackToolbarOpen(true);
  };
  const handleSnackToolbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackToolbarOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lists.map((n) => n.location_id);
      setSelected(newSelecteds);
      console.log(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, propname) => {
    const selectedIndex = selected.indexOf(propname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, propname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setrecordLength(newSelected.length);
    console.log(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    setCurrentPage(newPage);
    console.log(newPage);
    //setCurrentPage(newPage + 1);
    setSelected([]);
    //showPropertyList();
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("changes:::", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setSelected([]);
  };
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteId(id);
    setrecordLength(1);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };
  //Function for showing the property list
  const showPropertyList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      property_search: searchInput,
    };
    GetPropertyList(currentPage, { params: payload })
      // axios
      //   .post(`get-properties?page=${currentPage}`, {
      //     limit: rowsPerPage,
      //     property_search: searchInput,
      //   })
      .then((response) => {
        setLoading(false);
        setLists(response.data);
        // if (response.data.data != "") {
        //   setLists(response.data.data);
        // }
        setCurrentPage(response.meta.current_page);
        setTotalRecord(response.meta.total);
      })
      .catch((err) => {
        setLoading(false);
        setTotalRecord(0);
        console.log(err);
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    // axios
    //   .get(`property-column-list`)
    PropertyListColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    // axios
    //   .get(`property-column-list`)
    PropertyListColumns()
      .then((res) => {
        dispatch(getChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showPropertyList();
    getColumnList();
  }, [rowsPerPage, currentPage, searchInput]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
    // setTimeout(() => {
    //   dispatch(hideLoader());
    // }, 4000);
  }, []);

  //Handler for Single or multiple property delete
  const deleteList = () => {
    setAnchorEl(null);
    let delId = selected.length > 0 ? selected : deleteId;
    DeleteProperty(delId)
      .then((response) => {
        showPropertyList();
        handleDeleteClose();
        handleSnackToolbarClick();
        setSelected([]);
      })
      .catch((err) => {});
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setDeleteId(null);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const isSelected = (propname) => selected.indexOf(propname) !== -1;
  return (
    <div>
      <Paper mt={3}>
        <SnackToolbar
          handleSnackToolbarClose={handleSnackToolbarClose}
          snackToolbarOpen={snackToolbarOpen}
          recordLength={recordLength}
        />
        <div>
          {selected.length || deleteId > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button onClick={(handleDeleteClose, deleteList)} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          deleteList={deleteList}
          stateChanger={setLists}
          handleDeleteOpen={handleDeleteOpen}
          totalRecord={totalRecord}
          setTotalRecord={setTotalRecord}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          showPropertyList={showPropertyList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleFirstPage={handleFirstPage}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            /* maxWidth: { md: "900px", lg: "992px", xl: "100%" }, */
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableCell colSpan={9} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={lists?.length ? lists?.length : 0}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(lists, getComparator(order, orderBy)).map(
                    (list) => {
                      const isItemSelected = isSelected(list.location_id);
                      const labelId = `enhanced-table-checkbox-${list.location_id}`;
                      return (
                        <Tablerow
                          key={list.location_id}
                          list={list}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          handleClick={handleClick}
                          deleteId={deleteId}
                          handleActionClick={handleActionClick}
                          handleActionClose={handleActionClose}
                          anchorEl={anchorEl}
                          StyledMenu={StyledMenu}
                          handleDeleteOpen={handleDeleteOpen}
                          checked={list.status === "A" ? true : false}
                          showAlertModal={(type, msg) =>
                            showAlertModal(type, msg)
                          }
                        />
                      );
                    }
                  )}
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={9} align="center">
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          // page={page}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </div>
  );
}

function PropertyList() {
  // const [snackopen, setSnackopen] = useState(true);
  // const handleSnackClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackopen(false);
  // };
  // const loaderStatus = useSelector((state) => state.counter.loader);
  // const messageStatus = useSelector((state) => state.property.msg);
  return (
    <React.Fragment>
      {/* {loaderStatus && (
        <Snackbar
          open={snackopen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            variant="filled"
            sx={{ maxWidth: 600 }}
          >
            {messageStatus}
          </Alert>
        </Snackbar>
      )} */}
      <Helmet title="Property List" />
      <WithPermissionFallback controller="Property" action="getProperties">
        <Typography variant="h3" gutterBottom display="inline">
          Property List
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default PropertyList;
