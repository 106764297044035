import React, { useState } from "react";
import { IconButton, Button, Box, Switch, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Visibility as VisibilityIcon,
  Fullscreen as FullscreenIcon,
  Cached as CachedIcon,
  Groups as GroupsIcon,
  EmailRounded as EmailRoundedIcon,
  PhotoOutlined as PhotoOutlinedIcon,
  Menu as MenuIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import NonTourTaskDialog from "./NonTourTasksDialog";
import StandardActivityDialog from "./StandardActivityDialog";
import { useNavigate } from "react-router-dom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 60, // Smaller width
  height: 24, // Smaller height
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 2,
    top: "50%",
    transform: "translateY(-50%)",
    "&.Mui-checked": {
      transform: "translateX(36px) translateY(-50%)", // Adjust for smaller switch
      "& + .MuiSwitch-track": {
        "&::before": {
          opacity: 1, // Show "Top"
        },
        "&::after": {
          opacity: 0, // Hide "Right"
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18, // Smaller thumb
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 15, // Adjusted for smaller track
    position: "relative",
    width: "100%",
    height: "100%",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "50%",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "10px", // Smaller font size
      color: "white", // White text for visibility
    },
    "&::before": {
      content: '"Top"',
      left: 2, // Adjusted for smaller size
      opacity: 0,
    },
    "&::after": {
      content: '"Right"',
      right: 2, // Adjusted for smaller size
      opacity: 1,
    },
  },
}));

const MapHeader = () => {
  const navigate = useNavigate();
  const [kanbanMode, setKanbanMode] = useState(true);
  const [nonTourTaskDialog, setNonTourTaskDialog] = useState(false);
  const [standardActivityDialog, setStandardActivityDialog] = useState(false);

  const handleSwitchChange = (event) => {
    setKanbanMode(event.target.checked);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginRight: "12px",
          marginBottom: "12px",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Tooltip
          title='Select "Kanban" or "Standard Dispatch" mode.'
          placement="top"
          arrow
        >
          <CustomSwitch checked={kanbanMode} onChange={handleSwitchChange} />
        </Tooltip>
        <IconButton sx={{ marginLeft: "auto" }}>
          <FullscreenIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate("../backtrack");
          }}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton>
          <CachedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate("../live-cast-pics");
          }}
        >
          <PhotoOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => {}}>
          <EmailRoundedIcon />
        </IconButton>
        <IconButton>
          <GroupsIcon />
        </IconButton>
      </Box>
      {kanbanMode ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-19px",
            position: "relative",
            zIndex: "1",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ position: "relative", height: "19px" }}
            onClick={() => {
              setNonTourTaskDialog(true);
            }}
          >
            <MenuIcon color="white" />
          </Button>
        </Box>
      ) : (
        <Box
          style={{
            position: "absolute",
            right: "10px",
            top: "500px",
            zIndex: "1",
            background: "white",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2px",
            cursor: "pointer",
          }}
        >
          <ArrowForwardIosIcon
            color="primary"
            fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setStandardActivityDialog(true);
            }}
          />
        </Box>
      )}
      {nonTourTaskDialog && (
        <NonTourTaskDialog
          nonTourTaskDialog={nonTourTaskDialog}
          setNonTourTaskDialog={setNonTourTaskDialog}
        />
      )}
      {standardActivityDialog && (
        <StandardActivityDialog
          standardActivityDialog={standardActivityDialog}
          setStandardActivityDialog={setStandardActivityDialog}
        />
      )}
    </>
  );
};

export default MapHeader;
