import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import ListEditModal from "./inner-component/ListEditModal";
import ListSendNotice from "./inner-component/ListSendNotice";
import { DatePicker } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  RadioGroup,
  Radio,
  IconButton,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Checkbox,
  TextField,
  Select,
  InputLabel,
  Button,
  Box,
  Link,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { Settings as SettingsIcon } from "@mui/icons-material";
import {
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import {
  DeviceList,
  DuplicatesDeviceList,
  ChangeHideStatus,
  BulkUpdate,
} from "../../../api";
import { format } from "date-fns";
import CustomPagination from "../../../components/table-components/CustomPagination";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import hasPermission from "../../../utils/hasPermission";
import WithPermissionHide from "../../../utils/withPermissionHide";
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const ListDevice = () => {
  const [listEditDialog, setListEditDialog] = React.useState(false);
  const listEditDialogOpen = () => {
    setListEditDialog(true);
  };
  const listEditDialogClose = () => {
    setListEditDialog(false);
  };
  const [sendNoticeDialog, setSendNoticeDialog] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [listDevice, setListDevice] = useState([]);
  const [listDuplicateDevice, setListDuplicateDevice] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenDevice, setHiddenDevice] = useState("N");
  const [formData, setFormData] = useState({
    status: "",
    activation_date: null,
    expiry_date: null,
  });
  const [call, setCall] = useState(false);
  const dispatch = useDispatch();
  const sendNoticeDialogOpen = () => {
    setSendNoticeDialog(true);
  };
  const sendNoticeDialogClose = () => {
    setSendNoticeDialog(false);
  };

  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updatedRow, setUpdatedRow] = useState(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setUpdatedRow(row);
  };
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  const handleActionClose = (event) => {
    setAnchorEl(null);
  };
  const handleClick = (event, uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (uid) => selected.indexOf(uid) !== -1;
  console.log("formData", formData);
  const changeHideStatus = () => {
    let payload = {
      id: updatedRow.id,
      hide: updatedRow.hide == "Y" ? "N" : "Y",
    };
    console.log("payload", payload);
    ChangeHideStatus(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          listEditDialogClose();
          deviceList();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const bulkUpdate = () => {
    let payload = {
      id: selected,
      activation_date:
        formData.activation_date != null
          ? format(new Date(formData.activation_date), "yyyy-MM-dd")
          : null,
      expiry_date:
        formData.expiry_date != null
          ? format(new Date(formData.expiry_date), "yyyy-MM-dd")
          : null,
      status: formData.status,
    };
    if (payload.expiry_date == null && payload.status == "") {
      dispatch(getMsg("Please enter expiry date or select status"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } else {
      BulkUpdate(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setSelected([]);
            deviceList();
            dispatch(getMsg("Data updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {});
    }
  };

  const deviceList = () => {
    let payload = {
      hide: hiddenDevice,
    };
    setLoading(true);
    DeviceList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListDevice(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setListDevice([]);
          setLoading(false);
          setTotalRecord(0);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const duplicatesDeviceList = () => {
    let payload = {
      hide: hiddenDevice,
    };
    setLoading(true);
    DuplicatesDeviceList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListDuplicateDevice(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setListDuplicateDevice([]);
          setLoading(false);
          setTotalRecord(0);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    deviceList();
    if (hasPermission("Device", "duplicatesDeviceList")) {
      duplicatesDeviceList();
    }
  }, [hiddenDevice, rowsPerPage, currentPage]);

  const [about, setAbout] = useState(false);

  const handleAboutOpen = () => {
    setAbout(true);
  };

  const handleAboutClose = () => {
    setAbout(false);
  };
  return (
    <>
      <Helmet title={"Device List"} />
      <WithPermissionFallback controller="Device" action="deviceList">
        <Grid container spacing={4}>
          <Grid item lg={5} md={6}>
            <Typography variant="h3" gutterBottom mt={2}>
              {call ? "Devices" : "Mobile Devices"}
            </Typography>
          </Grid>
          <Grid item lg={3} md={2}>
            <WithPermissionHide
              controller="Device"
              action="duplicatesDeviceList"
            >
              {call != true && (
                <Typography
                  sx={{ textAlign: "right" }}
                  onClick={() => {
                    setCall(true);
                  }}
                  variant="body2"
                  gutterBottom
                  mt={3}
                >
                  <Link sx={{ color: "#000000de" }}>
                    {" "}
                    Show duplicate devices
                  </Link>
                </Typography>
              )}
            </WithPermissionHide>
            {call && (
              <Typography
                onClick={() => {
                  setCall(false);
                }}
                variant="body2"
                gutterBottom
                mt={3}
              >
                Show all devices
              </Typography>
            )}
          </Grid>
          <Grid item lg={2} md={2}>
            <Typography variant="body2" gutterBottom mt={3}>
              Show hidden devices?
            </Typography>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={hiddenDevice}
                onChange={(event) => setHiddenDevice(event.target.value)}
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Box mt={6} sx={{ marginLeft: "auto" }}>
            <IconButton
              aria-label="Menu"
              size="large"
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleActionClick(e)}
              style={{ color: "#1374C5" }}
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleAboutOpen}>
                <ListItemText primary={"Get Devices Info"} pl={1} />
              </MenuItem>
              <Dialog
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "100%",
                      border: "1px solid #ed6c02",
                    },
                  },
                }}
                open={about}
                onClose={handleAboutClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="visitor-modal"
                  sx={{
                    padding: "5px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <PrivacyTipIcon
                      sx={{ marginRight: "5px", color: "#ed6c02" }}
                    />
                    <Typography variant="h6">
                      FCM sent counts: 5 are success and 9 are failures
                    </Typography>
                  </Box>
                </DialogTitle>
              </Dialog>
            </StyledMenu>
          </Box>
        </Grid>

        {call != true && (
          <Card sx={{ marginTop: "25px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sel</TableCell>
                    <TableCell align="right">Nickname</TableCell>
                    <TableCell align="right">Bluetooth name</TableCell>
                    <TableCell align="right">Model</TableCell>
                    <TableCell align="right">OS</TableCell>
                    <TableCell align="right">IMEI 1</TableCell>
                    <TableCell align="right">App version</TableCell>
                    <TableCell align="right">Activation</TableCell>
                    <TableCell align="right">Expiration</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Lastseen</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {listDevice.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.device_nickname}
                          </TableCell>
                          <TableCell align="right">{row.device_name}</TableCell>
                          <TableCell align="right">
                            {row.device_model}
                          </TableCell>
                          <TableCell align="right">{row.device_os}</TableCell>
                          <TableCell align="right">
                            {row.device_meid_imei}
                          </TableCell>
                          <TableCell align="right">
                            {row.sova_app_version}
                          </TableCell>
                          <TableCell align="right">
                            {row.activation_date}
                          </TableCell>
                          <TableCell align="right">{row.expiry_date}</TableCell>
                          <TableCell align="right">
                            {row.status == "A"
                              ? "Active"
                              : row.status == "R"
                              ? "Rejected"
                              : row.status == "I"
                              ? "Temporary access restricted"
                              : null}
                          </TableCell>
                          <TableCell align="right">
                            {row.last_updated}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="Settings"
                              size="medium"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => {
                                handleActionClick(event, row);
                              }}
                            >
                              <SettingsIcon color="primary" />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <WithPermissionHide
                                controller="Device"
                                action="editDevice"
                              >
                                <MenuItem
                                  onClick={() => {
                                    listEditDialogOpen();
                                    handleActionClose();
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              </WithPermissionHide>
                              <WithPermissionHide
                                controller="Device"
                                action="changeHideStatus"
                              >
                                {row.hide == "Y" ? (
                                  <MenuItem
                                    onClick={() => {
                                      changeHideStatus();
                                      handleActionClose();
                                    }}
                                  >
                                    show
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() => {
                                      changeHideStatus();
                                      handleActionClose();
                                    }}
                                  >
                                    Hide
                                  </MenuItem>
                                )}
                              </WithPermissionHide>
                              <MenuItem
                                onClick={() => {
                                  sendNoticeDialogOpen();
                                  handleActionClose();
                                }}
                                disabled={
                                  !hasPermission("Device", "sendFcmMessage")
                                }
                              >
                                Send Notice
                              </MenuItem>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {listDevice.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomPagination
              handleChangeLimit={handleChangeLimit}
              handleChangePagination={handleChangePagination}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              totalRecord={totalRecord}
            />
          </Card>
        )}
        <WithPermissionHide controller="Device" action="bulkUpdate">
          {selected.length > 0 && (
            <Grid
              container
              spacing={4}
              sx={{
                marginTop: "30px",
              }}
            >
              <Grid item lg={2}>
                <DatePicker
                  name="activation_date"
                  label="Activation Date"
                  value={formData.activation_date}
                  onChange={(value) =>
                    setFormData({ ...formData, activation_date: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="activation_date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={2}>
                <DatePicker
                  id="date-to"
                  label="Date to"
                  name="expiry_date"
                  value={formData.expiry_date}
                  onChange={(value) =>
                    setFormData({ ...formData, expiry_date: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Change Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    value={formData.status}
                    label="Change Status"
                    onChange={(event) =>
                      setFormData({ ...formData, status: event.target.value })
                    }
                  >
                    <MenuItem value="">Change Status</MenuItem>
                    <MenuItem value="I">Temp Access Restricted</MenuItem>
                    <MenuItem value="A">Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} mt={2}>
                <Button
                  onClick={bulkUpdate}
                  variant="contained"
                  color="primary"
                >
                  Update
                </Button>
              </Grid>
              <Grid item lg={4}></Grid>
            </Grid>
          )}
        </WithPermissionHide>
        {call && (
          <Card>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Device Nickname</TableCell>
                    <TableCell align="right">Device Name</TableCell>
                    <TableCell align="right">Device Modal</TableCell>
                    <TableCell align="right">Device Os</TableCell>
                    <TableCell align="right">Device Unique Id</TableCell>
                    <TableCell align="right">Device Serial Number</TableCell>
                    <TableCell align="right">Sova App Version</TableCell>
                    <TableCell align="right">Activation Date</TableCell>
                    <TableCell align="right">Expiry Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Last Update</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                ) : (
                  <TableBody>
                    {listDuplicateDevice.map((row, index) => (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          {row.device_nickname}
                        </TableCell>
                        <TableCell align="right">{row.device_name}</TableCell>
                        <TableCell align="right">{row.device_model}</TableCell>
                        <TableCell align="right">{row.device_os} </TableCell>
                        <TableCell align="right">
                          {row.device_unique_id}
                        </TableCell>
                        <TableCell align="right">
                          {row.device_serial_number}
                        </TableCell>
                        <TableCell align="right">
                          {row.activation_date}{" "}
                        </TableCell>
                        <TableCell align="right">{row.expiry_date} </TableCell>
                        <TableCell align="right">
                          {" "}
                          {row.status == "A"
                            ? "Active"
                            : row.status == "R"
                            ? "Rejected"
                            : row.status == "I"
                            ? "Temporary access restricted"
                            : null}
                        </TableCell>
                        <TableCell align="right">{row.last_updated}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="Settings"
                            size="medium"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => {
                              handleActionClick(event, row);
                            }}
                          >
                            <SettingsIcon color="primary" />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                listEditDialogOpen();
                                handleActionClose();
                              }}
                            >
                              Edit
                            </MenuItem>
                            {row.hide == "Y" ? (
                              <MenuItem
                                onClick={() => {
                                  changeHideStatus();
                                  handleActionClose();
                                }}
                              >
                                show
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => {
                                  changeHideStatus();
                                  handleActionClose();
                                }}
                              >
                                Hide
                              </MenuItem>
                            )}
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                    {listDuplicateDevice.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomPagination
              handleChangeLimit={handleChangeLimit}
              handleChangePagination={handleChangePagination}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              totalRecord={totalRecord}
            />
          </Card>
        )}

        {listEditDialog && (
          <ListEditModal
            listEditDialog={listEditDialog}
            setListEditDialog={setListEditDialog}
            listEditDialogOpen={listEditDialogOpen}
            listEditDialogClose={listEditDialogClose}
            updatedRow={updatedRow}
            deviceList={deviceList}
          />
        )}
        {sendNoticeDialog && (
          <ListSendNotice
            sendNoticeDialog={sendNoticeDialog}
            setSendNoticeDialog={setSendNoticeDialog}
            sendNoticeDialogOpen={sendNoticeDialogOpen}
            sendNoticeDialogClose={sendNoticeDialogClose}
            updatedRow={updatedRow}
          />
        )}
      </WithPermissionFallback>
    </>
  );
};

export default ListDevice;
