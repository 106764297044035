import React, { useState, useCallback } from "react";
import { Modal, Box, Button, Typography, Slider } from "@mui/material";
import Cropper from "react-easy-crop";
import styled from "styled-components/macro";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageCropperModal = ({
  open,
  onClose,
  imageSrc,
  onCrop,
  onUpload,
  onDelete,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedArea);
  }, []);

  const handleCrop = () => {
    onCrop({ crop, zoom, rotation });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Crop and Rotate Image
        </Typography>

        <div
          style={{
            position: "relative",
            width: "300px",
            marginLeft: "auto",
            marginRight: "auto",
            height: "300px",
            marginBottom: 20,
            overflow: "hidden",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1} // Keep 1:1 aspect ratio
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>

        <Box sx={{ mb: 2, paddingLeft: "4px", paddingRight: "4px" }}>
          <Typography gutterBottom>Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setZoom(value)}
          />
        </Box>

        <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            onClick={() => setRotation((prev) => prev - 90)}
            sx={{ mr: 1 }}
          >
            Rotate Left
          </Button>
          <Button
            variant="contained"
            onClick={() => setRotation((prev) => prev + 90)}
          >
            Rotate Right
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={onDelete} sx={{ mr: 1 }}>
            Delete
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={""}
            sx={{ mr: 1 }}
          >
            Upload files
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  onUpload(file); // Pass the selected file to onUpload
                }
              }}
              multiple={false}
            />
          </Button>
          <Button variant="contained" onClick={handleCrop} sx={{ mr: 2 }}>
            Crop
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageCropperModal;
