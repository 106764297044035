import React, { useState, useEffect } from "react";
import IpRangeDialog from "./inner-component/IpRangeDialog";
import {
  Typography,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Card,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { IpRange as IpRangeList, DeleteRange } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import WithPermissionHide from "../../../utils/withPermissionHide";
import hasPermission from "../../../utils/hasPermission";

const IpRange = () => {
  const [ipRangeDialog, setIpRangeDialog] = React.useState(false);
  const [range, setRange] = useState([]);
  const [updatedRow, setUpdatedRow] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const dispatch = useDispatch();
  const ipRangeDialogOpen = () => {
    setIpRangeDialog(true);
  };

  const handleActionClick = (row) => {
    setUpdatedRow(row);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const ipRangeDialogClose = () => {
    setIpRangeDialog(false);
  };
  const ipRangeList = () => {
    IpRangeList()
      .then((res) => {
        if (res.statusCode == 200) {
          setRange(res.data);
        } else {
          setRange(res.data);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const deleteRange = () => {
    DeleteRange(updatedRow.id)
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          ipRangeList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUpdate = (newData) => {
    ipRangeList();
  };

  useEffect(() => {
    ipRangeList();
  }, []);
  return (
    <>
      <WithPermissionFallback controller="Settings" action="ipRange">
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete ip range?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteRange} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6}>
            <Typography variant="h3" gutterBottom mt={2}>
              IP Address Range
            </Typography>
          </Grid>
          <WithPermissionHide controller="Settings" action="addRange">
            <Grid item lg={6} md={6}>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    ipRangeDialogOpen();
                    setUpdatedRow(null);
                  }}
                >
                  New IP Range
                </Button>
              </Box>
            </Grid>
          </WithPermissionHide>
        </Grid>
        <Typography variant="body2" gutterBottom mt={4}>
          Setting an IP range can be used to control login access at the user
          level by specifying a range of allowed IP addresses on user's
          profile.Whwn you define IP address restrictions ,a login from any
          other IP address is defined. This Feature can add an additional level
          of security to your account to help prevent unauthorized account
          access.It may also be required for corporate compliance or governance.
        </Typography>
        <Typography variant="h6">Instruction</Typography>
        <List className="Ip-List">
          <ListItem>
            To add a range of IP address from which users can log in, Click New
            IP range.Enter a valid IPv4 address inn the IP start Address and a
            higher-numbered IP End Address field.Enter the IP address ranges
            that you want to allow using CIDR notation.An example range might be
            like 192.128.234.192 to 192.128.234.255.Enter one IP address range
            per line, and make sure there are no overlapping IP addresses.To
            allow logins from only a single IP address, enter the same address
            in both the fields.Make sure you include your own IP addresses so
            you don't lock yourself out.
          </ListItem>
          <ListItem>
            After saving a new range,Remain on this page and try logging into
            your client portal from a different computer.If you are blocked and
            unable to login, you will be able to remove or edit block you added
            from the first computer.Please use this suggestion or you may be
            completely locked out of your portal!
          </ListItem>
          <ListItem>
            To edit or remove ranges, Click the action icon in the table below
            and choose Edit or Delete for the range.
          </ListItem>
        </List>
        <Typography variant="body2" gutterBottom>
          <strong>Access from dynamic IP ranges:</strong>Your company network
          may utilize dynamic originating IP addresses. For example,a service
          that routes network traffic through one data center may start running
          from a difference data center due to a failover condition or other
          reason,thus dynamically changing its IP Address.The location-based
          conditional access policy in SOVA relies on fixed,trusted IP address
          ranges.If your IP address range cannot be determined up front,setting
          IP ranges may not be an option for your environment.
        </Typography>
        <Card
          sx={{
            marginTop: "20px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>IP Start Address</TableCell>
                <TableCell align="right">IP End Address</TableCell>
                <TableCell align="right">Modify Date</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {range.map((row, index) => (
                <TableRow>
                  <TableCell>{row.start}</TableCell>
                  <TableCell align="right">{row.end}</TableCell>
                  <TableCell align="right">{row.modified}</TableCell>
                  <TableCell align="right" sx={{ padding: 0 }}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        ipRangeDialogOpen();
                        handleActionClick(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleActionClick(row);
                        handleDeleteOpen();
                      }}
                      color="error"
                      disabled={!hasPermission("Settings", "deleteRange")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        {ipRangeDialog && (
          <IpRangeDialog
            ipRangeDialog={ipRangeDialog}
            setIpRangeDialog={setIpRangeDialog}
            ipRangeDialogOpen={ipRangeDialogOpen}
            ipRangeDialogClose={ipRangeDialogClose}
            updatedRow={updatedRow}
            onUpdate={handleUpdate}
          />
        )}
      </WithPermissionFallback>
    </>
  );
};

export default IpRange;
