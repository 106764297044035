import React, { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ViewLog from "./ViewLog";
import CloneVisitor from "./CloneVisitor";
import DialogBox from "../../../../components/form-components/DialogBox";
import {
  IconButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../../redux/slices/mainSlice";
import { DeleteVisitor, VisitorMobile } from "../../../../api";
import {
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  RateReview as RateReviewIcon,
  Image as ImageIcon,
  LocalShipping as LocalShippingIcon,
  Label as LabelIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import WithPermissionHide from "../../../../utils/withPermissionHide";

const ListingAction = ({
  data,
  phone,
  VisitorList,
  showAlertModal,
  setLoader,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    // setRecordId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const ListItemText = styled(MuiListItemText)(spacing);
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  }));

  const viewImage = (link) => {
    setClickedImg(link);
  };

  // View Log Modal
  const [viewLogDialog, setViewLogDialog] = useState(false);
  const viewLogDialogOpen = () => {
    setViewLogDialog(true);
    handleActionClose();
  };
  const viewLogDialogClose = () => {
    setViewLogDialog(false);
  };
  // Clone Visitor Dialog
  const [cloneVisitorDialog, setCloneVisitorDialog] = useState(false);
  const cloneVisitorDialogOpen = () => {
    setCloneVisitorDialog(true);
    handleActionClose();
  };
  const cloneVisitorDialogClose = () => {
    setCloneVisitorDialog(false);
  };

  const removeRecords = () => {
    if (data.id) {
      setModalMessage("Are you sure you want to delete?");
    } else {
      setModalMessage("You haven't selected any item!");
    }
    handleActionClose();
    setShowDeleteModal(true);
  };

  const handelDeleteAction = (status) => {
    if (status === true) {
      setLoader(true);
      DeleteVisitor(data.id)
        .then((res) => {
          setShowDeleteModal(false);
          if (res.statusCode === 200) {
            VisitorList();
          }
          showAlertModal("success", "Records has been deleted successfully");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setShowDeleteModal(false);
    }
  };
  // Visitor Mobile
  const handleVisitorMobile = () => {
    VisitorMobile({ id: data.id }).then((res) => {
      if (res.statusCode === 200) {
        dispatch(getMsg("Visitor Mobile pass sent successfully"));
        dispatch(getSnackAlert("success"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      } else {
        dispatch(getMsg("Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      }
    });
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="Settings"
        size="large"
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleActionClick(e, data.id)}
        style={{ color: "#1374C5" }}
      >
        <SettingsIcon />
      </IconButton>
      <StyledMenu
        id="icon-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Link
          to={"/visitors/edit-visitor/" + data.id}
          style={{ textDecoration: "none", color: "#374151" }}
        >
          <MenuItem>
            <ListItemText primary="Edit Visitor" pl={1} />
          </MenuItem>
        </Link>
        <MenuItem
          component={NavLink}
          to={`/visitors/process-visitor/${data.id}`}
        >
          <ListItemText primary="Process Visitor" pl={1} />
        </MenuItem>
        <MenuItem onClick={viewLogDialogOpen}>
          <ListItemText primary="View Log" pl={1} />
        </MenuItem>
        <WithPermissionHide controller="Visitor" action="deleteVisitor">
          <MenuItem onClick={() => removeRecords()}>
            <ListItemText primary="Delete Visitor" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        <WithPermissionHide controller="Visitor" action="deleteVisitor">
          <MenuItem onClick={cloneVisitorDialogOpen}>
            <ListItemText primary="Clone Visitor" pl={1} />
          </MenuItem>
        </WithPermissionHide>
        {data.visitor_mobile && (
          <MenuItem
            onClick={() => {
              handleVisitorMobile();
              handleActionClose();
            }}
          >
            <ListItemText primary="Send Mobile Pass" pl={1} />
          </MenuItem>
        )}
      </StyledMenu>
      {viewLogDialog && (
        <ViewLog
          show={viewLogDialog}
          handleModel={(show) => setViewLogDialog(show)}
          data={data}
        />
      )}
      {cloneVisitorDialog && (
        <CloneVisitor
          cloneVisitorDialog={cloneVisitorDialog}
          setCloneVisitorDialog={setCloneVisitorDialog}
          cloneVisitorDialogOpen={cloneVisitorDialogOpen}
          cloneVisitorDialogClose={cloneVisitorDialogClose}
          data={data}
          showAlertModal={(type, msg) => showAlertModal(type, msg)}
          VisitorList={() => VisitorList()}
          setLoader={(show) => setLoader(show)}
        />
      )}
      <DialogBox
        handleShow={showDeleteModal}
        message={modalMessage}
        conformation={(status) => handelDeleteAction(status)}
      />
    </React.Fragment>
  );
};

export default ListingAction;
