import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { ChromePicker } from "react-color";
import MuiPhoneNumber from "material-ui-phone-number";
import PasswordStrength from "./components/PasswordStrength";
import { useDispatch } from "react-redux";
import ImageCropper from "../../components/form-components/ImageCropper";
import { makeStyles } from "@mui/styles";
import {
  UserCorporate,
  UserGroupList,
  UserProperty,
  AddUser,
  ModuleList,
} from "../../api";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/userSlice";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputAdornment,
  IconButton,
  FormLabel,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  FormGroup,
  Checkbox,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import Toaster from "./components/Toaster";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
const Image = styled.img`
  width: 100%;
`;

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          lineHeight: "1.6",
          fontSize: "10px",
          marginRight: "-15px",
          maxWidth: "330px",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const StyledGroups = styled((props) => <Box {...props} />)(({ theme }) => ({
  "& .MuiBox-root": {
    backgroundColor: "#7aa8ff0d",
    padding: "10px 20px",
    marginTop: "10px",
    maxHeight: "245px",
    overflowY: "auto",
    borderRadius: "5px",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      borderBottom: "0px",
      marginBottom: "12px",
      "& .MuiTypography-subtitle2": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        margin: "0px 6px",
        width: "12px",
        height: "12px",
        verticalAlign: "middle",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const initialValues = {
  user_level: "",
  select_corporate: "",
  location_id: "",
  email: "",
  password: "",
  confirm_password: "",
  incognito_first_name: "",
  incognito_last_name: "",
  report_location_name: "",
  report_locaton_id: "",
  challenge_question: "",
  answer: "",
  first_name: "",
  last_name: "",
  user_intial: "",
  phoneNumber: "",
  code: "",
  country: "",
  map_pin_color: "",
  profile_name: "",
};
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  email: Yup.string()
    .email("Email must be valid email")
    .required("Please enter email address"),
  password: Yup.string()
    .required("Please enter password")
    .matches(/^\S*$/, "Whitespace is not allowed")
    .test(
      "regex",
      "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
      (val) => {
        let regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        return regExp.test(val);
      }
    ),

  confirm_password: Yup.string()
    .required("Please enter confirm password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  user_level: Yup.string().required("Please select user level"),
  select_corporate: Yup.string().required("Please select coporate"),
  location_id: Yup.string().required("Please select property"),
});

function AddUserForm() {
  let navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(null);
  const [corporates, setCorporates] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [color, setColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [image, setImage] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleColor = () => {
    setShowColorPicker((showColorPicker) => !showColorPicker);
  };
  //Check String contain lowercase and uppercase
  const isUpperCase = (string) => /[a-z]/.test(string) && /[A-Z]/.test(string);
  //Check String contain digit or not
  const hasNumber = (string) => /\d/.test(string);
  //check string contain special character or not
  const schar = (string) => /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/.test(string);

  //check whitespace not allowed
  const whiteSpaces = (string) => /^\S*$/.test(string);

  /* The above code is fetching the list of modules from the server. */
  const [module, setModule] = useState([]);
  const [moduleArray, setModuleArray] = useState([]);
  const moduleList = () => {
    ModuleList()
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
          setModule(res.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * If the checkbox is checked, add the value to the moduleArray. If the checkbox is unchecked, remove
   * the value from the moduleArray
   * @param e - The event object
   */
  const handleMoudle = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setModuleArray([...moduleArray, value]);
    } else {
      setModuleArray(moduleArray.filter((e) => e !== value));
    }
  };

  // State for image cropper modal

  /* The above code is creating a state variable called imageCropperDialog and setting it to false. It
 is also creating a function called imageCropperOpen that sets the imageCropperDialog state variable
 to true. */
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };

  /**
   * It closes the image cropper dialog.
   */
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };

  /* The above code is using the useState hook to create a state variable called imageData and a
  function called setImageData. The childToParent function is a callback function that is passed to
  the child component. The childToParent function is called in the child component and passes the
  childData to the setImageData function. */
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  /**
   * It clears the image data.
   */

  const imageCropperClear = () => {
    setImageData(null);
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        user_group_id: values.user_level,
        location_id: values.location_id.split(" "),
        corporate_id: values.select_corporate,
        email: values.email,
        password: values.password,
        cpassword: values.confirm_password,
        dummy_first_name: values.incognito_first_name,
        dummy_last_name: values.incognito_last_name,
        sec_question: values.challenge_question,
        sec_answer: values.answer,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phoneNumber,
        reporting_location_id: values.report_locaton_id,
        ru: values.report_location_name,
        user_initials: values.user_intial,
        color_code: values.map_pin_color,
        profile_image: imageData,
        module_active: moduleArray,
      };
      AddUser(payload)
        .then((res) => {
          setSuccess(res.status);
          setOpenSnack(true);
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("User has been submitted successfully"));
            navigate({
              pathname: "/users/user",
            });
            setLoading(false);
          } else {
            dispatch(getSnackClose());
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          dispatch(getSnackClose());
        });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  /**
   * The function is called corporate and it calls the UserCorporate function which returns a promise.
   * If the promise is fulfilled, the function sets the state of the corporates variable to the data
   * returned by the promise. If the promise is rejected, the function sets the state of the corporates
   * variable to an empty array
   */
  const corporate = () => {
    UserCorporate()
      .then((res) => {
        if (res.statusCode === 200) {
          setCorporates(res.data);
        } else {
          setCorporates([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userGroups = () => {
    UserGroupList()
      .then((res) => {
        if (res.statusCode === 200) {
          setUserGroup(res.data);
        } else {
          setUserGroup([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(code);
  const property = (id) => {
    UserProperty(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setPropertyList(res.data);
        } else {
          setPropertyList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    corporate();
    userGroups();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          {loading && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
          {imageCropperDialog && (
            <ImageCropper
              imageCropperDialog={imageCropperDialog}
              setImageCropperDialog={setImageCropperDialog}
              imageCropperOpen={imageCropperOpen}
              imageCropperClose={imageCropperClose}
              childToParent={childToParent}
              imageData={imageData}
            />
          )}
          <Toaster handleCloseSnack={handleCloseSnack} openSnack={openSnack} />
          <CardContent>
            {status && status.sent && success === "success" && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <TextField
                      id="demo-simple-select"
                      name="user_level"
                      select
                      value={values.user_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.user_level && errors.user_level)}
                      helperText={touched.user_level && errors.user_level}
                      label="User level*"
                      fullWidth
                      displayempty
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">--Click to select--</MenuItem>
                      {userGroup.map((user) => {
                        return (
                          <MenuItem
                            value={user.id}
                            key={user.id}
                            onClick={user.id == 7 ? () => moduleList() : null}
                          >
                            {user.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      id="demo-simple-select"
                      name="select_corporate"
                      value={values.select_corporate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        touched.select_corporate && errors.select_corporate
                      )}
                      helperText={
                        touched.select_corporate && errors.select_corporate
                      }
                      label="Select corporate*"
                      fullWidth
                      displayempty
                      select
                    >
                      <MenuItem value="">--Click to select--</MenuItem>
                      {corporates.map((corporate) => {
                        return (
                          <MenuItem
                            value={corporate.id}
                            key={corporate.id}
                            onClick={() => property(corporate.id)}
                          >
                            {corporate.corporate_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>
                      Choose property (select from the list below)*
                    </FormLabel>
                    <StyledGroups>
                      <Box>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="location_id"
                          onChange={handleChange}
                          value={values.location_id}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.location_id && errors.location_id
                          )}
                          helperText={touched.location_id && errors.location_id}
                        >
                          {propertyList.map((item) => {
                            return (
                              <FormControlLabel
                                value={item.LocationID}
                                control={<Radio />}
                                label={item.LocationName}
                                key={item.LocationID}
                              />
                            );
                          })}
                        </RadioGroup>
                      </Box>
                    </StyledGroups>
                    <Typography
                      style={{
                        color: "#f44336",
                        fontSize: "10px",
                        marginLeft: "15px",
                      }}
                    >
                      {touched.location_id && errors.location_id}
                    </Typography>
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="email"
                      name="email"
                      type="text"
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={handleChange}
                      value={values.email}
                      label="Email*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="me@you.com"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      label="Password*"
                      id="outlined-adornment-password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Must be over 30% complexity"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <PasswordStrength password={values.password} />
                  </FormControl>
                  <FormHelperText>
                    {values.password.length >= 8 ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Length of at least 8 characters
                  </FormHelperText>
                  <FormHelperText>
                    {isUpperCase(values.password) ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Contains uppercase and lowercase letters
                  </FormHelperText>
                  <FormHelperText>
                    {hasNumber(values.password) ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Contains number
                  </FormHelperText>
                  <FormHelperText>
                    {schar(values.password) ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Contains special characters
                  </FormHelperText>
                  <FormHelperText>
                    {whiteSpaces(values.password) ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Doesn’t contain spaces
                  </FormHelperText>
                  <FormHelperText>
                    <CheckCircleIcon
                      sx={{
                        color: "#71BF45",
                        fontSize: "15px",
                        verticalAlign: "sub",
                        marginRight: "10px",
                      }}
                    />
                    Doesn’t contain common password words
                  </FormHelperText>
                  <FormHelperText>
                    {values.password === values.confirm_password ? (
                      <CheckCircleIcon
                        sx={{
                          color: "#71BF45",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <CancelIcon
                        sx={{
                          color: "#D65454",
                          fontSize: "15px",
                          verticalAlign: "sub",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    Passwords match
                  </FormHelperText>
                  <FormControl mt={12}>
                    <TextField
                      id="confirm-password"
                      name="confirm_password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.confirm_password}
                      error={Boolean(
                        touched.confirm_password && errors.confirm_password
                      )}
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Confirm password*"
                      placeholder="Enter same value as above"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="incognito-fname"
                      name="incognito_first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.incognito_first_name}
                      label="Incognito first name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="False name visible during demos"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="incognito-lname"
                      name="incognito_last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.incognito_last_name}
                      label="Incognito last name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="False name visible during demos"
                    />
                  </FormControl>
                  <FormControl
                    mt={6}
                    sx={{ display: values.user_level == 7 ? "block" : "none" }}
                  >
                    <Typography>Select Modules</Typography>
                    <StyledFormControl>
                      <FormGroup sx={{ maxHeight: "180px", overflowY: "auto" }}>
                        {module.map((data) => {
                          return (
                            <FormControlLabel
                              key={data.id}
                              control={
                                <Checkbox
                                  value={data.id}
                                  onChange={handleMoudle}
                                />
                              }
                              label={data.module_name}
                            />
                          );
                        })}
                      </FormGroup>
                    </StyledFormControl>
                  </FormControl>

                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="Rpt-lname"
                      name="report_location_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.report_location_name}
                      label="Reporting location name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Name of your company"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      label="Reporting location ID"
                      id="outlined-adornment-text"
                      name="report_locaton_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.report_locaton_id && errors.report_locaton_id
                      )}
                      helperText={
                        touched.report_locaton_id && errors.report_locaton_id
                      }
                      value={values.report_locaton_id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="ABC"
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledTooltip
                              placement="top-end"
                              title={
                                <Typography>
                                  Issued by SOVA, used to add POI records by
                                  email
                                </Typography>
                              }
                            >
                              <ErrorIcon color="primary" />
                            </StyledTooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Typography
                    component="p"
                    sx={{ fontSize: "12px", fontWeight: "300" }}
                    mt={4}
                  >
                    Write a challenge question below and provide an answer. This
                    will be used to help protect your account from unauthorized
                    access.The challenge question and answer you provide should
                    only be known by this user. Note: answers are case
                    sensitive, and multiple word answers are encouraged.
                  </Typography>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="challenge-ques"
                      name="challenge_question"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.challenge_question}
                      error={Boolean(
                        touched.challenge_question && errors.challenge_question
                      )}
                      helperText={
                        touched.challenge_question && errors.challenge_question
                      }
                      label="Challenge question"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Provide a question and answer only this user would know"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="answer"
                      name="answer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.answer}
                      error={Boolean(touched.answer && errors.answer)}
                      helperText={touched.answer && errors.answer}
                      label="Answer"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="cAsE sEnSiTiVe"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl mt={3}>
                    <TextField
                      fullWidth
                      id="first-name"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      value={values.first_name}
                      label="First name*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Mike"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="last-name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      value={values.last_name}
                      label="Last name*"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Smith"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <TextField
                      fullWidth
                      id="cell-sign"
                      name="user_intial"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_intial}
                      label="User initials or Call sign"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="MS, 14, etc"
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <MuiPhoneNumber
                      name="phoneNumber"
                      id="contactPhoneNumber"
                      label=" Mobile phone (optional to send SMS)"
                      value={values.phoneNumber}
                      onChange={handleChange("phoneNumber")}
                      onBlur={handleBlur}
                      defaultCountry={"us"}
                      style={{ width: "100%" }}
                      variant="outlined"
                      disableAreaCodes={true}
                      // margin="normal"
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </FormControl>
                  {/* <FormControl mt={6}>
                    <InputLabel required htmlFor="map-pin">
                      Map Pin Color
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      id="map-pin"
                      name="map_pin_color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onClick={handleColor}
                      value={color}
                      error={Boolean(
                        touched.map_pin_color && errors.map_pin_color
                      )}
                      helperText={touched.map_pin_color && errors.map_pin_color}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Click to select color for BackTrack map"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <ErrorIcon
                                color="primary"
                                onClick={() => setOpen(true)}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Map pin color"
                    />
                    {showColorPicker && (
                      <ChromePicker
                        color={color}
                        onChange={(updatedColor) => setColor(updatedColor.hex)}
                      />
                    )}
                    <Dialog
                      open={open}
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      onClose={() => setOpen(false)}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle
                        id="form-dialog-title"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">Map Pin Color</Typography>
                        <DialogActions>
                          <IconButton onClick={() => setOpen(false)}>
                            <CancelIcon />
                          </IconButton>
                        </DialogActions>
                      </DialogTitle>
                      <DialogContent>
                        <ImageWrapper>
                          <Image
                            alt="demo-image"
                            src={`/static/img/unsplash/map-pin-color-popup.png`}
                          />
                        </ImageWrapper>
                      </DialogContent>
                    </Dialog>
                  </FormControl> */}
                  <FormControl mt={6}>
                    {/* <TextField
                      fullWidth
                      name="map_pin_color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.map_pin_color}
                      label="Map Pin Color"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="color"
                    /> */}
                  </FormControl>
                  <FormControl mt={6}>
                    <Typography
                      component="p"
                      sx={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Add profile image (or add via mobile app)
                    </Typography>
                    <BigAvatar alt="Remy Sharp" src={imageData} />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={imageCropperOpen}
                        mt={3}
                      >
                        Choose image
                      </Button>
                      {imageData && (
                        <Button
                          sx={{ marginLeft: "10px" }}
                          onClick={imageCropperClear}
                          variant="contained"
                          color="primary"
                          component="span"
                          mt={3}
                        >
                          Clear
                        </Button>
                      )}
                    </label>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{ width: "175px" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function AddUserPage() {
  return (
    <React.Fragment>
      <Helmet title="Add User" />
      <WithPermissionFallback controller="Users" action="addUser">
        <Typography variant="h3" gutterBottom display="inline">
          Add User
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tables/Users">
            User
          </Link>
          <Typography>Add User</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddUserForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default AddUserPage;
