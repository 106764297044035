import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Typography,
  Grid,
  Card,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  IconButton,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  FormControlLabel,
  FormLabel,
  Menu,
  Box,
  InputAdornment,
  Select,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  CardHeader,
} from "@mui/material";
import {
  UserGroupList,
  LocationList,
  UserSearch as Search,
  SaveInlineData,
  ChangeUserLocation,
} from "../../../api";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  BorderColor as BorderColorIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import hasPermission from "../../../utils/hasPermission";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    arrow
    title={title}
    placement="top"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          padding: 0,
          background: "transparent",
          "& .MuiCardContent-root": {
            paddingTop: 0,
          },
          "& .MuiTooltip-arrow": {
            color: "rgb(210 210 210)",
          },
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 70px;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const UserSearch = () => {
  const [anchorForm, setAnchorForm] = useState(null);
  const [value, setValue] = useState(null);
  const [formData, setFormData] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [listLocation, setListLocation] = useState([]);
  const [search, setSearch] = useState("");
  const [dataToFilter, setDataToFilter] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editedData, setEditedData] = useState({});

  const dispatch = useDispatch();

  const handleDataChange = (event, userId, key) => {
    const updatedEditedData = {
      ...editedData,
      [userId]: {
        ...editedData[userId],
        [key]: event.target.value,
      },
    };
    setEditedData(updatedEditedData);
  };

  const saveEditedData = (event, userId, field) => {
    if (editedData[userId]?.[field] !== undefined) {
      const { name, email } = editedData[userId];

      if (
        field === "name" &&
        name === formData.find((user) => user.UserID === userId)?.UserName
      ) {
        return; // No changes, so skip the API call
      } else if (
        field === "email" &&
        email === formData.find((user) => user.UserID === userId)?.Email
      ) {
        return; // No changes, so skip the API call
      }
      // Implement the API call or function to save the edited data here.
      saveInlineData(event, userId);
    }
  };

  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };

  const applySearch = () => {
    const payload = {
      email: search,
    };
    setDataToFilter(payload);
  };
  const changeUserLocation = (data) => {
    let payload = {
      id: data.userId,
      val: data.locationId,
    };
    setOpenModal(false);

    ChangeUserLocation(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          searchList();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Somthing went wrong please try again!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const saveInlineData = (event, id) => {
    let payload = {
      id: id,
      index: event.target.name,
      val: event.target.value,
    };
    SaveInlineData(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          searchList();
          dispatch(getMsg("Data updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Somthing went wrong please try again!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const searchList = () => {
    setLoading(true);
    let payload = {
      ...dataToFilter,
    };
    Search(payload)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          setFormData(res.data);
        } else {
          setFormData([]);
        }
      })
      .catch((err) => {});
  };
  const userGroupList = () => {
    UserGroupList()
      .then((res) => {
        if (res.statusCode == 200) {
          setUserGroup(res.data);
        } else {
          setUserGroup([]);
        }
      })
      .catch((err) => {});
  };
  const locationList = () => {
    LocationList()
      .then((res) => {
        if (res.statusCode == 200) {
          setListLocation(res.data);
        } else {
          setListLocation([]);
        }
      })
      .catch((err) => {});
  };
  const handleModal = (event, userId, previousLocation) => {
    setOpenModal(true);
    const payloadData = {
      userId: userId,
      locationId: event.target.value,
      previousLocation: previousLocation.key,
    };
    setData(payloadData);
  };
  useEffect(() => {
    userGroupList();
    locationList();
    searchList();
  }, [dataToFilter]);

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      applySearch();
    }
  };

  const clearSearch = () => {
    setSearch("");
    setIsValidEmail(true);
  };
  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSearchChange = (e) => {
    const email = e.target.value;
    setSearch(email);
    setIsValidEmail(validateEmail(email));
  };

  return (
    <>
      <WithPermissionFallback controller="Users" action="userSearch">
        <Grid container spacing={4}>
          <Grid item lg={5} md={5}>
            <Typography variant="h3" gutterBottom mt={7}>
              Users Search
            </Typography>
          </Grid>

          <Grid item lg={7} md={7}>
            <div className="searchTool">
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <Item sx={{ flexGrow: 1, width: "100%" }}>
                  <Box style={{ position: "relative" }}>
                    <TextField
                      style={{ width: "100%" }}
                      id="search"
                      type="email"
                      placeholder="Search by user email"
                      onChange={handleSearchChange}
                      onKeyPress={handleSearchKeyPress}
                      value={search}
                      inputProps={{}}
                    />
                    {search.length > 0 && (
                      <IconButton
                        onClick={clearSearch}
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "15px",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    {!isValidEmail && (
                      <span style={{ color: "red" }}>
                        Please include an '@' in the email address.
                      </span>
                    )}
                  </Box>
                </Item>
                <Item>
                  <Button
                    onClick={applySearch}
                    variant="contained"
                    color="primary"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Item>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Card
          sx={{
            marginTop: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">User Id</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">User Group</TableCell>
                  <TableCell align="center">Corporate</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center">Logged In</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Login Cities</TableCell>
                  <TableCell align="center">Created</TableCell>
                  <TableCell align="center">Last seen</TableCell>
                  <TableCell align="center">Pwd age</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <TableCell colSpan={14} align="center">
                  <CircularProgress />
                </TableCell>
              ) : (
                <TableBody>
                  {formData.map((row) => (
                    <TableRow key={row.UserID}>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.UserID}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 230,
                          "& .MuiFormControl-root": {
                            alignItems: "center",
                            justifyContent: "center",
                          },
                          "& .MuiInputBase-inputMultiline": {
                            paddingTop: "16px !important",
                            height: "auto !important",
                          },
                        }}
                      >
                        <StyledTooltip
                          title={
                            <Card>
                              <CardHeader title="Click to edit" />
                            </Card>
                          }
                        >
                          <TextField
                            sx={{
                              width: 230,
                              height: 120,

                              border: 0,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: 0,
                              },
                              "& .MuiOutlinedInput-input": {
                                textAlign: "center",
                              },
                            }}
                            multiline // Enable multiline mode
                            rows={2}
                            name="UserName"
                            value={
                              (editedData[row.UserID]?.name !== undefined
                                ? editedData[row.UserID]?.name
                                : row.UserName) || ""
                            }
                            onChange={(event) =>
                              handleDataChange(event, row.UserID, "name")
                            }
                            onBlur={(event) =>
                              saveEditedData(event, row.UserID, "name")
                            }
                          />
                        </StyledTooltip>
                      </TableCell>
                      <TableCell align="center">
                        <ThumbnailTableWrapper>
                          {row.profile_image ? (
                            <Image
                              component="img"
                              alt="Image Not Found"
                              src={row.profile_image}
                            />
                          ) : null}
                        </ThumbnailTableWrapper>
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 230,
                          "& .MuiFormControl-root": {
                            alignItems: "center",
                            justifyContent: "center",
                          },
                          "& .MuiInputBase-inputMultiline": {
                            paddingTop: "16px !important",
                            height: "auto !important",
                          },
                        }}
                      >
                        <StyledTooltip
                          title={
                            <Card>
                              <CardHeader title="Click to edit" />
                            </Card>
                          }
                        >
                          <TextField
                            sx={{
                              width: "100%",
                              border: 0,

                              "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: 0,
                              },
                              "& .MuiOutlinedInput-input": {
                                textAlign: "center",
                              },
                            }}
                            multiline // Enable multiline mode
                            rows={2}
                            name="Email"
                            value={
                              (editedData[row.UserID]?.email !== undefined
                                ? editedData[row.UserID]?.email
                                : row.Email) || ""
                            }
                            onChange={(event) =>
                              handleDataChange(event, row.UserID, "email")
                            }
                            onBlur={(event) =>
                              saveEditedData(event, row.UserID, "email")
                            }
                          />
                          {/* {row.Email} */}
                        </StyledTooltip>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl mt={3}>
                          <Select
                            fullWidth
                            name="user_group_id"
                            value={row.user_group_id}
                            onChange={(event) => {
                              saveInlineData(event, row.UserID);
                            }}
                            displayEmpty
                            disabled={!hasPermission("Users", "saveInlineData")}
                          >
                            {userGroup.map((item) => {
                              return (
                                <MenuItem value={item.id} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.corporate?.corporate_name}
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          fullWidth
                          name="LocationID"
                          value={row.LocationID}
                          onChange={(event) => {
                            // const selectedLocation = row;
                            const previousLocation = listLocation.find(
                              (item) => item.value === row.LocationID
                            );
                            handleModal(event, row.UserID, previousLocation);
                          }}
                          displayEmpty
                          disabled={
                            !hasPermission("Users", "changeUserLocation")
                          }
                        >
                          {listLocation.map((item) => {
                            return (
                              <MenuItem value={item.value} key={item.id}>
                                {" "}
                                {item.key}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.active == 1 ? "Yes" : "No"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.IsLoggedIn == "N"
                          ? "No"
                          : row.IsLoggedIn == "Y"
                          ? "Yes"
                          : "No"}
                      </TableCell>
                      {row.status == "D" ? (
                        <TableCell align="center">
                          <Select
                            fullWidth
                            name="status"
                            value={row.status}
                            onChange={(event) => {
                              saveInlineData(event, row.UserID);
                            }}
                            displayEmpty
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="D">D</MenuItem>
                          </Select>
                        </TableCell>
                      ) : (
                        <TableCell
                          align="center"
                          sx={{
                            cursor: "default",
                          }}
                        >
                          {row.status}
                        </TableCell>
                      )}

                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.login_cities.length > 0
                          ? row.login_cities.join(", ")
                          : "No cities found"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.created}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.last_login}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        {row.password_last_updated + " days"}
                      </TableCell>
                    </TableRow>
                  ))}
                  {formData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`The existing user account at ${
              data ? data.previousLocation : ""
            } will be deactivated. Do you want to proceed?`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => setOpenModal(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => changeUserLocation(data)}
              autoFocus
              color="primary"
              sx={{ ml: "10px" }}
              variant="contained"
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </WithPermissionFallback>
    </>
  );
};

export default UserSearch;
