import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  ClassificationList,
  AddClassifcition,
  DeleteClassifcition,
} from "../../../api";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  Chip,
  Paper,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import hasPermission from "../../../utils/hasPermission";
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const EncounterClassification = ({
  classificationDialog,
  classificationDialogClose,
  childToParent,
}) => {
  const [text, setText] = useState("");
  const [encounterClassification, setEncounterClassification] = useState([]);

  const classificationList = () => {
    ClassificationList()
      .then((res) => {
        if (res.statusCode === 200) {
          setEncounterClassification(res.data);
          childToParent(res.data);
        } else {
          setEncounterClassification([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = (e) => {
    AddClassifcition({ name: e.target.value })
      .then((res) => {
        if (res.statusCode === 200) {
          classificationList();
          console.log("success");
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (chipToDelete) => () => {
    DeleteClassifcition({ id: chipToDelete })
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("success");
          classificationList();
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    classificationList();
  }, []);

  return (
    <>
      <Dialog
        aria-labelledby="add-edit-locker-room-title"
        aria-describedby="add-edit-locker-room-desc"
        open={classificationDialog}
        onClose={classificationDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="add-edit-locker-room-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Encounter Classifications
          </Typography>
          <IconButton onClick={classificationDialogClose}>
            <CancelRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-edit-locker-room-desc">
            INSTRUCTIONS: Click into the box below to add your classification.
            Type the name, then click the enter key on your keyboard. If you
            make a mistake, remove and enter the name again.
          </DialogContentText>
          <Card variant="outlined" sx={{ marginTop: "15px" }}>
            <CardContent>
              <Paper
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {encounterClassification.map((data) => {
                  let icon;
                  return (
                    <ListItem key={data.value}>
                      <Chip
                        icon={icon}
                        label={data.key}
                        onDelete={
                          data.label === ""
                            ? undefined
                            : handleDelete(data.value)
                        }
                        disabled={!hasPermission("Poi", "deleteClassification")}
                      />
                    </ListItem>
                  );
                })}
              </Paper>
              <TextField
                placeholder="type to add"
                sx={{ marginTop: "15px" }}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAdd(e);
                    setText("");
                  }
                }}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={classificationDialogClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EncounterClassification;
