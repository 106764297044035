import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
  Typography,
  Grid,
  FormControl as MuiFormControl,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  ListTriggerWord,
  AddTriggerWord,
  DeleteTriggerWord,
} from "../../../api";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import hasPermission from "../../../utils/hasPermission";
import WithPermissionHide from "../../../utils/withPermissionHide";
const TriggerWords = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([{ words: "" }]);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleAddItem = () => {
    setFormData((prevFormData) => [...prevFormData, { words: "" }]);
  };
  const handleDelete = (index, row) => {
    if (row.id !== undefined) {
      deleteTriggerWord(row.id);
    }
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      newFormData.splice(index, 1);
      return newFormData;
    });
  };
  const handleFormChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...formData];
    list[index][name] = value;
    setFormData(list);
  };
  const deleteTriggerWord = (id) => {
    DeleteTriggerWord(id)
      .then((res) => {
        if (res.statusCode == 200) {
          // dispatch(getMsg("Data deleted successfully"));
          // dispatch(getSnackAlert("success"));
          // dispatch(getSnackOpen());
          // setTimeout(() => {
          //   dispatch(getSnackClose());
          // }, 3000);
        } else {
          // dispatch(getMsg("Something went wrong, please try again"));
          // dispatch(getSnackAlert("error"));
          // dispatch(getSnackOpen());
          // setTimeout(() => {
          //   dispatch(getSnackClose());
          // }, 3000);
        }
      })
      .catch((err) => {});
  };
  const addTriggerWord = () => {
    const errors = [];
    formData.forEach((item, index) => {
      if (!item.words) {
        errors.push(index);
      }
    });

    if (errors.length > 0) {
      setValidationErrors(errors);
      dispatch(getMsg("Please fill out the field"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      return;
    }
    // setLoading(true);
    let payload = {
      data: formData.map(
        ({ operator, created, modified, location_id, ...rest }) => rest
      ),
    };
    AddTriggerWord(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Data Saved successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
        listTriggerWord();
      })
      .catch((err) => {});
  };
  const listTriggerWord = () => {
    ListTriggerWord()
      .then((res) => {
        if (res.statusCode == 200) {
          setFormData(res.data);
        } else {
          console.log("something went wrong!");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    listTriggerWord();
  }, []);
  return (
    <>
      <WithPermissionFallback controller="Settings" action="listTriggerWord">
        <Typography variant="h4" gutterBottom>
          Trigger Words
        </Typography>
        <Typography variant="body2" gutterBottom mt={5}>
          Add words or phrases that you would like to track. Click the plus icon
          to add additional trigger words or phrases. If one of your trigger
          word is recorded in an incident report, task comment, person of
          interest encounter or lost and found record, SOVA automatically sends
          email and/or SMS messages to recipients listed in Settings-{">"}
          Communication-
          {">"}Trigger Words and shows a notification popup in the Web app. To
          receive trigger word alerts by email or SMS, be sure to add your name
          and email in Communication-{">"}Trigger words.
        </Typography>
        <Typography variant="h6" gutterBottom mt={3}>
          Add/Edit Trigger Words
        </Typography>
        {formData.map((row, index) => (
          <div key={index}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} mt={3}>
                {/* <FormControl> */}
                <TextField
                  fullWidth
                  id={`words-${index}`}
                  name="words"
                  value={row.words}
                  onChange={(event) => {
                    handleFormChange(event, index);
                  }}
                  placeholder="word or phrase i.e.fire,bomb threat"
                  disabled={!hasPermission("Settings", "addTriggerWord")}
                />
                {/* </FormControl> */}
              </Grid>
              <Grid item lg={6} md={6}>
                <Box mt={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddItem}
                    sx={{ minWidth: "40px", px: 0 }}
                    disabled={!hasPermission("Settings", "addTriggerWord")}
                  >
                    <AddIcon />
                  </Button>
                  {index != 0 && (
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleDelete(index, row)}
                      sx={{ minWidth: "40px", px: 0, ml: 2 }}
                      disabled={!hasPermission("Settings", "deleteTriggerWord")}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        ))}
        <Box mt={3}>
          <WithPermissionHide controller="Settings" action="addTriggerWord">
            <Button onClick={addTriggerWord} variant="contained">
              Submit
            </Button>
          </WithPermissionHide>
        </Box>
      </WithPermissionFallback>
    </>
  );
};

export default TriggerWords;
