import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Formik } from "formik";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomSelect from "../../../components/form-components/CustomSelect";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  GetEmployeeById,
  DepartmentList,
  AddAttendance,
  ViewAttendanceLog,
  UpdateAttendance,
  AttendanceLogUserList,
  GetDepartmentManager,
  AddAttendanceNotify,
} from "../../../api";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl as MuiFormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  InputAdornment,
  FormHelperText,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { TimePicker } from "@mui/lab";
import { format } from "date-fns";
import { handleApiResonseErrors } from "../../../helper/helper";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const name = [
  { key: "Cardiac Science", value: "option1" },
  { key: "Defibtech", value: "option2" },
  { key: "Heartsine", value: "option3" },
  { key: "Philips", value: "option4" },
  { key: "Medtronic", value: "option5" },
  { key: "ZOLL", value: "option6" },
  { key: "Other", value: "Other" },
];
const department = [
  { key: "Cardiac Science", value: "option1" },
  { key: "Defibtech", value: "option2" },
  { key: "Heartsine", value: "option3" },
  { key: "Philips", value: "option4" },
  { key: "Medtronic", value: "option5" },
  { key: "ZOLL", value: "option6" },
];
const typeData = [
  { key: "Call Off", value: "Call Off" },
  { key: "Call Off Covid", value: "Call Off Covid" },
  { key: "Call Off PTO", value: "Call Off PTO" },
  { key: "Call Off FMLO", value: "Call Off FMLO" },
  { key: "Late", value: "Late" },
  { key: "No Show/No Call", value: "No Show/No Call" },
  { key: "Departed Early", value: "Departed Early" },
];
const managers = [
  { title: "Sandeep Kundu" },
  { title: "Darin Redding" },
  { title: "Sanjeev Kundu" },
];
const EditLogAttendance = ({
  editLogAttendanceDialog,
  setEditLogAttendanceDialog,
  editLogAttendanceDialogOpen,
  editLogAttendanceDialogClose,
  id,
  attendanceLogList,
}) => {
  // const [timeCalled, setTimeCalled] = React.useState(null);
  // const [shiftTime, setShiftTime] = React.useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  // const [arrivalTime, setArrivalTime] = useState("timeClock");
  const [empId, setEmpId] = useState(null);
  const [viewData, setViewData] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [otherName, setOtherName] = useState("");

  // const selectArrivalTime = (event) => {
  //   setArrivalTime(event.target.value);
  //   console.log(event.target.value);
  // };

  const employeeId = () => {
    GetEmployeeById({ employee: empId })
      .then((res) => {
        if (res.statusCode === 200) {
          console.log(res.data);
        } else {
          setErrMsg("No Match Found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentList = () => {
    DepartmentList()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewAttendance = () => {
    setLoading(true);
    ViewAttendanceLog(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          handleManager(res.data.department_id);
          setLoading(false);
        } else {
          setViewData({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [userData, setUserData] = useState([]);
  const userList = () => {
    AttendanceLogUserList()
      .then((res) => {
        if (res.statusCode) {
          setUserData(res.data);
        } else {
          setUserData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [managerList, setManagerList] = useState([]);
  const handleManager = (value) => {
    GetDepartmentManager({ department: value })
      .then((res) => {
        if (res.statusCode == 200) {
          setManagerList(res.data);
        } else {
          setManagerList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let useData1 = [...userData, { key: "Other", value: "Other" }];
  const employeeList = [
    ...userData.map((item) => ({
      key: `${item.fname} ${item.lname}`,
      value: item.id,
    })),
    { key: "Other", value: "Other" },
  ];

  useEffect(() => {
    getDepartmentList();
    userList();
    viewAttendance();
  }, []);
  const {
    user_id,
    other_user_name,
    department_id,
    comment,
    type,
    time_called,
    number_called,
    call_shift_time,
    shift_time,
    arrival_time,
    time_verified_by,
    seeing_doctor,
    supervisor_notify,
    other_time_verified_by,
  } = viewData;

  let shiftTimeHrs = shift_time?.slice(0, 2);
  let shiftTimeMin = shift_time?.slice(3, 5);
  let shiftTime = new Date();
  shiftTime.setHours(shiftTimeHrs, shiftTimeMin, "00");

  let arrivalTimeHrs = arrival_time?.slice(0, 2);
  let arrivalTimeMin = arrival_time?.slice(3, 5);
  let arrivalTime = new Date();
  arrivalTime.setHours(arrivalTimeHrs, arrivalTimeMin, "00");

  let timeCalledHrs = time_called?.slice(0, 2);
  let timeCalledMin = time_called?.slice(3, 5);
  let timeCalled = new Date();
  timeCalled.setHours(timeCalledHrs, timeCalledMin, "00");

  let callShiftTimeHrs = call_shift_time?.slice(0, 2);
  let callShiftTimeMin = call_shift_time?.slice(3, 5);
  let callShiftTime = new Date();
  callShiftTime.setHours(callShiftTimeHrs, callShiftTimeMin, "00");

  console.log("user_id", user_id);
  let initialValues = {
    name: user_id == 0 ? "Other" : user_id || "",
    other_name: otherName ? otherName : other_user_name,
    department: department_id ? department_id : "",
    comment: comment ? comment : "",
    type: type ? type : "",
    seeing_a_doctor: seeing_doctor ? seeing_doctor : "",
    notified_supervisor: supervisor_notify ? supervisor_notify : "",
    time_called: time_called ? timeCalled : null,
    shift_time: shift_time ? shiftTime : null,
    called_from_number: number_called ? number_called : "",
    arrival_time: arrival_time ? arrivalTime : null,
    call_shift_time: call_shift_time ? callShiftTime : null,
    other_time_verified_by: other_time_verified_by
      ? other_time_verified_by
      : "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please select  name"),
    department: Yup.string().required("Please select  department"),
    type: Yup.string().required("Please select  type"),
    called_from_number: Yup.string()
      .nullable()
      .matches(/^[0-9]+$/, "Called From Number must be number")
      .min(10, "Called From Number must be at least 10 characters")
      .max(15, "Called From Number must be at most 15 characters"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setLoading(true);
      let payload = {
        id: id,
        name: values.name,
        other_user_name: values.other_name,
        department: values.department,
        comment: values.comment,
        type: values.type,
        time_called:
          values.time_called != null
            ? format(values.time_called, "HH:mm")
            : null,
        number_called: values.called_from_number,
        call_shift_time:
          values.call_shift_time != null
            ? format(values.call_shift_time, "HH:mm")
            : null,
        shift_time:
          values.shift_time != null ? format(values.shift_time, "HH:mm") : null,
        arrival_time:
          values.arrival_time != null
            ? format(values.arrival_time, "HH:mm")
            : null,
        other_time_verified_by: values.other_time_verified_by,
        seeing_doctor: values.seeing_a_doctor,
        supervisor_notify: values.notified_supervisor,
      };
      isNotify
        ? AddAttendanceNotify(payload)
            .then((res) => {
              if (res.statusCode === 200) {
                editLogAttendanceDialogClose();
                attendanceLogList();
                dispatch(getMsg("Record udpated successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              } else {
                dispatch(getMsg("Something went wrong!"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
                setLoading(false);
              }
            })
            .catch((err) => {
              let errors = err.data.errors;
              let error = handleApiResonseErrors(errors);
              dispatch(getMsg(error));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setLoading(false);
              setLoading(false);
            })
        : UpdateAttendance(payload)
            .then((res) => {
              if (res.statusCode === 200) {
                setLoading(false);
                editLogAttendanceDialogClose();
                attendanceLogList();
                dispatch(getMsg("Record udpated successfully"));
                dispatch(getSnackAlert("success"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              } else {
                setLoading(false);
                dispatch(getMsg("Something went wrong!"));
                dispatch(getSnackAlert("error"));
                dispatch(getSnackOpen());
                setTimeout(() => {
                  dispatch(getSnackClose());
                }, 3000);
              }
            })
            .catch((err) => {
              let errors = err.data.errors;
              let error = handleApiResonseErrors(errors);
              dispatch(getMsg(error));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setLoading(false);
            });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Dialog
        aria-labelledby="attendance-title-modal"
        aria-describedby="attendance-desc-modal"
        open={editLogAttendanceDialog}
        onClose={editLogAttendanceDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "650px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="attendance-title-modal"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Log Type: Attendance
          </Typography>
          <DialogActions>
            <IconButton
              onClick={editLogAttendanceDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} mt={3}>
                  <Grid item xs={12} md={12}>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="name"
                        fieldLabel="Name"
                        options={employeeList}
                        displayEmpty
                      />
                      <FormHelperText error>{errors.name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {values.name === "Other" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        {/* <CustomTextField
                          name="other_name"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Other Name"
                          autoComplete="off"
                        /> */}
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          options={userData.map((option) => option.key)}
                          onChange={(e, v) => {
                            setOtherName(v);
                            console.log("other user name", v);
                          }}
                          value={other_user_name ? other_user_name : ""}
                          // getOptionLabel={(option) => option.key}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              label="Other Name"
                              name="other_name"
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {values.department != "" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <Autocomplete
                          multiple
                          id="managers"
                          options={managerList}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label="Managers"
                              placeholder=""
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomSelect
                        name="department"
                        fieldLabel="Department"
                        options={departmentList}
                        displayEmpty
                        customCallbackMethod={(evt) => {
                          if (evt.target.value) {
                            handleManager(evt.target.value);
                          }
                        }}
                      />
                      <FormHelperText error>{errors.department}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        name="comment"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Comment"
                        autoComplete="off"
                        placeholder="Sick, vehicle troubles, etc."
                        multiline={true}
                        rows={4}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomSelect
                        name="type"
                        fieldLabel="Type"
                        options={typeData}
                        displayEmpty
                      />
                      <FormHelperText error>{errors.type}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {values.type === "Call Off" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <TimePicker
                          label="Time called"
                          name="time_called"
                          value={values.time_called}
                          onChange={(newValue) => {
                            setFieldValue("time_called", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="time_called"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {values.type === "Call Off" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <CustomTextField
                          name="called_from_number"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Called From Number"
                          autoComplete="off"
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {values.type === "Call Off" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <TimePicker
                          label="Shift Time"
                          name="call_shift_time"
                          value={values.call_shift_time}
                          onChange={(newValue) => {
                            setFieldValue("call_shift_time", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="call_shift_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {values.type === "Late" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <TimePicker
                          label="Shift Time"
                          name="shift_time"
                          value={values.shift_time}
                          onChange={(newValue) => {
                            setFieldValue("shift_time", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="shift_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {values.type === "Late" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <TimePicker
                          label="Arrival Time"
                          name="arrival_time"
                          value={values.arrival_time}
                          onChange={(newValue) => {
                            setFieldValue("arrival_time", newValue);
                          }}
                          ampm={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="arrival_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {values.type === "Late" && (
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel>Arrival time verified by</FormLabel>
                        <RadioGroup
                          aria-labelledby="arrival-time"
                          name="other_time_verified_by"
                          value={values.other_time_verified_by}
                          onChange={(e) =>
                            setFieldValue(
                              "other_time_verified_by",
                              e.target.value
                            )
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Time Clock"
                            value="Time Clock"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Other Clock"
                            value="Other Clock"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="CCTV Camera"
                            value="CCTV Camera"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Other"
                            value="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Seeing a doctor ?</FormLabel>
                      <RadioGroup
                        aria-labelledby="seeing-a-doctor"
                        name="seeing_a_doctor"
                        row
                        value={values.seeing_a_doctor}
                        onChange={(e) =>
                          setFieldValue("seeing_a_doctor", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unknown"
                          value="Unknown"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>
                        Has supervisor already been notified ?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="notified-supervisor"
                        name="notified_supervisor"
                        row
                        value={values.notified_supervisor}
                        onChange={(e) =>
                          setFieldValue("notified_supervisor", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="No"
                          value="No"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unknown"
                          value="Unknown"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ ml: "15px" }}
                    type="submit"
                    onClick={() => setIsNotify(false)}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => setIsNotify(true)}
                  >
                    Save and notify
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
};

export default EditLogAttendance;
