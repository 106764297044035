import React, { useState } from "react";
import PropTypes from "prop-types";
import InspectionArea from "./property-inspection-inner-component/InspectionArea";
import PropertyArea from "./property-inspection-inner-component/PropertyArea";
import PropertyAreaManager from "./property-inspection-inner-component/PropertyAreaManager";
import InspectionCategory from "./property-inspection-inner-component/InspectionCategory";
import InspectionQuestion from "./property-inspection-inner-component/InspectionQuestion";
import InspectionResults from "./property-inspection-inner-component/InspectionResult";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Paper as MuiPaper,
  Divider as MuiDivider,
  Modal,
} from "@mui/material";
import WithPermissionHide from "../utils/withPermissionHide";
import hasPermission from "../utils/hasPermission";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function aedTabs(index) {
  return {
    id: `incident-tab-${index}`,
    "aria-controls": `incident-tabpanel-${index}`,
  };
}

const accessDenied = () => {
  return (
    <Box
      sx={{
        width: 300,
        bgcolor: "background.paper",
        borderRadius: 2,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        m: "auto",
      }}
    >
      <Typography variant="h2">Access Denied</Typography>
      <Typography sx={{ mt: 2 }}>
        You do not have permission to access this resource.
      </Typography>
    </Box>
  );
};

function PropertyInspection() {
  /* Tabs */
  const [tabValue, setTabValue] = React.useState(2);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <MuiPaper>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Aed-management-tabs"
            variant="scrollable"
          >
            <Tab label="Property Area" {...aedTabs(0)} />
            <Tab label="Property Area Manager" {...aedTabs(1)} />
            <Tab label="Inspection Area" {...aedTabs(2)} />
            <Tab label="Inspection Category" {...aedTabs(3)} />
            <Tab label="Inspection Questions" {...aedTabs(4)} />
            <Tab label="Inspection Results" {...aedTabs(5)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {hasPermission("PropertyInspection", "getPropertyAreaList") ? (
            <PropertyArea />
          ) : (
            accessDenied()
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {hasPermission("PropertyInspection", "getPropertyAreaManagerList") ? (
            <PropertyAreaManager />
          ) : (
            accessDenied()
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {hasPermission("PropertyInspection", "getInspectionAreaList") ? (
            <InspectionArea />
          ) : (
            accessDenied()
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          {hasPermission("PropertyInspection", "getInspectionCategoryList") ? (
            <InspectionCategory />
          ) : (
            accessDenied()
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          {hasPermission("PropertyInspection", "getInspectionQuestionList") ? (
            <InspectionQuestion />
          ) : (
            accessDenied()
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          {hasPermission("PropertyInspection", "getInspectionResultList") ? (
            <InspectionResults />
          ) : (
            accessDenied()
          )}
        </TabPanel>
      </MuiPaper>
    </>
  );
}

export default PropertyInspection;
