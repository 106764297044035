import React from "react";
import {
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  ListItemIcon,
  MenuItem,
  Checkbox,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import WithPermissionHide from "../../../utils/withPermissionHide";
function Corporaterow({
  corporateList,
  anchorEl,
  StyledMenu,
  isItemSelected,
  labelId,
  handleActionClick,
  handleActionClose,
  handleClick,
  handleDeleteOpen,
  deleteId,
}) {
  const checkedValue = useSelector((state) => state.corporate.checked);

  return (
    <>
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        tabIndex={-1}
        key={corporateList.id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, corporateList.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          />
        </TableCell>
        {/* Code for testing purpose for making rows dynamic */}
        {/* {checkedValue.map((item) => {
          return (
            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              sx={{ lg: 'whiteSpace: "nowrap"' }}
              // align="left"
            >
              {corporateList[item]}
            </TableCell>
          );
        })} */}
        {checkedValue.includes("corporate_name") && (
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            sx={{ whiteSpace: "nowrap" }}
            // sx={{ lg: 'whiteSpace: "nowrap"' }}
            // align="left"
          >
            {corporateList.corporate_name}
          </TableCell>
        )}
        {checkedValue.includes("first_name") && (
          <TableCell align="left">{corporateList.first_name}</TableCell>
        )}
        {checkedValue.includes("last_name") && (
          <TableCell align="left">{corporateList.last_name}</TableCell>
        )}
        {checkedValue.includes("email") && (
          <TableCell align="left">{corporateList.email}</TableCell>
        )}
        {checkedValue.includes("phone") && (
          <TableCell align="left">{corporateList.phone}</TableCell>
        )}
        {checkedValue.includes("fax") && (
          <TableCell align="left">{corporateList.fax}</TableCell>
        )}
        {checkedValue.includes("title") && (
          <TableCell align="left">{corporateList.title}</TableCell>
        )}
        <TableCell align="left">
          <IconButton
            aria-label="Settings"
            size="large"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleActionClick(e, corporateList.id)}
            style={{ color: "#1374C5" }}
            value={corporateList.id}
          >
            <SettingsIcon />
          </IconButton>
          <StyledMenu
            id={`icon-menu-${corporateList.id}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="edit-property"
              component={NavLink}
              to={`/accounts/edit-corporate/${deleteId}`}
            >
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" pl={1} />
            </MenuItem>
            <WithPermissionHide controller="Property" action="deleteCorporate">
              <MenuItem onClick={(handleActionClose, handleDeleteOpen)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Delete" pl={1} />
              </MenuItem>
            </WithPermissionHide>
          </StyledMenu>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Corporaterow;
