import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import ChangeOrder from "./ChangeOrder";
import AddInspectionQuestion from "./AddInspectionQuestion";
import EditInspectionQuestion from "./EditInspectionQuestion";
import {
  GetInspectionQuestionList,
  DeleteInspectionQuestion,
  InspectionAreaDropdown,
  InspectionCategoryDropdown,
} from "../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  LowPriority as LowPriorityIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import hasPermission from "../../utils/hasPermission";
import CustomPagination from "../../components/table-components/CustomPagination";
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Image = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 12px auto 0;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "470px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "150px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(area, category, question, labela, labelb, labelc, action) {
  return {
    area,
    category,
    question,
    labela,
    labelb,
    labelc,
    action,
  };
}
const rows = [
  createData(
    "Front Desk",
    "Safe work practices",
    "Test question added. Test",
    "Yes",
    "No",
    "N/A",
    2,
    0,
    0,
    5,
    1,
    0,
    0
  ),
  createData(
    "Front Desk",
    "Safe work practices",
    "All contact surfaces wiped down",
    "Yes",
    "No",
    "N/A",
    1,
    0,
    0,
    1,
    0,
    0,
    0
  ),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "area",
    numeric: true,
    disablePadding: true,
    label: "Area",
  },

  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "question",
    numeric: true,
    disablePadding: false,
    label: "Question",
  },
  {
    id: "labela",
    numeric: true,
    disablePadding: false,
    label: "a",
    textAlign: "center",
  },
  {
    id: "labelb",
    numeric: true,
    disablePadding: false,
    label: "b",
    textAlign: "center",
  },
  {
    id: "labelc",
    numeric: true,
    disablePadding: false,
    label: "c",
    textAlign: "center",
  },
  {
    id: "scorea",
    numeric: true,
    disablePadding: false,
    label: "a",
    textAlign: "center",
  },
  {
    id: "scoreb",
    numeric: true,
    disablePadding: false,
    label: "b",
    textAlign: "center",
  },
  {
    id: "scorec",
    numeric: true,
    disablePadding: false,
    label: "c",
    textAlign: "center",
  },
  {
    id: "severitya",
    numeric: true,
    disablePadding: false,
    label: "a",
    textAlign: "center",
  },
  {
    id: "severityb",
    numeric: true,
    disablePadding: false,
    label: "b",
    textAlign: "center",
  },
  {
    id: "severityc",
    numeric: true,
    disablePadding: false,
    label: "c",
    textAlign: "center",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell colSpan="5"></TableCell>
          <TableCell colSpan="3" sx={{ textAlign: "center" }}>
            Label
          </TableCell>
          <TableCell colSpan="3" sx={{ textAlign: "center" }}>
            Score
          </TableCell>
          <TableCell colSpan="3" sx={{ textAlign: "center" }}>
            Severity
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all data" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.categoryname}
              align={headCell.numeric ? "left" : "left"}
              sx={{ whiteSpace: "nowrap" }}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.categoryname ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.categoryname}
                direction={orderBy === headCell.categoryname ? order : "asc"}
                // onClick={createSortHandler(headCell.categoryname)}
                hideSortIcon={true}
              >
                {headCell.label}
                {headCell.icon}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    totalRecord,
    inspectionQuestionList,
    setDataToFilter,
    handleDeleteOpen,
    setCurrentPage,
  } = props;
  const [actions, setActions] = React.useState("");
  const [isFilter, setIsFilter] = useState(false);
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  // Change Order Dialog
  const [changeOrderDialog, setChangeOrderDialog] = useState(false);
  const changeOrderDialogOpen = () => {
    setChangeOrderDialog(true);
  };
  const changeOrderDialogClose = () => {
    setChangeOrderDialog(false);
  };
  // Add Inspection Question Dialog
  const [addInspectionQuestionDialog, setAddInspectionQuestionDialog] =
    useState(false);
  const addInspectionQuestionDialogOpen = () => {
    setAddInspectionQuestionDialog(true);
  };
  const addInspectionQuestionDialogClose = () => {
    setAddInspectionQuestionDialog(false);
  };
  // Inspection area select
  const [inspectionArea, setInspectionArea] = React.useState("");
  const selectInspectionArea = (event) => {
    setInspectionArea(event.target.value);
  };
  // Inspection area select
  const [inspectionCategory, setInspectionCategory] = React.useState("");
  const selectInspectionCategory = (event) => {
    setInspectionCategory(event.target.value);
  };

  const [question, setQuestion] = useState("");
  const [inspectionAreas, setInspectionAreas] = useState([]);
  const inspectionAreaDropdown = () => {
    InspectionAreaDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionAreas(res.data);
        } else {
          setInspectionAreas([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [inspectionCategorie, setInspectionCategorie] = useState([]);
  const inspectionCategoryDropdown = () => {
    InspectionCategoryDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionCategorie(res.data);
        } else {
          setInspectionCategorie([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It takes the values of the form fields and sets them to the dataToFilter state
   */
  const applySearch = () => {
    const payload = {
      question: question,
      inspection_area: inspectionArea,
      inspection_category: inspectionCategory,
    };
    setDataToFilter(payload);
  };

  /**
   * It resets the state of the filter form to its default values
   */
  const resetFilter = () => {
    setQuestion("");
    setInspectionArea("");
    setInspectionCategory("");
  };

  useEffect(() => {
    inspectionAreaDropdown();
    inspectionCategoryDropdown();
  }, []);
  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <FormControl mr={2} sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Actions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Actions"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem
                  disabled={
                    !hasPermission(
                      "PropertyInspection",
                      "deleteInspectionQuestion"
                    )
                  }
                  value={"Delete"}
                  onClick={handleDeleteOpen}
                >
                  Delete
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        </div>
        <Spacer />
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                placeholder="Search by question or click down arrow"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FormControl mt={3}>
                              <TextField
                                fullWidth
                                id="question"
                                label="Question"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="question"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                              />
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="inspection-area">
                                Inspection area
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="inspection-area"
                                value={inspectionArea}
                                label="Department"
                                onChange={selectInspectionArea}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select</MenuItem>
                                {inspectionAreas.map((data) => {
                                  return (
                                    <MenuItem value={data.key} key={data.value}>
                                      {data.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel shrink id="inspection-category">
                                Inspection category
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="inspection-category"
                                value={inspectionCategory}
                                label="Inspection category"
                                onChange={selectInspectionCategory}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select</MenuItem>
                                {inspectionCategorie.map((data) => {
                                  return (
                                    <MenuItem value={data.key} key={data.value}>
                                      {data.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  setIsFilter(true);
                                  handleCloseForm();
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    applySearch({});
                    setIsFilter(true);
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  addInspectionQuestionDialogOpen();
                  handleClose();
                }}
                disabled={
                  !hasPermission(
                    "PropertyInspection",
                    "addUpdateInspectionQuestion"
                  )
                }
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Question" pl={0} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeOrderDialogOpen();
                  handleClose();
                }}
                disabled={
                  !hasPermission("PropertyInspection", "questionReorder")
                }
              >
                <ListItemIcon>
                  <LowPriorityIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Change Order" pl={0} />
              </MenuItem>
              {/* <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem> */}
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      4 out of 4 visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Category Name"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Description"
                        />

                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Status"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Action"
                        />
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button color="primary" variant="contained">
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {changeOrderDialog && (
        <ChangeOrder
          changeOrderDialog={changeOrderDialog}
          setChangeOrderDialog={setChangeOrderDialog}
          changeOrderDialogOpen={changeOrderDialogOpen}
          changeOrderDialogClose={changeOrderDialogClose}
          inspectionQuestionList={inspectionQuestionList}
        />
      )}
      {addInspectionQuestionDialog && (
        <AddInspectionQuestion
          addInspectionQuestionDialog={addInspectionQuestionDialog}
          setAddInspectionQuestionDialog={setAddInspectionQuestionDialog}
          addInspectionQuestionDialogOpen={addInspectionQuestionDialogOpen}
          addInspectionQuestionDialogClose={addInspectionQuestionDialogClose}
          inspectionQuestionList={inspectionQuestionList}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [inspectionQuestionData, setInspectionQuestionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [singleData, setSingleData] = useState({});
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inspectionQuestionData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, categoryname) => {
    const selectedIndex = selected.indexOf(categoryname);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, categoryname);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (categoryname) => selected.indexOf(categoryname) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    setSingleData(data);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  // Edit inspection question dialog
  const [editInspectionQuestionDialog, setEditInspectionQuestionDialog] =
    useState(false);
  const editInspectionQuestionDialogOpen = () => {
    setEditInspectionQuestionDialog(true);
  };
  const editInspectionQuestionDialogClose = () => {
    setEditInspectionQuestionDialog(false);
  };

  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setId(null);
  };

  const inspectionQuestionList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      // sorting: {
      //   order: order,
      //   by: orderBy,
      // },
      ...dataToFilter,
    };
    GetInspectionQuestionList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setInspectionQuestionData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setInspectionQuestionData([]);
          setLoading(false);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const deleteInspectionQuestion = () => {
    let url = selected.length > 0 ? selected : id;
    DeleteInspectionQuestion(url)
      .then((res) => {
        if (res.statusCode === 200) {
          inspectionQuestionList();
          handleDeleteClose();
          dispatch(getMsg(`Record(s) has been deleted successfully`));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setSelected([]);
        } else {
          dispatch(getMsg("Something went wrong, please try again later"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again later"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  useEffect(() => {
    inspectionQuestionList();
  }, [rowsPerPage, currentPage, dataToFilter]);
  return (
    <div>
      {selected.length || id > 0 ? (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete inspection question?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={deleteInspectionQuestion}
              variant="contained"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You haven't selected any item!"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          inspectionQuestionList={inspectionQuestionList}
          setDataToFilter={setDataToFilter}
          handleDeleteOpen={handleDeleteOpen}
          setCurrentPage={setCurrentPage}
        />
        <Typography sx={{ my: "20px", pl: "16px" }}>
          Click 'Add Question' to add questions to categories for a given
          Inspection Area. You must setup categories and Inspection Areas first
          if you haven't already done so.
        </Typography>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={inspectionQuestionData.length}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(
                  inspectionQuestionData,
                  getComparator(order, orderBy)
                )
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          padding="none"
                        >
                          {row.area}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.category}
                        </TableCell>
                        <TableCell align="left" sx={{ minWidth: "250px" }}>
                          {row.question}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#e2ffe4",
                          }}
                        >
                          {row.option_1_title}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#fff6dc",
                          }}
                        >
                          {row.option_2_title}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#f6f6f6",
                          }}
                        >
                          {row.option_3_title}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#e2ffe4",
                          }}
                        >
                          {row.option_1_score}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#fff6dc",
                          }}
                        >
                          {row.option_2_score}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#f6f6f6",
                          }}
                        >
                          {row.option_3_score}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#e2ffe4",
                          }}
                        >
                          {row.option_1_severity}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#fff6dc",
                          }}
                        >
                          {row.option_2_severity}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            backgroundColor: "#f6f6f6",
                          }}
                        >
                          {row.option_3_severity}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id, row)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                editInspectionQuestionDialogOpen();
                                handleActionClose();
                              }}
                              disabled={
                                !hasPermission(
                                  "PropertyInspection",
                                  "addUpdateInspectionQuestion"
                                )
                              }
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleDeleteOpen();
                                handleActionClose();
                              }}
                              disabled={
                                !hasPermission(
                                  "PropertyInspection",
                                  "deleteInspectionQuestion"
                                )
                              }
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {inspectionQuestionData.length === 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * inspectionQuestionData.length,
                    }}
                  >
                    <TableCell colSpan={15} align="center">
                      No record found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {editInspectionQuestionDialog && (
        <EditInspectionQuestion
          editInspectionQuestionDialog={editInspectionQuestionDialog}
          setEditInspectionQuestionDialog={setEditInspectionQuestionDialog}
          editInspectionQuestionDialogOpen={editInspectionQuestionDialogOpen}
          editInspectionQuestionDialogClose={editInspectionQuestionDialogClose}
          inspectionQuestionList={inspectionQuestionList}
          singleData={singleData}
        />
      )}
    </div>
  );
}

function InspectionQuestion() {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InspectionQuestion;
