import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Card,
  Switch,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { styled } from "@mui/material/styles";
import {
  AddNewTask,
  TaskActivityList,
  TaskUserList,
  GetNonTourData,
  FetchNonTourData,
  ChangeTaskStatus,
  DeleteTask,
} from "../../../api";
import { useDispatch } from "react-redux";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 60, // Smaller width
  height: 24, // Smaller height
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 2,
    top: "50%",
    transform: "translateY(-50%)",
    "&.Mui-checked": {
      transform: "translateX(36px) translateY(-50%)", // Adjust for smaller switch
      "& + .MuiSwitch-track": {
        "&::before": {
          opacity: 1, // Show "Top"
        },
        "&::after": {
          opacity: 0, // Hide "Right"
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18, // Smaller thumb
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 15, // Adjusted for smaller track
    position: "relative",
    width: "100%",
    height: "100%",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "50%",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "10px", // Smaller font size
      color: "white", // White text for visibility
    },
    "&::before": {
      content: '"Yes"',
      left: 2, // Adjusted for smaller size
      opacity: 0,
    },
    "&::after": {
      content: '"No"',
      right: 2, // Adjusted for smaller size
      opacity: 1,
    },
  },
}));

const StandardActivityDialog = ({
  standardActivityDialog,
  setStandardActivityDialog,
}) => {
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [startTask, setStartTask] = useState(false); // Checkbox state

  const fetchLists = async () => {
    try {
      const [activityResponse, userResponse] = await Promise.all([
        TaskActivityList(),
        TaskUserList(),
      ]);
      setActivityList(activityResponse?.data || []);
      setUserList(userResponse?.data || []);
    } catch (error) {
      console.error("Error fetching lists:", error);
      setActivityList([]);
      setUserList([]);
    }
  };

  const fetchTaskList = async () => {
    try {
      const response = await GetNonTourData();
      const combinedTasks = [
        ...(response?.data?.notStarted || []),
        ...(response?.data?.started || []),
      ];
      const sortedTasks = combinedTasks
        .map((task) => ({
          ...task,
          isStarted: task.scan_time !== null && task.scan_time !== "",
        }))
        .sort((a, b) => new Date(b.informed_time) - new Date(a.informed_time));
      setTasks(sortedTasks);
    } catch (error) {
      console.error("Error fetching task list:", error);
    }
  };
  const fetchTaskDetails = async (taskId) => {
    try {
      const response = await FetchNonTourData({ non_tour_id: taskId });
      const taskData = response?.data;
      setEditTask({
        activity: taskData?.TaskID.toString(),
        officer: taskData?.UserID.toString(),
        location: taskData?.task_location,
        informant: taskData?.informant,
        comment: taskData?.Comment,
      });
    } catch (error) {
      console.error("Error fetching task details:", error);
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  useEffect(() => {
    if (standardActivityDialog) {
      fetchLists();
      fetchTaskList();
    }
  }, [standardActivityDialog]);

  const handleSave = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const payload = {
        Nontourdata: {
          TaskID: values?.activity,
          UserID: values?.officer,
          location: values?.location,
          informant: values?.informant,
          Comment: values?.comment,
          start_time: startTask ? "1" : "0",
          ScanTime: "",
        },
      };
      await AddNewTask(payload);
      dispatch(getMsg("Task Added Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      fetchTaskList();
      resetForm();
      setStandardActivityDialog(false);
    } catch (error) {
      console.error("Error saving activity:", error);
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
    setLoading(false);
  };

  const handleToggleSwitch = async (taskId, currentStatus) => {
    const newStatus = currentStatus ? "NO" : "YES";
    try {
      await ChangeTaskStatus({ typeId: taskId, status: newStatus });
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.nontourdata_id === taskId
            ? { ...task, isStarted: !currentStatus }
            : task
        )
      );
      dispatch(getMsg("Task Status Updated Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } catch (error) {
      console.error("Error toggling task status:", error);
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await DeleteTask({ nontourdataID: taskId });
      fetchTaskList();
    } catch (error) {
      console.error("Error toggling task status:", error);
    }
  };

  const validationSchema = Yup.object({
    activity: Yup.string().required("Activity is required"),
    officer: Yup.string().required("Officer is required"),
    location: Yup.string().required("Location is required"),
    informant: Yup.string().required("Informant is required"),
    comment: Yup.string(),
  });

  return (
    <Dialog
      open={standardActivityDialog}
      onClose={() => setStandardActivityDialog(false)}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: 2,
            overflow: "hidden",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {editTask ? "Edit Activity" : "Add Activity"}
        </Typography>
        <IconButton onClick={() => setStandardActivityDialog(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{
          activity: editTask?.activity || "",
          officer: editTask?.officer || "",
          location: editTask?.location || "",
          informant: editTask?.informant || "",
          comment: editTask?.comment || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.activity && Boolean(errors.activity)}
                  >
                    <InputLabel id="activity-select-label">Activity</InputLabel>
                    <Select
                      labelId="activity-select-label"
                      name="activity"
                      value={values.activity}
                      onChange={(e) =>
                        setFieldValue("activity", e.target.value)
                      }
                    >
                      {activityList.map((activity) => (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.activity && errors.activity && (
                      <Typography variant="caption" color="error">
                        {errors.activity}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={touched.officer && Boolean(errors.officer)}
                  >
                    <InputLabel id="officer-select-label">Officer</InputLabel>
                    <Select
                      labelId="officer-select-label"
                      name="officer"
                      value={values.officer}
                      onChange={(e) => setFieldValue("officer", e.target.value)}
                    >
                      {userList.map((officer) => (
                        <MenuItem key={officer.id} value={officer.id}>
                          {officer.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.officer && errors.officer && (
                      <Typography variant="caption" color="error">
                        {errors.officer}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="location"
                    label="Location"
                    fullWidth
                    value={values.location}
                    onChange={handleChange}
                    error={touched.location && Boolean(errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="informant"
                    label="Informant"
                    fullWidth
                    value={values.informant}
                    onChange={handleChange}
                    error={touched.informant && Boolean(errors.informant)}
                    helperText={touched.informant && errors.informant}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="comment"
                    label="Comment"
                    fullWidth
                    multiline
                    rows={2}
                    value={values.comment}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={startTask}
                    onChange={(e) => setStartTask(e.target.checked)}
                  />
                  <Typography>Start task upon saving</Typography>
                </Box>

                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => setStandardActivityDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : "Save"}
                  </Button>
                </DialogActions>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  overflowY: "auto",
                  mt: 3,
                  background: "#f5f5f5",
                  p: 1,
                  borderRadius: "8px",
                  height: "400px",
                }}
              >
                {tasks.map((task) => (
                  <Card
                    key={task.nontourdata_id}
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                      p: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      borderLeft: "4px solid #1976d2",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "1rem", color: "#d32f2f" }}
                      >
                        {task.task_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Location: {task.task_location || "N/A"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Comment: {task.comment || "N/A"}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "#757575", fontSize: "0.8rem" }}
                      >
                        {moment(task.informed_time).fromNow()}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography variant="body2">Started</Typography>
                        <CustomSwitch
                          checked={task.isStarted}
                          onChange={() =>
                            handleToggleSwitch(
                              task.nontourdata_id,
                              task.isStarted
                            )
                          }
                        />
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          onClick={() => {
                            fetchTaskDetails(task.nontourdata_id);
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteTask(task.nontourdata_id);
                          }}
                          disabled={task?.isStarted}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton>
                          <TaskAltIcon color="success" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Card>
                ))}
              </Box>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default StandardActivityDialog;
