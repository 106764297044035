import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import CustomTextField from "../../components/form-components/CustomTextField";
import { DatePicker, TimePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import MUIRichTextEditor from "mui-rte";
import * as Yup from "yup";
import { ChromePicker } from "react-color";
import { addDays } from "date-fns";
import {
  Button as MuiButton,
  Link,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography,
  Box,
  RadioGroup,
  FormGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  Grid,
  FormLabel,
  Switch,
  FormHelperText,
  TextField,
  MenuItem,
  Menu,
  Paper,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import { ViewKioskSettingDetails, SaveKioskSetting } from "../../api";
import {
  ExpandMore as ExpandMoreIcon,
  Square as SquareIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import hasPermission from "../../utils/hasPermission";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
// Color picker dropdown
const StyledDropDownColorPicker = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenuItem-root": { padding: 0 },
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        padding: "0px 0 10px",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);
// Switch outside
const CustomSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
      },
    },
  })
);
//Switch value inside
//For yes or No
const CustomSwitchYesNo = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 75,
  position: "absolute",
  right: "-8px",
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked": {
    transform: "translateX(42px)",
    padding: "8.5px 0px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#000000",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "53%",
      transform: "translateY(-53%)",
      width: 35,
      height: 16,
      fontSize: "10px",
      color: "#fff",
    },
    "&:before": {
      content: '"YES"',
      left: 15,
    },
    "&:after": {
      content: '"NO"',
      right: "-5px",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 26,
    height: 26,
    margin: "-3.3px 0px 0 -1px",
    boxShadow: "0px 1px 3px 1px #0000001a",
  },
}));
// Classes Styling
const useStyles = makeStyles((theme) => ({
  textEditor: {
    position: "relative",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    borderRadius: "4px",
    "& #mui-rte-container": {
      marginTop: 0,
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      position: "absolute",
      padding: "15px",
      display: "block",
    },
    "& #mui-rte-editor": {
      height: "180px",
      overflowY: "auto",
    },
    "& #mui-rte-editor-container": {
      height: "170px",
      overflowY: "auto",
      borderTop: "1px solid",
      borderColor:
        theme.palette.mode === "light" ? "rgb(215 215 215)" : "#56606f",
    },
    "& #mui-rte-toolbar": {
      padding: "5px 15px",
    },
    "& .DraftEditor-editorContainer": {
      padding: "15px",
    },
  },
  colorPicker: {
    boxShadow: "none !important",
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
function AddVisitorForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState({});

  const daysOfWeek = [
    { key: "Monday", value: "Monday" },
    { key: "Tuesday", value: "Tuesday" },
    { key: "Wednesday", value: "Wednesday" },
    { key: "Thursday", value: "Thursday" },
    { key: "Friday", value: "Friday" },
    { key: "Saturday", value: "Saturday" },
    { key: "Sunday", value: "Sunday" },
  ];
  const [description, setDescription] = useState("");
  const [privacyPolicyDescrip, setPrivacyPolicyDescrip] = useState("");
  const [blackListDescrip, setBlackListDescrip] = useState("");
  //Add Company Select Control

  let kiosk_time_from_hr = viewData.kiosk_time_from
    ? viewData.kiosk_time_from?.slice(0, 2)
    : "00";

  let kiosk_time_from_min = viewData.kiosk_time_from
    ? viewData.kiosk_time_from?.slice(3, 5)
    : "00";
  let kiosk_time_from_format = new Date();
  kiosk_time_from_format.setHours(
    kiosk_time_from_hr,
    kiosk_time_from_min,
    "00"
  );
  let kiosk_time_to_hr = viewData.kiosk_time_to
    ? viewData.kiosk_time_to?.slice(0, 2)
    : "00";

  let kiosk_time_to_min = viewData.kiosk_time_to
    ? viewData.kiosk_time_to?.slice(3, 5)
    : "00";
  let kiosk_time_to_format = new Date();
  kiosk_time_to_format.setHours(kiosk_time_to_hr, kiosk_time_to_min, "00");

  let start_time_hr = viewData.start_time
    ? viewData.start_time?.slice(0, 2)
    : "00";

  let start_time_min = viewData.start_time
    ? viewData.start_time?.slice(3, 5)
    : "00";
  let start_time_format = new Date();
  start_time_format.setHours(start_time_hr, start_time_min, "00");

  let end_time_hr = viewData.end_time ? viewData.end_time?.slice(0, 2) : "00";

  let end_time_min = viewData.end_time ? viewData.end_time?.slice(3, 5) : "00";
  let end_time_format = new Date();
  end_time_format.setHours(end_time_hr, end_time_min, "00");

  const initialValues = {
    text_button_color: viewData
      ? viewData.text_button_color
        ? viewData.text_button_color
        : ""
      : "",
    custom_button_text_color: viewData
      ? viewData.custom_button_text_color
        ? viewData.custom_button_text_color
        : ""
      : "",
    notification: viewData
      ? viewData.notification
        ? viewData.notification
        : "0"
      : "0",
    start_date: viewData
      ? viewData.start_date
        ? viewData.start_date
        : null
      : null,
    end_date: viewData ? (viewData.end_date ? viewData.end_date : null) : null,
    start_time: viewData
      ? start_time_format
        ? start_time_format
        : null
      : null,
    end_time: viewData
      ? viewData.end_time_format
        ? viewData.end_time_format
        : null
      : null,
    everyday: viewData
      ? viewData.everyday
        ? viewData.everyday
        : "Yes"
      : "Yes",
    week_days: viewData ? (viewData.week_days ? viewData.week_days : []) : [],
    // notification_text: "",
    kiosk_time_from: viewData
      ? kiosk_time_from_format
        ? kiosk_time_from_format
        : null
      : null,
    kiosk_time_to: viewData
      ? kiosk_time_to_format
        ? kiosk_time_to_format
        : null
      : null,
    visitor_use_kiosk: viewData
      ? viewData.visitor_use_kiosk
        ? viewData.visitor_use_kiosk
        : "0"
      : "0",
    partial_search: viewData
      ? viewData.partial_search
        ? viewData.partial_search
        : "0"
      : "0",
    print_pass: viewData ? (viewData.print_pass ? viewData.print_pass : 0) : 0,
    self_checkout: viewData
      ? viewData.self_checkout
        ? viewData.self_checkout
        : "0"
      : "0",
    delivery_drop_off: viewData
      ? viewData.delivery_drop_off
        ? viewData.delivery_drop_off
        : "0"
      : "0",
    rapid_pass: viewData ? (viewData.rapid_pass ? viewData.rapid_pass : 0) : 0,
    covid_survey: viewData
      ? viewData.covid_survey
        ? viewData.covid_survey
        : 0
      : 0,
    covid_questions: viewData
      ? viewData.covid_questions
        ? viewData.covid_questions
        : "0"
      : "0",
    ext_barcode_scanner: viewData
      ? viewData.ext_barcode_scanner
        ? viewData.ext_barcode_scanner
        : 0
      : 0,
    ext_scanner_type: viewData
      ? viewData.ext_scanner_type
        ? viewData.ext_scanner_type
        : ""
      : "",
    privacy_policy: viewData
      ? viewData.privacy_policy
        ? viewData.privacy_policy
        : "0"
      : "0",
    // pp_text: "",
    blacklist_message: viewData
      ? viewData.blacklist_message
        ? viewData.blacklist_message
        : "0"
      : "0",
    // b_custom_text: "",
  };

  const validationSchema = Yup.object().shape({
    text_button_color: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Default(Gray)", "Custom"]),
    // start_date: Yup.string().nullable().required("Please enter details"),
    // end_date: Yup.string().nullable().required("Please enter details"),
    // start_time: Yup.string().nullable().required("Please enter details"),
    // end_time: Yup.string().nullable().required("Please enter details"),
    everyday: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Yes", "No"]),
  });
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setLoading(true);
    let formatStartDate = values.start_date
      ? moment(values.start_date).format("YYYY-MM-DD")
      : "";
    let formatEndDate = values.end_date
      ? moment(values.end_date).format("YYYY-MM-DD")
      : "";
    let formatStartTime = values.start_time
      ? moment(values.start_time).format("HH:mm:ss")
      : "";
    let formatEndTime = values.end_time
      ? moment(values.end_time).format("HH:mm:ss")
      : "";
    let formatKioskTimeFrom = values.kiosk_time_from
      ? moment(values.kiosk_time_from).format("HH:mm:ss")
      : "";
    let formatKioskTimeTo = values.kiosk_time_to
      ? moment(values.kiosk_time_to).format("HH:mm:ss")
      : "";
    let payload = {
      text_button_color: values.text_button_color,
      custom_button_text_color: color,
      notification: values.notification,
      start_date: formatStartDate,
      end_date: formatEndDate,
      start_time: formatStartTime,
      end_time: formatEndTime,
      everyday: values.everyday,
      week_days: values.week_days,
      notification_text: description,
      kiosk_time_from: formatKioskTimeFrom,
      kiosk_time_to: formatKioskTimeTo,
      visitor_use_kiosk: values.visitor_use_kiosk,
      partial_search: values.partial_search,
      print_pass: values.print_pass,
      self_checkout: values.self_checkout,
      delivery_drop_off: values.delivery_drop_off,
      rapid_pass: values.rapid_pass,
      covid_survey: values.covid_survey,
      covid_questions: values.covid_questions,
      ext_barcode_scanner: values.ext_barcode_scanner,
      ext_scanner_type: values.ext_scanner_type,
      privacy_policy: values.privacy_policy,
      pp_text: privacyPolicyDescrip,
      blacklist_message: values.blacklist_message,
      b_custom_text: blackListDescrip,
    };
    SaveKioskSetting(payload)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          dispatch(getMsg("Kiosk setting saved successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          // updatePadsBatteryExpiredDialogClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          // darInquiryClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  // API call for getting the editable data of the user
  const getEditData = () => {
    setLoading(true);
    ViewKioskSettingDetails()
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          setDescription(res.data.notification_text);
          setPrivacyPolicyDescrip(res.data.pp_text);
          setBlackListDescrip(res.data.b_custom_text);
          setColor(res.data.custom_button_text_color);
          setLoading(false);
        } else {
          setViewData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setLoading(false);
      });
  };

  useEffect(() => {
    getEditData();
  }, []);

  // Color Picker Dropdown
  const [color, setColor] = useState("");
  const [colorpicker, setColorPicker] = useState(null);
  const handleClickDropdown = (event, id) => {
    setColorPicker(event.currentTarget);
  };
  const closeColorPicker = () => {
    setColorPicker(null);
    setColor(color);
  };

  const saveColorPicker = () => {
    setColorPicker(null);
    setColor(color);
  };

  const cancelColorPicker = () => {
    setColorPicker(null);
    setColor(
      viewData?.custom_button_text_color
        ? viewData?.custom_button_text_color
        : ""
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography sx={{ marginBottom: "20px" }}>
          The settings below control how the visitor kiosk is setup. Select from
          the options here, then when you are ready, click the blue Switch to
          Kiosk Mode button above from the kiosk tablet PC. To deactivate kiosk
          mode from the tablet, tap screen 7 times in succession then enter the
          password when prompted.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel id="text-button-bg-color">
                      Text and button background color
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="text-button-bg-color"
                      name="text_button_color"
                      value={values.text_button_color.toString()}
                      onChange={(e) => {
                        setFieldValue(
                          "text_button_color",
                          e.currentTarget.value
                        );
                      }}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Default(Gray)"
                        value="Default(Gray)"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Custom"
                        value="Custom"
                      />
                    </RadioGroup>
                    <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                      {touched.text_button_color && errors.text_button_color}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        name="custom_button_text_color"
                        placeholder={values.custom_button_text_color}
                        value={color}
                        onChange={handleChange}
                        sx={{ maxWidth: "180px" }}
                      />
                      <Button
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                        }}
                        onClick={handleClickDropdown}
                        aria-label="color-picker"
                        size="large"
                        aria-owns={anchorEl ? "color-picker" : undefined}
                        aria-haspopup="true"
                      >
                        <SquareIcon
                          sx={{
                            color: color
                              ? color
                              : viewData?.custom_button_text_color,
                            fontSize: "50px",
                            marginLeft: "10px",
                          }}
                        />
                        <ArrowDropDownIcon sx={{ cursor: "pointer" }} />
                      </Button>
                      <StyledDropDownColorPicker
                        id="color-picker"
                        anchorEl={colorpicker}
                        open={Boolean(colorpicker)}
                        onClose={closeColorPicker}
                        // onClose={() => handleSave(user.id)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem>
                          <Box>
                            <ChromePicker
                              color={color}
                              onChange={(updatedColor) => {
                                const newColor = updatedColor.hex;
                                setFieldValue(
                                  "custom_button_text_color",
                                  newColor
                                );
                                setColor(newColor);
                              }}
                              className={classes.colorPicker}
                            />
                            <Button
                              sx={{ mt: "10px" }}
                              onClick={cancelColorPicker}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={saveColorPicker}
                              color="primary"
                              variant="outlined"
                              autoFocus
                              sx={{ marginLeft: "5px", mt: "10px" }}
                            >
                              save
                            </Button>
                          </Box>
                        </MenuItem>
                      </StyledDropDownColorPicker>
                    </Box>
                  </FormControl>
                  <FormControl mt={3}>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Schedule notification text for home page"
                        control={
                          <CustomSwitchYesNo
                            name="notification"
                            checked={values.notification === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "notification",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      (Shows the notification text typed below on the kiosk home
                      screen)
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormLabel>By date range</FormLabel>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <DatePicker
                          id="start-date"
                          // label="Pass issue date"
                          value={values.start_date}
                          onChange={(newValue) => {
                            setFieldValue("start_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="start_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Date from",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <DatePicker
                          id="end-date"
                          // label="Pass issue date"
                          value={values.end_date}
                          onChange={(newValue) => {
                            setFieldValue("end_date", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="end_date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Date to",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormHelperText>
                    (Show all notification text only on these dates)
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography textAlign="center">OR</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormLabel>By time range</FormLabel>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <TimePicker
                          // label="Pass issue time"
                          id="start-time"
                          name="start_time"
                          value={values.start_time}
                          onChange={(newValue) => {
                            setFieldValue("start_time", newValue);
                          }}
                          // inputFormat="HH:mm:ss"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="start_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Time from",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <TimePicker
                          // label="Pass issue time"
                          id="end-time"
                          value={values.end_time}
                          onChange={(newValue) => {
                            setFieldValue("end_time", newValue);
                          }}
                          // inputFormat="HH:mm:ss"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="end_time"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Time to",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <FormLabel id="everyday">Everyday</FormLabel>
                        <RadioGroup
                          aria-labelledby="text-button-bg-color"
                          name="everyday"
                          value={values.everyday.toString()}
                          onChange={(e) => {
                            setFieldValue("everyday", e.currentTarget.value);
                          }}
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value="Yes"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value="No"
                          />
                        </RadioGroup>
                        <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                          {touched.everyday && errors.everyday}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>Day of week</FormLabel>
                    <FormGroup row name="week_days">
                      {daysOfWeek.map((item) => (
                        <FormControlLabel
                          key={item.key}
                          control={
                            <Checkbox
                              disableRipple
                              // name="events_prior_collapse"
                              value={item.value}
                              onChange={(e) => {
                                const { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("week_days", [
                                    ...values.week_days,
                                    value,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "week_days",
                                    values.week_days.filter((e) => e !== value)
                                  );
                                }
                                // console.log(
                                //   "+++",
                                //   values.events_prior_collapse
                                // );
                              }}
                              checked={values.week_days.includes(item.value)}
                            />
                          }
                          label={item.key}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>Notification text for home screen</FormLabel>
                    <Box
                      className={classes.textEditor}
                      sx={{ marginTop: "15px" }}
                    >
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          value={description}
                          onChange={setDescription}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                      {/* <MUIRichTextEditor
                        label={
                          <Typography sx={{ padding: "15px" }}>
                            Type something here...
                          </Typography>
                        }
                        name="notification_text"
                        //   onSave={save}
                        inlineToolbar={true}
                      /> */}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormLabel>Time when kiosk is available</FormLabel>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <TimePicker
                          // label="Pass issue time"
                          id="kiosk-start-time"
                          name="kiosk_time_from"
                          value={values.kiosk_time_from}
                          onChange={(newValue) => {
                            setFieldValue("kiosk_time_from", newValue);
                          }}
                          // inputFormat="HH:mm:ss"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="kiosk_time_from"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Time from",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={3}>
                        <TimePicker
                          // label="Pass issue time"
                          id="kiosk-end-time"
                          value={values.kiosk_time_to}
                          onChange={(newValue) => {
                            setFieldValue("kiosk_time_to", newValue);
                          }}
                          // inputFormat="HH:mm:ss"
                          ampm={false}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              name="kiosk_time_to"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "Time to",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="New visitor can use kiosk"
                        control={
                          <CustomSwitchYesNo
                            name="visitor_use_kiosk"
                            checked={values.visitor_use_kiosk === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "visitor_use_kiosk",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>
                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, new visitors are permitted to use the kiosk. If
                      no, the kiosk will only accept returning or pre-booked
                      visitors and new visitors must check in through the
                      security office.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Allow partial name search for visitors"
                        control={
                          <CustomSwitchYesNo
                            name="partial_search"
                            checked={values.partial_search === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "partial_search",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, partial name searches are permitted and visitors
                      checking-in may see names similar to their own when
                      selecting their name from a list. If no, the kiosk will
                      only accept exact name or phone number matches to ensure
                      privacy. Recommendation: Leave set to yes unless your
                      corporate compliance or other regulations like GDPR
                      require setting this toggle to no, or if you prefer
                      allowing visitors to check in using their phone number.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Visitor print their own passes and are automatically
                        checked in"
                        control={
                          <CustomSwitchYesNo
                            name="print_pass"
                            checked={values.print_pass === "1"}
                            onChange={(event, checked) => {
                              setFieldValue("print_pass", checked ? "1" : "0");
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, visitor is checked in immediately after pass is
                      printed. If no, pass is printed and must be scanned by
                      security personnel to check in visitor.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Visitors can self sign out?"
                        control={
                          <CustomSwitchYesNo
                            name="self_checkout"
                            checked={values.self_checkout === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "self_checkout",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, kiosk sign out button is visible and visitor can
                      check themselves out via a barcode scan or by entering
                      their name. If no, visitors must return to the security
                      office and be scanned out.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Show delivery drop off feature?"
                        control={
                          <CustomSwitchYesNo
                            name="delivery_drop_off"
                            checked={values.delivery_drop_off === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "delivery_drop_off",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, kiosk will allow shipping carriers to drop off
                      packages and record package delivery. This should only be
                      set to yes if your location has a valid subscription to
                      the packages module.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Activate RapidPass"
                        control={
                          <CustomSwitchYesNo
                            name="rapid_pass"
                            checked={values.rapid_pass === "1"}
                            onChange={(event, checked) => {
                              setFieldValue("rapid_pass", checked ? "1" : "0");
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, kiosk will allow checking in by NFC scan. Useful
                      to process check-ins quickly when you have a lot of people
                      arriving at the same time. Requires issuing key fob or NFC
                      sticker to visitor. Stickers can be applied to a visitors
                      driver license, business card, etc. Requires value of NFC
                      sticker to be added in NFC ID field in visitor profile.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {values.rapid_pass == 1 && (
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSwitchControl>
                        <FormControlLabel
                          label="Require Covid-19 Survey"
                          control={
                            <CustomSwitchYesNo
                              name="covid_survey"
                              checked={values.covid_survey === 1}
                              onChange={(event, checked) => {
                                setFieldValue("covid_survey", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                      </CustomSwitchControl>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Show Covid-19 questions?"
                        control={
                          <CustomSwitchYesNo
                            name="covid_questions"
                            checked={values.covid_questions === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "covid_questions",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, visitors must read and sign the Covid-19 questions
                      before entry is granted. Their signature certifies they do
                      not have symptoms. Covid-19 is the name for a virus that
                      caused a global pandemic in 2020.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="External Barcode Scanner"
                        control={
                          <CustomSwitchYesNo
                            name="ext_barcode_scanner"
                            checked={values.ext_barcode_scanner === 1}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "ext_barcode_scanner",
                                checked ? 1 : 0
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      Choose yes if you have an external barcode scanner
                      attached to your kiosk. Otherwise, choose no to use the
                      kiosk front-facing camera to scan barcodes.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {values.ext_barcode_scanner == 1 && (
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="scanner-type">Scanner Type</FormLabel>
                      <RadioGroup
                        aria-labelledby="scanner-type"
                        name="ext_scanner_type"
                        value={values.ext_scanner_type.toString()}
                        onChange={(e) => {
                          setFieldValue(
                            "ext_scanner_type",
                            e.currentTarget.value
                          );
                        }}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="Flat scanner"
                          label="Flat scanner"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="Handheld scanner"
                          label="Handheld scanner"
                        />
                      </RadioGroup>
                      {/* <CustomTextField name="color" placeholder="#f1f1f1" /> */}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Show privacy policy screen?"
                        control={
                          <CustomSwitchYesNo
                            name="privacy_policy"
                            checked={values.privacy_policy === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "privacy_policy",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, visitors must read and sign the privacy policy and
                      visitor agreement before entry is granted.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      Privacy policy and visitor agreement text
                    </FormLabel>
                    <Box
                      className={classes.textEditor}
                      sx={{ marginTop: "15px" }}
                    >
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          value={privacyPolicyDescrip}
                          onChange={setPrivacyPolicyDescrip}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                      {/* <MUIRichTextEditor
                        name="pp_text"
                        label={
                          <Typography sx={{ padding: "15px" }}>
                            Type something here...
                          </Typography>
                        }
                        //   onSave={save}
                        inlineToolbar={true}
                      /> */}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomSwitchControl>
                      <FormControlLabel
                        label="Show custom message for blacklisted or expired visitors?"
                        control={
                          <CustomSwitchYesNo
                            name="blacklist_message"
                            checked={values.blacklist_message === "1"}
                            onChange={(event, checked) => {
                              setFieldValue(
                                "blacklist_message",
                                checked ? "1" : "0"
                              );
                            }}
                          />
                        }
                      />
                    </CustomSwitchControl>

                    <FormHelperText sx={{ ml: 0 }}>
                      If yes, blacklisted visitors will see message below
                      instead of default message
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormLabel>
                      Custom text for blacklisted or expired visitors
                    </FormLabel>
                    <Box
                      className={classes.textEditor}
                      sx={{ marginTop: "15px" }}
                    >
                      <QuillWrapper>
                        <ReactQuill
                          theme="snow"
                          value={blackListDescrip}
                          onChange={setBlackListDescrip}
                          placeholder="Type something.."
                        />
                      </QuillWrapper>
                      {/* <MUIRichTextEditor
                        name="b_custom_text"
                        label={
                          <Typography sx={{ padding: "15px" }}>
                            Type something here...
                          </Typography>
                        }
                        //   onSave={save}
                        inlineToolbar={true}
                      /> */}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    disabled={!hasPermission("Visitor", "kioskSetting")}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save kiosk settings
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        {loading && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
      </CardContent>
    </Card>
  );
}

function KioskSettings() {
  return (
    <React.Fragment>
      <Helmet title="Kiosk Settings" />
      <WithPermissionFallback controller="Visitor" action="viewKioskSetting">
        <Typography variant="h3" gutterBottom display="inline">
          Kiosk Settings
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/visitors/kiosk-mode">
            Switch to Kiosk Mode
          </Link>
          <Typography>Kiosk Settings</Typography>
        </Breadcrumbs>
        <Divider my={6} />
        <AddVisitorForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default KioskSettings;
