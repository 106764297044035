import React, { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { VisitorStatusUpdate } from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";

const ListToggle = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value === "A" ? true : false);
  const handleActive = (status, id) => {
    let payload = {
      active: status === true ? "I" : "A",
    };
    VisitorStatusUpdate(id, payload)
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onClick={() => {
              setActive(!active);
              handleActive(active, id);
            }}
            disabled={!hasPermission("Visitor", "activeInactiveVisitor")}
          />
        }
        label=""
      />
    </>
  );
};

export default ListToggle;
