import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import {
  GetAssignments,
  GetPositionList,
  SaveAssignment,
  CommunicationListById,
} from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../tasks/Toaster";
import { handleApiResonseErrors } from "../../../../helper/helper";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Pagination,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  FormControl as MuiFormControl,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  Switch,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Search as SearchIcon,
  Cancel as CancelIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../../../utils/withPermissionFallback";
import { useParams } from "react-router-dom";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";

// Styled Components
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function Item(props) {
  const { sx, ...other } = props;
  return <Box sx={{ ...sx }} {...other} />;
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

// Sorting Helpers
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

// Table Header Definitions
const headCells = [
  {
    id: "postion_name",
    numeric: false,
    disablePadding: false,
    label: "Position Name",
  },
  {
    id: "comm_sub_count",
    numeric: true,
    disablePadding: false,
    label: "Comm Sub Count",
  },
  {
    id: "selectPosition",
    numeric: true,
    disablePadding: false,
    label: "Select",
  },
];

const EnhancedTableHead = ({ order, orderBy }) => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "center" : "left"}
          sx={{ whiteSpace: "nowrap" }}
          padding={headCell.disablePadding ? "none" : "normal"}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = ({
  totalRecord,
  setDataToSearch,
  setCurrentPage,
}) => {
  const [positionName, setPositionName] = useState("");

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecord} records found
        </Typography>
      </div>
      <Spacer />
      <Box mt={3} sx={{ display: "flex", flexWrap: "nowrap" }}>
        <Item sx={{ flexGrow: 1, width: "100%" }}>
          <TextField
            id="search"
            autoComplete="off"
            value={positionName}
            onChange={(e) => setPositionName(e.target.value)}
            placeholder="Search by position name"
            InputProps={{
              sx: { minWidth: "300px" },
              endAdornment: positionName && (
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    setDataToSearch("");
                    setPositionName("");
                    setCurrentPage(1);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setDataToSearch(positionName);
                setCurrentPage(1);
              }
            }}
          />
        </Item>
        <Item>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              height: "100%",
              marginRight: { lg: "50px" },
              marginLeft: "-3px",
            }}
            onClick={() => {
              setDataToSearch(positionName);
              setCurrentPage(1);
            }}
          >
            <SearchIcon />
          </Button>
        </Item>
      </Box>
      <Spacer />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  totalRecord: PropTypes.number.isRequired,
  setDataToSearch: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

function EnhancedTable() {
  const params = useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("postion_name");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [positionList, setPositionList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataToSearch, setDataToSearch] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const [assignmentData, setAssignmentData] = useState([]);
  const [userData, setUserData] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);

  const communicationType = [
    { key: "AED Expired Battery or Pads", value: "aed_expired_components" },
    { key: "AED Monthly Inspection", value: "aed_monthly_inspection" },
    { key: "Activity Alert", value: "activity_alert" },
    { key: "Attendance Record", value: "attendance_record" },
    { key: "Critical IR", value: "critical_IR" },
    { key: "Critical Task", value: "critical_TASK" },
    { key: "Critical Tour Button Missed", value: "tour_submit" },
    { key: "E-Notify", value: "emergency_alert" },
    { key: "EAR High Severity", value: "sevraty_scale" },
    { key: "Executive Summary", value: "executive_summary" },
    { key: "Expired Daily Task", value: "expired_daily_task" },
    { key: "Expired Visitor", value: "expired_visitor" },
    { key: "IR Reminder", value: "ir_reminder" },
    { key: "IR Status", value: "IR_status" },
    { key: "Iot System", value: "iot_system" },
    { key: "Lost and Found High Value", value: "lf_value" },
    { key: "Lost and Found Payment", value: "lostfound_payment" },
    { key: "Lost and Found Inquiry", value: "lostfound_inquiry" },
    {
      key: "Lost and Found Payment Not Done",
      value: "lf_shipping_payment_notdone",
    },
    { key: "Man Down / Lone Worker Protection", value: "main_down" },
    { key: "Minimum Tour Count Per Day", value: "count_tour_per_day" },
    { key: "Mobile User Panic Alert", value: "panic_alert" },
    { key: "Mobile User No Movement", value: "no_movement" },
    { key: "New IR", value: "new_IR" },
    { key: "No Login", value: "no_login" },
    { key: "No SOVA Activity for 24 Hours", value: "no_activity" },
    { key: "O1 custom", value: "O1" },
    { key: "O2 custom", value: "O2" },
    { key: "O3 custom", value: "O3" },
    { key: "O4 custom", value: "O4" },
    { key: "O5 custom", value: "O5" },
    { key: "Occ Health Manager Notify", value: "send_ohm" },
    { key: "Overdue Keys", value: "overdue_keys" },
    { key: "POI Close Proximity", value: "poi_proximity" },
    { key: "POI High Threat Level", value: "threat_level" },
    { key: "Package Notify - Employee", value: "package_notify_employee" },
    { key: "Package Notify - Guest", value: "package_notify" },
    { key: "Panic Mp3 Email", value: "panic_mp3_email" },
    { key: "SMS Group Notify", value: "self_enroll" },
    { key: "Send DAR", value: "send_dar" },
    { key: "Send EAR", value: "send_ear" },
    { key: "Send IR", value: "send_ir" },
    { key: "Send SR", value: "send_sr" },
    { key: "Submit Tour", value: "submit_tour" },
    { key: "Team Notify", value: "team_notify" },
    { key: "Trigger Word", value: "trigger_word" },
    { key: "Undelivered Package", value: "undelivered_package_notify" },
    { key: "User Blocked", value: "user_blocked" },
    { key: "Visitor Notify", value: "visitor_arrival" },
    { key: "Visitor Overstay", value: "visitor_overstay" },
    { key: "Shift Equipment Report", value: "shift_equipment_report" },
  ];

  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // State to track SMS/Email selection per communication
  // Structure: { [comm_value]: { sms: bool, email: bool } }
  const [selectedCommunications, setSelectedCommunications] = useState({});

  const getEquipPositionList = () => {
    const payload = {
      limit: rowsPerPage,
      position_name: dataToSearch,
      sorting: {
        order: order,
        by: orderBy,
      },
    };
    setLoading(true);
    GetPositionList(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setPositionList(res.data);
          setTotalRecord(res.meta.total);
          setLoading(false);
        } else {
          setLoading(false);
          setPositionList([]);
          setTotalRecord(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") return;
    setOpenSnack(false);
  };

  useEffect(() => {
    getEquipPositionList();
    const timer = setTimeout(() => {
      dispatch(getSnackClose());
    }, 4000);
    return () => clearTimeout(timer);
  }, [currentPage, rowsPerPage, dataToSearch, order, orderBy, dispatch]);

  const toggleRowExpansion = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      // User selected this position
      if (selectedRowId && selectedRowId !== row.id) {
        // If a different position is selected, reset email_status and phone_status to 0 for all communications
        const updatedCommunications = Object.keys(
          selectedCommunications
        ).reduce((acc, key) => {
          acc[key] = {
            ...selectedCommunications[key],
            email_status: 0,
            phone_status: 0,
          };
          return acc;
        }, {});
        setSelectedCommunications(updatedCommunications);
      }
      setSelectedRowId(row.id);
      setSelectedRowData(row);
    } else {
      // User unselected the position
      setSelectedRowId(null);
      setSelectedRowData(null);
      // Reset email_status and phone_status to 0 for all communications
      const resetCommunications = Object.keys(selectedCommunications).reduce(
        (acc, key) => {
          acc[key] = {
            ...selectedCommunications[key],
            email_status: 0,
            phone_status: 0,
          };
          return acc;
        },
        {}
      );
      setSelectedCommunications(resetCommunications);
    }
  };

  // Handle SMS/Email checks for each communication
  const handleCommCheck = (comm, type, checked) => {
    setSelectedCommunications((prev) => ({
      ...prev,
      [comm]: {
        ...prev[comm],
        [type]: checked,
      },
    }));
  };

  // Generate payload for SaveAssignments
  const generatePayload = () => {
    if (!selectedRowId) {
      dispatch(getMsg("Please select a position"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      return null;
    } else if (Object.keys(selectedCommunications).length === 0) {
      dispatch(getMsg("No communication is selected for the position"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      return null;
    }

    // Build array of objects
    const payload = Object.keys(selectedCommunications).map((comm) => {
      const { phone_status, email_status, id } =
        selectedCommunications[comm] || {};
      const basePayload = {
        communication_type: comm,
        department_id: userData.department_id,
        email: userData.email,
        email_status: email_status ? 1 : 0,
        first_name: userData.first_name,
        last_name: userData.last_name,
        new_irs: userData.new_irs,
        phone: userData.phone,
        phone_status: phone_status ? 1 : 0,
        position_id: selectedRowId,
        send_irs: userData.send_irs,
        status: userData.status,
        status_sms: userData.status_sms,
        venue_id: userData.venue_id,
      };
      if (id) {
        basePayload.id = id;
      }
      return basePayload;
    });

    return payload;
  };

  const handleSaveAssignments = () => {
    setIsSaving(true);
    const payload = generatePayload();
    if (payload) {
      SaveAssignment({ data: payload })
        .then((res) => {
          if (res.statusCode == 200) {
            dispatch(getMsg("Data saved successfully!"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setIsSaving(false);
          } else {
            dispatch(getMsg("Something went wrong!"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
            setIsSaving(false);
          }
        })
        .catch((err) => {
          console.log("Error while saving data: ", err);
          dispatch(getMsg("Something went wrong!"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setIsSaving(false);
        });
    }
  };

  const processAssignmentsData = (data) => {
    const result = {};

    // Iterate over each key in the data
    for (const key of Object.keys(data)) {
      // Assign the key and its first item from the array to the result object
      result[key] = data[key]?.[0] || null;
    }
    const firstKey = Object.keys(data)[0];
    const positionId = data[firstKey]?.[0]?.position_id || null;

    setSelectedRowId(positionId);
    setSelectedCommunications(result);
  };

  const getAssignments = (user) => {
    let payload = {
      email: user?.email,
      fname: user?.first_name,
      lname: user?.last_name,
    };
    GetAssignments(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setAssignmentData(res?.data);
          processAssignmentsData(res?.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };

  const getUserData = async () => {
    try {
      const res = await CommunicationListById(params?.id);

      if (res?.statusCode === 200) {
        setUserData(res?.data);
        getAssignments(res?.data);
      } else {
        console.error("Unexpected response structure: ", res);
      }
    } catch (err) {
      console.error("Error while getting user data: ", err);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <Paper mt={3}>
        <Toaster
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
          severity={alert}
          message={msg}
        />

        <EnhancedTableToolbar
          totalRecord={totalRecord}
          setDataToSearch={setDataToSearch}
          setCurrentPage={setCurrentPage}
        />
        <TableContainer
          component={MuiPaper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="sticky table"
          >
            <EnhancedTableHead order={order} orderBy={orderBy} />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={headCells.length} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(positionList, getComparator(order, orderBy)).map(
                  (row) => {
                    const isExpanded = expandedRow === row.id;
                    return (
                      <TableRow hover key={row.id}>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          <Accordion
                            expanded={isExpanded}
                            onChange={() => toggleRowExpansion(row.id)}
                            sx={{
                              boxShadow: "none",
                              "&:before": { display: "none" },
                              backgroundColor: "transparent",
                              flex: 0,
                              justifyContent: "space-between",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ArrowDropDownIcon />}
                              aria-controls={`panel-${row.id}-content`}
                              id={`panel-${row.id}-header`}
                              sx={{
                                padding: 0,
                                justifyContent: "flex-start",
                                "& .MuiAccordionSummary-content": {
                                  marginLeft: "8px",
                                  flex: 0,
                                  justifyContent: "flex-start",
                                },
                              }}
                            >
                              <Typography>{row.postion_name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "8px 0 0 0" }}>
                              {row.communication &&
                              row.communication.length > 0 ? (
                                row.communication.map((comm) => {
                                  const commObj = communicationType.find(
                                    (ct) => ct.value === comm
                                  );
                                  const commLabel = commObj
                                    ? commObj.key
                                    : comm;
                                  const smsChecked =
                                    selectedCommunications[comm]
                                      ?.phone_status || false;
                                  const emailChecked =
                                    selectedCommunications[comm]
                                      ?.email_status || false;
                                  return (
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        key={comm}
                                        sx={{ mb: 1 }}
                                      >
                                        <Typography
                                          sx={{
                                            marginRight: "8px",
                                            maxWidth: 230,
                                            flex: "0 0 230px",
                                          }}
                                        >
                                          {commLabel}
                                        </Typography>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={
                                                userData.status_sms !== 3
                                              }
                                              name={`${comm}-sms`}
                                              color="primary"
                                              checked={smsChecked}
                                              onChange={(e) =>
                                                handleCommCheck(
                                                  comm,
                                                  "phone_status",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          }
                                          label="SMS"
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={userData.status !== 3}
                                              name={`${comm}-email`}
                                              color="primary"
                                              checked={emailChecked}
                                              onChange={(e) =>
                                                handleCommCheck(
                                                  comm,
                                                  "email_status",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          }
                                          label="Email"
                                        />
                                      </Box>
                                    </div>
                                  );
                                })
                              ) : (
                                <Typography>No communications</Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          {row.comm_sub_count}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          <Checkbox
                            checked={selectedRowId === row.id}
                            onChange={(e) => handleCheckboxChange(e, row)}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {positionList.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length}>
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "8px" }}>
                Rows per page:
              </Typography>
              <Select
                labelId="rows-per-page-select-label"
                id="rows-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
      <Box mt={3} textAlign="right" mr={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSaveAssignments();
          }}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save Assignments"}
        </Button>
      </Box>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={false} onChange={() => {}} />}
        label="Dense padding"
      />
    </div>
  );
}

function PositionSelect() {
  const [snackopen, setSnackopen] = useState(true);
  const SnackState = useSelector((state) => state.equipment.snackStatus);
  const messageStatus = useSelector((state) => state.equipment.msg);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackopen(false);
  };

  return (
    <React.Fragment>
      <WithPermissionFallback controller="Equipment" action="getPositionList">
        {SnackState && (
          <Snackbar
            open={snackopen}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              variant="filled"
              sx={{ maxWidth: 600 }}
            >
              {messageStatus}
            </Alert>
          </Snackbar>
        )}
        <Helmet title="Position Select" />
        <Typography variant="h3" gutterBottom display="inline">
          Position Select
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default PositionSelect;
