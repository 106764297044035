import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import VenuesEdit from "./inner-component/VenuesEdit";
import {
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Menu,
  Switch,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@mui/material";
import VenueStatusToggle from "./inner-component/VenueStatusToggle";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { VenueList, DeleteVenue } from "../../../api";
import { display } from "@mui/system";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import hasPermission from "../../../utils/hasPermission";
import WithPermissionHide from "../../../utils/withPermissionHide";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Venues = () => {
  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          ...sx,
        }}
        {...other}
      />
    );
  }
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  }));
  Item.propTypes = {
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  const [venuesEditDialog, setVenuesEditDialog] = React.useState(false);
  const [listVenue, setListVenue] = useState([]);
  const [searchVenue, setSearchVenue] = useState("");
  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [updatedRow, setUpdatedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const handleSearchChange = (event) => {
    setIsFilter(true);
    setSearchVenue(search);
  };
  const venuesEditDialogOpen = () => {
    setVenuesEditDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  const venuesEditDialogClose = () => {
    setVenuesEditDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, row) => {
    console.log(event);
    setUpdatedRow(row);
    setAnchorEl(event.currentTarget);
  };
  console.log("rowData", updatedRow);
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const [action, setAction] = React.useState(null);

  const handleClick = (event) => {
    setAction(event.currentTarget);
  };

  const handleClose = () => {
    setAction(null);
  };
  const deleteVenue = () => {
    DeleteVenue(updatedRow.id)
      .then((res) => {
        if (res.statusCode === 200) {
          handleDeleteClose();
          venueList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const venueList = () => {
    let payload = {
      venue: searchVenue,
    };
    VenueList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setListVenue(res.data);
        } else {
          setListVenue([]);
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    venueList();
  }, [searchVenue]);
  return (
    <>
      <WithPermissionFallback controller="Settings" action="venueList">
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete attendance log?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={deleteVenue} autoFocus variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3} sx={{ alignItems: "center" }}>
          <Grid item lg={2}>
            <Typography variant="h2">Venues</Typography>
          </Grid>
          <Grid item lg={8}>
            <Box
              mt={3}
              sx={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <TextField
                id="search"
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setIsFilter(true);
                    handleSearchChange();
                  }
                }}
                placeholder="Search by venues name"
                inputProps={{
                  sx: {
                    width: "100%",
                  },
                }}
              />
              <Item>
                {isFilter ? (
                  <Button
                    variant="contained"
                    color="primary"
                    mt={6}
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      setSearchVenue("");
                      setIsFilter(false);
                      setSearch("");
                    }}
                  >
                    Clear filter
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      height: "100%",
                      marginLeft: "-3px",
                    }}
                    onClick={() => {
                      if (search !== "") {
                        handleSearchChange();
                        setIsFilter(true);
                      }
                    }}
                  >
                    Search
                  </Button>
                )}
              </Item>
            </Box>
          </Grid>
          <Grid item lg={2}>
            <IconButton
              sx={{
                marginLeft: "auto",
                display: "flex",
              }}
              aria-label="Settings"
              size="large"
              aria-owns={action ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              style={{ color: "#1374C5" }}
            >
              <FilterListIcon color="primary" />
            </IconButton>
            <StyledMenu
              id="icon-menu"
              anchorEl={action}
              open={Boolean(action)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  venuesEditDialogOpen();
                  setUpdatedRow({});
                }}
                disabled={!hasPermission("Settings", "addVenue")}
              >
                <ListItemText primary="Add Venue" pl={1} />
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Venue Name</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listVenue.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.venue_name}</TableCell>
                  <TableCell align="right">
                    <VenueStatusToggle value={row.status} id={row.id} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="Settings"
                      size="large"
                      aria-owns={anchorEl ? "icon-menu" : undefined}
                      aria-haspopup="true"
                      onClick={(event) => handleActionClick(event, row)}
                      style={{ color: "#1374C5" }}
                    >
                      <SettingsIcon color="primary" />
                    </IconButton>
                    <StyledMenu
                      id="icon-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleActionClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <WithPermissionHide
                        controller="Settings"
                        action="addVenue"
                      >
                        <MenuItem
                          onClick={() => {
                            venuesEditDialogOpen();
                            handleActionClose();
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary="Edit" pl={1} />
                        </MenuItem>
                      </WithPermissionHide>
                      <MenuItem
                        onClick={() => {
                          handleDeleteOpen();
                          handleActionClose();
                        }}
                        disabled={!hasPermission("Settings", "deleteVenue")}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" pl={1} />
                      </MenuItem>
                    </StyledMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {venuesEditDialog && (
          <VenuesEdit
            venuesEditDialog={venuesEditDialog}
            setVenuesEditDialog={setVenuesEditDialog}
            venuesEditDialogClose={venuesEditDialogClose}
            venuesEditDialogOpen={venuesEditDialogOpen}
            updatedRow={updatedRow}
            venueList={venueList}
          />
        )}
      </WithPermissionFallback>
    </>
  );
};

export default Venues;
