import React, { useState } from "react";
import { Switch } from "@mui/material";
import { ToggleFieldsStatus } from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";

const InputStatus = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value ? true : false);
  const handleActive = (status, id) => {
    console.log(status, id);
    let payload = {
      action: status === true ? "delete" : "add",
      id: id,
    };
    ToggleFieldsStatus(payload)
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Switch
        checked={active}
        onClick={() => {
          setActive(!active);
          handleActive(active, id);
        }}
        disabled={!hasPermission("Settings", "toggleFieldsStatus")}
      />
    </>
  );
};

export default InputStatus;
