import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { VisitorDashboardCount, GenerateEmergencyListPDF } from "../../api";
import SearchExistingRecords from "./inner-components/SearchExistingRecords";
import moment from "moment";

import {
  Grid,
  Paper as MuiPaper,
  Typography,
  CardContent,
  Card,
  Box,
} from "@mui/material";
import {
  Search as SearchIcon,
  Build as BuildIcon,
  FormatListBulleted as FormatListBulletedIcon,
  LocalHospital as LocalHospitalIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PermDataSetting as PermDataSettingIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import axios from "axios";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
const Paper = styled(MuiPaper)(spacing);

// styles by classes
const useStyles = makeStyles((theme) => ({
  processVisitors: {
    backgroundColor: "#2f65cb",
  },
  searchVisitor: {
    backgroundColor: "#15db81",
  },
  todaysList: {
    backgroundColor: "#daac16",
  },
  emergencList: {
    backgroundColor: "#da3e16",
  },
  repoprts: {
    backgroundColor: "#15acdc",
  },
  equipment: {
    backgroundColor: "#f96305",
  },
  commonClass: {
    display: "flex",
    padding: "40px 20px",
    justifyContent: "space-between",
    color: "#fff",
    borderRadius: "4px",
    textDecoration: "none",
  },
  countdown: {
    display: "block",
    padding: "15px 20px 20px",
    color: "#fff",
    borderRadius: "4px",
    textAlign: "center",
    textDecoration: "none",
    marginBottom: "15px",
    "& .MuiTypography-h6": {
      fontSize: "13px",
    },
    "& .MuiTypography-caption": {
      fontSize: "30px",
      fontWeight: "600",
    },
  },
}));

// Couont Down
function CounterProgress(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <Typography variant="determinate" {...props} />
      <Typography variant="caption" component="div">
        {`${Math.round(props.value)}`}
      </Typography>
    </Box>
  );
}
CounterProgress.propTypes = {
  value: PropTypes.number.isRequired,
};
function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [visitorCheckedIn, setVisitorCheckedIn] = useState(0);
  const [visitorCheckedInToday, setVisitorCheckedInToday] = useState(0);
  const [totalEquipOut, setTotalEquipOut] = useState(0);
  const [visitorPreBooked, setVisitorPreBooked] = useState(0);
  const [totalVisitorVisitedToday, setTotalVisitorVisitedToday] = useState(0);
  const today = moment().format("YYYY-MM-DD"); // Get and format today's date using Moment.js
  const todayListUrl = `/visitors/list-visitors?todayList=${today}`;

  const classes = useStyles();

  useEffect(() => {
    GetCounts();
  }, []);

  const GetCounts = () => {
    VisitorDashboardCount()
      .then((res) => {
        if (res.statusCode == 200) {
          setVisitorCheckedIn(res.data.totalVisitorCheckedIn);
          setVisitorCheckedInToday(res.data.totalVisitorCheckedInToday);
          setTotalEquipOut(res.data.totalEquipOut);
          setVisitorPreBooked(res.data.totalVisitorPreBooked);
          setTotalVisitorVisitedToday(res.data.totalVisitorVisitedToday);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  // Search Existing Records Dialog
  const [searchExistingRecordsDialog, setSearchExistingRecordsDialog] =
    useState(false);
  const searchExistingRecordsDialogOpen = () => {
    setSearchExistingRecordsDialog(true);
  };
  const searchExistingRecordsDialogClose = () => {
    setSearchExistingRecordsDialog(false);
  };
  const generatePDF = () => {
    GenerateEmergencyListPDF()
      .then((response) => {
        // Convert the Base64 data to a Uint8Array
        const decodedPdfData = Uint8Array.from(atob(response.data), (c) =>
          c.charCodeAt(0)
        );
        // Create a blob from the Uint8Array
        const blob = new Blob([decodedPdfData], { type: "application/pdf" });
        // Create a blob URL for the PDF
        const pdfUrl = URL.createObjectURL(blob);
        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };
  return (
    <div>
      <Grid container spacing={6} mt={0}>
        <Grid item xs={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                className={classNames(
                  classes.processVisitors,
                  classes.commonClass
                )}
                component={NavLink}
                to="/visitors/process-visitor"
              >
                <PermDataSettingIcon fontSize="large" />
                <Typography variant="h3" gutterBottom component="div">
                  Process Visitors
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                className={classNames(
                  classes.searchVisitor,
                  classes.commonClass
                )}
                sx={{ cursor: "pointer" }}
                onClick={searchExistingRecordsDialogOpen}
              >
                <SearchIcon fontSize="large" />
                <Typography variant="h3" gutterBottom component="div">
                  Search
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                className={classNames(classes.todaysList, classes.commonClass)}
                component={NavLink}
                to={todayListUrl}
              >
                <FormatListBulletedIcon fontSize="large" />
                <Typography variant="h3" gutterBottom component="div">
                  Today's List
                </Typography>
              </Box>
            </Grid>
            <WithPermissionHide controller="Visitor" action="emergencyListPdf">
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  className={classNames(
                    classes.emergencList,
                    classes.commonClass
                  )}
                  onClick={generatePDF}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <LocalHospitalIcon fontSize="large" />
                  <Typography variant="h3" gutterBottom component="div">
                    Emergency List
                  </Typography>
                </Box>
              </Grid>
            </WithPermissionHide>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                className={classNames(classes.repoprts, classes.commonClass)}
              >
                <InsertDriveFileIcon fontSize="large" />
                <Typography variant="h3" gutterBottom component="div">
                  Reports
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                className={classNames(classes.equipment, classes.commonClass)}
                component={NavLink}
                to="/equipment/process-equipment"
              >
                <BuildIcon fontSize="large" />
                <Typography variant="h3" gutterBottom component="div">
                  Equipment
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box
            className={classNames(classes.processVisitors, classes.countdown)}
            component={NavLink}
            to="/visitors/list-visitors?status=CHECKED-IN"
          >
            <CounterProgress value={visitorCheckedIn} />
            <Typography variant="h6" gutterBottom component="div">
              Checked in Visitors
            </Typography>
          </Box>
          <Box
            className={classNames(classes.todaysList, classes.countdown)}
            component={NavLink}
            to={todayListUrl}
          >
            <CounterProgress value={totalVisitorVisitedToday} />
            <Typography variant="h6" gutterBottom component="div">
              Visits Today
            </Typography>
          </Box>
          <Box
            className={classNames(classes.searchVisitor, classes.countdown)}
            component={NavLink}
            to="/visitors/list-visitors?status=Pre-book"
          >
            <CounterProgress value={visitorPreBooked} />
            <Typography variant="h6" gutterBottom component="div">
              Pre-book
            </Typography>
          </Box>
          <Box
            className={classNames(classes.equipment, classes.countdown)}
            component={NavLink}
            to="/visitors/list-visitors?status=Pre-book"
          >
            <CounterProgress value={totalEquipOut} />
            <Typography variant="h6" gutterBottom component="div">
              Equip Checked-Out
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {searchExistingRecordsDialog && (
        <SearchExistingRecords
          searchExistingRecordsDialog={searchExistingRecordsDialog}
          setSearchExistingRecordsDialog={setSearchExistingRecordsDialog}
          searchExistingRecordsDialogOpen={searchExistingRecordsDialogOpen}
          searchExistingRecordsDialogClose={searchExistingRecordsDialogClose}
        />
      )}
    </div>
  );
}

function VisitorDashboard() {
  return (
    <React.Fragment>
      <Helmet title="Visitor Dashboard" />
      <WithPermissionFallback controller="Visitor" action="dashboard">
        <Typography variant="h3" gutterBottom display="inline">
          Visitor and Equipment Processing
        </Typography>
        <Dashboard />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default VisitorDashboard;
