import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  HeatmapLayer,
  LoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import { sova } from "../../../config";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";

const Map = ({
  locations,
  CustomMarker,
  CustomInfoWindow,
  mapCenter,
  isHeatMap = false,
  zoomLevel = 18,
}) => {
  const libraries = ["visualization"];
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
    libraries,
  });
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mapRef = useRef(null); // Create a ref to store the map instance

  const convertToHeatmapPoints = () => {
    if (isLoaded && window.google) {
      return locations
        .map((location) => {
          const lat = parseFloat(location.latitute);
          const lng = parseFloat(location.longtute);
          if (!isNaN(lat) && !isNaN(lng)) {
            return new window.google.maps.LatLng(lat, lng);
          }
          return null;
        })
        .filter(Boolean); // Remove invalid points
    }
    return [];
  };

  // Helper function to validate mapCenter
  const isValidCenter = (center) => {
    if (
      center &&
      typeof center.lat === "number" &&
      typeof center.lng === "number" &&
      !isNaN(center.lat) &&
      !isNaN(center.lng)
    ) {
      return true;
    } else {
      console.warn("Invalid mapCenter provided:", mapCenter);
      dispatch(getMsg("Invalid mapCenter provided!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      return false;
    }
  };

  // Update the map's center when `mapCenter` changes
  useEffect(() => {
    if (mapRef.current && isValidCenter(mapCenter)) {
      mapRef.current.panTo(mapCenter); // Update center using `panTo`
    }
  }, [mapCenter]);

  return (
    <>
      {loadError ? (
        "Error loading maps"
      ) : !isLoaded ? (
        "Loading maps..."
      ) : (
        <GoogleMap
          id="map"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
          zoom={zoomLevel}
          center={mapCenter}
          options={{ mapTypeId: "satellite" }}
          onLoad={(map) => (mapRef.current = map)} // Store map instance in the ref
        >
          {/* Render Custom Markers */}
          {locations &&
            locations.map((location, index) =>
              CustomMarker ? (
                <CustomMarker
                  key={index}
                  location={location}
                  onClick={() => setSelectedLocation(location)}
                />
              ) : null
            )}

          {/* Render heatmap if provided */}
          {isHeatMap && (
            <HeatmapLayer
              data={convertToHeatmapPoints()}
              options={{
                radius: 30, // Adjust the radius for larger points
                opacity: 0.8, // Increase opacity to make it more visible
              }}
            />
          )}

          {/* Render Custom InfoWindow */}
          {selectedLocation && CustomInfoWindow ? (
            <CustomInfoWindow
              location={selectedLocation}
              onClose={() => setSelectedLocation(null)}
            />
          ) : null}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
