import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import StorageLocation from "./Inner-components/StorageLocation";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  UpdatePackage,
  ViewPackage as View,
  TenantDropdown,
  GetPackageStorage,
  StorageLocationDropDown,
} from "../../api";
import { format } from "date-fns";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  TextField,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormGroup,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  Box,
  Tooltip,
  CircularProgress,
  Paper,
  FormHelperText,
  CardActions,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Error as ErrorIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import { spacing } from "@mui/system";
import GoogleAutoCompleteField from "../../components/form-components/GoogleAutoCompleteField";
import { handleApiResonseErrors } from "../../helper/helper";
import SignatureCanvas from "react-signature-canvas";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const Image = styled.img`
  max-width: fit-content;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: contain;
`;
const ImageWrapper = styled.div`
  height: 200px;
  overflow: hidden;
  &:before {
  }
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const tenant = [
  { key: "General Incident Report", value: "general_incident_report" },
  { key: "Alleged Food Illness", value: "food_illness" },
  { key: "AED Deployment", value: "aed_deployment" },
];
const weight = [
  { key: "0-1 lb.", value: "0-1 lb." },
  { key: "2-5 lbs.", value: "2-5 lbs." },
  { key: "6-10 lbs.", value: "6-10 lbs." },
  { key: "11-20 lbs.", value: "11-20 lbs." },
  { key: "over 20 lbs.", value: "over 20 lbs." },
];
const currentStorageLocation = [
  { key: "In person", value: "In person" },
  { key: "Spoke", value: "Spoke" },
  { key: "Phone", value: "Phone" },
  { key: "Voicemail", value: "Voicemail" },
  { key: "Email", value: "Email" },
  { key: "Note to guestroom", value: "Note to guestroom" },
];
const departmentSelect = [
  { key: "General Incident Report", value: "general_incident_report" },
  { key: "Alleged Food Illness", value: "food_illness" },
  { key: "AED Deployment", value: "aed_deployment" },
];
function AddPackageForm() {
  const classes = useStyles();
  const [guestArrivalDate, setArrivalDate] = React.useState(null);
  const [packageReceivedDate, setPackageReceivedDate] = React.useState(null);
  const [deliveryDatetime, setDeliveryDatetime] = React.useState(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [department, setDepartment] = useState("guest");
  const handleDepartment = (event) => {
    setDepartment(event.target.value);
    console.log(event.target.value);
  };
  //Signature pad
  //Ref for signature pad
  const sigCanvas = useRef({});
  const [signImage, setSignImage] = useState(null);
  const clearPad = () => {
    sigCanvas.current.clear();
    setSignImage(null);
  };
  const savePad = () => {
    setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  const [storageData, setStorageData] = useState([]);
  const storageList = () => {
    StorageLocationDropDown()
      .then((res) => {
        if (res.statusCode === 200) {
          setStorageData(res.data);
        } else {
          setStorageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentGridLocaiton, setCurrentGridLocaiton] = useState([]);
  const getPackageStorage = (value) => {
    GetPackageStorage({ params: { parent_id: value } })
      .then((res) => {
        if (res.statusCode === 200) {
          setCurrentGridLocaiton(res.data);
        } else {
          setCurrentGridLocaiton([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [viewData, setViewData] = useState({});
  const viewPackage = () => {
    View(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setViewData(res.data);
          getPackageStorage(res.data.current_storage_location);
          setLoading(false);
        } else {
          setViewData({});
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const [tenantData, setTenantData] = useState([]);
  const tenantDropdownList = () => {
    TenantDropdown()
      .then((res) => {
        if (res.statusCode === 200) {
          setTenantData(res.data);
        } else {
          setTenantData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    viewPackage();
    storageList();
    setLoading(true);
    tenantDropdownList();
  }, []);

  const {
    package_uuid,
    guest_first_name,
    guest_last_name,
    guest_address_1,
    guest_address_2,
    guest_city,
    guest_state,
    guest_country,
    guest_zip,
    guest_phone,
    guest_arrival_date,
    guest_email,
    group_name,
    group_contact_person_name,
    group_contact_person_phone,
    group_contact_person_email,
    guest_registered,
    notify_by_mail,
    guest_room_no,
    arrival_date,
    departure_date,
    package_status,
    package_arrival_date,
    carrier_dropping,
    other_pack_drop,
    tracking_number,
    recipient_first_name,
    recipient_last_name,
    recipient_group_name,
    sender_name,
    package_size,
    pallet_id,
    package_weight,
    handling_instruction,
    description_note,
    current_storage_location,
    recipient_notified,
    how_contacted,
    how_contacted_date,
    recipient_notification,
    followup_required,
    followup_datetime,
    delivery_to_location,
    other_guestroom_number,
    ballroom_name,
    other_delivery_location,
    receiver_first_name,
    receiver_last_name,
    receiver_gender,
    receiver_id,
    delivery_notes,
    delivery_datetime,
    client_notification,
    webCamImage,
    signature_image,
    tenant,
    current_grid_location,
  } = viewData;
  const validationSchema = Yup.object().shape({
    package_status: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Expected", "Received", "Delivered"]),
    package_received_date: Yup.string()
      .nullable()
      .required("Please select the package arrival date"),

    carrier_dropping: Yup.string()
      .required("Please select one of these options")
      .oneOf(["DHL", "Fed-Ex", "UPS", "USPS", "Amazon", "OnTrac", "Other"]),
    tracking_number: Yup.string().required("Please enter tracking number"),
    recipient_first_name: Yup.string().required(
      "Please enter recipient first name"
    ),
    recipient_last_name: Yup.string().required(
      "Please enter recipient last name"
    ),
    sender_name: Yup.string().required("Please enter sender name"),
    package_size: Yup.string()
      .required("Please select one of these options")
      .oneOf(["Envelope", "Tube", "Box", "Brate", "Pallet"]),
    // package_notify: Yup.string()
    //   .required("Please select one of these options")
    //   .oneOf(["emp", "guest"]),
    current_storage_location: Yup.string().required(
      "Please select current storage location"
    ),

    followup_datetime: Yup.string()
      .nullable()
      .when(["followup_required"], (same, schema) => {
        return same == true
          ? schema.required("Please enter date and time")
          : schema;
      }),
  });

  let initialValues = {
    package_unique_id: package_uuid ? package_uuid : "",
    r_first_name: guest_first_name ? guest_first_name : "",
    r_last_name: guest_last_name ? guest_last_name : "",
    addrss_1: guest_address_1 ? guest_address_1 : "",
    address_2: guest_address_2 ? guest_address_2 : "",
    city: guest_city ? guest_city : "",
    state: guest_state ? guest_state : "",
    zip: guest_zip ? guest_zip : "",
    phone: guest_phone ? guest_phone : "",
    guest_arrival_date: new Date(guest_arrival_date)
      ? new Date(guest_arrival_date)
      : null,
    email: guest_email ? guest_email : "",
    gname: group_name ? group_name : "",
    contact_person_name: group_contact_person_name
      ? group_contact_person_name
      : "",
    contact_person_phone: group_contact_person_phone
      ? group_contact_person_phone
      : "",
    contact_person_email: group_contact_person_email
      ? group_contact_person_email
      : "",
    country: guest_country ? guest_country : "",
    tenant: tenant ? tenant : "",
    guest_registered: guest_registered ? guest_registered : "",
    notify_recipient: notify_by_mail == 1 ? true : false,
    guestroom_number: guest_room_no ? guest_room_no : "",
    arrival_date: new Date(arrival_date) ? new Date(arrival_date) : null,
    departure_date: departure_date ? departure_date : null,
    //packages stated
    package_status: package_status ? package_status : "",
    package_received_date: new Date(package_arrival_date)
      ? new Date(package_arrival_date)
      : null,
    carrier_dropping: carrier_dropping ? carrier_dropping : "",
    other_carrier: other_pack_drop ? other_pack_drop : "",
    // package_notify: "",
    // department: "",
    tracking_number: tracking_number ? tracking_number : "",
    recipient_first_name: recipient_first_name ? recipient_first_name : "",
    recipient_last_name: recipient_last_name ? recipient_last_name : "",
    recipient_g_name: recipient_group_name ? recipient_group_name : "",
    sender_name: sender_name ? sender_name : "",
    package_size: package_size ? package_size : "",
    pallet_id: pallet_id ? pallet_id : "",
    weight: package_weight ? package_weight : "",
    special_handeling_ins: handling_instruction ? handling_instruction : "",
    package_description: description_note ? description_note : "",
    current_storage_location: current_storage_location
      ? current_storage_location
      : "",
    current_grid_location: current_grid_location ? current_grid_location : "",
    recipient_notified: recipient_notified == 1 ? true : false,
    how_contacted: how_contacted ? how_contacted : "",
    date_and_time_contacted: new Date(how_contacted_date)
      ? new Date(how_contacted_date)
      : null,
    recipient_notification: recipient_notification == 1 ? true : false,
    followup_required: followup_required == 1 ? true : false,
    followup_datetime: new Date(followup_datetime)
      ? new Date(followup_datetime)
      : null,
    //Delivery started
    delivery_to_location: delivery_to_location ? delivery_to_location : "",
    other_guestroom_number: other_guestroom_number
      ? other_guestroom_number
      : "",
    ballroom_name: ballroom_name ? ballroom_name : "",
    other_delivery_location: other_delivery_location
      ? other_delivery_location
      : "",
    fname_of_person_receiving_package: receiver_first_name
      ? receiver_first_name
      : "",
    lname_of_person_receiving_package: receiver_last_name
      ? receiver_last_name
      : "",
    identification_verified: receiver_id ? receiver_id : "",
    delivery_datetime: new Date(delivery_datetime)
      ? new Date(delivery_datetime)
      : null,
    delivery_notes: delivery_notes ? delivery_notes : "",
    client_notification: client_notification ? client_notification : false,
  };

  // const handleSubmit = async (
  //   values,
  //   { resetForm, setErrors, setStatus, setSubmitting }
  // ) => {
  //   try {
  //     setLoading(true);
  //     let payload = {
  //       id: id,
  //       guest_first_name: values.r_first_name,
  //       guest_last_name: values.r_last_name,
  //       guest_address_1: values.addrss_1,
  //       guest_address_2: values.address_2,
  //       guest_city: values.city,
  //       guest_state: values.state,
  //       guest_zip: values.zip,
  //       guest_phone: values.phone,
  //       guest_arrival_date:
  //         values.guest_arrival_date !== null
  //           ? format(values.guest_arrival_date, "yyyy-MM-dd")
  //           : format(guest_arrival_date, "yyyy-MM-dd"),
  //       guest_email: values.email,
  //       group_name: values.gname,
  //       notify_by_mail: values.notify_recipient ? 1 : null,
  //       group_contact_person_name: values.contact_person_name,
  //       group_contact_person_phone: values.contact_person_phone,
  //       group_contact_person_email: values.contact_person_email,
  //       guest_country: values.country,
  //       guest_registered: values.guest_registered,
  //       package_status: values.package_status,
  //       package_arrival_date:
  //         values.package_received_date !== null
  //           ? format(values.package_received_date, "yyyy-MM-dd HH:mm:ss")
  //           : format(package_arrival_date, "yyyy-MM-dd HH:mm:ss"),
  //       carrier_dropping: values.carrier_dropping,
  //       // package_notify: values.package_notify,
  //       other_pack_drop: values.other_carrier,
  //       tracking_number: values.tracking_number,
  //       // department_id: values.department,
  //       recipient_first_name: values.recipient_first_name,
  //       recipient_last_name: values.recipient_last_name,
  //       recipient_group_name: values.recipient_g_name,
  //       sender_name: values.sender_name,
  //       guest_room_no: values.guestroom_number,
  //       package_size: values.package_size,
  //       pallet_id: values.pallet_id,
  //       package_weight: values.weight,
  //       handling_instruction: values.special_handeling_ins,
  //       description_note: values.package_description,
  //       current_storage_location: values.current_storage_location,
  //       current_grid_location: values.current_grid_location,
  //       recipient_notified: values.recipient_notified ? 1 : null,
  //       how_contacted: values.how_contacted,
  //       how_contacted_date:
  //         values.date_and_time_contacted !== null
  //           ? format(values.date_and_time_contacted, "yyyy-MM-dd HH:mm:ss")
  //           : format(how_contacted_date, "yyyy-MM-dd HH:mm:ss"),
  //       recipient_notification: values.recipient_notification ? 1 : null,
  //       followup_required: values.followup_required ? 1 : null,
  //       followup_datetime:
  //         values.followup_datetime !== null
  //           ? format(values.followup_datetime, "yyyy-MM-dd HH:mm:ss")
  //           : format(followup_datetime, "yyyy-MM-dd HH:mm:ss"),
  //       receive_package_image: null,
  //       delivery_to_location: values.delivery_to_location,
  //       other_delivery_location: values.other_delivery_location,
  //       other_guestroom_number: values.other_guestroom_number,
  //       ballroom_name: values.ballroom_name,
  //       receiver_first_name: values.fname_of_person_receiving_package,
  //       receiver_last_name: values.lname_of_person_receiving_package,
  //       // receiver_gender
  //       receiver_id: values.identification_verified,
  //       delivery_datetime:
  //         values.delivery_datetime !== null
  //           ? format(values.delivery_datetime, "yyyy-MM-dd HH:mm:ss")
  //           : format(delivery_datetime, "yyyy-MM-dd HH:mm:ss"),
  //       delivery_notes: values.delivery_notes,
  //       client_notification: client_notification ? 1 : null,
  //       signature_image: null,
  //     };

  //     UpdatePackage(payload)
  //       .then((res) => {
  //         if (res.statusCode === 200) {
  //           dispatch(getMsg("Record submitted successfully"));
  //           dispatch(getSnackAlert("success"));
  //           dispatch(getSnackOpen());
  //           setTimeout(() => {
  //             dispatch(getSnackClose());
  //           }, 3000);
  //           setLoading(false);
  //           navigate({
  //             pathname: "/packages/list-packages",
  //           });
  //         } else {
  //           dispatch(getMsg("Something went wrong!"));
  //           dispatch(getSnackAlert("error"));
  //           dispatch(getSnackOpen());
  //           setTimeout(() => {
  //             dispatch(getSnackClose());
  //           }, 3000);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         let errors = err.data.errors;
  //         let error = handleApiResonseErrors(errors);
  //         dispatch(getMsg(error));
  //         dispatch(getSnackAlert("error"));
  //         dispatch(getSnackOpen());
  //         setLoading(false);
  //       });
  //     setStatus({ sent: true });
  //     setSubmitting(false);
  //   } catch (error) {
  //     setStatus({ sent: false });
  //     setErrors({ submit: error.message });
  //     setSubmitting(false);
  //   }
  // };
  return (
    <>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            // onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <CustomTextField
                      name="package_unique_id"
                      label="Package Unique ID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      InputProps={{
                        readOnly: true,
                      }}
                      placeholder=""
                    />
                  </FormControl>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} mt={12}>
                    <Typography variant="h2" component="div" gutterBottom>
                      Guest Information
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="r_first_name"
                        label="Recipient first name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="John"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        name="r_last_name"
                        label="Recipient last name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormControl mt={3}>
                        <GoogleAutoCompleteField
                          name="addrss_1"
                          label="Address1"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="3214 N University Ave"
                          getApiResponse={(res) => {
                            setFieldValue("addrss_1", res.formatted_address);
                            setFieldValue("city", res.locality);
                            setFieldValue(
                              "state",
                              res.administrative_area_level_1
                            );
                            setFieldValue("zip", res.postal_code);
                            setFieldValue("country", res.country);
                          }}
                        />
                      </FormControl>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="address_2"
                        label="Address 2"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Apt 23"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="city"
                        label="City"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Miami"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="state"
                        label="State"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="FL"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="zip"
                        label="ZIP"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="xxxxx"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="phone"
                        label="Phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="xxx-xxx-xxxx"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DatePicker
                        name="guest_arrival_date"
                        label="Guest arrival date"
                        value={values.guest_arrival_date}
                        disabled
                        onChange={(newValue) => {
                          setFieldValue("guest_arrival_date", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="email"
                        label="Email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="j.smith@microsoft.com"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="gname"
                        label="Group name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Microsoft"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="contact_person_name"
                        label="Group contact person name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Stacy Taylor"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="contact_person_phone"
                        label="Group contact person phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="xxx-xxx-xxxx"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="contact_person_email"
                        label="Group contact person email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="me@groupname.com"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="country"
                        label="Country"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="USA"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        disabled
                        inputProps={{ readOnly: true }}
                        name="tenant"
                        fieldLabel="Tenant"
                        options={tenantData}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        displayEmpty
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Guest registered?</FormLabel>
                      <RadioGroup
                        name="guest_registered"
                        aria-labelledby="guest-registered"
                        row
                        value={values.guest_registered}
                        onChange={(e) =>
                          setFieldValue("guest_registered", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Yes"
                          value="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="No"
                          value="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="notify_recipient"
                            checked={values.notify_recipient}
                            onChange={(e) =>
                              setFieldValue(
                                "notify_recipient",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Notify recipient via email when package arrives"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  {values.guest_registered == "Yes" && (
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={4}>
                          <FormControl>
                            <CustomTextField
                              fullWidth
                              name="guestroom_number"
                              label="Guestroom number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              autoComplete="off"
                              InputProps={{
                                readOnly: true,
                              }}
                              placeholder=""
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl>
                            <DatePicker
                              name="arrival_date"
                              label="Arrival date (from reservation)"
                              ampm={false}
                              disabled
                              value={values.arrival_date}
                              onChange={(newValue) => {
                                setFieldValue("arrival_date", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "mm/dd/yyyy",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl>
                            <DatePicker
                              name="departure_date"
                              label="Departure date (from reservation)"
                              value={values.departure_date}
                              ampm={false}
                              disabled
                              onChange={(newValue) => {
                                setFieldValue("departure_date", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "mm/dd/yyyy",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} mt={12}>
                    <Typography variant="h2" component="div" gutterBottom>
                      Package Information
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Package status *</FormLabel>
                      <RadioGroup
                        name="package_status"
                        aria-labelledby="package-status"
                        row
                        value={values.package_status}
                        onChange={(e) =>
                          setFieldValue("package_status", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Expected"
                          value="Expected"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Received"
                          value="Received"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Delivered"
                          value="Delivered"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormHelperText error>
                      {touched.package_status && errors.package_status}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <DateTimePicker
                        name="package_received_date"
                        label="Package received date *"
                        value={values.package_received_date}
                        ampm={false}
                        disabled
                        onChange={(newValue) => {
                          setFieldValue("package_received_date", newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            name="package_received_date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Carrier dropping off package *</FormLabel>
                      <RadioGroup
                        name="carrier_dropping"
                        aria-labelledby="carrier-dropping"
                        row
                        value={values.carrier_dropping}
                        onChange={(e) =>
                          setFieldValue("carrier_dropping", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="DHL"
                          value="DHL"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Fed-Ex"
                          value="Fed-Ex"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="UPS"
                          value="UPS"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="USPS"
                          value="USPS"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Amazon"
                          value="Amazon"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="OnTrac"
                          value="OnTrac"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Other"
                          value="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormHelperText error>
                      {touched.carrier_dropping && errors.carrier_dropping}
                    </FormHelperText>
                    {values.carrier_dropping == "Other" && (
                      <FormControl>
                        <CustomTextField
                          fullWidth
                          name="other_carrier"
                          label="Other Carrier"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="other carrier"
                        />
                      </FormControl>
                    )}
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="handle-dept"
                                  name="package_notify"
                                  value={values.package_notify}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "package_notify",
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    label="Guest"
                                    value="guest"
                                    control={<Radio />}
                                    disabled
                                  />
                                  <FormControlLabel
                                    label="Employee"
                                    value="emp"
                                    control={<Radio />}
                                    disabled
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormHelperText error>
                                {touched.package_notify &&
                                  errors.package_notify}
                              </FormHelperText>
                              {values.package_notify == "emp" && (
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomSelect
                                     inputProps={{ readOnly: true }}
                                      name="department"
                                      fieldLabel="Department"
                                      options={departmentSelect}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      displayEmpty
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                            </Grid> */}
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="tracking_number"
                        label="Tracking number *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Scan to enter package tracking barcode"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        disabled
                        fullWidth
                        name="recipient_first_name"
                        label="Guest first name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="John"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        disabled
                        fullWidth
                        name="recipient_last_name"
                        label="Guest last name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Smith"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        disabled
                        fullWidth
                        name="recipient_g_name"
                        label="Recipient group name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Microsoft"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        disabled
                        fullWidth
                        name="sender_name"
                        label="Sender name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="ACME product, Inc."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Package size *</FormLabel>
                      <RadioGroup
                        name="package_size"
                        aria-labelledby="package-size"
                        row
                        value={values.package_size}
                        onChange={(e) =>
                          setFieldValue("package_size", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Envelope"
                          value="Envelope"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Tube"
                          value="Tube"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Box"
                          value="Box"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Crate"
                          value="Crate"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Pallet"
                          value="Pallet"
                        />
                      </RadioGroup>
                    </FormControl>
                    {values.package_size == "Pallet" && (
                      <FormControl>
                        <CustomTextField
                          fullWidth
                          name="pallet_id"
                          label="Pallet ID"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="pallet id"
                        />
                      </FormControl>
                    )}
                    <FormHelperText error>
                      {touched.package_size && errors.package_size}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        disabled
                        inputProps={{ readOnly: true }}
                        name="weight"
                        fieldLabel="Weight"
                        options={weight}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        displayEmpty
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="special_handeling_ins"
                        label="Special handeling instructions"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Fragile, requires refrigeration, etc."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomTextField
                        fullWidth
                        name="package_description"
                        label="Package description"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Small brown box"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CustomSelect
                        inputProps={{ readOnly: true }}
                        name="current_storage_location"
                        fieldLabel="Current storage location *"
                        options={storageData}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        displayEmpty
                      />
                    </FormControl>
                    <FormHelperText error>
                      {touched.current_storage_location &&
                        errors.current_storage_location}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <FormControl>
                                <CustomSelect
                                 inputProps={{ readOnly: true }}
                                  name="current_grid_location"
                                  fieldLabel="Current Grid Location"
                                  options={currentGridLocaiton}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  displayEmpty
                                />
                              </FormControl> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Current Grid Location
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="current_grid_location"
                        value={values.current_grid_location}
                        onChange={handleChange}
                        label=" Current Grid Location"
                        displayEmpty
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                      >
                        {/* <MenuItem value="">Select</MenuItem> */}
                        {currentGridLocaiton.map((item) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="recipient_notified"
                            checked={values.recipient_notified}
                            onChange={(e) =>
                              setFieldValue(
                                "recipient_notified",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Recipient notified"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  {values.recipient_notified && (
                    <>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomSelect
                            inputProps={{ readOnly: true }}
                            name="how_contacted"
                            fieldLabel="How Contacted"
                            options={currentStorageLocation}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            displayEmpty
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <DateTimePicker
                            name="date_and_time_contacted"
                            label="Date and Time Contacted"
                            value={values.date_and_time_contacted}
                            ampm={false}
                            disabled
                            onChange={(newValue) => {
                              setFieldValue(
                                "date_and_time_contacted",
                                newValue
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                name="date_and_time_contacted"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="recipient_notification"
                              checked={values.recipient_notification}
                              onChange={(e) =>
                                setFieldValue(
                                  "recipient_notification",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Notify staff when package is delivered"
                          disabled
                        />
                        <Tooltip
                          sx={{ marginTop: "8px" }}
                          title="Checking this will send a notification by email and/or SMS to recipients listed in communication screen, Package Notifiy - Guest tab when the package is successfully delivered"
                          placement="top"
                          color="primary"
                        >
                          <ErrorIcon />
                        </Tooltip>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="followup_required"
                              checked={values.followup_required}
                              onChange={(e) =>
                                setFieldValue(
                                  "followup_required",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Notify staff after a certain date if package is not deliverd yet"
                          disabled
                        />
                        <Tooltip
                          sx={{ marginTop: "8px" }}
                          title="Checking this will allow you to set a date and time after which notifications will be sent to recipients listed in the communication screen, Undelivered Package tab if the package is not delivered by the date entered"
                          placement="top"
                          color="primary"
                        >
                          <ErrorIcon />
                        </Tooltip>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {values.followup_required && (
                    <Grid item xs={12} md={6}>
                      <FormLabel>
                        At the date and time chosen here, recipients listed in
                        the Communication Screen, Undelivered Package tab, will
                        be notified by email and/or text if the package status
                        is undelivered
                      </FormLabel>
                      <FormControl>
                        <DateTimePicker
                          name="followup_datetime"
                          // label=""
                          value={values.followup_datetime}
                          ampm={false}
                          disabled
                          onChange={(newValue) => {
                            setFieldValue("followup_datetime", newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              fullWidth
                              name="followup_datetime"
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm/dd/yyyy H:i:s",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} mt={12}>
                    <Typography variant="h2" component="div" gutterBottom>
                      Delivery Information
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Delivery to location</FormLabel>
                      <RadioGroup
                        name="delivery_to_location"
                        aria-labelledby="delivery-to-location"
                        row
                        value={values.delivery_to_location}
                        onChange={(e) =>
                          setFieldValue("delivery_to_location", e.target.value)
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Guest"
                          value="Guest"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Ballroom"
                          value="Ballroom"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Returned to sender"
                          value="Returned to sender"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Other"
                          value="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                    {values.delivery_to_location == "Guest" && (
                      <FormControl mt={3}>
                        <CustomTextField
                          fullWidth
                          name="other_guestroom_number"
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Guestroom number, front dest etc "
                        />
                      </FormControl>
                    )}
                    {values.delivery_to_location == "Ballroom" && (
                      <FormControl mt={3}>
                        <CustomTextField
                          fullWidth
                          name="ballroom_name"
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Ballroom name"
                        />
                      </FormControl>
                    )}
                    {values.delivery_to_location == "Other" && (
                      <FormControl mt={3}>
                        <CustomTextField
                          fullWidth
                          name="other_delivery_location"
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Delivery location other than guestroom"
                        />
                      </FormControl>
                    )}
                    <FormControl mt={3}>
                      <CustomTextField
                        fullWidth
                        name="fname_of_person_receiving_package"
                        label="First name of person receiving package"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Mark"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomTextField
                        fullWidth
                        name="lname_of_person_receiving_package"
                        label="Last name of person receiving package"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        placeholder="Johnson"
                      />
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel>Identification verified</FormLabel>
                      <RadioGroup
                        name="identification_verified"
                        aria-labelledby="identification-verified"
                        row
                        value={values.identification_verified}
                        onChange={(e) =>
                          setFieldValue(
                            "identification_verified",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Driver license"
                          value="Driver license"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Passport"
                          value="Passport"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          disabled
                          label="Military ID"
                          value="Military ID"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl mt={3}>
                      <DateTimePicker
                        name="delivery_datetime"
                        label="Delivery datetime"
                        value={values.delivery_datetime}
                        onChange={(newValue) => {
                          setFieldValue("delivery_datetime", newValue);
                        }}
                        ampm={false}
                        disabled
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            fullWidth
                            name="delivery_datetime"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "mm/dd/yyyy H:i:s",
                            }}
                          />
                        )}
                      />
                      <FormControl mt={6}>
                        <CustomTextField
                          fullWidth
                          name="delivery_notes"
                          label="Delivery Notes"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Given to (name), placed in vacant guestroom, etc."
                        />
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="client_notification"
                            checked={values.client_notification}
                            onChange={(e) =>
                              setFieldValue(
                                "client_notification",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Email delivery receipt to the guest"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ImageWrapper>
                      <Image
                        alt="SaaS Dashboard | SOVA"
                        src="/static/img/lost-found/lost_and_found_pic.jpg"
                      />
                    </ImageWrapper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <Typography>ACKNOWLEDGED RECEIPT OF GOODS</Typography>
                      <FormLabel>
                        I, ,acknowledge receipt and delivery of the goods
                        described on this form. The undersigned further
                        acknowledges that said goods have been inspected and are
                        without visible signs of defect and final acceptance is
                        confirmed.
                      </FormLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      variant="outlined"
                      className="signatureCanvas"
                      sx={{ padding: "0px" }}
                    >
                      <CardContent sx={{ padding: "0px" }}>
                        {signature_image ? (
                          <Image
                            height={300}
                            src={signature_image ? signature_image : null}
                          />
                        ) : (
                          <CardContent sx={{ padding: "0px" }}>
                            <SignatureCanvas
                              ref={sigCanvas}
                              sx={{ padding: "0px" }}
                            />
                          </CardContent>
                        )}
                      </CardContent>
                      {/* <CardActions>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={savePad}
                        >
                          Save
                        </Button>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={clearPad}
                        >
                          Clear
                        </Button>
                      </CardActions> */}
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} alignSelf="center">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <KeyboardBackspaceIcon
                        sx={{
                          marginRight: { md: 5 },
                          display: { xs: "none", md: "block" },
                        }}
                        // sx={{ marginRight: "15px" }}
                      />
                      <ArrowUpwardIcon
                        sx={{
                          marginRight: { xs: 3 },
                          display: { xs: "block", md: "none" },
                        }}
                      />

                      <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        sx={{ marginBottom: 0 }}
                      >
                        Signature
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ViewPackage() {
  // Storage Location
  const [storageLocation, setStorageLocation] = React.useState(false);
  const storageLocationOpen = () => {
    setStorageLocation(true);
  };
  const storageLocationClose = () => {
    setStorageLocation(false);
  };
  return (
    <React.Fragment>
      <Helmet title="View Package" />
      <WithPermissionFallback controller="Packages" action="viewPackage">
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Typography variant="h3" gutterBottom display="inline">
              View Package
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/packages/list-packages">
                List Packages
              </Link>
              <Typography>View Package</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        {storageLocation && (
          <StorageLocation
            storageLocation={storageLocation}
            setStorageLocation={setStorageLocation}
            storageLocationOpen={storageLocationOpen}
            storageLocationClose={storageLocationClose}
          />
        )}
        <Divider my={6} />
        <AddPackageForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}
export default ViewPackage;
