import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import LiveIconDilaog from "./inner-components/FilterActivityDialog";
import LivePlusDialog from "./inner-components/LivePlusDialog";
import MessageIconDialog from "./inner-components/MessageIconDialog";
import IconPopup from "./inner-components/IconPopup";
import LiveModuleDilaog from "./inner-components/LiveModuleDilaog";
import LiveModuleModal from "./inner-components/LiveModuleModal";
import { sova } from "../../config";

import {
  Grid,
  Typography,
  IconButton,
  Box,
  Chip,
  ListItem,
  Card,
  List,
  Switch,
  Button,
  Menu,
  MenuItem,
  StyledMenu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  TextField,
  TextareaAutosize as MuiTextareaAutosize,
  CardContent,
  CircularProgress,
} from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  TaskActivityList,
  TaskUserList,
  AddNewTask,
  LiveDispatchList,
  GetNonTourDataList,
  ChangeTaskStatus,
  ChangeCompleteStatus,
  CompletedTask,
} from "../../api";
import { spacing } from "@mui/system";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  FilterAlt as FilterAltIcon,
  Visibility as VisibilityIcon,
  Fullscreen as FullscreenIcon,
  Cached as CachedIcon,
  Groups as GroupsIcon,
  EmailRounded as EmailRoundedIcon,
  PhotoOutlined as PhotoOutlinedIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  DirectionsWalk as DirectionsWalkIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { borderTop } from "@mui/system";
import { ChannelProvider, useChannel } from "ably/react";
import Map from "./inner-components/Map";
const label = { inputProps: { "aria-label": "Switch demo" } };
const initialTasks = {
  notStarted: [],
  started: [],
  completed: [],
};

function LiveModule() {
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      maxWidth: "140px",
      width: "100%",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        "& .MuiMenuItem-root": {
          display: "block",
          "& .MuiList-root": {
            paddingTop: "0px",
            marginTop: "15px",
            paddingBottom: "0px",
            "& .MuiListItem-root": {
              padding: "0px",
              background: "#7aa8ff0d",
              "& .MuiListItemButton-root": {
                padding: "3px 5px",
              },
            },
          },
        },

        "& .Mui-focusVisible": {
          backgroundColor: "transparent",
        },
      },
    },
  }));
  const ImageWrapper = styled.div``;
  const Image = styled.img`
    width: 100%;
  `;
  const TextareaAutosize = styled(MuiTextareaAutosize)`
    ${spacing};
    border: 1px solid
      ${(props) =>
        props.theme.palette.mode === "dark" ? "#c4c4c4" : "#c4c4c4"};
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 120px !important;
    background: transparent;
    padding: 20px 12px 15px;
    font-size: 13px;
    opacity: 0.8;
    font-weight: 200;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    letter-spacing: normal;
    width: 100%;
  `;
  const [addTask, setAddTask] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [ablyTestMessages, setAblyTestMessages] = useState([]);

  const sovaTest = useChannel("sova-test", "testing", (msg) => {
    console.log("Received message:", msg);
    setAblyTestMessages((prev) => [...prev, msg]);
    const newLocation = msg?.data;
    setLocations((prev) => [newLocation, ...prev]);
  });

  console.log("Ably Messageesss: 152 ---", ablyTestMessages);
  console.log("Sova Test Channel 153: --", sovaTest);

  const handleTaskOpen = () => {
    setAddTask(true);
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    if (addTask) {
      TaskActivityList()
        .then((response) => {
          console.log("TaskActivityList response:", response.data); // Debug log
          setActivityList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskActivityList:", error);
          setActivityList([]); // Set as empty array if there's an error
        });

      TaskUserList()
        .then((response) => {
          console.log("TaskUserList response:", response.data); // Debug log
          setUserList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskUserList:", error);
          setUserList([]); // Set as empty array if there's an error
        });
    }
  }, [addTask]);
  useEffect(() => {
    setLoading(true);
    LiveDispatchList()
      .then((response) => {
        const mapData = response.map_data || [];
        if (Array.isArray(mapData)) {
          setLocations(mapData);
        } else {
          setLocations([]);
        }
      })
      .catch((error) => {
        setLocations([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    GetNonTourDataList()
      .then((response) => {
        if (response.statusCode === 200 && response.data) {
          const updatedTasks = {
            notStarted: response.data.notStarted.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })),
            started: response.data.started.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })), // Adjust these if your response contains "started" tasks
            completed: response.data.completed.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })), // Adjust these if your response contains "completed" tasks
          };
          setTasks(updatedTasks);
        } else {
          console.error("Invalid response:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTasks(initialTasks); // Fallback to empty state
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [iconPopup, setIconPopup] = React.useState(false);
  const iconPopupOpen = () => {
    setIconPopup(true);
  };
  const iconPopupClose = () => {
    setIconPopup(false);
  };
  const [emailDialog, setEmailDialog] = React.useState(false);
  const emailDialogOpen = () => {
    setEmailDialog(true);
  };
  const emailDialogClose = () => {
    setEmailDialog(false);
  };

  const [livePlusDilaog, setLivePlusDilaog] = React.useState(false);
  const livePlusDilaogOpen = () => {
    setLivePlusDilaog(true);
  };
  const livePlusDilaogClose = () => {
    setLivePlusDilaog(false);
  };
  const [liveIconDilaog, setLiveIconDilaog] = React.useState(false);
  const liveIconDilaogOpen = () => {
    setLiveIconDilaog(true);
  };
  const liveIconDilaogClose = () => {
    setLiveIconDilaog(false);
  };

  const [liveModuleDilaog, setLiveModuleDilaog] = React.useState(false);
  const liveModuleDilaogOpen = () => {
    setLiveModuleDilaog(true);
  };
  const liveModuleDilaogClose = () => {
    setLiveModuleDilaog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const [liveModal, setLiveModal] = React.useState(false);
  const liveModalOpen = () => {
    setLiveModal(true);
  };
  const liveModalClose = () => {
    setLiveModal(false);
  };
  const [location, setLocation] = useState("");
  const [informant, setInformant] = useState("");
  const [comment, setComment] = useState("");

  // Google map
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });
  const [isDivVisible, setIsDivVisible] = useState(false);

  const handleButtonClick = () => {
    setIsDivVisible(true); // Show the div and hide the button
  };

  const handleCloseClick = () => {
    setIsDivVisible(false); // Hide the div and show the button
  };
  // Drag and Drop

  const [tasks, setTasks] = useState(initialTasks);
  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    // If dropped outside of a droppable area
    if (!destination) return;

    // If dropped in the same place, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    // Clone the source and destination task arrays
    const sourceTasks = [...tasks[source.droppableId]];
    const destinationTasks = [...tasks[destination.droppableId]];

    // Remove the dragged task from the source array
    const [movedTask] = sourceTasks.splice(source.index, 1);

    // Add the task optimistically to the destination
    destinationTasks.splice(destination.index, 0, movedTask);

    // Update the state optimistically
    setTasks((prevTasks) => ({
      ...prevTasks,
      [source.droppableId]: sourceTasks,
      [destination.droppableId]: destinationTasks,
    }));

    // Handle logic for tasks moved to "Completed"
    if (destination.droppableId === "completed") {
      const payload = {
        non_tour_id: movedTask.id, // Assuming the ID is in the task object
      };

      try {
        const response = await ChangeCompleteStatus(payload);

        if (response.statusCode !== 200) {
          throw new Error("Failed to change task status to completed");
        }

        // If successful, call CompletedTask with the required data
        const completedPayload = {
          id: movedTask.id,
          user_activity_id: movedTask.userActivityId, // Assuming you have this in the task data
          comment: movedTask.comment || "No comment", // Add default if missing
          followup: "N",
          passdown: "Y",
          dispatch_hdn_lon: "",
          dispatch_hdn_lat: "",
          dispatch_hdn_user_id: movedTask.selectedOfficer, // Example ID, should be dynamic based on user
          dispatch_hdn_user_name: movedTask.userName, // Example name
          dispatch_hdn_display_name: movedTask.taskName, // Example display name
          start_time: "23:31", // This should be dynamic or based on task data
          end_time: new Date().toISOString(), // Example current time
          informed_time: "23:31",
          informant: movedTask.informant, // Example informant
          location: movedTask.location, // Example location
          timeAdjusted: "",
          save_completed_session: "1",
        };

        await CompletedTask(completedPayload);
      } catch (error) {
        console.error("Error updating task to completed:", error);

        // Revert task back to "Started" or "Not Started" in case of error
        sourceTasks.splice(source.index, 0, movedTask);
        destinationTasks.splice(destination.index, 1);

        setTasks((prevTasks) => ({
          ...prevTasks,
          [source.droppableId]: sourceTasks,
          [destination.droppableId]: destinationTasks,
        }));
      }
    }

    // Prevent moving tasks from "Completed" to any other column
    if (
      source.droppableId === "completed" &&
      destination.droppableId !== "completed"
    ) {
      alert("This task is completed and cannot be moved.");
      // Revert the task back to "Completed"
      sourceTasks.splice(source.index, 0, movedTask);
      setTasks((prevTasks) => ({
        ...prevTasks,
        [source.droppableId]: sourceTasks,
      }));
    }

    // Handle the normal drag-and-drop between "Not Started" and "Started"
    if (
      (source.droppableId === "notStarted" &&
        destination.droppableId === "started") ||
      (source.droppableId === "started" &&
        destination.droppableId === "notStarted")
    ) {
      const newStatus = destination.droppableId === "started" ? "YES" : "NO";

      const payload = {
        typeId: movedTask.id,
        status: newStatus,
      };

      try {
        const response = await ChangeTaskStatus(payload);

        if (response.statusCode !== 200) {
          throw new Error("Failed to update task status");
        }

        // Optionally, update the moved task with additional data from response
        if (response.data) {
          movedTask.scanTime = response.data.ScanTime;
        }
      } catch (error) {
        console.error("Error updating task status:", error);

        // Revert the task back to its original column
        sourceTasks.splice(source.index, 0, movedTask);
        destinationTasks.splice(destination.index, 1);

        setTasks((prevTasks) => ({
          ...prevTasks,
          [source.droppableId]: sourceTasks,
          [destination.droppableId]: destinationTasks,
        }));
      }
    }
  };

  // Add New Task
  const [activityList, setActivityList] = useState([]); // Initialize as empty array
  const [userList, setUserList] = useState([]);
  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedOfficer(event.target.value);
  };
  const handleSave = () => {
    if (
      !selectedActivity ||
      !selectedOfficer ||
      !location ||
      !informant ||
      !comment
    ) {
      alert("All fields are required.");
      return;
    }

    const payload = {
      Nontourdata: {
        TaskID: selectedActivity,
        UserID: selectedOfficer,
        location,
        informant,
        Comment: comment,
        start_time: "1",
        ScanTime: "",
      },
    };

    AddNewTask(payload)
      .then((response) => {
        console.log("Task added successfully:", response.data);

        // Add the new task to the "notStarted" list
        const newTask = {
          id: response.data?.id || "temp-id", // Use response ID or a temporary ID
          taskName: selectedActivity, // Adjust as per response data
          userName:
            userList.find((user) => user.id === selectedOfficer)?.text ||
            "Unknown",
          locationId: location,
          comment,
        };

        setTasks((prevTasks) => ({
          ...prevTasks,
          notStarted: [newTask, ...prevTasks.notStarted],
        }));

        handleTaskClose(); // Close the modal
      })
      .catch((error) => {
        console.error("Error adding new task:", error);
      });
  };

  // Updated handleTaskClose to call GetNonTourDataList
  const handleTaskClose = () => {
    setAddTask(false);
    // Call GetNonTourDataList to refresh the task list
    GetNonTourDataList()
      .then((response) => {
        if (response.statusCode === 200 && response.data) {
          const updatedTasks = {
            notStarted: response.data.notStarted.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })),
            started: response.data.started.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })),
            completed: response.data.completed.map((task) => ({
              id: task.id,
              userName: task.user_name || "No User Name",
              locationId: task.location_id || "No Location",
              taskName: task.task_name || "No Task Name",
              comment: task.comment || "No Comment",
            })),
          };
          setTasks(updatedTasks);
        } else {
          console.error("Invalid response:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTasks(initialTasks); // Fallback to empty state
      });
  };

  const [profileImage, setProfileImage] = React.useState(false);
  const showImage = () => {
    setProfileImage(true);
  };
  const closeImage = () => {
    setProfileImage(false);
  };

  console.log("Locations: 568: ", locations);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={4} md={4}>
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" color="error">
              1
            </Button>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "0px",
              }}
            >
              Last Update: 00:00:00
            </Typography>
            <Box sx={{ display: "flex" }}>
              <IconButton onClick={liveIconDilaogOpen}>
                <FilterAltIcon />
              </IconButton>
              <Button
                aria-label="Settings"
                aria-owns={anchorEl ? "icon-menu" : undefined}
                aria-haspopup="true"
                onClick={handleActionClick}
                style={{ color: "#1374C5" }}
                color="success"
                variant="outlined"
              >
                2
              </Button>
              <StyledMenu
                id="icon-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleActionClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Inactive Users
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Web Developer
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Sandeep Kundu
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              marginTop: "10px",
            }}
          >
            <List
              sx={{
                height: "566px",
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress /> {/* Material-UI loader */}
                </div>
              ) : locations.length > 0 ? (
                locations.map((location, index) => (
                  <ListItem
                    key={location.uid || index}
                    sx={{
                      display: "block",
                      borderBottom: "1px solid rgb(199 199 199)",
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={10} md={10} lg={10}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ color: "blue", marginRight: "4px" }}>
                            {location.user_name}
                          </span>
                          {location.activity_type}:
                          <span style={{ marginLeft: "4px" }}>
                            {location.buttonName}
                          </span>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {location.LocationName || "No Location Name"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {location.scanTime || "No Scan Time"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} md={2} lg={2}>
                        <img
                          onClick={showImage}
                          src={
                            location.profile_image ||
                            "/static/img/avatars/default-avatar.jpg"
                          }
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            cursor: "pointer",
                            objectFit: "cover", // Ensures the image fits nicely
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {locations.length === 0
                    ? "No data available."
                    : "Loading data..."}
                </Typography>
              )}
            </List>
          </Card>
        </Grid>

        <Grid item xs={8} md={8}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Switch
              {...label}
              checked={isChecked}
              onChange={handleToggle}
              defaultChecked={true}
            />

            <IconButton sx={{ marginLeft: "auto" }}>
              <FullscreenIcon />
            </IconButton>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
            <IconButton>
              <CachedIcon />
            </IconButton>
            <IconButton>
              <PhotoOutlinedIcon />
            </IconButton>
            <IconButton onClick={emailDialogOpen}>
              <EmailRoundedIcon />
            </IconButton>
            <IconButton>
              <GroupsIcon />
            </IconButton>
            <IconButton onClick={livePlusDilaogOpen}>
              <AddBoxOutlinedIcon color="error" />
            </IconButton>
          </Box>

          <Box
            sx={{
              paddingTop: "10px",
              height: "576px",
              position: "relative",
            }}
          >
            {!isDivVisible && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-19px",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ position: "relative", height: "19px" }}
                  onClick={handleButtonClick}
                >
                  <MenuIcon color="white" />
                </Button>
              </div>
            )}

            {isDivVisible && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    padding: "7px",
                    backgroundColor: "transparent",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "10px",
                    zIndex: "1",
                  }}
                >
                  {/* Not Started Column */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      Not Started ({tasks.notStarted.length})
                    </Typography>
                    <Droppable droppableId="notStarted">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "100%",
                            maxHeight: "400px",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            scrollbarWidth: "none",
                          }}
                        >
                          {tasks.notStarted.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={String(task.id)}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                    overflow: "visible!important",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.taskName} to {task.userName}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Comment: {task.comment}
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Location: {task.locationId}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "0",
                        left: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <AddIcon
                        color="primary"
                        fontSize="large"
                        onClick={handleTaskOpen}
                        sx={{ cursor: "pointer" }}
                      />
                    </div>
                  </Box>

                  {/* Started Column */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      Started ({tasks.started.length})
                    </Typography>
                    <Droppable droppableId="started">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "100%",
                            maxHeight: "400px",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            scrollbarWidth: "none",
                          }}
                        >
                          {tasks.started.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={String(task.id)}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                    overflow: "visible!important",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.taskName} to {task.userName}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Comment: {task.comment}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Location: {task.locationId}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Scan Time:
                                      {task.scanTime || "No Scan Time"}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>

                  {/* Completed Column */}
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        Completed ({tasks.completed.length})
                      </Typography>
                      <IconButton
                        onClick={handleCloseClick}
                        sx={{ padding: "0px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Droppable droppableId="completed">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "100%",
                            maxHeight: "400px",
                            overflowY: "auto",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                            scrollbarWidth: "none",
                          }}
                        >
                          {tasks.completed.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={String(task.id)}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                    overflow: "visible!important",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.taskName} to {task.userName}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Comment: {task.comment}
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Location: {task.locationId}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </Box>
              </DragDropContext>
            )}
            {isChecked ? null : (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "280px",
                  zIndex: "1",
                  background: "white",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "2px",
                  cursor: "pointer",
                }}
              >
                <ArrowForwardIosIcon
                  color="primary"
                  fontSize="large"
                  sx={{ cursor: "pointer" }}
                  onClick={iconPopupOpen}
                />
              </div>
            )}
            {loadError ? (
              "Error loading maps"
            ) : !isLoaded ? (
              "Loading maps..."
            ) : (
              <GoogleMap
                id="map"
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
                zoom={16}
                center={mapCenter}
                options={{ mapTypeId: "satellite" }}
              >
                {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={location}
                    icon={{
                      url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      scaledSize: new window.google.maps.Size(30, 30),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(15, 15),
                    }}
                    title={location.title}
                    onClick={() => {
                      setSelectedLocation(location);
                    }}
                  />
                ))}
                {selectedLocation && (
                  <InfoWindow
                    position={selectedLocation}
                    onCloseClick={() => {
                      setSelectedLocation(null);
                    }}
                  >
                    <div
                      style={{
                        minWidth: "320px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ paddingRight: "12px" }}>
                        <p>Property: {selectedLocation.property}</p>
                        <p>Alias: {selectedLocation.alias}</p>
                        <p>Threat Level: {selectedLocation.threat_level}</p>
                        <p>Encounter Time: {selectedLocation.encounter_time}</p>
                        <p>Group: {selectedLocation.group}</p>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            )}
          </Box>
        </Grid>
      </Grid>
      {liveModal && (
        <LiveModuleModal
          liveModal={liveModal}
          setLiveModal={setLiveModal}
          liveModalOpen={liveModalOpen}
          liveModalClose={liveModalClose}
        />
      )}

      {liveModuleDilaog && (
        <LiveModuleDilaog
          liveModuleDilaog={liveModuleDilaog}
          setLiveModuleDilaog={setLiveModuleDilaog}
          liveModuleDilaogOpen={liveModuleDilaogOpen}
          liveModuleDilaogClose={liveModuleDilaogClose}
        />
      )}
      {liveIconDilaog && (
        <LiveIconDilaog
          liveIconDilaog={liveIconDilaog}
          setLiveIconDilaog={setLiveIconDilaog}
          liveIconDilaogOpen={liveIconDilaogOpen}
          liveIconDilaogClose={liveIconDilaogClose}
        />
      )}
      {livePlusDilaog && (
        <LivePlusDialog
          livePlusDilaog={livePlusDilaog}
          setLivePlusDilaog={setLivePlusDilaog}
          livePlusDilaogOpen={livePlusDilaogOpen}
          livePlusDilaogClose={livePlusDilaogClose}
        />
      )}
      {emailDialog && (
        <MessageIconDialog
          emailDialog={emailDialog}
          setEmailDialog={setEmailDialog}
          emailDialogOpen={emailDialogOpen}
          emailDialogClose={emailDialogClose}
        />
      )}
      {iconPopup && (
        <IconPopup
          iconPopup={iconPopup}
          setIconPopup={setIconPopup}
          iconPopupOpen={iconPopupOpen}
          iconPopupClose={iconPopupClose}
        />
      )}

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={addTask}
        onClose={handleTaskClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px !important",
          }}
        >
          <Typography variant="h5">Add Activity</Typography>
          <IconButton onClick={handleTaskClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6} mt={2}>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Activity</InputLabel>
                <Select
                  labelId="activity-select-label"
                  value={selectedActivity}
                  onChange={handleActivityChange}
                >
                  {Array.isArray(activityList) && activityList.length > 0 ? (
                    activityList.map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Officer</InputLabel>
                <Select
                  labelId="officer-select-label"
                  value={selectedOfficer}
                  onChange={handleUserChange}
                >
                  {Array.isArray(userList) && userList.length > 0 ? (
                    userList.map((officer) => (
                      <MenuItem key={officer.id} value={officer.id}>
                        {officer.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Location"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Informant"
                fullWidth
                value={informant}
                onChange={(e) => setInformant(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextareaAutosize
                placeholder="Comment about the task"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleTaskClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
        open={profileImage}
        onClose={closeImage}
      >
        <DialogTitle>
          nontourdata.TaskName - nontourdata.task_location
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                location.profile_image ||
                "/static/img/avatars/default-avatar.jpg"
              }
              style={{
                width: "150px",
                height: "150px",

                objectFit: "cover", // Ensures the image fits nicely
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ marginTop: "15px" }}>
            Comment: nontourdata.Comment
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="button" onClick={closeImage}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// import React from 'react'

const LiveModuleWrapper = () => {
  return (
    <>
      <ChannelProvider channelName="sova-test">
        <LiveModule />
      </ChannelProvider>
    </>
  );
};

export { LiveModuleWrapper as default };
