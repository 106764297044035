import React, { useState } from "react";
import { Switch } from "@mui/material";
import { IrCriticalTogggle } from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";

const CriticalToggle = (props) => {
  const { value, id } = props;
  const [active, setActive] = useState(value === "critical" ? true : false);
  const handleActive = (status, id) => {
    console.log(status, id);
    let payload = {
      status: status === true ? "non-critical" : "critical",
      IncidentTypeID: id,
    };
    IrCriticalTogggle(payload)
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Switch
        checked={active}
        onClick={() => {
          setActive(!active);
          handleActive(active, id);
        }}
        disabled={!hasPermission("Setttings", "changeClassification")}
      />
    </>
  );
};

export default CriticalToggle;
