import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  CircularProgress,
  Card,
  CardContent,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  Whatshot as WhatshotIcon,
  Error as ErrorIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

// Replace with your actual API call
import {
  GetNonTourData,
  ChangeTaskStatus,
  FetchNonTourData,
  CompletedTask,
  DeleteTask,
} from "../../../api";
import AddEditActivityDialog from "./AddEditActivityDialog";
import { useDispatch } from "react-redux";

const NonTourTaskDialog = ({ nonTourTaskDialog, setNonTourTaskDialog }) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState({
    notStarted: [],
    started: [],
    completed: [],
  });

  const [loader, setLoader] = useState(false);
  const [addActivityDialog, setAddActivityDialog] = useState(false);
  const [editActivityDialog, setEditActivityDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const fetchTasks = async () => {
    setLoader(true);
    try {
      const response = await GetNonTourData();
      if (response.statusCode === 200) {
        setTasks({
          notStarted: response.data.notStarted || [],
          started: response.data.started || [],
          completed: response.data.completed || [],
        });
      } else {
        dispatch(getMsg("Something went wrong!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      }
    } catch (error) {
      dispatch(getMsg("An error occurred while fetching tasks."));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
    setLoader(false);
  };

  const deleteTask = async (taskId) => {
    try {
      setLoader(true);
      await DeleteTask({ nontourdataID: taskId });
      dispatch(getMsg("Task Deleted Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      setTasks((prev) => ({
        ...prev,
        notStarted: prev.notStarted.filter(
          (task) => task.nontourdata_id !== taskId
        ),
      }));
    } catch (error) {
      console.error("Error deleting task:", error);
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } finally {
      setLoader(false);
    }
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    // If dropped outside a valid column
    if (!destination) return;

    const sourceColumn = source.droppableId;
    const destinationColumn = destination.droppableId;

    // Restrict movement rules
    if (
      (sourceColumn === "notStarted" && destinationColumn === "completed") || // Can't directly move Not Started to Completed
      (sourceColumn === "completed" && destinationColumn !== "completed") // Can't move from Completed to other columns
    ) {
      return;
    }

    const startTasks = [...tasks[sourceColumn]];
    const finishTasks = [...tasks[destinationColumn]];

    // Remove the task from the source column
    const [movedTask] = startTasks.splice(source.index, 1);

    // Add the task to the destination column
    finishTasks.splice(destination.index, 0, movedTask);

    setTasks((prev) => ({
      ...prev,
      [sourceColumn]: startTasks,
      [destinationColumn]: finishTasks,
    }));

    // Call the appropriate API based on the movement
    try {
      if (sourceColumn === "notStarted" && destinationColumn === "started") {
        // Moving from Not Started to Started
        await ChangeTaskStatus({
          typeId: movedTask.nontourdata_id,
          status: "YES",
        });
      } else if (
        sourceColumn === "started" &&
        destinationColumn === "completed"
      ) {
        // Moving from Started to Completed
        const { data } = await FetchNonTourData({
          non_tour_id: movedTask.nontourdata_id,
        });
        const payload = {
          id: data?.id,
          user_activity_id: data?.user_activity_id,
          comment: data?.Comment,
          followup: data?.followup,
          passdown: data?.passdown,
          dispatch_hdn_lon: data?.gps_longitude,
          dispatch_hdn_lat: data?.gps_latitude,
          dispatch_hdn_user_id: data?.UserID,
          dispatch_hdn_user_name: data?.user_name,
          dispatch_hdn_display_name: data?.TaskName,
          start_time: data?.start_time,
          end_time: data?.endTime,
          informed_time: data?.informed_time,
          informant: data?.informant,
          location: data?.task_location,
          timeAdjusted: "",
          save_completed_session: "1",
        };
        await CompletedTask(payload);
      } else if (
        sourceColumn === "started" &&
        destinationColumn === "notStarted"
      ) {
        // Moving from Started to Not Started
        await ChangeTaskStatus({
          typeId: movedTask.nontourdata_id,
          status: "NO",
        });
      }
      dispatch(getMsg("Task Status Updated Successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    } catch (error) {
      console.error("Error moving task:", error);
      dispatch(getMsg("Failed to update task status. Reverting changes"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);

      // Revert the changes in state if API fails
      finishTasks.splice(destination.index, 1); // Remove task from destination
      startTasks.splice(source.index, 0, movedTask); // Add task back to source
      setTasks((prev) => ({
        ...prev,
        [sourceColumn]: startTasks,
        [destinationColumn]: finishTasks,
      }));
    }
  };

  useEffect(() => {
    if (nonTourTaskDialog) {
      fetchTasks();
    }
  }, [nonTourTaskDialog]);

  const TaskCard = ({ task, index, column }) => (
    <Draggable
      key={task.nontourdata_id}
      draggableId={String(task.nontourdata_id)}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            mb: 2,
            p: 2,
            backgroundColor: "#fff",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            position: "relative",
          }}
          onClick={() => {
            if (column !== "completed") {
              setSelectedTaskId(task.nontourdata_id);
              setEditActivityDialog(true);
            }
          }}
        >
          {column === "notStarted" && (
            <IconButton
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={(event) => {
                event.stopPropagation();
                deleteTask(task.nontourdata_id);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
          <CardContent>
            <Typography variant="h6">{task.task_name}</Typography>
            <Typography variant="body2" color="textSecondary">
              Location: {task.location_id || "N/A"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Comment: {task.comment || "N/A"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              {task.urgent && (
                <Tooltip title="Urgent">
                  <WhatshotIcon fontSize="small" color="error" />
                </Tooltip>
              )}
              {task.problem && (
                <Tooltip title="Problem">
                  <ErrorIcon fontSize="small" color="warning" sx={{ ml: 1 }} />
                </Tooltip>
              )}
              {task.question && (
                <Tooltip title="Question">
                  <HelpIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="caption" sx={{ color: "#555" }}>
                {moment(task.informed_time).format("MMM DD, YYYY HH:mm")}
              </Typography>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={task.profile_image || "/static/images/avatar/1.jpg"}
                alt={task.username}
              />
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );

  return (
    <>
      <Dialog
        open={nonTourTaskDialog}
        onClose={() => setNonTourTaskDialog(false)}
        maxWidth="lg"
        PaperProps={{
          sx: {
            margin: "2rem auto",
            width: "80%",
            maxWidth: "1200px",
            height: "80vh",
            borderRadius: 2,
            overflow: "hidden", // Prevent dialog scrolling
          },
        }}
      >
        {loader && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Non-Tour Task Board</Typography>
          <IconButton onClick={() => setNonTourTaskDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 2,
              height: "calc(100% - 70px)", // Adjust for header height
              px: 2,
              pb: 2,
            }}
          >
            {["notStarted", "started", "completed"].map((column) => (
              <Box
                key={column}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflowY: "auto", // Allows individual column scrolling
                  background: "rgba(242, 242, 242, 0.8)",
                  p: 2,
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    p: 2,
                    background: "aliceblue",
                    borderRadius: "10px",
                    mb: 2,
                  }}
                >
                  {column === "notStarted"
                    ? `Not Started (${tasks[column].length})`
                    : column === "started"
                    ? `Started (${tasks[column].length})`
                    : `Completed (${tasks[column].length})`}
                </Typography>
                <Droppable droppableId={column}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ flex: 1 }}
                    >
                      {tasks[column].map((task, index) => (
                        <TaskCard
                          key={task.nontourdata_id}
                          task={task}
                          index={index}
                          column={column}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            ))}
          </Box>
        </DragDropContext>
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            width: "40px",
            height: "40px",
            bgcolor: "#444",
            color: "#fff",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            transition: "transform 0.1s ease-in-out",
            "&:hover": {
              transform: "scale(1.07)",
            },
          }}
          onClick={() => setAddActivityDialog(true)}
        >
          <AddIcon sx={{ fontSize: "18px" }} />
        </Box>
      </Dialog>
      <AddEditActivityDialog
        dialogOpen={addActivityDialog}
        setDialogOpen={setAddActivityDialog}
        fetchTasks={fetchTasks}
      />
      <AddEditActivityDialog
        dialogOpen={editActivityDialog}
        setDialogOpen={setEditActivityDialog}
        fetchTasks={fetchTasks}
        taskId={selectedTaskId}
        isEdit={true}
      />
    </>
  );
};

export default NonTourTaskDialog;
