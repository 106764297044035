import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  DialogActions,
  Typography,
  Card,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import CustomSelect from "../../../../components/form-components/CustomSelect";
import {
  IncidentTypeList,
  GetAssignments,
  SaveAssignment,
  DepartmentList,
} from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";
import WithPermissionHide from "../../../../utils/withPermissionHide";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const communiactionType = [
  { key: "AED Expired Battery or Pads", value: "aed_expired_components" },
  { key: "AED Monthly Inspection", value: "aed_monthly_inspection" },
  { key: "Activity Alert", value: "activity_alert" },
  { key: "Attendance Record", value: "attendance_record" },
  { key: "Critical IR", value: "critical_IR" },
  { key: "Critical Task", value: "critical_TASK" },
  { key: "Critical Tour Button Missed", value: "tour_submit" },
  { key: "E-Notify", value: "emergency_alert" },
  { key: "EAR High Severity", value: "sevraty_scale" },
  { key: "Executive Summary", value: "executive_summary" },
  { key: "Expired Daily Task", value: "expired_daily_task" },
  { key: "Expired Visitor", value: "expired_visitor" },
  { key: "IR Reminder", value: "ir_reminder" },
  { key: "IR Status", value: "IR_status" },
  { key: "Iot System", value: "iot_system" },
  { key: "Lost and Found High Value", value: "lf_value" },
  { key: "Lost and Found Payment", value: "lostfound_payment" },
  { key: "Lost and Found Inquiry", value: "lostfound_inquiry" },
  {
    key: "Lost and Found Payment Not Done",
    value: "lf_shipping_payment_notdone",
  },
  { key: "Man Down / Lone Worker Protection", value: "main_down" },
  { key: "Minimum Tour Count Per Day", value: "count_tour_per_day" },
  { key: "Mobile User Panic Alert", value: "panic_alert" },
  { key: "Mobile User No Movement", value: "no_movement" },
  { key: "New IR", value: "new_IR" },
  { key: "No Login", value: "no_login" },
  { key: "No SOVA Activity for 24 Hours", value: "no_activity" },
  { key: "O1 custom", value: "O1" },
  { key: "O2 custom", value: "O2" },
  { key: "O3 custom", value: "O3" },
  { key: "O4 custom", value: "O4" },
  { key: "O5 custom", value: "O5" },
  { key: "Occ Health Manager Notify", value: "send_ohm" },
  { key: "Overdue Keys", value: "overdue_keys" },
  { key: "POI Close Proximity", value: "poi_proximity" },
  { key: "POI High Threat Level", value: "threat_level" },
  { key: "Package Notify - Employee", value: "package_notify_employee" },
  { key: "Package Notify - Guest", value: "package_notify" },
  { key: "Panic Mp3 Email", value: "panic_mp3_email" },
  { key: "SMS Group Notify", value: "self_enroll" },
  { key: "Send DAR", value: "send_dar" },
  { key: "Send EAR", value: "send_ear" },
  { key: "Send IR", value: "send_ir" },
  { key: "Send SR", value: "send_sr" },
  { key: "Submit Tour", value: "submit_tour" },
  { key: "Team Notify", value: "team_notify" },
  { key: "Trigger Word", value: "trigger_word" },
  { key: "Undelivered Package", value: "undelivered_package_notify" },
  { key: "User Blocked", value: "user_blocked" },
  { key: "Visitor Notify", value: "visitor_arrival" },
  { key: "Visitor Overstay", value: "visitor_overstay" },
  { key: "Shift Equipment Report", value: "shift_equipment_report" },
];
const SubscriptionForTestUser = ({
  subscriptionForTestUserDialog,
  subscriptionForTestUserDialogClose,
  updatedRow,
  GetCommunicationList,
}) => {
  const [incidentDropdown, setIncidentDropdown] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [assigncheckData, setAssignCheckData] = useState([]);
  const [criticalIrs, setCriticalIrs] = useState([]);
  const [allCriticalIrs, setAllCriticalIrs] = useState([]);
  const [sendIr, setSendIr] = useState([]);
  const [allSendIr, setAllSendIr] = useState([]);
  const [saveSend, setSaveSend] = useState([]);
  const [saveCritical, setSaveCritical] = useState([]);
  const [showDepartment, setShowDepartment] = useState({
    attendance_record: false,
    emergency_alert: false,
    package_notify_employee: false,
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentValue, setDepartmentValue] = useState("");
  const [getDepValue, setGetDepValue] = useState("");

  // departments state for attandence_record, e-notify, employee
  const [attendanceRecordDepartments, setAttendanceRecordDepartments] =
    useState([]);
  const [eNotifyDepartments, setENotifyDepartments] = useState([]);
  const [packageNotifyDepartments, setPackageNotifyDepartments] = useState([]);

  const handleFormChange = (event, item, comm) => {
    const { name, type, checked, value } = event.target;

    const fieldValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    const updatedField = { [name]: fieldValue };

    const communiactionType =
      comm === undefined ? item.communication_type : comm;
    const isFieldExist = assigncheckData.some(
      (data) =>
        data.id === item.id && data.communication_type === communiactionType
    );

    {
      showDepartment.hasOwnProperty(communiactionType) &&
        setShowDepartment((prevState) => ({
          ...prevState,
          [communiactionType]: !prevState[communiactionType],
        }));
    }

    if (isFieldExist) {
      setAssignCheckData((prevArray) =>
        prevArray.map((index) =>
          index.id === item.id ? { ...index, ...updatedField } : index
        )
      );
    }
    if (!isFieldExist) {
      // Update the state with the updated field value only if it does not exist
      setAssignCheckData([
        ...assigncheckData,
        { ...item, ...updatedField, communication_type: communiactionType },
      ]);
    }
  };

  const updateShowDepartment = (responseData) => {
    setShowDepartment((prevState) => {
      const newState = { ...prevState };
      for (const key in newState) {
        if (responseData[key] && responseData[key].length > 0) {
          newState[key] = responseData[key][0].email_status === 1;
        }
      }
      return newState;
    });
  };

  // const handeleChangeDepartment = (event) => {
  //   setDepartmentValue(event.target.value);
  // };
  // const handeleChangeDepartment = (value, item, comm) => {
  //   console.log("item: ", item);
  //   const communiactionType =
  //     comm === undefined ? item.communication_type : comm;
  //   const isFieldExist = assigncheckData.some(
  //     (data) => data.communication_type === communiactionType
  //   );
  //   console.log("isFieldExist: ", isFieldExist);
  //   let updatedField = {};

  //   if (communiactionType == "critical_IR") {
  //     updatedField = {
  //       ["critical_irs"]: value.map((it) => it.value),
  //     };
  //   } else if (communiactionType == "send_ir") {
  //     updatedField = {
  //       ["send_irs"]: value.map((it) => it.value),
  //     };
  //   } else if (
  //     communiactionType == "attendance_record" ||
  //     communiactionType == "emergency_alert" ||
  //     communiactionType == "package_notify_employee"
  //   ) {
  //     updatedField = {
  //       ["department_id"]: value.map((it) => it.value),
  //     };
  //   }

  //   if (isFieldExist) {
  //     setAssignCheckData((prevArray) =>
  //       prevArray.map((index) =>
  //         index.communication_type === communiactionType
  //           ? { ...index, ...updatedField }
  //           : index
  //       )
  //     );
  //   }
  //   if (!isFieldExist) {
  //     setAssignCheckData([
  //       ...assigncheckData,
  //       { ...item, ...updatedField, communication_type: communiactionType },
  //     ]);
  //   }
  // };

  useEffect(() => {
    if (saveSend.length > 0) {
      let result = [];
      saveSend.forEach((obj) => {
        sendIr.forEach((result3) => {
          if (result3.value == obj) {
            result.push(result3);
          }
        });
      });
      setSendIr(result);
    }
    if (saveCritical.length > 0) {
      let result = [];
      saveCritical.forEach((obj) => {
        criticalIrs.forEach((result3) => {
          if (result3.value == obj) {
            result.push(result3);
          }
        });
      });
      setCriticalIrs(result);
    }
  }, [saveSend, saveCritical]);
  const row = updatedRow;
  delete updatedRow.active;
  delete updatedRow.auto_confirm;
  delete updatedRow.created;
  delete updatedRow.critical_irs;
  delete updatedRow.id;
  delete updatedRow.email_token;
  delete updatedRow.listcount;
  delete updatedRow.location_id;
  delete updatedRow.modified;
  delete updatedRow.phone_country;
  delete updatedRow.send_link_time;
  delete updatedRow.sms_token;
  // Create a copy of the updatedRow object
  const updatedRowCopy = { ...updatedRow };

  // Set email_status and phone_status to 0
  updatedRowCopy.email_status = 0;
  updatedRowCopy.phone_status = 0;

  // Now you can use updatedRowCopy to update the state or perform other operations
  const [propRow, setpropRow] = useState(updatedRowCopy);
  const getIncidentTypeList = () => {
    IncidentTypeList()
      .then((res) => {
        if (res.statusCode == 200) {
          setSendIr(res.data);
          setAllSendIr(res.data);
          setCriticalIrs(res.data);
          setAllCriticalIrs(res.data);
        } else {
          setSendIr([]);
          setCriticalIrs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAssignments = () => {
    let payload = {
      email: updatedRow.email,
      fname: updatedRow.first_name,
      lname: updatedRow.last_name,
    };
    GetAssignments(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setAssignmentData(res.data);
          updateShowDepartment(res.data);
          setSaveSend(res.data.send_ir[0].send_irs);
          setSaveCritical(res.data.critical_IR[0].critical_irs);
          // setGetDepValue(res.data.attendance_record[0].department_id);  commented for testing
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const departmentList = () => {
    DepartmentList()
      .then((res) => {
        if (res.statusCode == 200) {
          setDepartmentData(res.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const saveAssignment = () => {
    // setLoading(true);
    let payload = {
      data: assigncheckData,
      critical_ir: criticalIrs.map((it) => it.value),
      send_irs: sendIr.map((it) => it.value),
      department_id: departmentValue,
    };
    SaveAssignment(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          GetCommunicationList();
        } else {
        }
      })
      .catch((err) => {});
  };
  const isValidPhoneNumber = (number) => /^\+?[1-9]\d{7,14}$/.test(number);
  useEffect(() => {
    getIncidentTypeList();
    getAssignments();
    departmentList();
  }, []);
  return (
    <>
      <Dialog
        aria-labelledby="subscription-modal"
        aria-describedby="subscription-desc-modal"
        open={subscriptionForTestUserDialog}
        onClose={subscriptionForTestUserDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="vcomm-log-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Subscriptions For {updatedRow.first_name} {updatedRow.last_name}
          </Typography>
          <DialogActions>
            <IconButton
              onClick={subscriptionForTestUserDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            INSTRUCTIONS: Subscribe this user to one or more lists below by
            checking the email and/or SMS checkboxes. Checkboxes are disabled if
            those details are unverified.
          </DialogContentText>
          <Card variant="outlined" sx={{ borderBottom: 0, marginTop: "15px" }}>
            <TableContainer className="tableContainer">
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>List</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>SMS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {communiactionType.map((data) => {
                    return (
                      <TableRow>
                        <TableCell>{data.key}</TableCell>

                        <TableCell sx={{ minWidth: "150px" }}>
                          {data.value === "attendance_record" &&
                            (getDepValue !== "" ? (
                              getDepValue
                            ) : (
                              <>
                                {showDepartment[data.value] && (
                                  // <Select
                                  //   id="department"
                                  //   value={departmentValue}
                                  //   onChange={handeleChangeDepartment}
                                  //   displayEmpty
                                  // >
                                  //   <MenuItem value="">
                                  //     -- Select Department --
                                  //   </MenuItem>
                                  //   {departmentData.map((option) => (
                                  //     <MenuItem
                                  //       key={option.value}
                                  //       value={option.value}
                                  //     >
                                  //       {option.key}
                                  //     </MenuItem>
                                  //   ))}
                                  // </Select>
                                  <Autocomplete
                                    multiple
                                    id="attendance_record_departments"
                                    // limitTags={1}
                                    fullWidth
                                    value={attendanceRecordDepartments}
                                    options={departmentData}
                                    onChange={(e, v) => {
                                      setAttendanceRecordDepartments(v);
                                      //   handeleChangeDepartment(
                                      //     v,
                                      //     propRow,
                                      //     data.value
                                      //   );
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.key}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.key}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="attendance_record_departments"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder={
                                          attendanceRecordDepartments.length ===
                                          0
                                            ? "-- Select Departments --"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                )}
                              </>
                            ))}
                          {data.value === "emergency_alert" &&
                            (getDepValue !== "" ? (
                              getDepValue
                            ) : (
                              <>
                                {showDepartment[data.value] && (
                                  <Autocomplete
                                    multiple
                                    id="emergency_alert_departments"
                                    // limitTags={1}
                                    fullWidth
                                    value={eNotifyDepartments}
                                    options={departmentData}
                                    onChange={(e, v) => {
                                      setENotifyDepartments(v);
                                      // handeleChangeDepartment(
                                      //   v,
                                      //   propRow,
                                      //   data.value
                                      // );
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.key}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.key}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="emergency_alert_departments"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder={
                                          eNotifyDepartments.length === 0
                                            ? "-- Select Departments --"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                )}
                              </>
                            ))}
                          {data.value === "package_notify_employee" &&
                            (getDepValue !== "" ? (
                              getDepValue
                            ) : (
                              <>
                                {showDepartment[data.value] && (
                                  <Autocomplete
                                    multiple
                                    id="package_notify_departments"
                                    // limitTags={1}
                                    fullWidth
                                    value={packageNotifyDepartments}
                                    options={departmentData}
                                    onChange={(e, v) => {
                                      setPackageNotifyDepartments(v);
                                      // handeleChangeDepartment(
                                      //   v,
                                      //   propRow,
                                      //   data.value
                                      // );
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.key}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.key}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="package_notify_departments"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        placeholder={
                                          packageNotifyDepartments.length === 0
                                            ? "-- Select Departments --"
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                )}
                              </>
                            ))}
                          {data.value === "critical_IR" && (
                            <Autocomplete
                              multiple
                              id="critical_irs"
                              limitTags={1}
                              fullWidth
                              value={criticalIrs}
                              options={allCriticalIrs}
                              onChange={(e, v) => {
                                setCriticalIrs(v);
                                // handeleChangeDepartment(v, propRow, data.value);
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.key}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.key}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="critical_irs"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder={
                                    criticalIrs.length === 0 ? "Select" : ""
                                  }
                                />
                              )}
                            />
                          )}
                          {data.value === "send_ir" && (
                            <Autocomplete
                              multiple
                              id="send_ir"
                              limitTags={1}
                              fullWidth
                              value={sendIr}
                              options={allSendIr}
                              onChange={(e, v) => {
                                setSendIr(v);
                                // handeleChangeDepartment(v, propRow, data.value);
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.key}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.key}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="send_ir"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder={
                                    sendIr.length === 0 ? "Select" : ""
                                  }
                                />
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {assignmentData[data.value] ? (
                            assignmentData[data.value]
                              .slice(0, 1)
                              .map((item) => (
                                <Checkbox
                                  name="email_status"
                                  defaultChecked={
                                    item.email_status === 1 ? true : false
                                  }
                                  key={item.id}
                                  disabled={updatedRow.status != 3}
                                  onChange={(event) =>
                                    handleFormChange(event, item)
                                  }
                                />
                              ))
                          ) : (
                            <Checkbox
                              name="email_status"
                              disabled={updatedRow.status != 3}
                              onChange={(event) =>
                                handleFormChange(event, propRow, data.value)
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {assignmentData[data.value] ? (
                            assignmentData[data.value]
                              .slice(0, 1)
                              .map((item) => (
                                <Checkbox
                                  name="phone_status"
                                  disabled={
                                    !isValidPhoneNumber(updatedRow.phone)
                                  }
                                  defaultChecked={item.phone_status === 1}
                                  key={item.id}
                                  onChange={(event) =>
                                    handleFormChange(event, item)
                                  }
                                />
                              ))
                          ) : (
                            <Checkbox
                              name="phone_status"
                              disabled={!isValidPhoneNumber(updatedRow.phone)}
                              onChange={(event) =>
                                handleFormChange(event, propRow, data.value)
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              // GetCommunicationList();
              subscriptionForTestUserDialogClose();
            }}
          >
            Cancel
          </Button>
          <WithPermissionHide
            controller="Communication"
            action="saveAssignments"
          >
            <Button
              onClick={() => {
                saveAssignment();
                subscriptionForTestUserDialogClose();
              }}
              color="primary"
              variant="contained"
              sx={{ marginLeft: "5px" }}
              disabled={!hasPermission("Communication", "saveAssignments")}
            >
              Save
            </Button>
          </WithPermissionHide>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionForTestUser;
