import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../components/form-components/CustomSelect";
// import classNames from "classnames";
import PropTypes from "prop-types";
import SignatureCanvas from "react-signature-canvas";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  Tooltip,
  Box,
  Menu,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize as MuiTextareaAutosize,
  FormGroup,
  RadioGroup,
  Radio,
  Checkbox,
  IconButton,
  CardActions,
  FormHelperText,
  Autocomplete,
  Paper,
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  VolumeMuteSharp,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import CanvasDraw from "react-canvas-draw";
import { spacing } from "@mui/system";
import { DateTimePicker, DatePicker, TimePicker } from "@mui/lab";
import CustomTextField from "../components/form-components/CustomTextField";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../helper/helper";
import UpdatePreventive from "./ear-component/UpdatePreventive";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../redux/slices/mainSlice";
import {
  DepartmentList,
  PositionByDepartment,
  ViewEmployeeAccidentReport,
  EditManagerAccident,
  PreventativeActionPlanListing,
  ViewPreventativeActionPlan,
  UpdatePreventativeActionPlan,
  InvestigationTeamList,
  DeleteInvestigation,
  WitnessList,
  DeleteWitness,
} from "../api";
import GoogleAutoCompleteField from "../components/form-components/GoogleAutoCompleteField";
import EditEarForm from "./inner-components-EAR/EditEarForm";
import CustomProgressBar from "../components/form-components/CustomProgressBar";
import WithPermissionFallback from "../utils/withPermissionFallback";
import hasPermission from "../utils/hasPermission";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
const selectDepartment = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
];
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "120px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        padding: "10px",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;
const selectSites = [
  { title: "None selected" },
  { title: "One" },
  { title: "Two" },
  { title: "Three" },
  { title: "Fouor" },
  { title: "Five" },
  { title: "Six" },
];

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function EditMgrForm(props) {
  //States after
  const [loader, setLoader] = useState(false); //For page loader
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [empPositionList, setEmpPositionList] = useState([]);
  const [writtenPositionList, setWrittenPositionList] = useState([]);
  const [reviewPositionList, setReviewPositionList] = useState([]);
  const [editData, setEditData] = useState({});
  const [preventativeListing, setPreventativeListing] = useState([]);
  const [investigationListing, setInvestigationListing] = useState([]);
  const [witnessListing, setWitnessListing] = useState([]);
  const [prevId, setPrevId] = useState("");
  const [editPrevData, setEditPrevData] = useState({});
  const [progressCount, setProgressCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionClick = (event, previd) => {
    setAnchorEl(event.currentTarget);
    setPrevId(previd);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
    setPrevId(null);
  };
  //Signature pad
  //Ref for Employee signature pad
  const sigCanvas = useRef({});
  const [signImage, setSignImage] = useState(null);
  const clearPad = () => {
    sigCanvas.current.clear();
    setSignImage(null);
  };
  const savePad = () => {
    setSignImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  //Ref for manager signature
  const mgmSignCanvas = useRef({});
  const [mgmSignImage, setMgmSignImage] = useState(null);
  const clearMgmSignPad = () => {
    mgmSignCanvas.current.clear();
    setMgmSignImage(null);
  };
  const saveMgmSignPad = () => {
    setMgmSignImage(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  //Body canvas draw section
  const [canvasImg, setCanvasImg] = useState(null);
  const saveDamagedImage = () => {
    const width = canvasRef.current.props.canvasWidth;
    const height = canvasRef.current.props.canvasHeight;
    const background = canvasRef.current.canvasContainer.children[0];
    const drawing = canvasRef.current.canvasContainer.children[1];

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // composite now
    canvas.getContext("2d").drawImage(background, 0, 0);
    canvas.getContext("2d").globalAlpha = 1.0;
    canvas.getContext("2d").drawImage(drawing, 0, 0);

    const dataUri = canvas.toDataURL("image/jpeg", 1.0);
    const data = dataUri.split(",")[1];
    const mimeType = dataUri.split(";")[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    console.log(dataUri);
    setCanvasImg(dataUri);
    // return { blob: blob, dataUri: dataUri };
  };

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  //Method for calculating Progressbar percetage
  const ProgressCounter = (val) => {
    let emptyField = {};
    let nonEmptyField = {};
    // const keys = Object.keys(val);
    for (const key in val) {
      if (val[key] === null || val[key] === "" || val[key].length == 0) {
        emptyField = {
          ...emptyField,
          [key]: val[key],
        };
      } else {
        nonEmptyField = {
          ...nonEmptyField,
          [key]: val[key],
        };
      }
    }
    let empFieldLength = Object.keys(emptyField).length;
    let nonEmpFieldLength = Object.keys(nonEmptyField).length;
    console.log("++Empty field++++++++", emptyField);
    console.log("+++NonEmpty field +++", nonEmptyField);
    function percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    }
    let perVal = percentage(nonEmpFieldLength, 75);
    console.log(perVal);
    console.log("nonEmpFieldLength", nonEmpFieldLength);
    setProgressCount(perVal);
  };

  const lengthType = [
    { key: "Less than 1 year", value: "1" },
    { key: "1-2 year", value: "2" },
    { key: "3-5 years", value: "3" },
    { key: "More than 5 years", value: "4" },
  ];

  const expType = [
    { key: "Less than 1 month", value: "1" },
    { key: "1-5 months", value: "2" },
    { key: "6 months to a year", value: "3" },
    { key: "1-5 years", value: "4" },
    { key: "More than 5 years", value: "5" },
  ];

  const bodyPartImpacted = [
    { id: 1, title: "Ankle Left", value: 1 },
    { id: 2, title: "Ankle Right", value: 2 },
    { id: 3, title: "Arm Left", value: 3 },
    { id: 4, title: "Arm Right", value: 4 },
    { id: 5, title: "Back Lower", value: 5 },
    { id: 6, title: "Back Upper", value: 6 },
    { id: 7, title: "Elbow Left", value: 7 },
    { id: 8, title: "Elbow Right", value: 8 },
    { id: 9, title: "Eye Left", value: 9 },
    { id: 10, title: "Eye right", value: 10 },
    { id: 11, title: "Foot Left toe(s)", value: 11 },
    { id: 12, title: "Foot Right toe(s)", value: 12 },
    { id: 13, title: "Hand Left finger(s)", value: 13 },
    { id: 14, title: "Hand Right finger(s)", value: 14 },
    { id: 15, title: "Head-area injured", value: 15 },
    {
      id: 16,
      title: "Leg Left-area? thigh, knee shin or calf",
      value: 16,
    },
    {
      id: 17,
      title: "Leg Right-area? thigh, knee shin or calf",
      value: 17,
    },
    { id: 18, title: "Neck", value: 18 },
    { id: 19, title: "Shoulder Left", value: 19 },
    { id: 20, title: "Shoulder Right", value: 20 },
    { id: 21, title: "Wrist Left", value: 21 },
    { id: 22, title: "Wrist Right", value: 22 },
    { id: 23, title: "Other", value: 23 },
  ];

  const natureInjury = [
    { id: 1, title: "Abrasion, scrapes", value: 0 },
    { id: 2, title: "Broken bone", value: 1 },
    { id: 3, title: "Bruise", value: 2 },
    { id: 4, title: "Burn (heat)", value: 3 },
    { id: 5, title: "Burn (chemical)", value: 4 },
    { id: 6, title: "Loss of conciousness", value: 5 },
    {
      id: 7,
      title: "Cut, laceration, puncture",
      value: 6,
    },
    { id: 8, title: "Respiratory illness", value: 7 },
    { id: 9, title: "Pain", value: 8 },
    { id: 10, title: "Other", value: 9 },
  ];

  const unsafeWorkplaceOption = [
    { id: 1, title: "Inadequate guard", value: 0 },
    { id: 2, title: "Unguarded hazard", value: 1 },
    { id: 3, title: "Safety device is defective", value: 2 },
    { id: 4, title: "Tool or equipment defective", value: 3 },
    { id: 5, title: "Workstation layout", value: 4 },
    { id: 6, title: "Unsafe lighting", value: 5 },
    { id: 7, title: "Unsafe ventilation", value: 6 },
    { id: 8, title: "Floor Condition", value: 7 },
    {
      id: 9,
      title: "Lack of needed personal protective equipment",
      value: 8,
    },
    { id: 10, title: "Lack of appropriate equipment/ tools", value: 9 },
    { id: 11, title: "Unsage clothing/ shoes", value: 10 },
    { id: 12, title: "No training or insufficient training", value: 11 },
    { id: 13, title: "other", value: 12 },
  ];

  const unsafeActsOption = [
    { id: 1, title: "Operating without permission", value: 0 },
    { id: 2, title: "Operating at unsafe speed", value: 1 },
    { id: 3, title: "Servicing equipment that has power to it", value: 2 },
    { id: 4, title: "Making a safety device inoperative", value: 3 },
    { id: 5, title: "Using defective equipment", value: 4 },
    { id: 6, title: "Using equipment in an approved way", value: 5 },
    { id: 7, title: "Lifting/ pushing/ pulling improperly", value: 6 },
    { id: 8, title: "Taking unsafe position and posture", value: 7 },
    {
      id: 9,
      title: "Distraction, teasing, horseplay",
      value: 8,
    },
    {
      id: 10,
      title: "Failure to wear personal protective equipment",
      value: 9,
    },
    {
      id: 11,
      title: "Failure to use the available equipment/ tools",
      value: 10,
    },
    { id: 12, title: "other", value: 11 },
  ];

  const suggestionOption = [
    { id: 1, title: "Stop this activity", value: 0 },
    { id: 2, title: "Guard the hazard", value: 1 },
    { id: 3, title: "Train the employee(s)", value: 2 },
    { id: 4, title: "Train the supervisor(s)", value: 3 },
    { id: 5, title: "Redesign the work", value: 4 },
    { id: 6, title: "Write a new policy/rule", value: 5 },
    { id: 7, title: "Enforce existing policy", value: 6 },
    { id: 8, title: "Signage/ Warning", value: 7 },
    {
      id: 9,
      title: "Routinely inspect for the hazard",
      value: 8,
    },
    {
      id: 10,
      title: "Personal Protective Equipment",
      value: 9,
    },
    {
      id: 11,
      title: "other",
      value: 10,
    },
  ];

  //Formik states  and validation
  const initialValues = {
    //Employee section
    //Step-1- Details of Employee Involved
    // report_type: editData
    //   ? editData.report_type
    //     ? editData.report_type
    //     : ""
    //   : "",
    // name: editData ? (editData.name ? editData.name : "") : "",
    // supervisor_dept: editData
    //   ? editData.supervisor_dept
    //     ? editData.supervisor_dept
    //     : ""
    //   : "",
    // manager: editData ? (editData.manager ? editData.manager : "") : "",
    // witness_name: editData
    //   ? editData.witness_name
    //     ? editData.witness_name
    //     : ""
    //   : "",
    // supervisor_informed: editData
    //   ? editData.supervisor_informed
    //     ? editData.supervisor_informed
    //     : ""
    //   : "",
    // medicare_status: editData
    //   ? editData.medicare_status
    //     ? editData.medicare_status
    //     : ""
    //   : "",
    // injury_datetime: editData
    //   ? editData.injury_datetime
    //     ? editData.injury_datetime
    //     : null
    //   : null,
    // emp_position: editData
    //   ? editData.emp_position
    //     ? editData.emp_position
    //     : ""
    //   : "",
    // supervisor_title: editData
    //   ? editData.supervisor_title
    //     ? editData.supervisor_title
    //     : ""
    //   : "",
    // //step-2 -Details of the Incident
    // incident_location: editData
    //   ? editData.incident_location
    //     ? editData.incident_location
    //     : ""
    //   : "",
    // emp_desc_notes: editData
    //   ? editData.emp_desc_notes
    //     ? editData.emp_desc_notes
    //     : ""
    //   : "",
    // full_desc_injury: editData
    //   ? editData.full_desc_injury
    //     ? editData.full_desc_injury
    //     : ""
    //   : "",
    // crew_shoes_status: editData
    //   ? editData.crew_shoes_status
    //     ? editData.crew_shoes_status
    //     : ""
    //   : "",
    // emp_desc_prevent: editData
    //   ? editData.emp_desc_prevent
    //     ? editData.emp_desc_prevent
    //     : ""
    //   : "",
    // //Step-3 - Treatment for injury
    // doctor_called: editData
    //   ? editData.doctor_called
    //     ? editData.doctor_called
    //     : ""
    //   : "",
    // injured_before: editData
    //   ? editData.injured_before
    //     ? editData.injured_before
    //     : ""
    //   : "",
    // //Step-4 - Signature
    // date_signed: editData
    //   ? editData.date_signed
    //     ? editData.date_signed
    //     : null
    //   : null,
    // translating_called: editData
    //   ? editData.translating_called
    //     ? editData.translating_called
    //     : ""
    //   : "",
    //Manager section
    //step-1- employee data
    manager_name: editData
      ? editData.manager_name
        ? editData.manager_name
        : ""
      : "",
    department_id: editData
      ? editData.department_id
        ? editData.department_id
        : ""
      : "",
    mng_position: editData
      ? editData.emp_position
        ? editData.emp_position
        : ""
      : "",
    address: editData ? (editData.address ? editData.address : "") : "",
    city: editData ? (editData.city ? editData.city : "") : "",
    state: editData ? (editData.state ? editData.state : "") : "",
    zip: editData ? (editData.zip ? editData.zip : "") : "",
    dob: editData ? (editData.dob ? editData.dob : null) : null,
    gender: editData ? (editData.gender ? editData.gender : "") : "",
    home_phone: editData
      ? editData.home_phone
        ? editData.home_phone
        : ""
      : "",
    associate_works: editData
      ? editData.associate_works
        ? editData.associate_works
        : ""
      : "",
    hire_date: editData
      ? editData.hire_date
        ? editData.hire_date
        : null
      : null,
    service_len: editData
      ? editData.service_len
        ? editData.service_len
        : ""
      : "",
    //step -2 Injury data
    injury_type: [],
    injury_nature: [],
    accident_reported_datetime: editData
      ? editData.accident_reported_datetime
        ? editData.accident_reported_datetime
        : null
      : null,
    part_associate: editData
      ? editData.part_associate
        ? editData.part_associate
        : ""
      : "",
    other_part_of_workday: editData
      ? editData.other_part_of_workday
        ? editData.other_part_of_workday
        : ""
      : "",
    supervisor_at_incident: editData
      ? editData.supervisor_at_incident
        ? editData.supervisor_at_incident
        : ""
      : "",
    shift_time: editData
      ? editData.shift_time
        ? editData.shift_time
        : null
      : null,
    employer_premises_status: editData
      ? editData.employer_premises_status
        ? editData.employer_premises_status
        : ""
      : "",
    exact_location: editData
      ? editData.exact_location
        ? editData.exact_location
        : ""
      : "",
    accident_date: editData
      ? editData.accident_date
        ? editData.accident_date
        : null
      : null,
    exact_time: editData
      ? editData.exact_time
        ? editData.exact_time
        : null
      : null,
    emp_work_with: editData
      ? editData.emp_work_with
        ? editData.emp_work_with
        : ""
      : "",
    coworkerlist: editData
      ? editData.coworkerlist
        ? editData.coworkerlist
        : ""
      : "",
    per_pro_equip: editData
      ? editData.per_pro_equip
        ? editData.per_pro_equip
        : ""
      : "",
    full_desc: editData ? (editData.full_desc ? editData.full_desc : "") : "",
    experience: editData
      ? editData.experience
        ? editData.experience
        : ""
      : "",
    injury_occur: editData
      ? editData.injury_occur
        ? editData.injury_occur
        : ""
      : "",
    emp_harmed_obj: editData
      ? editData.emp_harmed_obj
        ? editData.emp_harmed_obj
        : ""
      : "",
    emp_bef_incident: editData
      ? editData.emp_bef_incident
        ? editData.emp_bef_incident
        : ""
      : "",
    wear_shoes_status: editData
      ? editData.wear_shoes_status
        ? editData.wear_shoes_status
        : ""
      : "",
    insurance_notify_status: editData
      ? editData.insurance_notify_status
        ? editData.insurance_notify_status
        : ""
      : "",
    notified_date: editData
      ? editData.notified_date
        ? editData.notified_date
        : null
      : null,
    contacted_person: editData
      ? editData.contacted_person
        ? editData.contacted_person
        : ""
      : "",
    witness_status: editData
      ? editData.witness_status
        ? editData.witness_status
        : ""
      : "",
    witness_list: [
      {
        manager_refer_witness: "",
      },
    ],
    cctv_status: editData
      ? editData.cctv_status
        ? editData.cctv_status
        : ""
      : "",
    phy_by_emp: editData
      ? editData.phy_by_emp
        ? editData.phy_by_emp
        : ""
      : "",
    physician_name: editData
      ? editData.physician_name
        ? editData.physician_name
        : ""
      : "",
    physician_phone: editData
      ? editData.physician_phone
        ? editData.physician_phone
        : ""
      : "",
    requested: editData ? (editData.requested ? editData.requested : "") : "",
    first_aid_status: editData
      ? editData.first_aid_status
        ? editData.first_aid_status
        : ""
      : "",
    clinic_status: editData
      ? editData.clinic_status
        ? editData.clinic_status
        : ""
      : "",
    emergency_room_status: editData
      ? editData.emergency_room_status
        ? editData.emergency_room_status
        : ""
      : "",
    taken_by_ambulance: editData
      ? editData.taken_by_ambulance
        ? editData.taken_by_ambulance
        : ""
      : "",
    hospital_name: editData
      ? editData.hospital_name
        ? editData.hospital_name
        : ""
      : "",
    //Step-3-Why did the incident happen?
    unsafe_conditions: [],
    unsafe_con_reason: editData
      ? editData.unsafe_con_reason
        ? editData.unsafe_con_reason
        : ""
      : "",
    unsafe_acts: [],
    unsafe_act_reason: editData
      ? editData.unsafe_act_reason
        ? editData.unsafe_act_reason
        : ""
      : "",
    unsafe_act_prior: editData
      ? editData.unsafe_act_prior
        ? editData.unsafe_act_prior
        : ""
      : "",
    desc_unsafe_act_prior: editData
      ? editData.desc_unsafe_act_prior
        ? editData.desc_unsafe_act_prior
        : ""
      : "",
    incident_prior_similar: editData
      ? editData.incident_prior_similar
        ? editData.incident_prior_similar
        : ""
      : "",
    desc_incident_prior_similar: editData
      ? editData.desc_incident_prior_similar
        ? editData.desc_incident_prior_similar
        : ""
      : "",
    satisfaction_status: editData
      ? editData.satisfaction_status
        ? editData.satisfaction_status
        : ""
      : "",
    emp_response: editData
      ? editData.emp_response
        ? editData.emp_response
        : ""
      : "",
    //Step-4-How can future incidents be prevented?
    recurrence_probab: editData
      ? editData.recurrence_probab
        ? editData.recurrence_probab
        : ""
      : "",
    severity_potential: editData
      ? editData.severity_potential
        ? editData.severity_potential
        : ""
      : "",
    severity_scale: editData
      ? editData.severity_scale
        ? editData.severity_scale
        : ""
      : "",
    suggestions: [],
    other_suggestion_you_have: editData
      ? editData.other_suggestion_you_have
        ? editData.other_suggestion_you_have
        : ""
      : "",
    carry_out_sugesstions: editData
      ? editData.carry_out_sugesstions
        ? editData.carry_out_sugesstions
        : ""
      : "",
    investigation_team_members: [
      {
        team_members: "",
      },
    ],
    //Step-5-Preventative Action Plan
    action_plan: [
      {
        root_cause: "",
        preventive_action: "",
        assigned_to: "",
        date_assigned: null,
        target_date: null,
        date_completed: null,
      },
    ],
    //Step-6- Sign and review
    written_by: editData
      ? editData.written_by
        ? editData.written_by
        : ""
      : "",
    written_dept: editData
      ? editData.written_dept
        ? editData.written_dept
        : ""
      : "",
    written_title: editData
      ? editData.written_title
        ? editData.written_title
        : ""
      : "",
    completed_date: editData
      ? editData.completed_date
        ? editData.completed_date
        : null
      : null,
    reviewed_by: editData
      ? editData.reviewed_by
        ? editData.reviewed_by
        : ""
      : "",
    review_dept: editData
      ? editData.review_dept
        ? editData.review_dept
        : ""
      : "",
    review_title: editData
      ? editData.review_title
        ? editData.review_title
        : ""
      : "",
    review_date: editData
      ? editData.review_date
        ? editData.review_date
        : null
      : null,
    translated_status: editData
      ? editData.translated_status
        ? editData.translated_status
        : ""
      : "",
    employer: editData ? (editData.employer ? editData.employer : "") : "",
    language: editData ? (editData.language ? editData.language : "") : "",
    manager_sign_image: editData
      ? editData.manager_sign_image
        ? editData.manager_sign_image
        : null
      : null,
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    // report_type: Yup.string()
    //   .required("Please select one of these options")
    //   .oneOf(["injury", "illness", "near_miss"]),
    // name: Yup.string().required("Please enter Employee full name"),
    // injury_datetime: Yup.string()
    //   .nullable()
    //   .required("Please select date/time of injury"),
    // manager: Yup.string().required("Please enter supervisor name"),
    manager_name: Yup.string().required("Please enter employee name"),
    department_id: Yup.string().required("Please select department"),
    physician_phone: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      // .min(10, "Phone must be at least 10 characters")
      .max(15, "Phone must be at most 15 characters"),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log(values);
      setLoader(true);
      let formatedInjuryDate = values.injury_datetime
        ? moment(values.injury_datetime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let formatedSignDate = values.date_signed
        ? moment(values.date_signed).format("YYYY-MM-DD")
        : "";
      let formatedDob = values.dob
        ? moment(values.dob).format("YYYY-MM-DD")
        : "";
      let formatedHireDate = values.hire_date
        ? moment(values.hire_date).format("YYYY-MM-DD")
        : "";
      let formatedAccidentDateTime = values.accident_reported_datetime
        ? moment(values.accident_reported_datetime).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : "";
      let formatedShiftTime = values.shift_time
        ? moment(values.shift_time).format("HH:mm")
        : "";
      let formatedAccidentDate = values.accident_date
        ? moment(values.accident_date).format("YYYY-MM-DD")
        : "";
      let formatedExactTime = values.exact_time
        ? moment(values.exact_time).format("HH:mm")
        : "";
      let formatedNotifiedDate = values.notified_date
        ? moment(values.notified_date).format("YYYY-MM-DD")
        : "";
      let formatedCompleteDate = values.completed_date
        ? moment(values.completed_date).format("YYYY-MM-DD")
        : "";
      let formatedReviewDate = values.review_date
        ? moment(values.review_date).format("YYYY-MM-DD")
        : "";
      let formData = {
        //Employee section payload
        // report_type: values.report_type,
        // name: values.name,
        // supervisor_dept: values.supervisor_dept,
        // manager: values.manager,
        // witness_name: values.witness_name,
        // supervisor_informed: values.supervisor_informed,
        // medicare_status: values.medicare_status,
        // injury_datetime: formatedInjuryDate,
        // emp_position: values.emp_position,
        // supervisor_title: values.supervisor_title,
        // incident_location: values.incident_location,
        // emp_desc_notes: values.emp_desc_notes,
        // full_desc_injury: values.full_desc_injury,
        // crew_shoes_status: values.crew_shoes_status,
        // emp_desc_prevent: values.emp_desc_prevent,
        // doctor_called: values.doctor_called,
        // injured_before: values.injured_before,
        // date_signed: formatedSignDate,
        // translating_called: values.translating_called,
        // b_image: canvasImg,
        // signature_image: signImage,
        //Manager section payload
        //step-1
        id: id,
        manager_name: values.manager_name,
        department_id: values.department_id,
        mng_position: values.mng_position,
        address: values.address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        dob: formatedDob,
        gender: values.gender,
        home_phone: values.home_phone,
        associate_works: values.associate_works,
        hire_date: formatedHireDate,
        service_len: values.service_len,
        //step -2
        injury_type: values.injury_type
          ? values.injury_type.map((data) => data.value)
          : [],
        injury_nature: values.injury_nature
          ? values.injury_nature.map((data) => data.value)
          : [],
        accident_reported_datetime: formatedAccidentDateTime,
        part_associate: values.part_associate,
        other_part_of_workday: values.other_part_of_workday,
        supervisor_at_incident: values.supervisor_at_incident,
        shift_time: formatedShiftTime,
        employer_premises_status: values.employer_premises_status,
        exact_location: values.exact_location,
        accident_date: formatedAccidentDate,
        exact_time: formatedExactTime,
        emp_work_with: values.formatedExactTime,
        coworkerlist: values.coworkerlist,
        per_pro_equip: values.per_pro_equip,
        full_desc: values.full_desc,
        experience: values.experience,
        injury_occur: values.injury_occur,
        emp_harmed_obj: values.emp_harmed_obj,
        emp_bef_incident: values.emp_bef_incident,
        wear_shoes_status: values.wear_shoes_status,
        insurance_notify_status: values.insurance_notify_status,
        notified_date: formatedNotifiedDate,
        contacted_person: values.contacted_person,
        witness_status: values.witness_status,
        manager_refer_witness: values.witness_list.map(
          (data) => data.manager_refer_witness
        ),
        cctv_status: values.cctv_status,
        phy_by_emp: values.phy_by_emp,
        physician_name: values.physician_name,
        physician_phone: values.physician_phone,
        requested: values.requested,
        first_aid_status: values.first_aid_status,
        clinic_status: values.clinic_status,
        emergency_room_status: values.emergency_room_status,
        taken_by_ambulance: values.taken_by_ambulance,
        hospital_name: values.hospital_name,
        //Step-3
        unsafe_conditions: values.unsafe_conditions,
        unsafe_con_reason: values.unsafe_con_reason,
        unsafe_acts: values.unsafe_acts,
        unsafe_act_reason: values.unsafe_act_reason,
        unsafe_act_prior: values.unsafe_act_prior,
        desc_unsafe_act_prior: values.desc_unsafe_act_prior,
        incident_prior_similar: values.incident_prior_similar,
        desc_incident_prior_similar: values.desc_incident_prior_similar,
        satisfaction_status: values.satisfaction_status,
        emp_response: values.emp_response,
        //Step-4
        recurrence_probab: values.recurrence_probab,
        severity_potential: values.severity_potential,
        severity_scale: values.severity_scale,
        suggestions: values.suggestions,
        other_suggestion_you_have: values.other_suggestion_you_have,
        carry_out_sugesstions: values.carry_out_sugesstions,
        investigation_team_members: values.investigation_team_members.map(
          (data) => data.team_members
        ),
        //Step-5-Preventative
        additional_preventative: values.additional_preventative,
        //Step-6-
        written_by: values.written_by,
        written_dept: values.written_dept,
        written_title: values.written_title,
        completed_date: formatedCompleteDate,
        reviewed_by: values.reviewed_by,
        review_dept: values.review_dept,
        review_title: values.review_title,
        review_date: formatedReviewDate,
        translated_status: values.translated_status,
        employer: values.employer,
        language: values.language,
        manager_sign_image: values.manager_sign_image,
      };
      EditManagerAccident(formData)
        .then((res) => {
          setLoader(false);
          if (res.statusCode === 200) {
            navigate({ pathname: "/employee-accidents" });
            dispatch(getMsg("EAR report successfully updated"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          // setVehicleLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
          setLoader(false);
        });
    } catch (err) {
      // setVehicleLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      // setPageLoad(false);
    }
  };

  //For Dropdown
  const getDepartmentList = () => {
    setLoader(true);
    DepartmentList({})
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const getPositionList = (value) => {
    setLoader(true);
    PositionByDepartment(value)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setPositionList(res.data);
        } else {
          setPositionList([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //Emp position list data
  const getEmpPositionList = (value) => {
    setLoader(true);
    PositionByDepartment(value)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setEmpPositionList(res.data);
        } else {
          setEmpPositionList([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //written position list data
  const getWrittenPositionList = (value) => {
    setLoader(true);
    PositionByDepartment(value)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setWrittenPositionList(res.data);
        } else {
          setWrittenPositionList([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //written position list data
  const getReviewPositionList = (value) => {
    setLoader(true);
    PositionByDepartment(value)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setReviewPositionList(res.data);
        } else {
          setReviewPositionList([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //API call for view employee accident
  const getAccidentViewData = () => {
    setLoader(true);
    ViewEmployeeAccidentReport(id)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setEditData(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //API call for Preventative Action Listing
  const getPreventativeListData = () => {
    setLoader(true);
    let payload = {
      id: id,
    };
    PreventativeActionPlanListing(payload)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setPreventativeListing(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //API call for investigation team listing
  const getInvestigationListData = () => {
    setLoader(true);
    let payload = {
      id: id,
    };
    InvestigationTeamList(payload)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setInvestigationListing(res.data);
        } else {
          setInvestigationListing([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //API for Delete investigation Record
  const DeleteInvestigationRec = (val) => {
    setLoader(true);
    let payload = {
      id: id,
      value: val,
    };
    DeleteWitness(payload)
      .then((res) => {
        setLoader(false);
        getInvestigationListData();
        if (res.statusCode == 200) {
          dispatch(getMsg("Investigation team member successfully deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //API call for Witness Listing
  const getWitnessListData = () => {
    setLoader(true);
    let payload = {
      id: id,
    };
    WitnessList(payload)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setWitnessListing(res.data);
        } else {
          setWitnessListing([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //Method to delete witness
  const DeleteWitnessRec = (val) => {
    setLoader(true);
    let payload = {
      id: id,
      value: val,
    };
    DeleteWitness(payload)
      .then((res) => {
        setLoader(false);
        getWitnessListData();
        if (res.statusCode == 200) {
          dispatch(getMsg("Witness successfully deleted"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  //function for getting data for involved vehicle
  const getPreventativeData = () => {
    setLoader(true);
    ViewPreventativeActionPlan(prevId)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          setEditPrevData(res.data);
        } else {
          setEditPrevData({});
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  useEffect(() => {
    getDepartmentList();
    getAccidentViewData();
    getPreventativeListData();
    getInvestigationListData();
    getWitnessListData();
  }, []);

  useEffect(() => {
    if (editData && editData.supervisor_dept) {
      getPositionList(editData.supervisor_dept);
    }
    ProgressCounter(initialValues);
  }, [editData]);

  const [updatePreventiveDialog, setUpdatePreventiveDialog] = useState(false);
  const updatePrevDialogClose = () => {
    setUpdatePreventiveDialog(false);
    setEditPrevData({});
  };
  return (
    <Card mb={6}>
      <EditEarForm />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.accordion}>
                {/* <Typography variant="h4" component="div" mb={4} gutterBottom>
                  Employee
                </Typography>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step1-content"
                    id="step1-header"
                  >
                    <Typography>
                      Step 1: Details of Employee Involved
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel component="legend">
                            I am reporting a work related *
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="workreport"
                              name="report_type"
                              value={values.report_type.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "report_type",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Injury"
                                value="injury"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Illness"
                                value="illness"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Near miss"
                                value="near_miss"
                                disabled
                              />
                            </RadioGroup>
                            <FormHelperText error={true} sx={{ marginLeft: 0 }}>
                              {touched.report_type && errors.report_type}
                            </FormHelperText>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="name"
                            id="emp-name"
                            label="Employee full name *"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            placeholder="First name last name"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="supervisor_dept"
                            fieldLabel="Department"
                            options={departmentList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            displayEmpty
                            customCallbackMethod={(evt) => {
                              if (evt.target.value) {
                                getPositionList(evt.target.value);
                              }
                            }}
                          />
                          <FormHelperText error>
                            {touched.supervisor_dept && errors.supervisor_dept}
                          </FormHelperText>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="manager"
                            id="supervisor-name"
                            label="Name of supervisor at time of injury *"
                            placeholder="Direct manager on day of accident"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="witness_name"
                            id="witness-name"
                            label="Name of Witnesses (seperate by commas):"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            placeholder="John Smith, Marry Johnson, Alex Jones"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Have you notified your supervisor about this
                            injury/near miss?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="notify-supervisor"
                              name="supervisor_informed"
                              value={values.supervisor_informed.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "supervisor_informed",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Currently on medicare?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby="medicarestatus"
                              name="medicare_status"
                              value={values.medicare_status.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "medicare_status",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <DateTimePicker
                            id="date-range"
                            label="* Date & time of injury : "
                            value={values.injury_datetime}
                            onChange={(newValue) => {
                              setFieldValue("injury_datetime", newValue);
                            }}
                            disabled
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="injury_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="emp_position"
                            fieldLabel="Employee position"
                            options={positionList}
                            displayEmpty
                            disabled
                            firstOptionTitle="Select"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomSelect
                            name="supervisor_title"
                            fieldLabel="Supervisor position "
                            options={positionList}
                            displayEmpty
                            disabled
                            firstOptionTitle="Select"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step2-content"
                    id="step2-header"
                  >
                    <Typography>Step 2: Details of the Incident</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <CustomTextField
                            name="incident_location"
                            id="incident-location"
                            label="Where exactly did  the injury/near miss out?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            inputProps={{ maxLength: 100 }}
                            placeholder="Be specific: name the department, floor, room or hallway"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="emp_desc_notes"
                            label="If you were injurded what were you doing at the moment?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            placeholder="Describe the activity you were doing"
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="full_desc_injury"
                            label="Describe step by step how the injury/near miss
                                occured:"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            autoComplete="off"
                            placeholder="Describe surroundings and your activities leading up too the accident"
                            multiline={true}
                            rows={6}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Where you were wearing Shoes or Crews? (or similar)
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="crew_shoes_status"
                              value={values.crew_shoes_status.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "crew_shoes_status",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <CustomTextField
                            name="emp_desc_prevent"
                            label="What could have been done to prevent this
                                injury/near miss?"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            autoComplete="off"
                            placeholder="Better training, improve work training, better equipment etc."
                            multiline={true}
                            rows={6}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          color="primary"
                          variant="contained"
                          mb={3}
                          onClick={saveDamagedImage}
                          disabled
                        >
                          Save
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          mb={3}
                          sx={{ marginLeft: 5, marginRight: 5 }}
                          onClick={() => {
                            canvasRef.current.undo();
                          }}
                          disabled
                        >
                          Undo
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          mb={3}
                          onClick={() => {
                            canvasRef.current.clear();
                          }}
                          disabled
                        >
                          Clear
                        </Button>
                        <CanvasDraw
                          imgSrc="/static/img/unsplash/body-part.jpg"
                          // imgSrc="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
                          brushColor="rgba(255, 0, 0)"
                          brushRadius={1}
                          canvasWidth={450}
                          canvasHeight={500}
                          hideGrid={true}
                          disabled
                          // disabled={vehicleActionStatus}
                          // getSaveData={()=>console.log()}
                          ref={canvasRef}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step3-content"
                    id="step3-header"
                  >
                    <Typography>Step 3: Treatment for Injury</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <FormLabel component="legend">
                            Did you see about this point Injury/Illness?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="doctor_called"
                              value={values.doctor_called.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "doctor_called",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">
                            Has this part of your body been injured before?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="injured_before"
                              value={values.injured_before.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "injured_before",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step4-content"
                    id="step4-header"
                  >
                    <Typography>Step 4: Signature</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <DatePicker
                            id="date-signed"
                            label="Date signed"
                            value={values.date_signed}
                            onChange={(newValue) => {
                              setFieldValue("date_signed", newValue);
                            }}
                            disabled
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="date_signed"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl mt={6}>
                          <FormLabel component="legend">Sign here</FormLabel>
                          <Card variant="outlined" className="signatureCanvas">
                            <CardContent>
                              <SignatureCanvas ref={sigCanvas} />
                            </CardContent>
                            <CardActions>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={savePad}
                                disabled
                              >
                                Save
                              </Button>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={clearPad}
                                disabled
                              >
                                Clear
                              </Button>
                            </CardActions>
                          </Card>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl mt={3}>
                          <FormLabel component="legend">
                            Did you have help translating this form?
                          </FormLabel>
                          <StyledRadio>
                            <RadioGroup
                              aria-labelledby=""
                              name="translating_called"
                              value={values.translating_called.toString()}
                              onChange={(e) =>
                                setFieldValue(
                                  "translating_called",
                                  e.currentTarget.value
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                                disabled
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                                disabled
                              />
                            </RadioGroup>
                          </StyledRadio>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion> */}
                <Typography
                  variant="h4"
                  component="div"
                  mt={4}
                  mb={4}
                  gutterBottom
                >
                  Manager
                </Typography>
                <CustomProgressBar value={progressCount} />
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step1-manager"
                    id="step1-manager"
                  >
                    <Typography>Step 1: Employee Data</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomTextField
                            name="manager_name"
                            id="emp-name"
                            label="Employee name"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            placeholder="Name"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setFieldValue("manager_name", editData?.name);
                            setFieldValue(
                              "department_id",
                              editData?.supervisor_dept
                            );
                            getEmpPositionList(editData.supervisor_dept);
                            setFieldValue(
                              "mng_position",
                              editData?.emp_position
                            );
                            ProgressCounter(values);
                          }}
                        >
                          Populate
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomSelect
                            name="department_id"
                            fieldLabel="Department"
                            options={departmentList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            displayEmpty
                            customCallbackMethod={(evt) => {
                              if (evt.target.value) {
                                getEmpPositionList(evt.target.value);
                              }
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                          <FormHelperText error>
                            {touched.department_id && errors.department_id}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomSelect
                            name="mng_position"
                            fieldLabel="Employee position"
                            options={empPositionList}
                            displayEmpty
                            firstOptionTitle="Select"
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <GoogleAutoCompleteField
                            // disabled={disabledFields}
                            name="address"
                            label="Address"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            autoComplete="off"
                            placeholder="3214 N University Ave"
                            getApiResponse={(res) => {
                              setFieldValue("address", res.formatted_address);
                              setFieldValue("city", res.locality);
                              setFieldValue(
                                "state",
                                res.administrative_area_level_1
                              );
                              setFieldValue("zip", res.postal_code);
                              ProgressCounter(values);
                              // setFieldValue("country", res.country);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomTextField
                            name="city"
                            id="emp-city"
                            label="City"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomTextField
                            name="state"
                            id="emp-state"
                            label="State"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomTextField
                            name="zip"
                            id="emp-zip"
                            label="Zip"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DatePicker
                            id="date-of-birth"
                            label="Date of birth"
                            value={values.dob}
                            onChange={(newValue) => {
                              setFieldValue("dob", newValue);
                              ProgressCounter(values);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="dob"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <FormLabel>Gender</FormLabel>
                          <RadioGroup
                            aria-labelledby="gender"
                            name="gender"
                            value={values.gender.toString()}
                            onChange={(e) => {
                              setFieldValue("gender", e.currentTarget.value);
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Male"
                              value="M"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Female"
                              value="F"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomTextField
                            name="home_phone"
                            id="home-phone"
                            label="Home Phone"
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>Work status</FormLabel>
                          <RadioGroup
                            aria-labelledby="work-status"
                            name="associate_works"
                            value={values.associate_works.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "associate_works",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Regular full time"
                              value="RF"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Regular part time"
                              value="RP"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="On call"
                              value="OC"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <DatePicker
                            id="date-of-recent-hire"
                            label="Date of most recent hire"
                            value={values.hire_date}
                            onChange={(newValue) => {
                              setFieldValue("hire_date", newValue);
                              ProgressCounter(values);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="hire_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Click to insert date",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl>
                          <CustomSelect
                            name="service_len"
                            fieldLabel="Length of service of employee"
                            options={lengthType}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            firstOptionTitle="Select service length"
                            displayEmpty
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step2-manager"
                    id="step2-manager"
                  >
                    <Typography>Step 2: Injury/Medical Data</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <Autocomplete
                            multiple
                            id="bodypart-impacted"
                            limitTags={1}
                            fullWidth
                            options={bodyPartImpacted}
                            value={values.injury_type}
                            onChange={(e, v) => {
                              setFieldValue("injury_type", v);
                              ProgressCounter(values);
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            )}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="injury_type"
                                label="Body part injured (select one or more)"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <Autocomplete
                            multiple
                            id="nature-of-injury"
                            limitTags={1}
                            fullWidth
                            options={natureInjury}
                            // value={bodyImpartedArray}
                            value={values.injury_nature}
                            onChange={(e, v) => {
                              // setBodyImpartedArray(v);
                              setFieldValue("injury_nature", v);
                              ProgressCounter(values);
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            )}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="injury_nature"
                                label="Nature of injury (select one or more)"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DateTimePicker
                            id="date-range"
                            label="Date & time reported"
                            value={values.accident_reported_datetime}
                            onChange={(newValue) => {
                              setFieldValue(
                                "accident_reported_datetime",
                                newValue
                              );
                              ProgressCounter(values);
                            }}
                            inputFormat="MM/dd/yyyy HH:mm"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="accident_reported_datetime"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm/dd/yyyy H:i:s",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>Part of workday</FormLabel>
                          <RadioGroup
                            aria-labelledby="part-of-workdays"
                            name="part_associate"
                            value={values.part_associate.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "part_associate",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Entering or leaving work"
                              value="A"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Doing normal work activities"
                              value="B"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="During meal period"
                              value="C"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="During break"
                              value="D"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Working overtime"
                              value="E"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Other"
                              value="F"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl
                          mt={6}
                          sx={{
                            display:
                              values.part_associate == "F" ? "block" : "none",
                          }}
                        >
                          <CustomTextField
                            name="other_part_of_workday"
                            id="provide-details"
                            label="Provide Details"
                            InputLabelProps={{ shrink: true }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            label="Name of employee’s immediate supervisor at time of incident"
                            name="supervisor_at_incident"
                            placeholder="First and Last name"
                            InputLabelProps={{ shrink: true }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <TimePicker
                            label="Time shift started"
                            id="time-shift-started"
                            value={values.shift_time}
                            onChange={(newValue) => {
                              setFieldValue("shift_time", newValue);
                              ProgressCounter(values);
                            }}
                            inputFormat="HH:mm"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel>
                            Did incident occur on employer’s premises?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="premises"
                            name="employer_premises_status"
                            value={values.employer_premises_status.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "employer_premises_status",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomTextField
                            label="Location of the incident"
                            name="exact_location"
                            placeholder="Identify location of accident using specific details"
                            InputLabelProps={{ shrink: true }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DatePicker
                            id="date-of-accident"
                            label="Date of accident"
                            value={values.accident_date}
                            onChange={(newValue) => {
                              setFieldValue("accident_date", newValue);
                              ProgressCounter(values);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="accident_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "mm-dd-yy",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <TimePicker
                            id="time-of-accident"
                            label="Exact time of accident"
                            value={values.exact_time}
                            onChange={(newValue) => {
                              setFieldValue("exact_time", newValue);
                              ProgressCounter(values);
                            }}
                            inputFormat="HH:mm"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="exact_time"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>Employee was working</FormLabel>
                          <RadioGroup
                            aria-labelledby="employee-work-status"
                            name="emp_work_with"
                            value={values.emp_work_with.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "emp_work_with",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Alone"
                              value="A"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="With fellow workers"
                              value="FW"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            id="co-workers-list"
                            name="coworkerlist"
                            label="List co-workers (separate names with a comma)"
                            placeholder="If blank, employee didn't include names"
                            InputLabelProps={{ shrink: true }}
                            disabled={
                              values.emp_work_with == "FW" ? false : true
                            }
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            id="ppe"
                            name="per_pro_equip"
                            label="What personal protective equipment (PPE) was being worn (if any)"
                            InputLabelProps={{ shrink: true }}
                            placeholder=""
                            inputProps={{ maxLength: 100 }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel sx={{ marginBottom: 5 }}>
                            Describe step-by-step the events that led up to the
                            injury. Includes names of any machines, parts,
                            objects, tools, materials, and other important
                            details:
                          </FormLabel>
                          <CustomTextField
                            name="full_desc"
                            rows={6}
                            multiline={true}
                            // label=""
                            id="describe-steps"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomSelect
                            name="experience"
                            fieldLabel="How much experience does the employee have in the task performed"
                            options={expType}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            displayEmpty
                            firstOptionTitle="Select experience level"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            name="injury_occur"
                            label="How did the Injury occur?"
                            InputLabelProps={{ shrink: true }}
                            id="substance"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            name="emp_harmed_obj"
                            label="What object or substance directly harmed the employee?"
                            InputLabelProps={{ shrink: true }}
                            id="substance"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <CustomTextField
                            name="emp_bef_incident"
                            label="What was the employee doing just before the incident occurred?"
                            InputLabelProps={{ shrink: true }}
                            id="incident-occured"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>
                            Was the employee wearing Shoes for Crews (or
                            similar)?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="wearing_shoes"
                            name="wear_shoes_status"
                            value={values.wear_shoes_status.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "wear_shoes_status",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>Was insurance carrier notified?</FormLabel>
                          <RadioGroup
                            aria-labelledby="insurance-notify-status"
                            name="insurance_notify_status"
                            value={values.insurance_notify_status.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "insurance_notify_status",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display:
                            values.insurance_notify_status == "Y"
                              ? "block"
                              : "none",
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <DatePicker
                                id="date-of-recent-hire"
                                label="Date Notified"
                                value={values.notified_date}
                                onChange={(newValue) => {
                                  setFieldValue("notified_date", newValue);
                                  ProgressCounter(values);
                                }}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="notified_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Click to insert date",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="contacted_person"
                                label="Name of person contacted"
                                InputLabelProps={{ shrink: true }}
                                id="incident-occured"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>Any witnesses?</FormLabel>
                          <RadioGroup
                            aria-labelledby="witnesses-status"
                            name="witness_status"
                            value={values.witness_status.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "witness_status",
                                e.currentTarget.value
                              );
                              setFieldValue("witness_list", [
                                { manager_refer_witness: "" },
                              ]);
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                            <Button
                              color="primary"
                              // onClick={() => alert("Testing witness name")}
                            >
                              Show co-workers
                            </Button>
                          </RadioGroup>
                        </FormControl>
                        <Card variant="outlined" sx={{ borderBottom: 0 }}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Witness List</TableCell>
                                  <TableCell sx={{ textAlign: "right" }}>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {witnessListing.map((item, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        {item.manager_refer_witness}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        <IconButton
                                          color="error"
                                          onClick={() =>
                                            DeleteWitnessRec(
                                              item.manager_refer_witness
                                            )
                                          }
                                          disabled={
                                            !hasPermission(
                                              "EmployeeAccidentReport",
                                              "deleteWitness"
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {witnessListing.length == 0 && (
                                  <TableRow
                                  // style={{
                                  //   height: (dense ? 6 : 0) * totalRecord,
                                  // }}
                                  >
                                    <TableCell colSpan={2} align="center">
                                      No records added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                          display:
                            values.witness_status === "Y" ? "block" : "none",
                        }}
                      >
                        {values.witness_list.map((item, index) => {
                          return (
                            <Box sx={{ marginBottom: "20px" }}>
                              <Grid container spacing={6}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={6}
                                  order={{ lg: 1, xs: 2 }}
                                >
                                  <FormControl>
                                    <TextField
                                      label="Name of witness"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={item.manager_refer_witness}
                                      fullWidth
                                      onChange={(e) => {
                                        let newp = values.witness_list;
                                        newp[index].manager_refer_witness =
                                          e.target.value;
                                        setFieldValue("witness_list", newp);
                                        // handleChangePassengerf_name(index, e);
                                      }}
                                      error={Boolean(
                                        errors?.witness_list &&
                                          errors?.witness_list[index]
                                            ?.manager_refer_witness
                                      )}
                                      helperText={
                                        errors?.witness_list &&
                                        errors?.witness_list[index]
                                          ?.manager_refer_witness
                                      }
                                      // my={2}
                                    />
                                  </FormControl>
                                </Grid>
                                {index === 0 ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={1}
                                    textAlign="right"
                                    alignSelf="center"
                                    order={{ lg: 4, xs: 1 }}
                                  >
                                    <Tooltip title="Add" placement="top">
                                      <IconButton
                                        color="primary"
                                        onClick={() => {
                                          let copy = {
                                            manager_refer_witness: "",
                                          };
                                          setFieldValue("witness_list", [
                                            ...values.witness_list,
                                            copy,
                                          ]);
                                        }}
                                      >
                                        <AddBoxIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={1}
                                    textAlign="right"
                                    alignSelf="center"
                                    order={{ lg: 4, xs: 1 }}
                                  >
                                    <Tooltip title="Delete" placement="top">
                                      <IconButton
                                        color="error"
                                        onClick={() => {
                                          // removePassengers(index);
                                          let newArr = [];
                                          for (
                                            let i = 0;
                                            i < values.witness_list.length;
                                            i++
                                          ) {
                                            if (i !== index) {
                                              newArr.push(
                                                values.witness_list[i]
                                              );
                                            }
                                          }
                                          setFieldValue("witness_list", newArr);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>
                            Was the incident captured on CCTV?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="incident_captured"
                            name="cctv_status"
                            value={values.cctv_status.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "cctv_status",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>
                            Was first treating physician chosen by employee?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="physician_chosen"
                            name="phy_by_emp"
                            value={values.phy_by_emp.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "phy_by_emp",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display: values.phy_by_emp == "Y" ? "block" : "none",
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="physician_name"
                                label="Physicians Name"
                                InputLabelProps={{ shrink: true }}
                                id="physician-name"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="physician_phone"
                                label="Phone Number"
                                InputLabelProps={{ shrink: true }}
                                id="physician-phone"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                                inputProps={{ maxLength: 15 }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl>
                          <FormLabel>
                            Was medical attention requested?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="medical_attention_requested"
                            name="requested"
                            value={values.requested.toString()}
                            onChange={(e) => {
                              setFieldValue("requested", e.currentTarget.value);
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Yes"
                              value="Y"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="No"
                              value="N"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display: values.requested == "Y" ? "block" : "none",
                        }}
                      >
                        <Typography variant="h6" mt={4} mb={4}>
                          Type of medical attention:
                        </Typography>
                        <Grid container spacing={6}>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <FormLabel>First Aid ?</FormLabel>
                              <RadioGroup
                                aria-labelledby="first-aid-status"
                                name="first_aid_status"
                                value={values.first_aid_status.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "first_aid_status",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <FormLabel>Sent to Clinic ?</FormLabel>
                              <RadioGroup
                                aria-labelledby="clinic-status"
                                name="clinic_status"
                                value={values.clinic_status.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "clinic_status",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <FormLabel>Sent to Emergency Room ?</FormLabel>
                              <RadioGroup
                                aria-labelledby="emergency-room-status"
                                name="emergency_room_status"
                                value={values.emergency_room_status.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "emergency_room_status",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <FormLabel>Taken by ambulance ?</FormLabel>
                              <RadioGroup
                                aria-labelledby="taken-by-ambulance"
                                name="taken_by_ambulance"
                                value={values.taken_by_ambulance.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "taken_by_ambulance",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="hospital_name"
                                label="Name of hospital or clinic"
                                InputLabelProps={{ shrink: true }}
                                id="hospital-name"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step3-manager"
                    id="step3-manager"
                  >
                    <Typography>
                      Step 3: Why did the accident/incident happen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel>
                            Unsafe Workplace Conditions: (check all that apply)
                          </FormLabel>
                          <FormGroup>
                            {unsafeWorkplaceOption.map((mod, index) => {
                              return (
                                <FormControlLabel
                                  key={mod.id}
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name={mod.value}
                                      value={mod.value}
                                      // id={mod.id}
                                      onChange={(e) => {
                                        const { value, checked } = e.target;
                                        if (checked) {
                                          let filter = [
                                            ...values.unsafe_conditions,
                                            value,
                                          ];
                                          setFieldValue(
                                            "unsafe_conditions",
                                            filter
                                          );
                                        } else {
                                          let filter =
                                            values.unsafe_conditions.filter(
                                              (e) => e !== value
                                            );
                                          setFieldValue(
                                            "unsafe_conditions",
                                            filter
                                          );
                                        }
                                        ProgressCounter(values);
                                      }}
                                    />
                                  }
                                  label={mod.title}
                                />
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                        {values.unsafe_conditions.length > 0 && (
                          <FormControl mt={6}>
                            <CustomTextField
                              name="unsafe_con_reason"
                              label="Why did the unsafe conditions exist?"
                              InputLabelProps={{ shrink: true }}
                              id="unsafe-condition-occur"
                              placeholder=""
                              onBlur={() => {
                                ProgressCounter(values);
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <FormLabel>
                            Unsafe Acts by People: (check all that apply)
                          </FormLabel>
                          <FormGroup>
                            {unsafeActsOption.map((mod, index) => {
                              return (
                                <FormControlLabel
                                  key={mod.id}
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name={mod.value}
                                      value={mod.value}
                                      onChange={(e) => {
                                        const { value, checked } = e.target;
                                        if (checked) {
                                          let filter = [
                                            ...values.unsafe_acts,
                                            value,
                                          ];
                                          setFieldValue("unsafe_acts", filter);
                                        } else {
                                          let filter =
                                            values.unsafe_acts.filter(
                                              (e) => e !== value
                                            );
                                          setFieldValue("unsafe_acts", filter);
                                        }
                                        ProgressCounter(values);
                                      }}
                                    />
                                  }
                                  label={mod.title}
                                />
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                        {values.unsafe_acts.length > 0 && (
                          <FormControl mt={6}>
                            <CustomTextField
                              name="unsafe_act_reason"
                              label="Why did the unsafe act occur?"
                              InputLabelProps={{ shrink: true }}
                              id="unsafe-act-reason"
                              placeholder=""
                              onBlur={() => {
                                ProgressCounter(values);
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <FormLabel>
                                Were the unsafe acts or conditions selected
                                above reported prior to the incident?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="unsafe-acts"
                                name="unsafe_act_prior"
                                value={values.unsafe_act_prior.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "unsafe_act_prior",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display:
                                values.unsafe_act_prior == "Y"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <FormControl>
                              <CustomTextField
                                id="provide-details"
                                name="desc_unsafe_act_prior"
                                label="Provide details"
                                InputLabelProps={{ shrink: true }}
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <FormLabel>
                                Have there been similar incidents or near misses
                                prior to this one?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="unsafe-acts"
                                name="incident_prior_similar"
                                value={values.incident_prior_similar.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "incident_prior_similar",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display:
                                values.incident_prior_similar == "Y"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <FormControl>
                              <CustomTextField
                                id="provide-details"
                                name="desc_incident_prior_similar"
                                label="Provide details"
                                InputLabelProps={{ shrink: true }}
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={6}>
                            <FormControl>
                              <FormLabel>
                                Are you satisfied that the injury occurred in
                                the way alleged by the employee?
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="unsafe-acts"
                                name="satisfaction_status"
                                value={values.satisfaction_status.toString()}
                                onChange={(e) => {
                                  setFieldValue(
                                    "satisfaction_status",
                                    e.currentTarget.value
                                  );
                                  ProgressCounter(values);
                                }}
                                row
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label="Yes"
                                  value="Y"
                                />
                                <FormControlLabel
                                  control={<Radio />}
                                  label="No"
                                  value="N"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display:
                                values.satisfaction_status == "N"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <FormControl>
                              <FormLabel sx={{ marginBottom: 5 }}>
                                Below, please explain the reason you do not
                                agree with the allegation: (Note: this is your
                                personal opinion only, your comment will not
                                appear in the report):
                              </FormLabel>
                              <CustomTextField
                                id="provide-details"
                                name="emp_response"
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step4-manager"
                    id="step4-manager"
                  >
                    <Typography>
                      Step 4: How can future accident/incidents be improved?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={8}>
                        <FormControl>
                          <FormLabel>Probability of Recurrence</FormLabel>
                          <RadioGroup
                            aria-labelledby="unsafe-acts"
                            name="recurrence_probab"
                            value={values.recurrence_probab.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "recurrence_probab",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Rare"
                              value="1"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Unlikely"
                              value="2"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Possible"
                              value="3"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Likely"
                              value="4"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Certain"
                              value="6"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Loss Severity Potential</FormLabel>
                          <RadioGroup
                            aria-labelledby="unsafe-acts"
                            name="severity_potential"
                            value={values.severity_potential.toString()}
                            onChange={(e) => {
                              setFieldValue(
                                "severity_potential",
                                e.currentTarget.value
                              );
                              ProgressCounter(values);
                            }}
                            row
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Very Low"
                              value="1"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Low"
                              value="2"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Medium"
                              value="3"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="High"
                              value="4"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Very high"
                              value="5"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl>
                          <CustomTextField
                            id="severity-scale"
                            name="severity_scale"
                            label="Severity scale"
                            type="number"
                            value={
                              values.severity_potential >
                              values.recurrence_probab
                                ? values.severity_potential
                                : values.recurrence_probab
                            }
                            disabled={true}
                            InputLabelProps={{ shrink: true }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl>
                          <FormLabel>
                            What changes do you suggest to prevent this
                            incident/near miss from happening again? Tip: please
                            consider adding items checked here to the
                            preventative action plan section below
                          </FormLabel>
                          <FormGroup>
                            {suggestionOption.map((mod, index) => {
                              return (
                                <FormControlLabel
                                  key={mod.id}
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name={mod.value}
                                      value={mod.value}
                                      onChange={(e) => {
                                        const { value, checked } = e.target;
                                        if (checked) {
                                          let filter = [
                                            ...values.suggestions,
                                            value,
                                          ];
                                          setFieldValue("suggestions", filter);
                                        } else {
                                          let filter =
                                            values.suggestions.filter(
                                              (e) => e !== value
                                            );
                                          setFieldValue("suggestions", filter);
                                        }
                                        ProgressCounter(values);
                                      }}
                                    />
                                  }
                                  label={mod.title}
                                />
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                        {values.suggestions.find((a) => a == "10") == "10" && (
                          <FormControl mt={3}>
                            <CustomTextField
                              name="other_suggestion_you_have"
                              id="what-suggestion"
                              label="What suggestion(s) do you have?"
                              InputLabelProps={{ shrink: true }}
                              onBlur={() => {
                                ProgressCounter(values);
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormControl>
                          <FormLabel sx={{ marginBottom: 5 }}>
                            What should be (or has been) done to carry out the
                            suggestion(s) noted above?
                          </FormLabel>
                          <CustomTextField
                            multiline={true}
                            rows={6}
                            name="carry_out_sugesstions"
                            label=""
                            id="suggestions"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Card variant="outlined" sx={{ borderBottom: 0 }}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    Names of Investigation Team Members
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "right" }}>
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {investigationListing.map((item, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell>
                                        {item.investigation_team_members}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        <IconButton
                                          color="error"
                                          onClick={() =>
                                            DeleteInvestigationRec(
                                              item.investigation_team_members
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {investigationListing.length == 0 && (
                                  <TableRow
                                  // style={{
                                  //   height: (dense ? 6 : 0) * totalRecord,
                                  // }}
                                  >
                                    <TableCell colSpan={2} align="center">
                                      No records added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        {values.investigation_team_members.map(
                          (item, index) => {
                            return (
                              <Box sx={{ marginBottom: "20px" }}>
                                <Grid container spacing={6}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    lg={6}
                                    order={{ lg: 1, xs: 2 }}
                                  >
                                    <FormControl>
                                      <TextField
                                        label="Names of Investigation Team members"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={item.team_members}
                                        fullWidth
                                        onChange={(e) => {
                                          let newp =
                                            values.investigation_team_members;
                                          newp[index].team_members =
                                            e.target.value;
                                          setFieldValue(
                                            "investigation_team_members",
                                            newp
                                          );
                                          // handleChangePassengerf_name(index, e);
                                        }}
                                        error={Boolean(
                                          errors?.investigation_team_members &&
                                            errors?.investigation_team_members[
                                              index
                                            ]?.team_members
                                        )}
                                        helperText={
                                          errors?.investigation_team_members &&
                                          errors?.investigation_team_members[
                                            index
                                          ]?.team_members
                                        }
                                        // my={2}
                                      />
                                    </FormControl>
                                  </Grid>
                                  {index === 0 ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={1}
                                      textAlign="right"
                                      alignSelf="center"
                                      order={{ lg: 4, xs: 1 }}
                                    >
                                      <Tooltip title="Add" placement="top">
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            let copy = {
                                              team_members: "",
                                            };
                                            setFieldValue(
                                              "investigation_team_members",
                                              [
                                                ...values.investigation_team_members,
                                                copy,
                                              ]
                                            );
                                          }}
                                        >
                                          <AddBoxIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={1}
                                      textAlign="right"
                                      alignSelf="center"
                                      order={{ lg: 4, xs: 1 }}
                                    >
                                      <Tooltip title="Delete" placement="top">
                                        <IconButton
                                          color="error"
                                          onClick={() => {
                                            // removePassengers(index);
                                            let newArr = [];
                                            for (
                                              let i = 0;
                                              i <
                                              values.investigation_team_members
                                                .length;
                                              i++
                                            ) {
                                              if (i !== index) {
                                                newArr.push(
                                                  values
                                                    .investigation_team_members[
                                                    i
                                                  ]
                                                );
                                              }
                                            }
                                            setFieldValue(
                                              "investigation_team_members",
                                              newArr
                                            );
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step5-manager"
                    id="step5-manager"
                  >
                    <Typography>Step 5: Preventative Action Plan</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={12}>
                        <Typography>
                          Please take time to determine the root cause and
                          corrective actions required to prevent an incident
                          like this in the future. Add additional actions by
                          clicking the green plus button.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Card variant="outlined" sx={{ borderBottom: 0 }}>
                          <TableContainer
                            className="tableContainer"
                            component={Paper}
                            sx={{
                              "&::-webkit-scrollbar": {
                                width: 7,
                                height: 7,
                              },
                              "&::-webkit-scrollbar-track": {
                                background: "#bed2f5",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                background: "#92a6c9",
                              },
                            }}
                          >
                            <Table
                              stickyHeader
                              aria-labelledby="tableTitle"
                              aria-label="sticky table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Root Causes</TableCell>
                                  <TableCell>Corrective Action</TableCell>
                                  <TableCell>Assigned To</TableCell>
                                  <TableCell>Date Assigned</TableCell>
                                  <TableCell>Target Date</TableCell>
                                  <TableCell>Date Completed</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {preventativeListing.map((item, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>{item.root_cause}</TableCell>
                                      <TableCell>
                                        {item.preventive_action}
                                      </TableCell>
                                      <TableCell>{item.assigned_to}</TableCell>
                                      <TableCell>
                                        {item.date_assigned}
                                      </TableCell>
                                      <TableCell>{item.target_date}</TableCell>
                                      <TableCell>
                                        {item.date_completed}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          aria-label="Settings"
                                          size="large"
                                          aria-owns={
                                            anchorEl ? "icon-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) =>
                                            handleActionClick(e, item.id)
                                          }
                                          style={{ color: "#1374C5" }}
                                        >
                                          <SettingsIcon />
                                        </IconButton>
                                        <StyledMenu
                                          id="icon-menu"
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleActionClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              setUpdatePreventiveDialog(true);
                                              getPreventativeData();
                                              handleActionClose();
                                            }}
                                            disabled={
                                              !hasPermission(
                                                "EmployeeAccidentReport",
                                                "editpreventive"
                                              )
                                            }
                                          >
                                            <ListItemIcon>
                                              <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Edit"
                                              pl={1}
                                            />
                                          </MenuItem>
                                        </StyledMenu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                                {preventativeListing.length == 0 && (
                                  <TableRow
                                  // style={{
                                  //   height: (dense ? 6 : 0) * totalRecord,
                                  // }}
                                  >
                                    <TableCell colSpan={7} align="center">
                                      No records added
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        {values.action_plan.map((item, index) => {
                          return (
                            <Grid container spacing={6}>
                              {index == 0 ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  xl={12}
                                  sx={{ textAlign: "right" }}
                                >
                                  <Tooltip title="Add" placement="top">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        let copy = {
                                          root_cause: "",
                                          preventive_action: "",
                                          assigned_to: "",
                                          date_assigned: null,
                                          target_date: null,
                                          date_completed: null,
                                        };
                                        setFieldValue("action_plan", [
                                          ...values.action_plan,
                                          copy,
                                        ]);
                                      }}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  textAlign="right"
                                  // alignSelf="center"
                                  // order={{ lg: 4, xs: 1 }}
                                >
                                  <Tooltip title="Delete" placement="top">
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        // removePassengers(index);
                                        let newArr = [];
                                        for (
                                          let i = 0;
                                          i < values.action_plan.length;
                                          i++
                                        ) {
                                          if (i !== index) {
                                            newArr.push(values.action_plan[i]);
                                          }
                                        }
                                        setFieldValue("action_plan", newArr);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <TextField
                                    // name="root_causes"
                                    label="Root causes"
                                    id="root-causes"
                                    placeholder="Slippery floor"
                                    InputLabelProps={{ shrink: true }}
                                    value={item.root_cause}
                                    onChange={(e) => {
                                      let newp = values.action_plan;
                                      newp[index].root_cause = e.target.value;
                                      setFieldValue("action_plan", newp);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.action_plan &&
                                        touched.action_plan[index]
                                          ?.root_cause &&
                                        errors?.action_plan &&
                                        errors?.action_plan[index]?.root_cause
                                    )}
                                    helperText={
                                      touched.action_plan &&
                                      touched.action_plan[index]?.root_cause &&
                                      errors?.action_plan &&
                                      errors?.action_plan[index]?.root_cause
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <TextField
                                    // name="correction_action"
                                    label="Corrective Action"
                                    id="correction-action"
                                    placeholder="Add mat"
                                    InputLabelProps={{ shrink: true }}
                                    value={item.preventive_action}
                                    onChange={(e) => {
                                      let newp = values.action_plan;
                                      newp[index].preventive_action =
                                        e.target.value;
                                      setFieldValue("action_plan", newp);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.action_plan &&
                                        touched.action_plan[index]
                                          ?.preventive_action &&
                                        errors?.action_plan &&
                                        errors?.action_plan[index]
                                          ?.preventive_action
                                    )}
                                    helperText={
                                      touched.action_plan &&
                                      touched.action_plan[index]
                                        ?.preventive_action &&
                                      errors?.action_plan &&
                                      errors?.action_plan[index]
                                        ?.preventive_action
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <TextField
                                    name="dept_name"
                                    label="Assigned To"
                                    id="dept-name"
                                    placeholder="Dept. or Name"
                                    InputLabelProps={{ shrink: true }}
                                    value={item.assigned_to}
                                    onChange={(e) => {
                                      let newp = values.action_plan;
                                      newp[index].assigned_to = e.target.value;
                                      setFieldValue("action_plan", newp);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.action_plan &&
                                        touched.action_plan[index]
                                          ?.assigned_to &&
                                        errors?.action_plan &&
                                        errors?.action_plan[index]?.assigned_to
                                    )}
                                    helperText={
                                      touched.action_plan &&
                                      touched.action_plan[index]?.assigned_to &&
                                      errors?.action_plan &&
                                      errors?.action_plan[index]?.assigned_to
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <DatePicker
                                    id="date-assigned"
                                    label="Date assigned"
                                    value={item.date_assigned}
                                    onChange={(newValue) => {
                                      let newD = values.action_plan;
                                      newD[index].date_assigned = newValue;
                                      setFieldValue("action_plan", newD);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="date_signed"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "mm/dd/yy",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <DatePicker
                                    id="target-date"
                                    label="Target date"
                                    value={item.target_date}
                                    onChange={(newValue) => {
                                      let newD = values.action_plan;
                                      newD[index].target_date = newValue;
                                      setFieldValue("action_plan", newD);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="date_signed"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "mm/dd/yy",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4} xl={4}>
                                <FormControl>
                                  <DatePicker
                                    id="date-completed"
                                    label="Date completed"
                                    value={item.date_completed}
                                    onChange={(newValue) => {
                                      let newD = values.action_plan;
                                      newD[index].date_completed = newValue;
                                      setFieldValue("action_plan", newD);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="date_signed"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "mm/dd/yy",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12} xl={12}>
                                <Divider my={4} />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion mt={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="step6-manager"
                    id="step6-manager"
                  >
                    <Typography>Step 6: Sign &amp; Review</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomTextField
                            name="written_by"
                            label="Written by"
                            InputLabelProps={{ shrink: true }}
                            id="written-by"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomSelect
                            name="written_dept"
                            fieldLabel="Department"
                            options={departmentList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                            displayEmpty
                            customCallbackMethod={(evt) => {
                              if (evt.target.value) {
                                getWrittenPositionList(evt.target.value);
                              }
                            }}
                          />
                          {/* <FormHelperText error>
                            {touched.supervisor_dept && errors.supervisor_dept}
                          </FormHelperText> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomSelect
                            name="written_title"
                            fieldLabel="Position"
                            options={writtenPositionList}
                            displayEmpty
                            firstOptionTitle="Select"
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DatePicker
                            id="review-date"
                            label="Review date"
                            value={values.completed_date}
                            onChange={(newValue) => {
                              setFieldValue("completed_date", newValue);
                              ProgressCounter(values);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                name="completed_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomTextField
                            name="reviewed_by"
                            label="Reviewed by"
                            InputLabelProps={{ shrink: true }}
                            id="reviewed-by"
                            placeholder=""
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomSelect
                            name="review_dept"
                            fieldLabel="Department"
                            options={departmentList}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            displayEmpty
                            customCallbackMethod={(evt) => {
                              if (evt.target.value) {
                                getReviewPositionList(evt.target.value);
                              }
                            }}
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <CustomSelect
                            name="review_title"
                            fieldLabel="Position"
                            options={reviewPositionList}
                            displayEmpty
                            firstOptionTitle="Select"
                            onBlur={() => {
                              ProgressCounter(values);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl>
                          <DatePicker
                            id="review-date"
                            label="Review date"
                            value={values.review_date}
                            onChange={(newValue) => {
                              setFieldValue("review_date", newValue);
                              ProgressCounter(values);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="review_date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "",
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Grid item xs={12} lg={6}>
                          <FormControl>
                            <FormLabel>Translated?</FormLabel>
                            <RadioGroup
                              aria-labelledby="translated"
                              name="translated_status"
                              value={values.translated_status.toString()}
                              onChange={(e) => {
                                setFieldValue(
                                  "translated_status",
                                  e.currentTarget.value
                                );
                                setFieldValue("employer", "");
                                setFieldValue("language", "");
                                ProgressCounter(values);
                              }}
                              row
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Yes"
                                value="Y"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="No"
                                value="N"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display:
                            values.translated_status == "Y" ? "block" : "none",
                        }}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="employer"
                                label="Translated by"
                                InputLabelProps={{ shrink: true }}
                                id="employer"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <FormControl>
                              <CustomTextField
                                name="language"
                                label="Language"
                                InputLabelProps={{ shrink: true }}
                                id="language"
                                placeholder=""
                                onBlur={() => {
                                  ProgressCounter(values);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl mt={6}>
                          {editData.manager_sign_image !== null ? (
                            <Card
                              variant="outlined"
                              className="signatureCanvas"
                            >
                              <Image
                                sx={{ height: "150px" }}
                                src={editData.manager_sign_image}
                              />
                            </Card>
                          ) : (
                            <>
                              <FormLabel component="legend">
                                Sign here
                              </FormLabel>
                              <Card
                                variant="outlined"
                                className="signatureCanvas"
                              >
                                <CardContent>
                                  <SignatureCanvas ref={mgmSignCanvas} />
                                </CardContent>
                                <CardActions>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      setFieldValue(
                                        "manager_sign_image",
                                        mgmSignCanvas.current
                                          .getTrimmedCanvas()
                                          .toDataURL("image/png")
                                      );
                                      ProgressCounter(values);
                                      dispatch(getMsg("Image saved"));
                                      dispatch(getSnackAlert("success"));
                                      dispatch(getSnackOpen());
                                      setTimeout(() => {
                                        dispatch(getSnackClose());
                                      }, 3000);
                                    }}
                                    // onClick={saveMgmSignPad}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                      mgmSignCanvas.current.clear();
                                      setFieldValue("manager_sign_image", null);
                                      dispatch(getMsg("Image cleared"));
                                      dispatch(getSnackAlert("success"));
                                      dispatch(getSnackOpen());
                                      setTimeout(() => {
                                        dispatch(getSnackClose());
                                      }, 3000);
                                      ProgressCounter(values);
                                    }}
                                    // onClick={clearMgmSignPad}
                                  >
                                    Clear
                                  </Button>
                                </CardActions>
                              </Card>
                            </>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  mt={3}
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        {loader && (
          <Paper className={classes.fixedLoader}>
            <CircularProgress />
          </Paper>
        )}
        {updatePreventiveDialog && (
          <UpdatePreventive
            updatePreventiveDialog={updatePreventiveDialog}
            setUpdatePreventiveDialog={setUpdatePreventiveDialog}
            editPrevData={editPrevData}
            setLoader={setLoader}
            getPreventativeListData={getPreventativeListData}
            id={id}
            updatePrevDialogClose={updatePrevDialogClose}
          />
        )}
      </CardContent>
    </Card>
  );
}

function EditEar() {
  return (
    <React.Fragment>
      <Helmet title="Edit EAR Report" />
      <WithPermissionFallback
        controller="EmployeeAccidentReport"
        action="editEarMgr"
      >
        <Typography variant="h3" gutterBottom display="inline">
          Edit EAR Report
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/employee-accidents">
            EAR Reports
          </Link>
          <Typography>Edit EAR Report</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <EditMgrForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditEar;
