import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper as MuiPaper,
  Link,
  Card,
  CardContent,
  TextField,
  FormControl as MuiFormControl,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { spacing } from "@mui/system";
import { SaveIrNumbering, GetIrNumbering } from "../../../api";
import { useDispatch } from "react-redux";
import { RestartAlt } from "@mui/icons-material";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import hasPermission from "../../../utils/hasPermission";
import WithPermissionHide from "../../../utils/withPermissionHide";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// Classes Styling
const useStyles = makeStyles((theme) => ({
  BulletList: {
    display: "block",
    padding: "15px 15px",
    "& .MuiListItem-root": {
      padding: "10px 0 0",
      listStyle: "disc",
      display: "list-item",
    },
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

function IrNumberingSection(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    incrementing_ir_value: "",
    suffix_ir_value_d: false,
    prefix_ir_value_d: false,
    prefix_ir_value: "",
    suffix_ir_value: "",
  });
  const [pageLoad, setPageLoad] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
          ? "date"
          : false
        : event.target.value;
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: fieldValue,
    }));
  };

  const getIrNumbering = () => {
    GetIrNumbering()
      .then((res) => {
        if (res.statusCode == 200) {
          // setFormData(res.data);
          setFormData((prev) => ({
            ...prev,
            prefix_ir_value_d:
              res.data.prefix_ir_value == "date" ? true : false,
            incrementing_ir_value: res.data.incrementing_ir_value,
            suffix_ir_value_d:
              res.data.suffix_ir_value == "date" ? true : false,
            suffix_ir_value:
              res.data.suffix_ir_value == "date"
                ? ""
                : res.data.suffix_ir_value,
            prefix_ir_value:
              res.data.prefix_ir_value == "date"
                ? ""
                : res.data.prefix_ir_value,
          }));
        } else {
          setFormData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("data", formData);
  const saveIrNumbering = () => {
    console.log("length", formData.incrementing_ir_value.length);
    if (
      formData.incrementing_ir_value.length < 2 ||
      formData.incrementing_ir_value.length > 4
    ) {
      setErrMsg("Incrementing value must be between 2 and 4 digits in length");
    } else {
      setPageLoad(true);
      SaveIrNumbering(formData)
        .then((res) => {
          if (res.statusCode == 200) {
            console.log("data save suceessfully");
            setErrMsg("");
            dispatch(getMsg("IR Number Save successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            console.log("something wen't wrong");
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          setPageLoad(false);
        });
    }
  };
  useEffect(() => {
    getIrNumbering();
  }, []);
  return (
    <>
      <Paper mt={6}>
        <Card>
          <CardContent>
            <Typography component="div" variant="h5" gutterBottom>
              Instructions
            </Typography>
            <List className={classes.BulletList}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Incident reports are numbering according to this format:{" "}
                      <span style={{ color: "blue" }}>
                        prefix-increment-suffix.
                      </span>{" "}
                      Only the incrementing value is required. For example, if
                      you wish your IR's to be numbered simply like 1004, 1005
                      and so on, enter the starting number like '1005' in the
                      incrementing field and save. The next IR created will be
                      given the number 1006.
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Alternatively, if you are looking for a more detailed
                      numbering system, you can choose to add the current date
                      to the IR number, either in front of, or after the
                      incrementing number. You can also include any fixed series
                      of letters or numbers as a prefix or suffix. For example,
                      you may choose to add the 2 digit year as a prefix,
                      followed by the incrementing value. This would yield an IR
                      number like '19-1005'. You would then manually change the
                      prefix each year.
                    </>
                  }
                />
              </ListItem>
            </List>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <FormControl>
                    <FormLabel>Prefix</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.prefix_ir_value_d}
                          name="prefix_ir_value_d"
                          onChange={handleChange}
                          disabled={
                            !hasPermission("Settings", "saveIrNumbering")
                          }
                        />
                      }
                      label="Incident date"
                    />
                    <Typography>Or</Typography>
                    <TextField
                      fullWidth
                      name="prefix_ir_value"
                      onChange={handleChange}
                      value={formData.prefix_ir_value}
                      disabled={
                        formData.prefix_ir_value_d == "date" ||
                        !hasPermission("Settings", "saveIrNumbering")
                      }
                      placeholder="Prefix"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} alignSelf="end">
                  <FormControl>
                    <TextField
                      fullWidth
                      name="incrementing_ir_value"
                      id="increment"
                      label="Increment*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                      value={formData.incrementing_ir_value}
                      disabled={!hasPermission("Settings", "saveIrNumbering")}
                    />
                  </FormControl>
                  <FormHelperText error>{errMsg}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl>
                    <FormLabel>Suffix</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.suffix_ir_value_d}
                          name="suffix_ir_value_d"
                          onChange={handleChange}
                          disabled={
                            !hasPermission("Settings", "saveIrNumbering")
                          }
                        />
                      }
                      label="Incident date"
                    />
                    <Typography>Or</Typography>
                    <TextField
                      fullWidth
                      name="suffix_ir_value"
                      value={formData.suffix_ir_value}
                      onChange={handleChange}
                      placeholder="Suffix"
                      disabled={
                        formData.suffix_ir_value_d ||
                        !hasPermission("Settings", "saveIrNumbering")
                      }
                    />
                  </FormControl>
                </Grid>
                <WithPermissionHide
                  controller="Settings"
                  action="saveIrNumbering"
                >
                  <Grid item xs={12} md={4}>
                    <Button
                      onClick={saveIrNumbering}
                      color="primary"
                      variant="contained"
                      disabled={!hasPermission("Settings", "saveIrNumbering")}
                    >
                      Save
                    </Button>
                  </Grid>
                </WithPermissionHide>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Paper>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function IrNumbering() {
  return (
    <React.Fragment>
      <Helmet title="Incident Report Numbering" />
      <WithPermissionFallback controller="Settings" action="getIrNumbering">
        <Typography variant="h3" gutterBottom display="inline">
          <BorderColorIcon /> Incident Report Numbering
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <IrNumberingSection />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default IrNumbering;
