import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import EditAudit from "./Inner-components/EditAudit";
import AddAudit from "./Inner-components/AddAudit";
import { TenantList as List } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import TenantListCol from "../table-columns-json/packages/Tenant";
import { setTenantList } from "../../redux/slices/packages/packagesTableColSlice";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../redux/slices/mainSlice";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  Divider as MuiDivider,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Attractions as AttractionsIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PriceCheck as PriceCheckIcon,
  Approval as ApprovalIcon,
  DeliveryDining as DeliveryDiningIcon,
  Print as PrintIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomPagination from "../../components/table-components/CustomPagination";
import TenantStatus from "./Inner-components/TenantStatus";
import AddTenant from "./Inner-components/AddTenant";
import StyledColumnOptions from "../../helper/StyledColumnOptions";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
import hasPermission from "../../utils/hasPermission";
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);

// ThumbnailTableWrapper
const ThumbnailTableWrapper = styled.div`
  width: 100px;
`;
// Image
const Image = styled.img`
  width: 100%;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  author,
  date,
  storagelocation,
  packagecount,
  unaccounted,
  action
) {
  return {
    author,
    date,
    storagelocation,
    packagecount,
    unaccounted,
    action,
  };
}
const rows = [
  createData("Darin Redding", "2022-05-11", "Main Storage", "1", 0, 0),
  createData("Marin Redding", "2022-05-11", "Main Storage", "1", 0, 0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "company_name",
    numeric: true,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "admin_name",
    numeric: true,
    disablePadding: false,
    label: "Admin Name",
  },
  {
    id: "admin_email",
    numeric: true,
    disablePadding: false,
    label: "Admin Email",
  },
  {
    id: "admin_phone",
    numeric: true,
    disablePadding: false,
    label: "Admin Phone",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableCalRedux,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {TenantListCol.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={tableCalRedux[headCell.id] === true ? "" : "hide-cell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id == "action" ? null : createSortHandler(headCell.id)
              }
              hideSortIcon={headCell.id == "action" ? true : false}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, totalRecord, tenantList, tableCalRedux } = props;
  const [actions, setActions] = React.useState("");
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultPackagesTableCol.tenantList
  );
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);
  //Select
  const [storageLocation, setStorageLocation] = React.useState("");
  const selectStorageLocation = (event) => {
    setStorageLocation(event.target.value);
  };
  const [carrier, setCarrier] = React.useState("");
  const selectCarrier = (event) => {
    setCarrier(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  // Add Audit
  const [addTenant, setAddTenant] = React.useState(false);
  const addTenantOpen = () => {
    setAddTenant(true);
  };
  const addTenantClose = () => {
    setAddTenant(false);
  };

  const [dropdowncategory, setDropCategory] = useState(null);
  const handleClickCategory = (event) => {
    setDropCategory(event.currentTarget);
  };
  const handleCloseCategory = () => {
    setDropCategory(null);
  };

  return (
    <Toolbar>
      <div>
        <Typography
          color="inherit"
          variant="subtitle1"
          mt={3}
          style={{ width: "max-content", marginLeft: "15px" }}
        >
          {totalRecord} records found
        </Typography>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide controller="Tenant" action="addTenant">
              <MenuItem onClick={addTenantOpen}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Tenant" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {
                      Object.keys(tableCalRedux).filter(
                        (k) => tableCalRedux[k] === true
                      ).length
                    }{" "}
                    out of {Object.keys(tableCalRedux).length} visible
                  </Typography>
                  <StyledColumnOptions
                    calStatus={tableCalRedux}
                    updateCalStatus={(data) => {
                      dispatch(setTenantList(data));
                    }}
                    listing={TenantListCol}
                  />
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        dispatch(setTenantList(defaultTableCalRedux));
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
          {addTenant && (
            <AddTenant
              addTenant={addTenant}
              setAddTenant={setAddTenant}
              addTenantOpen={addTenantOpen}
              addTenantClose={addTenantClose}
              tenantList={tenantList}
            />
          )}
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [tenantData, setTenantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [dataToFilter, setDataToFilter] = useState(null);
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const tableCalRedux = useSelector(
    (state) => state.packagesTableCol.tenantList
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tenantData.map((n) => n.author);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, author) => {
    const selectedIndex = selected.indexOf(author);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, author);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (author) => selected.indexOf(author) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const [addTenant, setAddTenant] = React.useState(false);
  const addTenantOpen = () => {
    setAddTenant(true);
    setIsEdit(true);
  };
  const addTenantClose = () => {
    setAddTenant(false);
    setIsEdit(false);
  };
  /**
   * It sets the current page to the value of the page number that was clicked
   * @param e - the event object
   * @param value - The value of the page that was clicked.
   */
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };

  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const tenantList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      ...dataToFilter,
    };
    List(currentPage, payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setTenantData(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setTenantData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    tenantList();
  }, [rowsPerPage, currentPage, dataToFilter, order, orderBy]);
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          tenantList={tenantList}
          tableCalRedux={tableCalRedux}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tenantData.length}
              tableCalRedux={tableCalRedux}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(tenantData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                          className={
                            tableCalRedux.company_name === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.company_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.admin_name === true ? "" : "hide-cell"
                          }
                        >
                          {row.admin_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.admin_email === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.tenant_email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                          className={
                            tableCalRedux.tenant_phone === true
                              ? ""
                              : "hide-cell"
                          }
                        >
                          {row.tenant_phone}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.status === true ? "" : "hide-cell"
                          }
                        >
                          <FormGroup>
                            <TenantStatus id={row.id} value={row.status} />
                          </FormGroup>
                        </TableCell>

                        <TableCell
                          align="left"
                          className={
                            tableCalRedux.action === true ? "" : "hide-cell"
                          }
                        >
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleActionClick(e, row.id)}
                            style={{ color: "#1374C5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <StyledMenu
                            id="icon-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                addTenantOpen();
                                handleActionClose();
                              }}
                              disabled={!hasPermission("Tenant", "editTenant")}
                            >
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText primary="Edit" pl={1} />
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {tenantData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {addTenant && (
        <AddTenant
          addTenant={addTenant}
          setAddTenant={setAddTenant}
          addTenantOpen={addTenantOpen}
          addTenantClose={addTenantClose}
          tenantList={tenantList}
          id={id}
          isEdit={isEdit}
        />
      )}
    </div>
  );
}

function TenantList() {
  return (
    <React.Fragment>
      <Helmet title="Tenant List" />
      <WithPermissionFallback controller="Tenant" action="tenantList">
        <Typography variant="h3" gutterBottom display="inline">
          Tenant List
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/packages/list-packages">
            List Packages
          </Link>
          <Typography>Tenant List</Typography>
        </Breadcrumbs>
        <Divider my={6} />
        <EnhancedTable />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default TenantList;
