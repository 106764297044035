import React, { useState } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import CustomTextField from "../../../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../../redux/slices/mainSlice";
import { format } from "date-fns";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { EditDevice } from "../../../../api";
import hasPermission from "../../../../utils/hasPermission";

const ListEditModal = ({
  listEditDialog,
  setListEditDialog,
  listEditDialogOpen,
  listEditDialogClose,
  updatedRow,
  deviceList,
}) => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const [formData, setFormData] = useState(updatedRow);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  console.log("formData", formData);
  const editDevice = () => {
    let payload = {
      id: formData.id ? formData.id : null,
      device_nickname: formData.device_nickname,
      device_meid_imei: formData.device_meid_imei,
      device_serial_number: formData.device_serial_number,
      sova_app_version: formData.sova_app_version,
      activation_date:
        formData.activation_date != null
          ? format(new Date(formData.activation_date), "yyyy-MM-dd")
          : null,
      expiry_date:
        formData.expiry_date != null
          ? format(new Date(formData.expiry_date), "yyyy-MM-dd")
          : null,
      notes: formData.notes,
      status: formData.status,
      device_unique_id: formData.device_unique_id,
      device_name: formData.device_name,
      device_model: formData.device_model,
      device_os: formData.device_os,
    };
    EditDevice(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          listEditDialogClose();
          deviceList();
          if (formData?.id) {
            dispatch(getMsg("Data updated successfully"));
          } else {
            dispatch(getMsg("Data add successfully"));
          }
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "850px",
            },
          },
        }}
        open={listEditDialog}
        onClose={listEditDialogClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Edit Devices
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setListEditDialog(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={6} mt={6}>
              <FormControl>
                <TextField
                  fullWidth
                  id="outlined"
                  name="device_nickname"
                  value={
                    formData?.device_nickname ? formData.device_nickname : null
                  }
                  onChange={handleChange}
                  label="Device Nickname"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={6}>
              <FormControl>
                <TextField
                  id="outlined"
                  name="device_name"
                  value={formData?.device_name ? formData.device_name : null}
                  label="Device Name"
                  disabled
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  name="device_model"
                  value={formData?.device_model ? formData.device_model : null}
                  label="Device Modal"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  name="device_os"
                  value={formData?.device_os ? formData.device_os : null}
                  label="Device OS"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  fullWidth
                  id="outlined"
                  name="device_meid_imei"
                  value={
                    formData?.device_meid_imei
                      ? formData.device_meid_imei
                      : null
                  }
                  label="IMEI 1"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  fullWidth
                  id="outlined"
                  name="device_serial_number"
                  value={
                    formData?.device_serial_number
                      ? formData.device_serial_number
                      : null
                  }
                  label="IMEI 2"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  id="outlined"
                  name="device_unique_id"
                  value={
                    formData?.device_unique_id
                      ? formData.device_unique_id
                      : null
                  }
                  label="Device Unique Id"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl>
                <TextField
                  fullWidth
                  id="outlined"
                  label="Sova App Version"
                  name="sova_app_version"
                  value={
                    formData?.sova_app_version
                      ? formData.sova_app_version
                      : null
                  }
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControl></FormControl>
              <FormControl>
                <FormLabel variant="body2" gutterBottom>
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="status"
                  value={formData?.status ? formData.status : null}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="R"
                    control={<Radio />}
                    label="Required"
                  />
                  <FormControlLabel
                    value="A"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="I"
                    control={<Radio />}
                    label="Temporary access restricted"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={6} mt={3}>
              <FormControl mt={6}>
                <DatePicker
                  name="activation_date"
                  label="Activation Date"
                  value={
                    formData?.activation_date ? formData.activation_date : null
                  }
                  onChange={(value) =>
                    setFormData({ ...formData, activation_date: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="activation_date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6}>
              <FormControl mt={3}>
                <DatePicker
                  id="date-to"
                  label="Date to"
                  name="expiry_date"
                  value={formData?.expiry_date}
                  onChange={(value) =>
                    setFormData({ ...formData, expiry_date: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Date to"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} mt={3}>
              <FormControl>
                <TextField
                  fullWidth
                  id="outlined"
                  label="Device Notes"
                  name="notes"
                  value={formData?.notes ? formData.notes : null}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            disabled={!hasPermission("Device", "editDevice")}
            onClick={editDevice}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListEditModal;
