import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import CopyIrClasses from "./inner-component/CopyIrClasses";
import styled from "styled-components/macro";
import CustomPagination from "../../../components/table-components/CustomPagination";
import { format } from "date-fns";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  /* List,
  ListItem, */
  ListItemIcon,
  CircularProgress,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  ContentCopy as ContentCopyIcon,
  ListAlt as ListAltIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  TrendingDown as TrendingDownIcon,
  Print as PrintIcon,
  Send as SendIcon,
  Mail as MailIcon,
} from "@mui/icons-material";
import { ShipmentsReports, UpdateGdpr } from "../../../api";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import hasPermission from "../../../utils/hasPermission";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },
      "& .MuiMenuItem-root:hover": {
        background: "transparent",
      },
      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "142px",
                width: "100%",
                whiteSpace: "pre-line",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  sel,
  id,
  recipientName,
  address,
  phone,
  email,
  tracking,
  carrier,
  trackingStatus,
  finalTrackingMessage,
  created,
  modified,
  action
) {
  return {
    sel,
    id,
    recipientName,
    address,
    phone,
    email,
    tracking,
    carrier,
    trackingStatus,
    finalTrackingMessage,
    created,
    modified,
    action,
  };
}
const rows = [
  createData("A test of IR task", "34", 0, 0, 0, 0, 0, 0),
  createData("Abandoned vehicle", "34", 0, 0, 0, 0, 0, 0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "recipientName",
    numeric: true,
    disablePadding: false,
    label: "Recipient Name",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "tracking",
    numeric: true,
    disablePadding: false,
    label: "Tracking#",
  },
  {
    id: "carrier",
    numeric: true,
    disablePadding: false,
    label: "Carrier",
  },
  {
    id: "trackingStatus",
    numeric: true,
    disablePadding: false,
    label: "Tracking Status",
  },
  {
    id: "finalTrackingMessage",
    numeric: true,
    disablePadding: false,
    label: "Final Tracking Message",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "modified",
    numeric: true,
    disablePadding: false,
    label: "Modified",
  },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            sx={{ whiteSpace: "nowrap" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.icon}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, totalRecord, selected, updateGdpr, setDataToFilter } =
    props;
  const [actions, setActions] = React.useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [search, setSearch] = useState("");

  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  const [value, setValue] = React.useState(null);

  // Copy IR Classes
  const [copyIrClassesDialog, setCopyIrClassesDialog] = useState(false);
  const copyIrClassesDialogOpen = () => {
    setCopyIrClassesDialog(true);
  };
  const copyIrClassesDialogClose = () => {
    setCopyIrClassesDialog(false);
  };

  const [position, setPosition] = React.useState("");
  const selectPosition = (event) => {
    setPosition(event.target.value);
  };
  const applySearch = () => {
    console.log(dateFrom);
    const payload = {
      start_date: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
      end_date: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    };
    // console.log(payload);
    setDataToFilter(payload);
  };
  const resetFilter = () => {
    setSearch("");
    setDateFrom(null);
    setDateTo(null);
  };

  return (
    <>
      <Toolbar>
        <div>
          <Paper mt={4}>
            <FormControl
              mr={2}
              sx={{
                minWidth: 148,
                display: "flex",
              }}
            >
              <InputLabel id="demo-simple-select-label">Action</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actions}
                label="Action"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        boxShadow:
                          "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                        margin: "0px 3px 5px 3px",
                        padding: "0",
                      },
                    },
                  },
                }}
                onChange={handleChange}
              >
                {selected.length > 0 && (
                  <MenuItem
                    disabled={!hasPermission("Settings", "updateGdpr")}
                    onClick={updateGdpr}
                  >
                    Remove Personal data
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Paper>
        </div>
        <div className="totalRecord">
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        </div>
        {/* <Spacer /> */}
        <div className="searchTool">
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <Item sx={{ flexGrow: 1, width: "100%" }}>
              <TextField
                id="search"
                type="search"
                placeholder="Search by location, code or click down arrow"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    applySearch({});
                    setIsFilter(true);
                  }
                }}
                inputProps={{}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-owns={anchorForm ? "search-form" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <StyledForm
                        id="search-form"
                        anchorEl={anchorForm}
                        open={Boolean(anchorForm)}
                        onClose={handleCloseForm}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{ maxHeight: "380px", overflowY: "auto" }}
                        >
                          <form>
                            <FormControl>
                              <DatePicker
                                id="date-from"
                                label="Date from"
                                value={dateFrom}
                                onChange={(newValue) => {
                                  setDateFrom(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Date from",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <DatePicker
                                id="date-to"
                                label="Date to"
                                value={dateTo}
                                onChange={(newValue) => {
                                  setDateTo(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "Date to",
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                mt={6}
                                onClick={() => {
                                  applySearch({});
                                  handleCloseForm();
                                  setIsFilter(true);
                                }}
                              >
                                Filter
                              </Button>
                            </FormControl>
                          </form>
                        </MenuItem>
                      </StyledForm>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Item>
              {isFilter ? (
                <Button
                  variant="contained"
                  color="primary"
                  mt={6}
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    resetFilter();
                    setDataToFilter(null);
                    setIsFilter(false);
                    setSearch("");
                  }}
                >
                  Clear filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    height: "100%",
                    marginLeft: "-3px",
                  }}
                  onClick={() => {
                    if (search !== "") {
                      applySearch();
                      setIsFilter(true);
                    }
                  }}
                >
                  Search
                </Button>
              )}
            </Item>
          </Box>
        </div>

        <div>
          <Paper mt={3}>
            {/* <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton> */}
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                component={NavLink}
                to="/settings/corporate-user/add-ir-classification"
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText
                  size="large"
                  primary="Add Classification"
                  pl={0}
                />
              </MenuItem>
              <MenuItem onClick={copyIrClassesDialogOpen}>
                <ListItemIcon>
                  <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Copy IR Classes" pl={0} />
              </MenuItem>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      7 out of 7 visible
                    </Typography>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked disableRipple />}
                          label="Classification Name"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Count"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Critical"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Nurse Notify"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Img Required"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Task Count"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Active Status"
                        />
                        <FormControlLabel
                          control={<Checkbox disableRipple />}
                          label="Action"
                        />
                      </FormGroup>
                    </Box>
                    <Toolbar>
                      <Button color="primary" variant="contained">
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem> */}
            </StyledColumnDropdown>
          </Paper>
        </div>
      </Toolbar>
      {copyIrClassesDialog && (
        <CopyIrClasses
          copyIrClassesDialog={copyIrClassesDialog}
          setCopyIrClassesDialog={setCopyIrClassesDialog}
          copyIrClassesDialogOpen={copyIrClassesDialogOpen}
          copyIrClassesDialogClose={copyIrClassesDialogClose}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lostFoundList, setLostFoundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToFilter, setDataToFilter] = useState(null);
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lostFoundList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  console.log("selected", selected);
  const updateGdpr = () => {
    UpdateGdpr(selected)
      .then((res) => {
        if (res.statusCode === 200) {
          setSelected([]);
          shipmentsReports();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const shipmentsReports = () => {
    let payload = {
      ...dataToFilter,
    };
    setLoading(true);
    ShipmentsReports(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setLoading(false);
          setLostFoundList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          console.log("data", res.meta.total);
        } else {
          setLoading(false);
          setLostFoundList([]);
          setTotalRecord(0);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    shipmentsReports();
  }, [rowsPerPage, currentPage, dataToFilter]);

  return (
    <div>
      <Typography variant="body2" gutterBottom mt={3} mb={5}>
        Records appearing in the table below contain personal information for
        persons that reside in the EU or other Countries that fall under a
        privacy regulation known as GDPR. Your responsibility in helping to
        maintain compliance with the GDPR is to regularly visit this page,
        select any records shown below and click the button at the bottom of the
        table to remove sensitive information from these records. The lost and
        found record will still exist in the system, but certain pieces of
        information, like the recipients name, phone and email will be removed.
      </Typography>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalRecord={totalRecord}
          selected={selected}
          updateGdpr={updateGdpr}
          setDataToFilter={setDataToFilter}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <TableBody>
                {stableSort(lostFoundList, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            selected={isItemSelected}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ lg: 'whiteSpace: "nowrap"' }}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.tracking_id}</TableCell>
                        <TableCell align="left">{row.carrier_chosen}</TableCell>
                        <TableCell align="left">
                          {row.tracking_status}
                        </TableCell>
                        <TableCell align="left">
                          {row.final_tracking_message}
                        </TableCell>
                        <TableCell align="left">{row.created}</TableCell>
                        <TableCell align="left">{row.modified}</TableCell>
                      </TableRow>
                    );
                  }
                )}
                {lostFoundList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

function LostFoundPrivacy() {
  return (
    <React.Fragment>
      <Helmet title="GDPR Lost & Found Privacy" />
      <WithPermissionFallback controller="Settings" action="shipmentsReports">
        <Typography variant="h3" gutterBottom display="inline">
          GDPR Lost & Found Privacy
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default LostFoundPrivacy;
