import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import SendDar from "./inner-components/SendDar";
import ShowChart from "./inner-components/ShowChart";
import ChangeLogDialog from "./inner-components/ChangeLogDialog";
import EditActivityDialog from "./inner-components/EditActivityDialog";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  /* FormGroup, */
  TableContainer,
  InputAdornment,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  /* Tooltip, */
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Radio,
  CardContent,
  FormLabel,
  RadioGroup,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Tooltip,
  ButtonGroup,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress,
  Pagination,
} from "@mui/material";
import ImageCropperModal from "./inner-components/ImageShowcaseModal";
import { TimePicker } from "@mui/lab";
import { format } from "date-fns";
import {
  FilterList as FilterListIcon,
  Mail as MailIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  ListAlt as ListAltIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PieChart as PieChartIcon,
  RateReview as RateReviewIcon,
  CameraAlt as CameraAltIcon,
  Send as SendIcon,
  Delete as DeleteIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { spacing } from "@mui/system";
import { subDays } from "date-fns";
import DailyActivityD1 from "./inner-components/DailyActivityD1";
import {
  DailyActivityReport,
  UserAutocompleteList,
  DailyActivityColumns,
  UploadNewImage,
} from "../../api";
import {
  dailyActivityList,
  getDailyActivityChecked,
  getSnackClose,
} from "../../redux/slices/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import RecentActivityDialog from "./inner-components/RecentActivityDialog";
import CustomDateRange from "../../components/form-components/CustomDateRange";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DARInquiryDialog from "./inner-components/DARInquiryDialog";
import Toaster from "../tasks/Toaster";
import ImageListingModal from "../components/ImageGallery/ImageListingModal";
import imageData from "../components/ImageGallery/images.json";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */

// Classes Styling
const useStyles = makeStyles((theme) => ({
  searchFilter: {
    position: "relative",
  },
  clearSearch: {
    position: "absolute",
    right: "45px",
  },
  dateRangeDropdown: {
    position: "absolute",
    left: 0,
    top: "100%",
    width: "100%",
    zIndex: 2,
    marginLeft: "0 !important",
    maxHeight: "200px",
    overflowY: "auto",

    "& .MuiBox-root": {
      padding: "15px",
      "& .MuiButton-contained": {
        marginLeft: "15px",
      },
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root:hover": {
        backgroundColor: "#376fd012",
      },
    },
  },
  DateList: {
    border: "1px solid #0000001c",
    marginTop: "22px",
    padding: 0,
    "& .MuiListItem-root": {
      borderBottom: "1px solid #0000001c",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: "#376fd012",
    },
  },
  FormInquiry: {
    "& .MuiFormControl-root": {
      width: "100%",
      minWidth: "auto",
    },
  },
  ErrorImage: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",
    marginBottom: "15px",
  },
  AvatarMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    "& .MuiButtonBase-root": {
      whiteSpace: "nowrap",
    },
  },
  AvatarMediaBody: {
    display: "flex",
    alignItems: "center",
  },
  AvatarImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  textAreaControl: {
    position: "relative",
    width: "100%",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);
const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
// Image Wrapper
const ImageWrapper = styled.div`
  margin-top: 10px;
`;
// const Image = styled.img`
//   width: 100%;
// `;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      "& a": {
        color:
          theme.palette.mode === "light" ? "#374151" : theme.palette.grey[300],
        textDecoration: "none !important",
      },
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const StyledForm = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginLeft: "75px",
    marginTop: "8px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      minWidth: "435px",
      "& form": {
        padding: "15px 20px",
      },
      "& .MuiMenuItem-root": {
        padding: "0",
        display: "block",
        "& .MuiFormControl-root": {
          display: "block",
          width: "100%",
          marginBottom: "20px",
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  datetime,
  ts,
  activity,
  location,
  image,
  remarks,
  so,
  action
) {
  return {
    datetime,
    ts,
    activity,
    location,
    image,
    remarks,
    so,
    action,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector(
    (state) => state.report.dailyActivityChecked
  );

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    setHeadColumn(result);
  };

  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all data" }}
          /> */}
          <Tooltip title="Checked rows require follow up" followCursor>
            <HelpIcon
              color="primary"
              sx={{ verticalAlign: "middle", cursor: "pointer" }}
            />
          </Tooltip>
        </TableCell>
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            sx={{ whiteSpace: "nowrap" }}
            sortDirection={orderBy === headCell.key ? order : false}
            padding={headCell.key === "activity_time" ? "none" : "normal"}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell.key)}
              // hideSortIcon={headCell.key === "activity_time" ? false : true}
            >
              <Tooltip title={headCell.tooltipMsg}>
                <div>{headCell.value}</div>
              </Tooltip>
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const {
    numSelected,
    setDataToFilter,
    filterRecord,
    totalRecord,
    colArray,
    getColumnList,
    getColumnChecked,
    generatePdf,
    startDateToFilter,
    setStartDateToFilter,
    endDateToFilter,
    setEndDateToFilter,
    handleFirstPage,
    setCurrentPage,
    getDailyActivityCSV,
    csvDataList,
  } = props;
  //States for filter
  // Date Pickers

  // Time Pickers
  const [fromtime, setFromTime] = useState(null);
  const [totime, setToTime] = useState(null);

  // Select officer
  const [officer, setOfficer] = React.useState("");
  const selectOfficer = (event) => {
    setOfficer(event.target.value);
  };
  const [sentimentValue, setSentimentValue] = useState("");
  const [activityValue, setActivityValue] = useState("");
  const [remarkValue, setRemarkValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [actions, setActions] = React.useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [fadebox, setFadedBox] = React.useState(false);
  const [officerList, setOfficerList] = useState([]);

  const activityList = useSelector((state) => state.report.dailyActivityData);
  const checkedValue = useSelector(
    (state) => state.report.dailyActivityChecked
  );

  const dispatch = useDispatch();
  const handleChange = (event) => {
    setActions(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    getDailyActivityCSV();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Search Dropdown
  const [anchorForm, setAnchorForm] = React.useState(null);
  const handleClickDropdown = (event) => {
    setAnchorForm(event.currentTarget);
  };

  const handleCloseForm = () => {
    setAnchorForm(null);
  };
  // Select Result
  const [result, setResult] = React.useState("");
  const selectResult = (event) => {
    setResult(event.target.value);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  //Scan Visitor ID modal
  const [open, visitorModal] = React.useState(false);
  // const scanVisitorModalOpen = () => {
  //   visitorModal(true);
  // };
  // const scanVisitorModalClose = () => {
  //   visitorModal(false);
  // };
  // const [fadebox, setFadedBox] = React.useState(false);
  const classes = useStyles();

  //Method for search filter
  const applySearch = () => {
    const payload = {
      // startTemp: startDateToFilter,
      // endTemp: endDateToFilter,
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      // start_date: dateToValue ? moment(dateToValue).format("MM-DD-YYYY") : "",
      // end_date: dateFromValue ? moment(dateFromValue).format("MM-DD-YYYY") : "",
      start_time: fromtime ? moment(fromtime).format("HH:mm:ss") : "00:00:00",
      end_time: totime ? moment(totime).format("HH:mm:ss") : "23:59:00",
      user_id: officer,
      activity_type: activityValue,
      sentiment_icon: sentimentValue,
      comments: remarkValue,
      location: locationValue,
      search_input: searchInput,
    };
    filterRecord(payload);
  };

  //function for reseting the filter
  const resetFilter = () => {
    setStartDateToFilter(new Date());
    setEndDateToFilter(new Date());
    // setDateFromValue(null);
    // setDateToValue(null);
    setFromTime(null);
    setToTime(null);
    setOfficer("");
    setSentimentValue("");
    setActivityValue("");
    setRemarkValue("");
    setLocationValue("");
    setSearchInput("");
    filterRecord({});
  };
  // Send DAR Modal
  const [sendDar, setSendDar] = React.useState(false);
  const sendDarOpen = () => {
    setSendDar(true);
  };
  const sendDarClose = () => {
    setSendDar(false);
  };

  //Handler for user/Officer suggestion autocomplete
  const getOfficerList = () => {
    UserAutocompleteList()
      .then((res) => {
        if (res.statusCode == 200) {
          setOfficerList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOfficerList();
  }, []);

  // Show chart modal
  const [showChart, setShowChart] = useState(false);
  const showChartOpen = () => {
    setShowChart(true);
  };
  const showChartClose = () => {
    setShowChart(false);
  };
  // Change log Modal
  const [changeLog, setChangeLog] = React.useState(false);
  const changeLogOpen = () => {
    setChangeLog(true);
  };
  const changeLogClose = () => {
    setChangeLog(false);
  };
  //Header for CSV
  let exportCSVheaders = [
    { label: "Datetime", key: "activity_time" },
    { label: "TS", key: "ts" },
    { label: "Activity", key: "activity_type" },
    { label: "Location", key: "location" },
    { label: "Remarks", key: "comments" },
    { label: "SO", key: "so" },
  ];

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getDailyActivityChecked([...checkedValue, value]));
    } else {
      dispatch(
        getDailyActivityChecked(checkedValue.filter((e) => e !== value))
      );
    }
  };

  return (
    <Toolbar>
      <div>
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "10px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            mt={3}
            id="tableTitle"
            style={{ width: "max-content", marginLeft: "10px" }}
          >
            {totalRecord} record found
          </Typography>
        )}
      </div>
      <Spacer />
      <div className="searchTool">
        <Box
          mt={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <Item sx={{ flexGrow: 1, width: "100%" }}>
            <TextField
              id="search"
              type="text"
              className={classes.searchFilter}
              autoComplete="off"
              placeholder="Search by keyword, or click down arrow"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  applySearch();
                  setIsFilter(true);
                  // handleFirstPage();
                  setCurrentPage(1);
                }
              }}
              inputProps={{}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-owns={anchorForm ? "search-form" : undefined}
                      aria-haspopup="true"
                      onClick={handleClickDropdown}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <StyledForm
                      id="search-form"
                      anchorEl={anchorForm}
                      open={Boolean(anchorForm)}
                      onClose={handleCloseForm}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem sx={{ maxHeight: "380px", overflowY: "auto" }}>
                        <form>
                          <ButtonGroup mt={3}>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                setStartDateToFilter(subDays(new Date(), 1));
                                setEndDateToFilter(subDays(new Date(), 1));
                              }}
                            >
                              Yesterday
                            </Button>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                setStartDateToFilter(subDays(new Date(), 1));
                                setEndDateToFilter(new Date());
                              }}
                            >
                              Last 2 days
                            </Button>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                setStartDateToFilter(subDays(new Date(), 2));
                                setEndDateToFilter(new Date());
                              }}
                            >
                              Last 3 days
                            </Button>
                          </ButtonGroup>
                          <FormControl mt={6}>
                            <TextField
                              fullWidth
                              id="dateRange"
                              label="Date range"
                              value={
                                startDateToFilter && endDateToFilter
                                  ? moment(startDateToFilter).format(
                                      "MM/DD/YYYY"
                                    ) +
                                    " - " +
                                    moment(endDateToFilter).format("MM/DD/YYYY")
                                  : ""
                              }
                              onClick={() => setFadedBox((prev) => !prev)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Select date range"
                            />
                            {fadebox && (
                              <CustomDateRange
                                handleShow={fadebox}
                                conformation={(status) =>
                                  setFadedBox((prev) => !prev)
                                }
                                setDates={(start, end) => {
                                  setStartDateToFilter(start);
                                  setEndDateToFilter(end);
                                }}
                                selectedStart={startDateToFilter}
                                selectedEnd={endDateToFilter}
                              />
                            )}
                          </FormControl>
                          <FormControl>
                            <TimePicker
                              label="Time from"
                              name="time_from"
                              ampm={false}
                              value={fromtime}
                              onChange={(newValue) => {
                                setFromTime(newValue);
                              }}
                              inputProps={{
                                placeholder: "Click to select",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Click to select"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl>
                            <TimePicker
                              label="Time to"
                              name="time_to"
                              ampm={false}
                              value={totime}
                              onChange={(newValue) => {
                                setToTime(newValue);
                              }}
                              inputProps={{
                                placeholder: "Click to select",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Click to select"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                          <WithPermissionHide
                            controller="Reports"
                            action="userList"
                          >
                            <FormControl mt={6}>
                              <InputLabel
                                shrink
                                id="officer"
                                sx={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  marginLeft: "-4px",
                                }}
                              >
                                Select officer
                              </InputLabel>
                              <Select
                                labelId="department"
                                id="officer"
                                value={officer}
                                label="Select officer"
                                onChange={selectOfficer}
                                fullWidth
                                displayEmpty
                              >
                                <MenuItem value="">Select officer</MenuItem>
                                {officerList.map((item) => {
                                  return (
                                    <MenuItem value={item.key} key={item.value}>
                                      {item.key}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </WithPermissionHide>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="activity"
                              label="Activity"
                              value={activityValue}
                              onChange={(e) => {
                                setActivityValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter one or more words"
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Task sentiment</FormLabel>
                            <RadioGroup
                              aria-labelledby="task-sentiment"
                              name="sentiment"
                              defaultValue="Positive"
                              value={sentimentValue}
                              onChange={(e) => {
                                setSentimentValue(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Positive"
                                value="1"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Warning"
                                value="2"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Caution"
                                value="3"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Danger"
                                value="4"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="remarks"
                              label="Remarks"
                              value={remarkValue}
                              onChange={(e) => {
                                setRemarkValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter one or more words"
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              fullWidth
                              id="location"
                              label="Location"
                              value={locationValue}
                              onChange={(e) => {
                                setLocationValue(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter one or more words"
                            />
                          </FormControl>
                          <FormControl>
                            {/* <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              mt={6}
                            >
                              Clear Filter
                            </Button> */}
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              mt={6}
                              sx={{ marginLeft: "5px" }}
                              onClick={(e) => {
                                applySearch({});
                                setIsFilter(true);
                                handleCloseForm();
                                setCurrentPage(1);
                              }}
                            >
                              Filter
                            </Button>
                          </FormControl>
                        </form>
                      </MenuItem>
                    </StyledForm>
                  </InputAdornment>
                ),
              }}
            />
          </Item>
          <Item>
            {isFilter ? (
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  resetFilter();
                  setDataToFilter(null);
                  setIsFilter(false);
                  setSearchInput("");
                }}
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
              >
                Clear
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  height: "100%",
                  marginLeft: "-3px",
                }}
                onClick={(e) => {
                  if (searchInput !== "") {
                    applySearch({});
                    setIsFilter(true);
                    // handleFirstPage();
                    setCurrentPage(1);
                  }
                }}
              >
                {/* <SearchIcon /> */}
                Search
              </Button>
            )}
          </Item>
        </Box>
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <WithPermissionHide controller="Reports" action="getShowChartData">
              <MenuItem onClick={showChartOpen}>
                <ListItemIcon>
                  <PieChartIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Show Chart" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem onClick={generatePdf}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Download PDF" pl={0} />
            </MenuItem>
            <CSVLink
              data={csvDataList}
              headers={exportCSVheaders}
              // onClick={handleClose}
              filename={`SOVA_dar_report-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <MenuItem>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Download CSV" pl={0} />
              </MenuItem>
            </CSVLink>
            <WithPermissionHide
              controller="Reports"
              action="sendMultipleUserMail"
            >
              <MenuItem onClick={sendDarOpen}>
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Send DAR" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <WithPermissionHide
              controller="Reports"
              action="getActivityChangeLog"
            >
              <MenuItem onClick={changeLogOpen}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Change Log" pl={0} />
              </MenuItem>
            </WithPermissionHide>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsInputComponentIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          {/* Send DAR */}
          {sendDar && (
            <SendDar
              sendDar={sendDar}
              setSendDar={setSendDar}
              sendDarClose={sendDarClose}
            />
          )}
          {/* Show Chart modal */}
          {showChart && (
            <ShowChart
              showChart={showChart}
              showChartOpen={showChartOpen}
              showChartClose={showChartClose}
              setShowChart={setShowChart}
            />
          )}
          {changeLog && (
            <ChangeLogDialog
              changeLog={changeLog}
              setChangeLog={setChangeLog}
              changeLogOpen={changeLogOpen}
              changeLogClose={changeLogClose}
            />
          )}
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  //States related to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const dispatch = useDispatch();
  const [reportList, setReportList] = useState("");
  const activityList = useSelector((state) => state.report.dailyActivityData);
  const [taskNameDialog, setTaskNameDialog] = useState("");
  const [activityNameDialog, setActivityNameDialog] = useState("");
  const [dataToFilter, setDataToFilter] = useState(null);
  const [userIdDialog, setUserIdDialog] = useState("");
  const [darDateTime, setDarDateTime] = useState("");
  const [darActivity, setDarActivity] = useState("");
  const [darLocation, setDarLocation] = useState("");
  const [darRemark, setDarRemark] = useState("");
  const [darId, setDarId] = useState("");
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const snackbarStatus = useSelector((state) => state.report.snackStatus);
  const snackbarMsg = useSelector((state) => state.report.snackMsg);
  const snackbarAlert = useSelector((state) => state.report.snackAlert);
  const LoggedInUserInfo = useSelector((state) => state.login.user);
  const [startDateToFilter, setStartDateToFilter] = useState(new Date());
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());
  const [clickedImg, setClickedImg] = useState(null); //State related to images lightbox
  const [currentIndex, setCurrentIndex] = useState(null); //State related to image lighbox
  const [csvDataList, setCsvDataList] = useState([]); //state for csvDataList
  const [imageCrop, setImageCrop] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);
  // Check for permission for the specific action
  const hasPermissionToGetActivityData = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "getActivityData" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  console.log("login", currentUser);
  console.log("id", currentUser.UserID);

  // Methods related to Image lightbox
  const handleClickImage = () => {
    let firstLink = reportList[0].image;
    setCurrentIndex(0);
    setClickedImg(firstLink);
  };

  //Handler for image lighbox right roatation
  const handelRotationRight = () => {
    const totalLength = imageData.data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = imageData.data[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = imageData.data.filter((item) => {
      return imageData.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  //Handler for image lighbox left roatation

  const handelRotationLeft = () => {
    const totalLength = imageData.data.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = imageData.data[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = imageData.data.filter((item) => {
      return imageData.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const checkedValue = useSelector(
    (state) => state.report.dailyActivityChecked
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = activityList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActionClick = (
    event,
    activitytime,
    activitytype,
    location,
    comments,
    userId,
    id
  ) => {
    setDarDateTime(activitytime);
    setDarActivity(activitytype);
    setDarLocation(location);
    setDarRemark(comments);
    // setDarUserId(userid);
    setUserIdDialog(userId);
    setDarId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  // Non Tour Task Modal
  const [nttcompleted, setNttCompleted] = React.useState(false);
  const nttCompletedOpen = (taskName, activityType) => {
    setTaskNameDialog(taskName);
    setActivityNameDialog(activityType);
    setNttCompleted(true);
  };
  const nttCompletedClose = () => {
    setNttCompleted(false);
  };
  // Recent Activities Modal
  const [recentactivities, setRecentActivities] = React.useState(false);
  const recentActivitiesOpen = () => {
    setRecentActivities(true);
  };
  const recentActivitiesClose = () => {
    setRecentActivities(false);
  };
  // Edit Activity Modal
  const [editCommentDialog, setEditCommentDialog] = React.useState(false);
  const editCommentDialogOpen = () => {
    setEditCommentDialog(true);
  };
  const editCommentDialogClose = () => {
    setEditCommentDialog(false);
  };
  // DAR Inquiry Modal
  const [darinquiry, setDarInquiry] = React.useState(false);
  const darInquiryOpen = () => {
    setDarInquiry(true);
  };
  const darInquiryClose = () => {
    setDarInquiry(false);
  };
  const [startdate, setStartDate] = React.useState(null);
  const [enddate, setEndDate] = React.useState(null);
  const classes = useStyles();

  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  //Method for generating PDF
  const generatePdf = () => {
    const doc = new jsPDF();

    const newDatePDF = moment(new Date()).format("MM/DD/YYYY");
    const newTimePDF = moment(new Date()).format("HH:mm:ss");
    var img = new Image();
    img.src = "/static/img/brands/logo.png";
    doc.addImage(img, "png", 10, 10, 45, 20);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(100, 45, "Daily Activity Report", "center");

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Date From : ", 15, 80);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${moment(startDateToFilter).format("MM/DD/YYYY")}`, 40, 80);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Date To : ", 15, 85);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`${moment(endDateToFilter).format("MM/DD/YYYY")}`, 40, 85);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Report By : `, 145, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(LoggedInUserInfo.alias_name, 165, 60);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text(`Generated : `, 145, 65);

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(`${newDatePDF} ${newTimePDF} hours`, 163, 65);

    const expPdfData = activityList.map((item) => {
      return [
        item.follow_up === "Yes" ? "\u2714" : "",
        // "", // <i class="fa fa-check" aria-hidden="true"></i>,
        item.activity_time,
        // "Dummy",
        item.activity_type,
        item.location,
        item.comments,
        item.so,
      ];
    });
    autoTable(doc, {
      // styles: { marginTop: "400px" },
      margin: { top: 90 },
      head: [["?", "Date Time", "Activity", "Location", "Remarks", "S/O"]],
      body: expPdfData,
      showFoot: "everyPage",
      // showHead: "firstPage",
      // Footer
      didDrawPage: function (data) {
        if (data.pageCount >= 1) {
          data.settings.margin.top = 10;
        }
        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 110, pageHeight - 3);
        doc.setLineWidth(0.1);
        doc.line(10, pageHeight - 8, 200, pageHeight - 8, "F");
      },
    });
    let fileName =
      "SOVA-DAR-" + moment(new Date()).format("YYYY-MM-DD") + ".pdf";
    doc.save(fileName);
  };

  // Method for setting csvDataList
  const getDailyActivityCSV = () => {
    let payload = {
      limit: totalRecord,
      sorting: {
        order: order,
        by: orderBy,
      },
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    }; //this will be used for sending filtered data
    if (totalRecord !== 0) {
      DailyActivityReport(1, payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setCsvDataList(res.data);
          } else {
            setCsvDataList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Method for calling the listing API
  const getDailyActivityList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
      sorting: {
        order: order,
        by: orderBy,
      },
      start_date: startDateToFilter
        ? moment(startDateToFilter).format("YYYY-MM-DD")
        : "",
      end_date: endDateToFilter
        ? moment(endDateToFilter).format("YYYY-MM-DD")
        : "",
      ...dataToFilter,
    }; //this will be used for sending filtered data
    DailyActivityReport(currentPage, payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(dailyActivityList(res.data));
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setReportList(res?.data);
        } else {
          dispatch(dailyActivityList(res.data));
          setTotalRecord(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(dailyActivityList([]));
        console.log(err);
        setTotalRecord(0);
      });
  };

  const handleUploadNewImage = async (file) => {
    console.log("file", file);
    console.log("id", selectedId);
    try {
      const formData = new FormData();
      formData.append("id", selectedId);
      formData.append("image", file);
      const response = await UploadNewImage(formData);
      handleCloseModal();
      getDailyActivityList();
      console.log("Image updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating the image:", error);
    }
  };
  const handleDeleteImage = async (file) => {
    console.log("file", file);
    console.log("id", selectedId);
    try {
      const formData = new FormData();
      formData.append("id", selectedId);
      formData.append("image", "");

      const response = await UploadNewImage(formData);
      getDailyActivityList();
      console.log("null", formData);
      console.log("Image updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating the image:", error);
    }
  };

  console.log("hy", reportList);

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    DailyActivityColumns()
      .then((res) => {
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    DailyActivityColumns()
      .then((res) => {
        dispatch(getDailyActivityChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelected([]);
  };

  useEffect(() => {
    getDailyActivityList();
    getColumnList();
  }, [rowsPerPage, currentPage, order, orderBy, dataToFilter]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
  }, []);
  const displayStartDate = moment(startDateToFilter).format("MM/DD/YYYY");
  const displayEndDate = moment(endDateToFilter).format("MM/DD/YYYY");

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const handleOpenModal = (imageUrl, id) => {
    setImageCrop(imageUrl); // Store the clicked image URL
    setSelectedId(id); // Store the selected row ID
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleCrop = (cropped) => {
    setCroppedImage(cropped);
    handleCloseModal();
  };
  console.log("image", imageCrop);

  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          dataToFilter={dataToFilter}
          setDataToFilter={setDataToFilter}
          filterRecord={(data) => filterRecord(data)}
          totalRecord={totalRecord}
          colArray={colArray}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
          generatePdf={generatePdf}
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          handleFirstPage={handleFirstPage}
          setCurrentPage={setCurrentPage}
          getDailyActivityCSV={getDailyActivityCSV}
          csvDataList={csvDataList}
        />
        <Typography mt={3} sx={{ padding: "0 12px" }}>
          From: {displayStartDate} To: {displayEndDate}
          {/* `From ${displayStartDate} to ${displayEndDate}` */}
        </Typography>
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  {/* <TableCell colSpan={8} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={activityList.length}
                  colArray={colArray}
                />
                <TableBody>
                  {
                    // stableSort(activityList, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    activityList.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {row.follow_up === "Yes" ? (
                            <TableCell padding="checkbox">
                              <Tooltip title="Requires followup" followCursor>
                                <CheckBoxOutlinedIcon
                                  sx={{ color: "red", cursor: "pointer" }}
                                />
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, row.id)}
                              inputProps={{ "aria-labelledby": labelId }}
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                            /> */}
                            </TableCell>
                          )}
                          {checkedValue.includes("activity_time") && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{ lg: 'whiteSpace: "nowrap"' }}
                            >
                              {row.activity_time}
                            </TableCell>
                          )}
                          {checkedValue.includes("sentimentIcon") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.ts === 0 ? null : row.ts === 1 ? (
                                <SentimentSatisfiedAltIcon
                                  sx={{ color: "green" }}
                                />
                              ) : row.ts === 2 ? (
                                <SentimentSatisfiedAltIcon
                                  sx={{ color: "yellow" }}
                                />
                              ) : row.ts === 3 ? (
                                <SentimentNeutralIcon
                                  sx={{ color: "orange" }}
                                />
                              ) : row.ts === 4 ? (
                                <SentimentVeryDissatisfiedIcon
                                  sx={{ color: "red" }}
                                />
                              ) : null}
                            </TableCell>
                          )}
                          {checkedValue.includes("activity_type") && (
                            <TableCell
                              align="left"
                              aria-disabled={true}
                              sx={{
                                cursor: !hasPermissionToGetActivityData
                                  ? "not-allowed"
                                  : "pointer",
                                color: !hasPermissionToGetActivityData
                                  ? "#B0B0B0"
                                  : "#0077B5",
                                pointerEvents: !hasPermissionToGetActivityData
                                  ? "none"
                                  : "auto",
                                whiteSpace: "nowrap",
                              }}
                              onClick={(e) => {
                                if (hasPermissionToGetActivityData) {
                                  nttCompletedOpen(
                                    row.task_name,
                                    row.activity_type
                                  );
                                }
                              }}
                            >
                              {row.activity_type}
                            </TableCell>
                          )}
                          {checkedValue.includes("custom_field_4") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.location}
                            </TableCell>
                          )}
                          {checkedValue.includes("img_url") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.image && (
                                <CameraAltIcon
                                  color="primary"
                                  key={index}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleOpenModal(row.image, row.id)
                                  }
                                />
                              )}
                            </TableCell>
                          )}
                          {checkedValue.includes("comments") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.comments}
                            </TableCell>
                          )}
                          {checkedValue.includes("userInitials") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.so}
                            </TableCell>
                          )}
                          {checkedValue.includes("display_name") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.task_name}
                            </TableCell>
                          )}
                          {checkedValue.includes("sentiment_icon") && (
                            <TableCell
                              align="left"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {row.sentiment_icon == 1
                                ? "Positive"
                                : row.sentiment_icon == 2
                                ? "Warning"
                                : row.sentiment_icon == 3
                                ? "Caution"
                                : row.sentiment_icon == 4
                                ? "Danger"
                                : null}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <IconButton
                              aria-label="Settings"
                              size="large"
                              aria-owns={anchorEl ? "icon-menu" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleActionClick(
                                  e,
                                  row.activity_time,
                                  row.activity_type,
                                  row.location,
                                  row.comments,
                                  row.user_id,
                                  row.id
                                );
                              }}
                              style={{ color: "#1374C5" }}
                              // onClick={() => {
                              //   setUserIdDialog(row.user_id);
                              //   handleActionClick();
                              // }}
                            >
                              <SettingsIcon />
                            </IconButton>
                            <StyledMenu
                              id="icon-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleActionClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <WithPermissionHide
                                controller="Reports"
                                action="sendMailDar"
                              >
                                <MenuItem
                                  onClick={() => {
                                    darInquiryOpen();
                                    handleActionClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <MailIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Email" pl={1} />
                                </MenuItem>
                              </WithPermissionHide>
                              <WithPermissionHide
                                controller="Reports"
                                action="getActivityByUser"
                              >
                                <MenuItem
                                  onClick={() => {
                                    recentActivitiesOpen();
                                    handleActionClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <ListAltIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Activity" pl={1} />
                                </MenuItem>
                              </WithPermissionHide>
                              {(currentUser.UserID === row.user_id ||
                                currentUser.user_group === "Admin" ||
                                currentUser.user_group ===
                                  "Property Admin") && (
                                <WithPermissionHide
                                  controller="Reports"
                                  action="editDailyActivity"
                                >
                                  <MenuItem
                                    onClick={() => {
                                      editCommentDialogOpen();
                                      handleActionClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <RateReviewIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Edit Comment"
                                      pl={1}
                                    />
                                  </MenuItem>
                                </WithPermissionHide>
                              )}
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                  {totalRecord === 0 && (
                    <TableRow style={{ height: (dense ? 6 : 0) * totalRecord }}>
                      <TableCell colSpan={10} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {/* Edit Activity Comment Dialog in Action-> Edit comment */}
      {editCommentDialog && (
        <EditActivityDialog
          editCommentDialog={editCommentDialog}
          setEditCommentDialog={setEditCommentDialog}
          editCommentDialogOpen={editCommentDialogOpen}
          editCommentDialogClose={editCommentDialogClose}
          darId={darId}
          darLocation={darLocation}
          darDateTime={darDateTime}
          darRemark={darRemark}
          getDailyActivityList={getDailyActivityList}
        />
      )}

      <ImageCropperModal
        open={isModalOpen}
        onClose={handleCloseModal}
        imageSrc={imageCrop}
        onCrop={handleCrop}
        onUpload={handleUploadNewImage}
        onDelete={handleDeleteImage}
      />

      {/* snackbar for Edit So comments */}
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
      {/* NTT completed code modal Dialog in Activity column in listing */}
      {nttcompleted && (
        <DailyActivityD1
          nttcompleted={nttcompleted}
          setNttCompleted={setNttCompleted}
          nttCompletedClose={nttCompletedClose}
          taskNameDialog={taskNameDialog}
          activityNameDialog={activityNameDialog}
        />
      )}
      {/* Recent activities Dialog in Action-> Activity*/}
      {recentactivities && (
        <RecentActivityDialog
          recentactivities={recentactivities}
          setRecentActivities={setRecentActivities}
          recentActivitiesClose={recentActivitiesClose}
          userIdDialog={userIdDialog}
        />
      )}

      {/* DAR Inquiry */}
      {darinquiry && (
        <DARInquiryDialog
          darinquiry={darinquiry}
          setDarInquiry={setDarInquiry}
          darInquiryClose={darInquiryClose}
          darDateTime={darDateTime}
          darActivity={darActivity}
          darLocation={darLocation}
          darRemark={darRemark}
          userIdDialog={userIdDialog}
          // darUserId={darUserId}
        />
      )}
      {/* Component for Image Lighbox */}
      {clickedImg && (
        <ImageListingModal
          clickedImg={clickedImg}
          handelRotationRight={handelRotationRight}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
        />
      )}
    </div>
  );
}

function DailyActivity() {
  return (
    <React.Fragment>
      <WithPermissionFallback
        controller="Reports"
        action="dailyActivityReports"
      >
        <Helmet title="Daily Activity" />
        <Typography variant="h3" gutterBottom display="inline">
          Daily Activity
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default DailyActivity;
