import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

import {
  Typography,
  Select,
  Grid,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
  Card,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Switch,
} from "@mui/material";
import InputStatus from "./inner-component/InputStatus";
import { CustomInputs } from "../../../api";
import CustomPagination from "../../../components/table-components/CustomPagination";
import WithPermissionFallback from "../../../utils/withPermissionFallback";

const CustomizeReport = () => {
  const FormControlSpacing = styled(MuiFormControl)(spacing);
  const FormControl = styled(FormControlSpacing)`
    width: 100%;
  `;
  const [module, setModule] = useState("lost_badge_report");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [customizeList, setCustomizeList] = useState([]);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
  };
  /**
   * When the user changes the number of rows per page, the current page is reset to 1
   * @param e - the event object
   */
  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleChange = (event) => {
    setModule(event.target.value);
  };
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  const customInputs = () => {
    let payload = {
      name: module,
    };
    setLoading(true);
    CustomInputs(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setCustomizeList(res.data);
          setTotalRecord(res.meta.total);
          setCurrentPage(res.meta.current_page);
          setLoading(false);
        } else {
          setCustomizeList(res.data);
          setLoading(false);
          setTotalRecord(0);
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    customInputs();
  }, [rowsPerPage, currentPage, module]);
  return (
    <>
      <Helmet title="Customize Reports" />
      <WithPermissionFallback controller="Settings" action="customInputs">
        <Grid container spacing={4}>
          <Grid item lg={4} md={4}>
            <Typography variant="h3" gutterBottom mt={4}>
              Input Visiblity
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} pl={5}>
            <FormControl
              sx={{
                maxWidth: "450px",
              }}
            >
              <Select
                labelId="department"
                id="demo-select-small"
                // label="Lost Key/Badge Report"
                value={module}
                onChange={handleChange}
                placeholder="Select template(optional)"
              >
                <MenuItem value="lost_badge_report">
                  Lost Key/Badge Report
                </MenuItem>
                <MenuItem value="shift_report">Shift Report</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="body2" gutterBottom mt={5}>
          Below is a list of sections per the report selected from the dropdown
          above. Based on your needs, you can show or hide sections by changing
          the toggle setting in a particular row. After saving, load the form
          (for example, in a separate browser tab) and check to make sure you
          have the fields you need. You can always return to this page and make
          further adjustments.
        </Typography>
        <Card
          sx={{
            marginTop: "20px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Label</TableCell>
                <TableCell align="right">Show in form</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customizeList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell align="right">
                    <InputStatus value={row.status_id} id={row.id} />
                  </TableCell>
                </TableRow>
              ))}
              {customizeList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
        <CustomPagination
          handleChangeLimit={handleChangeLimit}
          handleChangePagination={handleChangePagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRecord={totalRecord}
        />
      </WithPermissionFallback>
    </>
  );
};

export default CustomizeReport;
