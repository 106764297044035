import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import KioskModeSubmitPassword from "./KioskModeSubmitPassword";
import { KioskModeData } from "../../api";
import {
  Paper as MuiPaper,
  Card,
  Grid,
  Typography,
  CardHeader,
  CardContent,
  Button,
  Box,
  AppBar,
} from "@mui/material";
import {
  Person as PersonIcon,
  Logout as LogoutIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";
const Paper = styled(MuiPaper)(spacing);
const Image = styled.img`
  max-width: initial;
  height: 80px;
  opacity: 0.7;
`;
const useStyles = makeStyles((theme) => ({
  boxes: {
    marginBottom: "20px",
    paddingBottom: "20px",
    textAlign: "center",
    "& .MuiButtonBase-root": {
      fontSize: "40px",
      padding: "5px 50px",
      borderRadius: "50px",
      marginTop: "15px",
      boxShadow: "-2px 5px 16px 4px #00000030",
      "& .MuiSvgIcon-root": {
        fontSize: "40px",
        marginRight: "12px",
      },
    },
  },
  mainTitle: {
    fontSize: "100px",
    opacity: 0.4,
  },
  subTitle: {
    opacity: 0.4,
  },
  launchScreen: {
    width: "100%",
    fontSize: "22px",
    borderRadius: "40px",
    fontWeight: "bold",
  },
}));
function KioskMode() {
  const classes = useStyles();
  const location = useLocation();
  // Kiosk mode submit password modal
  const [kioskModeSubmitPasswordModal, setKioskModeSubmitPasswordModal] =
    useState(false);
  const kioskModeSubmitPasswordModalClose = () => {
    setKioskModeSubmitPasswordModal(false);
  };
  const [fullScreenBtn, setFullScreenBtn] = useState(true);
  const [kioskSettingData, setKioskSettingData] = useState({});
  const [locationData, setLocationData] = useState({});

  const launchFullScreen = () => {
    if (!document.fullscreenElement) {
      setFullScreenBtn(false);
      localStorage.setItem("fullscreen", "true");
    } else {
      setFullScreenBtn(false);
    }
    document.documentElement.requestFullscreen();
  };

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setKioskModeSubmitPasswordModal(true);
    }
  }

  const updateParentState = () => {
    kioskModeSubmitPasswordModalClose();
    setFullScreenBtn(true);
  };

  const getKioskModeData = () => {
    KioskModeData()
      .then((response) => {
        if (response.statusCode == 200) {
          setKioskSettingData(response.data.kioskSetting);
          setLocationData(response.data.locationData);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((error) => {
        console.log("something went wrong");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("fullscreen") === "true") {
      launchFullScreen();
    }
  }, []);
  useEffect(() => {
    getKioskModeData();
  }, []);

  return (
    <>
      <WithPermissionFallback controller="Visitor" action="kioskMode">
        {fullScreenBtn === true && (
          <AppBar component="header" sx={{ backgroundColor: "transparent" }}>
            <Card>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      "& .MuiButton-containedPrimary:hover": {
                        opacity: 0.7,
                        backgroundColor:
                          kioskSettingData?.custom_button_text_color
                            ? kioskSettingData?.custom_button_text_color
                            : "#000000",
                      },
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor:
                          kioskSettingData?.custom_button_text_color
                            ? kioskSettingData?.custom_button_text_color
                            : "#000000",
                      }}
                      variant="contained"
                      className={classes.launchScreen}
                      onClick={launchFullScreen}
                    >
                      Launch full-screen for tablet use
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.launchScreen}
                      component={NavLink}
                      to="/visitors/kiosk-settings"
                    >
                      Go to kiosk settings
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </AppBar>
        )}
        <Box sx={{ px: 5, mt: 35 }}>
          <Paper>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box sx={{ px: 4 }}>
                  <Image src={locationData.logo_image} />
                  <Typography
                    className={classes.mainTitle}
                    variant="h1"
                    component="div"
                  >
                    Hello,
                  </Typography>
                  <Typography
                    className={classes.subTitle}
                    variant="h3"
                    component="div"
                  >
                    Please check in here
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  className={classes.boxes}
                  sx={{
                    "& .MuiButton-containedPrimary:hover": {
                      opacity: 0.7,
                      backgroundColor:
                        kioskSettingData?.custom_button_text_color
                          ? kioskSettingData?.custom_button_text_color
                          : "#000000",
                    },
                  }}
                >
                  <Typography variant="h2" component="div" gutterBottom>
                    Visitors
                  </Typography>
                  <Typography>
                    New and returning visitors check in here
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor:
                        kioskSettingData?.custom_button_text_color
                          ? kioskSettingData?.custom_button_text_color
                          : "success",
                    }}
                    variant="contained"
                    component={NavLink}
                    to="/visitors/check-in"
                    state={{
                      buttonColor: kioskSettingData?.custom_button_text_color,
                    }}
                  >
                    <PersonIcon /> Check In
                  </Button>
                </Box>
                <Box
                  className={classes.boxes}
                  sx={{
                    "& .MuiButton-containedPrimary:hover": {
                      opacity: 0.7,
                      backgroundColor:
                        kioskSettingData?.custom_button_text_color
                          ? kioskSettingData?.custom_button_text_color
                          : "#000000",
                    },
                  }}
                >
                  <Typography>
                    Are you leaving the building? Please sign out
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor:
                        kioskSettingData?.custom_button_text_color
                          ? kioskSettingData?.custom_button_text_color
                          : "#000000",
                    }}
                    variant="contained"
                    component={NavLink}
                    to="/visitors/returning-visitor-info"
                    state={{ searchParam: "SignOut" }}
                  >
                    <LogoutIcon /> Sign Out
                  </Button>
                </Box>
                <WithPermissionHide
                  controller="Visitor"
                  action="savePackageDetail"
                >
                  <Box
                    className={classes.boxes}
                    sx={{
                      "& .MuiButton-containedPrimary:hover": {
                        opacity: 0.7,
                        backgroundColor:
                          kioskSettingData?.custom_button_text_color
                            ? kioskSettingData?.custom_button_text_color
                            : "#000000",
                      },
                    }}
                  >
                    <Typography variant="h2" component="div" gutterBottom>
                      Packages
                    </Typography>
                    <Typography>Do you have a parcel or delivery</Typography>
                    <Button
                      sx={{
                        backgroundColor:
                          kioskSettingData?.custom_button_text_color
                            ? kioskSettingData?.custom_button_text_color
                            : "#000000",
                      }}
                      variant="contained"
                      component={NavLink}
                      to="/visitors/packages-delivery"
                    >
                      <LocalShippingIcon /> Delivery
                    </Button>
                  </Box>
                </WithPermissionHide>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {kioskModeSubmitPasswordModal && (
          <KioskModeSubmitPassword
            kioskModeSubmitPasswordModal={kioskModeSubmitPasswordModal}
            setKioskModeSubmitPasswordModal={setKioskModeSubmitPasswordModal}
            kioskModeSubmitPasswordModalClose={
              kioskModeSubmitPasswordModalClose
            }
            launchFullScreen={launchFullScreen}
            onDataFromChild={updateParentState}
          />
        )}
      </WithPermissionFallback>
    </>
  );
}

export default KioskMode;
