import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import CustomTextField from "../../components/form-components/CustomTextField";
import CustomSelect from "../../components/form-components/CustomSelect";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ViewPoiEncounter as ViewEncounter } from "../../api";
import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Card,
  CardContent,
  FormLabel,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  LocalPrintshop as LocalPrintshopIcon,
  FullscreenExit as FullscreenExitIcon,
  Code as CodeIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import PoiEncountorLocation from "./inner-component/PoiEncountorLocation";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
// const Avatar = styled(MuiAvatar)`
//   width: 90px;
//   height: 90px;
//   object-fit: cover;
// `;
// const BigAvatar = styled(Avatar)`
//   width: 180px;
//   height: 180px;
//   object-fit: cover;
//   margin: 30px auto 0;
// `;

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
`;

const teethSelect = [
  { title: "None selected" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
  { title: "The Dark Knight" },
  { title: "12 Angry Men" },
  { title: "Schindler's List" },
  { title: "Pulp Fiction" },
];
const otherFacial = [
  { title: "None selected" },
  { title: "The Godfather" },
  { title: "The Godfather: Part II" },
  { title: "The Dark Knight" },
  { title: "12 Angry Men" },
  { title: "Schindler's List" },
  { title: "Pulp Fiction" },
];

function AddPersonOfInterestForm() {
  //const classes = useStyles();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  const [narrativeData, setNarrativeData] = useState("");
  const [narrativebtn, setNarrativeBtn] = React.useState("undobtn");
  const handleNarrativeBtn = (event, newNarrativeBtn) => {
    setNarrativeBtn(newNarrativeBtn);
  };

  const [locationDialog, setLocationDialog] = useState(false);
  const locationDialogOpen = () => {
    setLocationDialog(true);
  };
  const locationDialogClose = () => {
    setLocationDialog(false);
  };

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [encounterData, setEncounterData] = useState({});
  const viewEncounter = () => {
    setLoader(true);
    ViewEncounter(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setEncounterData(res.data);
          setNarrativeData(res.data.narrative);
          setCoordinates({
            lat: parseFloat(res.data.lat),
            lng: parseFloat(res.data.long),
          });
          setLoader(false);
        } else {
          setEncounterData({});
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const locationEncounter = [
    { key: "Outdoors", value: "Outdoors" },
    { key: "Indoors", value: "Indoors" },
    { key: "Stairwell", value: "Stairwell" },
    { key: "Rooptop", value: "Rooptop" },
    { key: "Landscaping", value: "Landscaping" },
    { key: "Other", value: "Other" },
  ];

  const alertLevel = [
    { key: "Lucid", value: "Lucid" },
    { key: "Not lucid", value: "Not lucid" },
    { key: "Other", value: "Other" },
  ];

  const resolutionData = [
    {
      key: "Left property without escort",
      value: "Left property without escort",
    },
    { key: "Left property with escort", value: "Left property with escort" },
    { key: "Taken by police", value: "Taken by police" },
    { key: "Citation in lieu of arrest", value: "Citation in lieu of arrest" },
    {
      key: "Documented, no action taken",
      value: "Documented, no action taken",
    },
    {
      key: "Taken by ambulance",
      value: "Taken by ambulance",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  const {
    first_name,
    last_name,
    alias,
    property,
    poi_location,
    map_location,
    alert_level,
    behavioral_factors,
    police_contacted,
    trespass_issued,
    trespass,
    trespass_type,
    resolution,
    narrative,
    image,
    encounter_date,
    other_enconters,
  } = encounterData;

  let initialValues = {
    first_name: first_name ? first_name : "",
    last_name: last_name ? last_name : "",
    alias: alias ? alias : "",
    property: property ? property : "",
    // reporting_unit: poi_location ? poi_location : "",
    location_encounter: poi_location ? poi_location : "",
    resolution: resolution ? resolution : "",
    alert_level: alert_level ? alert_level : "",
    on_site: police_contacted ? police_contacted : "",
  };

  useEffect(() => {
    viewEncounter();
  }, [id]);

  return (
    <>
      <Card>
        <CardContent>
          <Formik initialValues={initialValues} enableReinitialize={true}>
            <form>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <Typography variant="h6" component="div" gutterBottom>
                    View other encounters by this POI (click to open)
                  </Typography>
                  {other_enconters?.map((encounter) => {
                    return (
                      <Typography
                        key={encounter?.id}
                        component={NavLink}
                        to={`/poi/view-poi-encounter/${encounter?.id}`}
                        sx={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                          marginRight: "5px",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {encounter?.enc_date},
                      </Typography>
                    );
                  })}
                  <Typography>
                    <strong>Encounter Date: </strong>
                    {encounter_date}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl mt={3}>
                    <CustomTextField
                      name="first_name"
                      label="First name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="Sarah"
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="last_name"
                      label="Last name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="Sanayei"
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="alias"
                      label="Alias"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="none given"
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="property"
                      label="Group"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder="Marigold hotel"
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomTextField
                      name="reporting_unit"
                      label="Reporting unit"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoComplete="off"
                      placeholder=""
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomSelect
                      name="location_encounter"
                      fieldLabel="Location of encounter"
                      options={locationEncounter}
                      displayEmpty
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={map_location == 1 ? true : false}
                          />
                        }
                        label="Add POI encounter location on a map"
                      />
                      <Button
                        sx={{ marginLeft: "5px" }}
                        color="primary"
                        variant="contained"
                        onClick={locationDialogOpen}
                      >
                        View map
                      </Button>
                    </FormGroup>
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomSelect
                      name="alert_level"
                      fieldLabel="Alert level"
                      options={alertLevel}
                      displayEmpty
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <Typography>
                      <strong>Behavioural factors</strong>
                    </Typography>
                    <Typography>{behavioral_factors}</Typography>
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Police On-Site</FormLabel>
                    <RadioGroup
                      aria-labelledby="police-on-site"
                      // defaultValue="yes"
                      name="on_site"
                      row
                      value={police_contacted ? police_contacted : ""}
                    >
                      <FormControlLabel
                        control={<Radio disabled />}
                        label="Yes"
                        value="Yes"
                      />
                      <FormControlLabel
                        control={<Radio disabled />}
                        label="No"
                        value="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Trespass warning issued</FormLabel>
                    <RadioGroup
                      aria-labelledby="trespass"
                      // defaultValue="yes"
                      name="trespass"
                      row
                      value={trespass_issued ? trespass_issued : ""}
                    >
                      <FormControlLabel
                        control={<Radio disabled />}
                        label="Yes"
                        value="Yes"
                      />
                      <FormControlLabel
                        control={<Radio disabled />}
                        label="No"
                        value="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl mt={6}>
                    <CustomSelect
                      name="resolution"
                      fieldLabel="Resolution"
                      options={resolutionData}
                      displayEmpty
                      inputProps={{ readOnly: true }}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Narrative</FormLabel>
                    <QuillWrapper>
                      <ReactQuill
                        theme="snow"
                        value={narrativeData}
                        onChange={setNarrativeData}
                        placeholder="Type something.."
                        readOnly={true}
                      />
                    </QuillWrapper>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card
                    variant="outlined"
                    sx={{ width: "350px", maxHeight: "400px" }}
                  >
                    <Image
                      src={image}
                      sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </form>
          </Formik>
        </CardContent>
      </Card>
      {locationDialog && (
        <PoiEncountorLocation
          locationDialog={locationDialog}
          setLocationDialog={setLocationDialog}
          locationDialogOpen={locationDialogOpen}
          locationDialogClose={locationDialogClose}
          // getCoordinates={getCoordinates}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      )}
      {loader && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}
function ViewPoiEncounter() {
  const { id } = useParams();
  return (
    <React.Fragment>
      <Helmet title="View POI Encounter" />
      <WithPermissionFallback controller="Poi" action="viewEncounter">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" gutterBottom display="inline">
              View POI Encounter
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/poi/poi-encounters">
                POI Encounters
              </Link>
              <Typography>View POI Encounter</Typography>
            </Breadcrumbs>
          </div>
          <Button
            component={NavLink}
            to={`/poi/edit-poi-encounter/${id}`}
            variant="contained"
            color="primary"
            mt={6}
          >
            Edit
          </Button>
        </div>

        <Divider my={6} />
        <AddPersonOfInterestForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}
export default ViewPoiEncounter;
