import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { PoiImageSearch as PoiImage } from "../../api";
// import CancelIcon from "@mui/icons-material/Cancel";
import {
  Grid,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Box,
  Button,
  Tooltip,
  Card,
  CardHeader as MuiCardHeader,
  CardContent,
  TextField,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  FormLabel,
  IconButton,
  TablePagination,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import {
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import CustomSelect from "../../components/form-components/CustomSelect";
import ImageSearchDialog from "./inner-component/ImageSearchDialog";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
//Classes Styling
const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: "1px solid",
    padding: "15px",
    borderColor:
      theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
  },
  DescriptionText: {
    overflow: "hidden",
    lineClamp: 3,
    WebkitLineClamp: 3,
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
  },
  hoveredOverlay: {
    position: "relative",
    height: "100%",
    "& .MuiBox-root:hover .MuiPaper-root": {
      display: "block",
      zIndex: 1,
    },
    "& .MuiBox-root": {
      height: "100%",
    },
    "& .MuiPaper-root": {
      height: "100%",
    },
    "& p": {
      fontSize: "12px",
    },
  },
  overlayCard: {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    boxShadow: "0px 0px 10px 3px #0000001a",
    borderRadius: "3px",
    height: "100%;",
  },
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          maxWidth: "235px",
          padding: 0,
          backgroundColor: "#fff",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const Image = styled.img`
  max-width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  position: relative;
  z-index: 0;
  object-fit: cover;
`;
const ImageWrapper = styled.div`
  height: 100%;
  &:before {
  }
`;
const CardHeader = styled(MuiCardHeader)`
  padding-bottom: 0;
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function PoiImageArea(props) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState("modified");
  const threatLevelData = [
    { value: "1,2,3", key: "1-3" },
    { value: "4,5,6", key: "4-6" },
    { value: "7,8,9,10", key: "7-10" },
  ];
  const genderData = [
    { value: "M", key: "Male" },
    { value: "F", key: "Female" },
    { value: "U", key: "Undetermined" },
  ];
  const raceData = [
    { value: "white", key: "White" },
    { value: "black", key: "Black" },
    { value: "asian", key: "Asian" },
    { value: "hispanic", key: "Hispanic" },
    { value: "native_american", key: "Native American" },
    { value: "pacific_islander", key: "Pacific Islander" },
    { value: "unkown", key: "Unkown" },
  ];
  const ageData = [
    { value: "18-25", key: "18-25" },
    { value: "26-30", key: "26-30" },
    { value: "31-35", key: "31-35" },
    { value: "36-40", key: "36-40" },
    { value: "41-45", key: "41-45" },
    { value: "46-50", key: "46-50" },
    { value: "51-60", key: "51-60" },
    { vlaue: "Over 60", key: "Over 60" },
  ];
  const hairColorData = [
    { value: "Blonde", key: "Blonde" },
    { value: "Brown", key: "Brown" },
    { value: "Red", key: "Red" },
    { value: "Grey", key: "Grey" },
    { value: "Black", key: "Black" },
    { value: "Other", key: "Other" },
  ];
  const tatoosData = [
    { value: "None", key: "None" },
    { value: "Any", key: "Any" },
  ];
  const glassesData = [
    { value: "None", key: "None" },
    { value: "Any", key: "Any" },
  ];

  const [filterData, setFilterData] = useState({
    gender: "",
    race: "",
    age: "",
    hair_color: "",
    tatoos: "",
    glasses: "",
    threat_level: "",
  });
  const [poiImageList, setPoiImageList] = useState([]);
  const poiImage = () => {
    const payload = {
      ...filterData,
      sorting: { order: "desc", by: orderBy },
    };
    PoiImage(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          setPoiImageList(res.data);
        } else {
          setPoiImageList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    poiImage();
  }, [filterData, orderBy]);
  const initialValues = {
    gender: "",
    race: "",
    age: "",
    hair_color: "",
    tatoos: "",
    glasses: "",
    threat_level: "",
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setFilterData(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const [id, setId] = useState("");
  const [imageSearchDialog, setImageSearchDialog] = useState(false);
  const imageSearchDialogOpen = (id) => {
    setImageSearchDialog(true);
    setId(id);
  };
  const imageSearchDialogClose = () => {
    setImageSearchDialog(false);
  };
  return (
    <div>
      <Box sx={{ margin: "10px 0" }}>
        <Grid item xs={12} lg={12}>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <FormLabel sx={{ fontWeight: "bold" }}>Sort By:</FormLabel>
            <RadioGroup
              aria-labelledby="Sort By"
              name="order_by"
              row
              value={orderBy}
              onChange={(e) => {
                setOrderBy(e.target.value);
              }}
            >
              <FormControlLabel
                control={<Radio />}
                label="Date (Newest First)"
                value="modified"
              />
              <FormControlLabel
                control={<Radio />}
                label="Threat Level (High to Low)"
                value="threat_level"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={6}>
              {poiImageList.map((data) => {
                return (
                  <Grid item xs={12} lg={4} xl={4}>
                    <Paper
                      className={classes.hoveredOverlay}
                      sx={{ minHeight: "200px" }}
                    >
                      <Box>
                        <Card className={classes.overlayCard}>
                          <CardHeader
                            title={`POI added by: ${data.LocationName}`}
                            action={
                              <IconButton
                                aria-label="settings"
                                color="primary"
                                onClick={() => imageSearchDialogOpen(data.id)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            }
                          />
                          <CardContent>
                            <Typography>
                              <strong>Date Added:</strong>
                              {data.created}
                            </Typography>
                            <Typography>
                              <strong>Threat Level:</strong> {data.threat_level}
                            </Typography>
                            <Typography>
                              <strong>Last encounter:</strong>{" "}
                              {data.last_poi_encountr}
                            </Typography>
                          </CardContent>
                        </Card>
                        <ImageWrapper className={classes.ImageWrapper}>
                          {data.image ? (
                            <Image
                              alt="SaaS Dashboard | SOVA"
                              src={data.image}
                            />
                          ) : (
                            <Image
                              alt="Placeholder Image"
                              src={"path_of_placeholder_image"}
                            />
                          )}
                        </ImageWrapper>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values,
                status,
              }) => (
                <Card>
                  <CardHeader title="Current Filter" />
                  <Box>
                    <Button
                      onClick={() =>
                        setFilterData({
                          gender: "",
                          race: "",
                          age: "",
                          hair_color: "",
                          tatoos: "",
                          glasses: "",
                          threat_level: "",
                        })
                      }
                    >
                      Clear All
                    </Button>
                    {filterData.threat_level != "" && (
                      <Box>
                        Threat Level:{values.threat_level}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              threat_level: "",
                            }));
                            values.threat_level = "";
                          }}
                        />
                      </Box>
                    )}
                    {filterData.gender != "" && (
                      <Box>
                        Gender:{values.gender}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              gender: "",
                            }));
                            values.gender = "";
                          }}
                        />
                      </Box>
                    )}

                    {filterData.race != "" && (
                      <Box>
                        Race:{values.race}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              race: "",
                            }));
                            values.rece = "";
                          }}
                        />
                      </Box>
                    )}

                    {filterData.age != "" && (
                      <Box>
                        Age:{values.age}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              age: "",
                            }));
                            values.age = "";
                          }}
                        />
                      </Box>
                    )}

                    {filterData.hair_color != "" && (
                      <Box>
                        Hair Color:{values.hair_color}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              hair_color: "",
                            }));
                            values.hair_color = "";
                          }}
                        />
                      </Box>
                    )}

                    {filterData.tatoos != "" && (
                      <Box>
                        Tatoos:{values.tatoos}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              tatoos: "",
                            }));
                            values.tatoos = "";
                          }}
                        />
                      </Box>
                    )}

                    {filterData.glasses != "" && (
                      <Box>
                        Glasses:{values.glasses}{" "}
                        <CancelIcon
                          onClick={() => {
                            setFilterData((prevState) => ({
                              ...prevState,
                              glasses: "",
                            }));
                            values.glasses = "";
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <CardContent>
                    <Box mt={6}>
                      <Typography>Additional Filters</Typography>
                      <form onSubmit={handleSubmit}>
                        {filterData.threat_level == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="threat_level"
                              fieldLabel="Threat level"
                              options={threatLevelData}
                              displayEmpty
                            />
                          </FormControl>
                        )}

                        {filterData.gender == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="gender"
                              fieldLabel="Gender"
                              options={genderData}
                              displayEmpty
                            />
                          </FormControl>
                        )}
                        {filterData.race == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="race"
                              fieldLabel="Race"
                              options={raceData}
                              displayEmpty
                            />
                          </FormControl>
                        )}

                        {filterData.age == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="age"
                              fieldLabel="Age"
                              options={ageData}
                              displayEmpty
                            />
                          </FormControl>
                        )}

                        {filterData.hair_color == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="hair_color"
                              fieldLabel="Hair color"
                              options={hairColorData}
                              displayEmpty
                            />
                          </FormControl>
                        )}
                        {filterData.tatoos == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="tatoos"
                              fieldLabel="Tatoos"
                              options={tatoosData}
                              displayEmpty
                            />
                          </FormControl>
                        )}
                        {filterData.glasses == "" && (
                          <FormControl mt={6}>
                            <CustomSelect
                              name="glasses"
                              fieldLabel="Glasses"
                              options={glassesData}
                              displayEmpty
                            />
                          </FormControl>
                        )}

                        <FormControl mt={4}>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Add filter
                          </Button>
                        </FormControl>
                      </form>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      {imageSearchDialog && (
        <ImageSearchDialog
          imageSearchDialog={imageSearchDialog}
          imageSearchDialogClose={imageSearchDialogClose}
          id={id}
        />
      )}
    </div>
  );
}
function PoiImageSearch() {
  return (
    <React.Fragment>
      <Helmet title="POI Image Search" />
      <WithPermissionFallback controller="Poi" action="pioImageSearch">
        <Typography variant="h3" gutterBottom display="inline">
          POI Image Search
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <PoiImageArea />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default PoiImageSearch;
