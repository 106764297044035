import {
  Grid,
  Box,
  Button,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Map from "./inner-components/Map";
import { LiveDispatchList } from "../../api";
import UserActivityList from "./inner-components/UserActivityList";
import MapHeader from "./inner-components/MapHeader";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { OverlayView, InfoWindow } from "@react-google-maps/api";
import styled from "styled-components/macro";
import { useAbly } from "ably/react";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const LiveModule = () => {
  const ably = useAbly();
  const locationId =
    JSON.parse(localStorage.getItem("location") || "{}")?.LocationID || null;
  const [locationIds, setLocationIds] = useState([locationId]);
  const [loading, setLoading] = useState(false);
  const [liveDispatchData, setLiveDispatchData] = useState({});
  const [activityPayload, setActivityPayload] = useState({
    page: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const [siteLocation, setSiteLocation] = useState({});
  const [mapCenter, setMapCenter] = useState({
    lat: 37.784279,
    lng: -122.407234,
  });

  const transformActivityData = (activity) => {
    return {
      LocationName: activity.LocationName || "Unknown Location",
      scanTime: activity.scanTime || activity.activityTime || "Unknown Time",
      lattitude: activity.latitude || "0.0000", // Transform latitude
      longitude: activity.longitude || "0.0000", // Transform longitude
      buttonName: activity.buttonName || "Unknown Button",
      userId: activity.userId || "",
      activity_type: activity.activity_type || "Unknown Activity",
      user_name: activity.user_name || "Unknown User",
      user_id: activity.user_id || "",
      comments: activity.comments || "No comments",
      task_location: activity.task_location || "",
      tbl_id: activity.tbl_id || "",
      IsLoggedIn: activity.IsLoggedIn || "N", // Default to "N"
      status: activity.status || "I", // Default to "I"
      floor_plans_id: activity.floor_plans_id || "0", // Default to "0"
      profile_image:
        activity.profile_image || "https://via.placeholder.com/150", // Fallback profile image
    };
  };

  const onMessage = (msg) => {
    const data = msg?.data;

    console.log("Received message: ", data);

    setLiveDispatchData((prevData) => {
      // Extract the incoming activity data
      const newActivity = data?.activity;

      if (!newActivity) {
        console.warn("No activity data found in message.");
        return prevData;
      }

      // Transform the incoming activity data
      const transformedActivity = transformActivityData(newActivity);

      // Get the existing map_data_array
      const existingMapDataArray = prevData.map_data_array || [];

      // Check if the user already exists in the array
      const existingIndex = existingMapDataArray.findIndex(
        (item) => item.user_id === transformedActivity.user_id
      );

      let updatedMapDataArray;
      if (existingIndex !== -1) {
        // If user exists, update the object
        updatedMapDataArray = [...existingMapDataArray];
        updatedMapDataArray[existingIndex] = {
          ...updatedMapDataArray[existingIndex],
          ...transformedActivity, // Update with transformed data
        };
      } else {
        // If user does not exist, insert the new object
        updatedMapDataArray = [transformedActivity, ...existingMapDataArray];
      }

      // Return the updated state
      return {
        ...prevData,
        activeUser: data.activeUser || prevData.activeUser,
        inactiveUser: data.inactiveUser || prevData.inactiveUser,
        map_data: [newActivity, ...(prevData.map_data || [])],
        map_data_array: updatedMapDataArray, // Updated map_data_array
      };
    });
  };

  useEffect(() => {
    // Store references to all the channels
    const channels = locationIds.map((id) => {
      const channelName = `activitychannel_${id}`;
      return ably.channels.get(channelName); // Get the channel instance
    });

    // Subscribe to all channels
    channels.forEach((channel) => {
      channel.subscribe(onMessage);
    });

    // Cleanup on unmount
    return () => {
      channels.forEach((channel) => {
        // Unsubscribe from the channel
        channel.unsubscribe(onMessage);

        // Detach the channel before releasing it
        channel.detach((err) => {
          if (err) {
            console.error(`Failed to detach channel ${channel.name}:`, err);
          } else {
            ably.channels.release(channel.name); // Release the channel
          }
        });
      });
    };
  }, [ably, locationIds]);

  const getActivityList = async (payload) => {
    try {
      setLoading(true);
      const res = await LiveDispatchList(payload);

      if (res.statusCode === 404) {
        // No more data available
        setHasMore(false);
        return;
      }

      // Merge the new data into the existing `liveDispatchData`
      setLiveDispatchData((prevData) => ({
        ...prevData,
        activeUser: res.activeUser || prevData.activeUser,
        inactiveUser: res.inactiveUser || prevData.inactiveUser,
        map_data: [...(prevData.map_data || []), ...(res.map_data || [])],
        map_data_array: prevData.map_data_array?.length
          ? prevData.map_data_array
          : [...(res.map_data_array || [])],
        property_lat_lon_array:
          res.property_lat_lon_array || prevData.property_lat_lon_array,
      }));

      setHasMore((res.map_data || []).length > 0);

      if (res.property_lat_lon_array) {
        setMapCenter({
          lat: res.property_lat_lon_array.property_default_lat,
          lng: res.property_lat_lon_array.property_default_long,
        });
        setSiteLocation({
          lat: res.property_lat_lon_array.property_default_lat,
          lng: res.property_lat_lon_array.property_default_long,
        });
      }
    } catch (error) {
      console.error("Error fetching activity list:", error);

      // Handle pagination end if error occurs
      if (payload.page > 0) {
        setHasMore(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreActivities = () => {
    setActivityPayload((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const locations = [
    {
      LocationName: "1 SOVA Test Site",
      scanTime: "01/02/2025 14:33:21",
      latitute: "28.6190187",
      longtute: "77.4003692",
      buttonName: "inactive",
      userId: "619935166429",
      activity_type: "Status",
      user_name: "Test Vipin",
      user_id: "619935166429",
      comments: "User Test Vipin is inactive",
      task_location: "",
      tbl_id: "447235214",
      IsLoggedIn: "I",
      status: "I",
      floor_plans_id: "0",
      profile_image:
        "https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?s=612x612&w=0&k=20&c=tyLvtzutRh22j9GqSGI33Z4HpIwv9vL_MZw_xOE19NQ=",
    },
    {
      LocationName: "1 SOVA Test Site",
      scanTime: "01/02/2025 14:33:21",
      latitute: "28.6190187",
      longtute: "78.4003692",
      buttonName: "inactive",
      userId: "619935166429",
      activity_type: "Status",
      user_name: "Test Vipin",
      user_id: "619935166429",
      comments: "User Test Vipin is inactive",
      task_location: "Noida 63",
      tbl_id: "447235214",
      IsLoggedIn: "I",
      status: "I",
      floor_plans_id: "0",
      profile_image:
        "https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?s=612x612&w=0&k=20&c=tyLvtzutRh22j9GqSGI33Z4HpIwv9vL_MZw_xOE19NQ=",
    },
    {
      LocationName: "1 SOVA Test Site",
      scanTime: "01/02/2025 14:33:21",
      latitute: "28.6190187",
      longtute: "71.4003692",
      buttonName: "inactive",
      userId: "619935166429",
      activity_type: "Status",
      user_name: "Test Dev",
      user_id: "619935166429",
      comments: "User Test Vipin is inactive",
      task_location: "Delhi",
      tbl_id: "447235214",
      IsLoggedIn: "I",
      status: "A",
      floor_plans_id: "0",
      profile_image:
        "https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?s=612x612&w=0&k=20&c=tyLvtzutRh22j9GqSGI33Z4HpIwv9vL_MZw_xOE19NQ=",
    },
  ];

  const CustomMarker = ({ location, onClick }) => {
    const [menuPosition, setMenuPosition] = useState(null); // Store mouse position

    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    });

    const setMarkerPosition = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.lattitude)) && // Safely parse latitute
        !isNaN(parseFloat(location?.longitude)) // Safely parse longitude
      ) {
        return {
          lat: parseFloat(location?.lattitude), // Convert latitute string to number
          lng: parseFloat(location?.longitude), // Convert longitude string to number
        };
      } else {
        return siteLocation; // Fallback to siteLocation if invalid
      }
    };

    return (
      <OverlayView
        position={setMarkerPosition()}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <>
          <div
            style={{
              position: "relative",
              width: "60px",
              height: "60px",
              cursor: "pointer",
            }}
            onClick={onClick}
            onContextMenu={(e) => {
              e.preventDefault();
              setMenuPosition({ mouseX: e.clientX, mouseY: e.clientY });
            }}
            title={`${location.user_name}`}
          >
            {/* Circular Marker Background */}
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              {location.profile_image ? (
                <img
                  src={location.profile_image}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#cccccc",
                  }}
                ></div>
              )}
            </div>

            {/* Status Dot */}
            <div
              style={{
                position: "absolute",
                bottom: "3px",
                right: "3px",
                width: "14px",
                height: "14px",
                backgroundColor:
                  location.status === "A" ? "#00FF00" : "#FF0000",
                borderRadius: "50%",
                border: "2px solid #ffffff",
              }}
            ></div>
          </div>

          <StyledMenu
            id="context-menu"
            anchorReference="anchorPosition"
            anchorPosition={
              menuPosition
                ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                : undefined
            }
            open={Boolean(menuPosition)}
            onClose={() => setMenuPosition(null)}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "260px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              },
            }}
          >
            <MenuItem disabled>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  padding: "8px 12px",
                  whiteSpace: "normal",
                }}
              >
                {location.user_name || "Unknown User"}
              </Typography>
            </MenuItem>

            {/* Menu Options */}
            {[
              "Send Message",
              "Activate user microphone to listen",
              "Activate user camera to watch",
              "Activate ringer in device",
              "Force logout",
              "Check Device Name(s)",
              "Go Indoors",
            ].map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => console.log(`${option} clicked`)}
                sx={{
                  padding: "6px 12px",
                  fontSize: "15px",
                  fontWeight: "500",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#f9f9f9",
                  "&:hover": {
                    backgroundColor: "#e0f7fa",
                  },
                }}
              >
                <Typography variant="body2">{option}</Typography>
              </MenuItem>
            ))}
          </StyledMenu>
        </>
      </OverlayView>
    );
  };

  const CustomInfoWindow = ({ location, onClose }) => {
    const setWidnowPostion = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.lattitude)) && // Safely parse latitute
        !isNaN(parseFloat(location?.longitude)) // Safely parse longitude
      ) {
        console.log("in if");
        return {
          lat: parseFloat(location?.lattitude), // Convert latitute string to number
          lng: parseFloat(location?.longitude), // Convert longitude string to number
        };
      } else {
        console.log("in else");
        return siteLocation; // Fallback to siteLocation if invalid
      }
    };
    return (
      <InfoWindow
        position={setWidnowPostion()}
        onCloseClick={() => {
          onClose();
        }}
      >
        <div
          style={{
            minWidth: "320px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Info Content */}
          <div style={{ paddingRight: "12px" }}>
            <p>
              <strong>User:</strong> {location?.user_name}
            </p>
            <p>
              <strong>{location?.activity_type}:</strong> {location?.buttonName}
            </p>
            <p>
              <strong>Location:</strong> {location?.task_location}
            </p>
            <p>
              <strong>Remarks:</strong> {location?.comments}
            </p>
          </div>
        </div>
      </InfoWindow>
    );
  };

  useEffect(() => {
    getActivityList(activityPayload);
  }, [activityPayload]);

  return (
    <>
      <Box
        sx={{
          height: "80vh",
          width: "100%",
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid
            item
            xs={4}
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UserActivityList
              liveDispatchData={liveDispatchData}
              loading={loading}
              setActivityPayload={setActivityPayload}
              fetchMoreActivities={fetchMoreActivities}
              hasMore={hasMore}
              setMapCenter={setMapCenter}
              setLocationIds={setLocationIds}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                height: "45px",
                flexShrink: 0,
              }}
            >
              <MapHeader />
            </Box>

            <Box
              sx={{
                flex: "1",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Map
                locations={liveDispatchData?.map_data_array}
                CustomMarker={CustomMarker}
                CustomInfoWindow={CustomInfoWindow}
                mapCenter={mapCenter}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LiveModule;
