import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import CommunicationDetailsUnverified from "./inner-component/CommunicationDetailsUnverified";
import Confirmation from "./inner-component/Confirmation";
import SubscriptionForTestUser from "./inner-component/SubscriptionForTestUser";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  CommunicationList,
  RemoveVerification,
  verifyStatus,
  UpdateCommRecord,
  DeleteCommnication,
  AddCommunication,
  saveAssignment,
  SendVerification,
} from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import {
  Typography,
  Grid,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  FormControlLabel,
  TablePagination,
  Switch,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  Checkbox,
  Paper as MuiPaper,
  Button,
  Box,
  Menu,
  InputLabel as MuiInputLabel,
  Select,
  TextField,
  FormControl as MuiFormControl,
  RadioGroup,
  Radio,
  FormLabel,
  Link,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PhoneEnabled as PhoneEnabledIcon,
  MailOutline as MailOutlineIcon,
  ListAltSharp as ListAltSharpIcon,
  Save as SaveIcon,
  Info as InfoIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import TestCommunicationDialog from "./inner-component/TestCommunicationDialog";
import TestCommReportDialog from "./inner-component/TestCommReport";
import WithPermissionFallback from "../../../utils/withPermissionFallback";
import WithPermissionHide from "../../../utils/withPermissionHide";
import hasPermission from "../../../utils/hasPermission";

const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const FormControlSpacing = styled(MuiFormControl)(spacing);
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const FormControl = styled(FormControlSpacing)`
  display: block;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    arrow
    title={title}
    placement="top-end"
    /* arrow */
    componentsProps={{
      tooltip: {
        sx: {
          padding: 0,
          background: "transparent",
          "& .MuiCardContent-root": {
            paddingTop: 0,
          },
          "& .MuiTooltip-arrow": {
            color: "rgb(210 210 210)",
          },
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
const headCells = [
  {
    id: "active",
    numeric: true,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "confirm",
    numeric: true,
    disablePadding: false,
    label: "Confirm",
  },
  {
    id: "verified",
    numeric: true,
    disablePadding: false,
    label: "Verified",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "fname",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lname",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "listcount",
    numeric: true,
    disablePadding: false,
    label: "List Count",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
function createData(
  active,
  confirm,
  verified,
  email,
  phone,
  fname,
  lname,
  listcount,
  action
) {
  return {
    active,
    confirm,
    verified,
    email,
    phone,
    fname,
    lname,
    listcount,
    action,
  };
}
const rows = [createData(0, 0, 0, 0, 0, 0, 0, 0, 0)];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const EnhancedTableHead = (props) => {
  const { order, orderBy, numSelected, rowCount, onRequestSort, filterData } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          (headCell.id === "verified" ||
            headCell.id === "listcount" ||
            headCell.id === "confirm") &&
          filterData ? null : (
            <TableCell
              key={headCell.fname}
              align={headCell.numeric ? "left" : "left"}
              sx={{ whiteSpace: "nowrap" }}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.fname ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.fname}
                direction={orderBy === headCell.fname ? order : "asc"}
                // onClick={createSortHandler(headCell.fname)}
                hideSortIcon={true}
              >
                {headCell.label}
                {headCell.id === "verified" && (
                  <StyledTooltip
                    title={
                      <Card>
                        <CardHeader title="Green = confirmed, Yellow = sent but not confirmed, Red = verification not sent" />
                      </Card>
                    }
                  >
                    <IconButton color="primary">
                      <InfoIcon />
                    </IconButton>
                  </StyledTooltip>
                )}
                {headCell.icon}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  const { communicationData, dropDownFilter, filterRecord } = props;
  // Mail list dropdown
  const [mainList, setMainList] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("email");
  const [searchInput, setSearchInput] = React.useState("");

  // states for test communication dialog
  const [testCommDialog, setTestCommDialog] = React.useState(false);

  const testCommDialogClose = () => {
    setTestCommDialog(false);
  };

  // state for test report comm dialog
  const [testCommReportDialog, setTestCommReportDialog] = useState(false);

  const testCommReportDialogClose = () => {
    setTestCommReportDialog(false);
  };

  const selectMainList = (event) => {
    dropDownFilter(event.target.value);
    setMainList(event.target.value);
  };
  const radioFilter = (event) => {
    setSearchBy(event.target.value);
  };
  const applySearch = () => {
    const payload = {
      searchBy: searchBy,
      searchValue: searchInput ? searchInput : "",
    };
    filterRecord(payload);
  };
  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      applySearch();
    }
  };
  const clearSearch = () => {
    setSearchInput("");
    const payload = {
      searchBy: searchBy,
      searchValue: "",
    };
    filterRecord(payload);
  };
  const option = [
    { key: "AED Expired Battery or Pads", value: "aed_expired_components" },
    { key: "AED Monthly Inspection", value: "aed_monthly_inspection" },
    { key: "Activity Alert", value: "activity_alert" },
    { key: "Attendance Record", value: "attendance_record" },
    { key: "Critical IR", value: "critical_IR" },
    { key: "Critical Task", value: "critical_TASK" },
    { key: "Critical Tour Button Missed", value: "tour_submit" },
    { key: "E-Notify", value: "emergency_alert" },
    { key: "EAR High Severity", value: "sevraty_scale" },
    { key: "Executive Summary", value: "executive_summary" },
    { key: "Expired Daily Task", value: "expired_daily_task" },
    { key: "Expired Visitor", value: "expired_visitor" },
    { key: "IR Reminder", value: "ir_reminder" },
    { key: "IR Status", value: "IR_status" },
    { key: "Iot System", value: "iot_system" },
    { key: "Lost and Found High Value", value: "lf_value" },
    { key: "Lost and Found Payment", value: "lostfound_payment" },
    { key: "Lost and Found Inquiry", value: "lostfound_inquiry" },
    {
      key: "Lost and Found Payment Not Done",
      value: "lf_shipping_payment_notdone",
    },
    { key: "Man Down / Lone Worker Protection", value: "main_down" },
    { key: "Minimum Tour Count Per Day", value: "count_tour_per_day" },
    { key: "Mobile User Panic Alert", value: "panic_alert" },
    { key: "Mobile User No Movement", value: "no_movement" },
    { key: "New IR", value: "new_IR" },
    { key: "No Login", value: "no_login" },
    { key: "No SOVA Activity for 24 Hours", value: "no_activity" },
    { key: "O1 custom", value: "O1" },
    { key: "O2 custom", value: "O2" },
    { key: "O3 custom", value: "O3" },
    { key: "O4 custom", value: "O4" },
    { key: "O5 custom", value: "O5" },
    { key: "Occ Health Manager Notify", value: "send_ohm" },
    { key: "Overdue Keys", value: "overdue_keys" },
    { key: "POI Close Proximity", value: "poi_proximity" },
    { key: "POI High Threat Level", value: "threat_level" },
    { key: "Package Notify - Employee", value: "package_notify_employee" },
    { key: "Package Notify - Guest", value: "package_notify" },
    { key: "Panic Mp3 Email", value: "panic_mp3_email" },
    { key: "SMS Group Notify", value: "self_enroll" },
    { key: "Send DAR", value: "send_dar" },
    { key: "Send EAR", value: "send_ear" },
    { key: "Send IR", value: "send_ir" },
    { key: "Send SR", value: "send_sr" },
    { key: "Submit Tour", value: "submit_tour" },
    { key: "Team Notify", value: "team_notify" },
    { key: "Trigger Word", value: "trigger_word" },
    { key: "Undelivered Package", value: "undelivered_package_notify" },
    { key: "User Blocked", value: "user_blocked" },
    { key: "Visitor Notify", value: "visitor_arrival" },
    { key: "Visitor Overstay", value: "visitor_overstay" },
  ];
  return (
    <>
      <Box
        sx={{
          paddingTop: "20px",
          paddingBottom: "15px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Typography>
          Select your list from the dropdown to view users subscribed to that
          list. Learn about lists{" "}
          <a
            href="https://support.sovasystems.com/portal/en/kb/articles/communication-screen"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            here.
          </a>{" "}
          Add or edit users from the MAIN LIST only.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <FormControl mt={3}>
            <Select
              labelId="main-list"
              id="main-list"
              value={mainList}
              onChange={selectMainList}
              displayEmpty
            >
              <MenuItem value="">-- MAIN LIST --</MenuItem>
              {option.map((item) => {
                return <MenuItem value={item.value}> {item.key}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: "1.5rem" }}>
            <WithPermissionHide
              controller="Communication"
              action="sendTestComm"
            >
              <FormControl mt={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setTestCommDialog(true);
                  }}
                >
                  Test Comm
                </Button>
              </FormControl>
            </WithPermissionHide>
            <WithPermissionHide
              controller="Communication"
              action="getDeliveryReports"
            >
              <FormControl mt={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setTestCommReportDialog(true);
                  }}
                >
                  Get Test Report
                </Button>
              </FormControl>
            </WithPermissionHide>
          </Box>
        </Box>

        <Paper mt={6}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} lg={4}>
              <FormControl>
                <FormLabel component="legend">Search by</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="search-by"
                  defaultValue={searchBy}
                  onChange={radioFilter}
                  name="search_by"
                >
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="first_name"
                    control={<Radio />}
                    label="First name"
                  />
                  <FormControlLabel
                    value="last_name"
                    control={<Radio />}
                    label="Last name"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <Item sx={{ flexGrow: 1, width: "100%" }}>
                  <Box style={{ position: "relative" }}>
                    <TextField
                      style={{ width: "100%" }}
                      id="search"
                      type="text"
                      placeholder="Enter search term"
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                      onKeyPress={handleSearchKeyPress}
                      value={searchInput}
                      inputProps={{}}
                    />
                    {searchInput.length > 0 && (
                      <IconButton
                        onClick={clearSearch}
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "15px",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Box>
                </Item>
                <Item>
                  <StyledTooltip
                    title={
                      <Card>
                        <CardHeader title="Search this list only" />
                      </Card>
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        applySearch();
                      }}
                      sx={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        height: "100%",
                        marginRight: { lg: "0px" },
                        marginLeft: "-3px",
                      }}
                    >
                      <SearchIcon />
                    </Button>
                  </StyledTooltip>
                </Item>
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography color="inherit" variant="subtitle1" textAlign="right">
                Active records: {communicationData.length}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {testCommDialog && (
        <TestCommunicationDialog
          testCommDialog={testCommDialog}
          testCommDialogClose={testCommDialogClose}
        />
      )}
      {testCommReportDialog && (
        <TestCommReportDialog
          testCommReportDialog={testCommReportDialog}
          testCommReportDialogClose={testCommReportDialogClose}
        />
      )}
    </>
  );
};
function EnhancedTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("color");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [communicationData, setCommunicationData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [listLength, setListLength] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [dataToFilter, setDataToFilter] = useState({});
  const [formChangedIndex, setFormChangedIndex] = useState(-1);
  const [updatedRow, setUpdatedRow] = useState(null);
  const [formData, setFormData] = useState({});
  const [saveChange, setSaveChange] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [originalCommunicationList, setOriginalCommunicationList] = useState(
    []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePhoneChange = (newPhoneNumber, row, index) => {
    setFormChangedIndex(row.id);
    const updatedField = { phone: newPhoneNumber };
    setFormData({ ...row, ...updatedField });
    const list = [...communicationData];
    list[index] = {
      ...list[index], // Create a new object copy
      ["phone"]: newPhoneNumber, // Update the specific field
    };
    setCommunicationData(list);
  };
  const handleFormChange = (event, row, index) => {
    setFormChangedIndex(row.id);
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;
    const { id, value } = event.target;
    const updatedField = { [id]: fieldValue };
    setFormData({ ...row, ...updatedField });
    const list = [...communicationData];
    list[index] = {
      ...list[index], // Create a new object copy
      [id]: fieldValue, // Update the specific field
    };
    setCommunicationData(list);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionClick = (event, row) => {
    setUpdatedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const [countryCode, setCountryCode] = React.useState("");
  const selectCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  // Add More Rows
  const [newRecord, setNewRecord] = React.useState(0);
  const [newDataCount, setNewDataCount] = useState(0);
  const addNewRecord = () => {
    setNewDataCount(newDataCount + 1);
    setSaveChange(true);
    setCommunicationData([...communicationData, formData]);
    setCommunicationData([
      ...communicationData,
      {
        active: "",
        email: "",
        phone_country: "",
        phone: "+1",
        first_name: "",
        last_name: "",
        communication_type: "",
        email_status: "",
        phone_status: "",
        status_sms: "",
      },
    ]);
  };
  // Communicattion Details Unverified Modal
  const [commDetailsUnverified, setCommDetailsUnverified] = useState(false);
  const commDetailsUnverifiedOpen = () => {
    setCommDetailsUnverified(true);
  };
  const commDetailsUnverifiedClose = () => {
    setCommDetailsUnverified(false);
  };
  // Confirmation Modal
  const [confirmationModal, setConfirmationModal] = useState(false);
  const confirmationModalOpen = () => {
    const initialObject = originalCommunicationList.find(
      (item) => item.id === formData.id
    );
    if (initialObject) {
      const emailOrPhoneChanged =
        formData.email !== initialObject.email ||
        formData.phone !== initialObject.phone;

      const statusIs3 = formData.status === 3 || formData.status_sms === 3;

      const autoConfirm = formData.auto_confirm;

      if (!autoConfirm && emailOrPhoneChanged) {
        setConfirmationModal(true);
      } else {
        setConfirmationModal(false);
        updateCommRecord("No");
      }
    } else {
      console.log("Initial object not found.");
    }
  };
  const confirmationModalClose = () => {
    setConfirmationModal(false);
  };
  // Toggle Save Button
  const [toggleSave, setToggleSave] = useState(false);
  const handleToggleSave = (event) => {
    setToggleSave(event.target.checked);
  };

  // Subscription for test user
  const [subscriptionForTestUserDialog, setSubscriptionForTestUserDialog] =
    useState(false);
  const subscriptionForTestUserDialogOpen = () => {
    setSubscriptionForTestUserDialog(true);
  };
  const subscriptionForTestUserDialogClose = () => {
    setSubscriptionForTestUserDialog(false);
  };
  const dropDownFilter = (val) => {
    setFilterData(val);
  };
  const filterRecord = (data) => {
    setDataToFilter(data);
  };

  // Function to validate email
  function isValidEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const updateCommRecord = (sendVerification) => {
    if (hasPermission("Communication", "updateCommRecord")) {
      // setLoading(true);
      let payload = {
        id: formData.id,
        active: formData.active,
        email: formData.email,
        phone: formData.phone,
        country: formData.phone_country,
        first_name: formData.first_name,
        last_name: formData.last_name,
        verified: formData.auto_confirm,
        sendVerification: sendVerification,
      };

      const checkValidEmail = isValidEmail(payload.email);
      if (!checkValidEmail) {
        dispatch(getMsg("Please enter valid email"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
        return;
      }
      UpdateCommRecord(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setFormChangedIndex(null);
            GetCommunicationList();
            dispatch(getMsg("Data updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } else {
      dispatch(getMsg("You do not have permission to update record."));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  const addCommunication = (sendVerification) => {
    // setLoading(true);
    const modifiedCommunicationData = communicationData.map((item) => {
      if (item.auto_confirm === 1) {
        return { ...item, auto_confirm: "make_confirm" };
      }
      return item;
    });
    let payload = {
      data: modifiedCommunicationData.map(
        ({
          // auto_confirm,
          created,
          critical_irs,
          department_id,
          email_token,
          listcount,
          modified,
          send_irs,
          send_link_time,
          sms_token,
          status,
          ...rest
        }) => rest
      ),
      sendVerification: sendVerification,
    };

    const lastElement = payload.data.slice(-newDataCount);

    // Count valid emails
    const validEmailCount = lastElement.reduce((count, element) => {
      return count + (isValidEmail(element.email) ? 1 : 0);
    }, 0);

    if (validEmailCount < newDataCount) {
      dispatch(getMsg("Please enter valid email"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      return;
    }

    AddCommunication(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setSaveChange(false);
          GetCommunicationList();
          dispatch(getMsg("Data Saved successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  const handleDoubleClick = (id, type, length) => {
    if (hasPermission("Communication", "sendVerification")) {
      setLoading(true);
      if (length > 5) {
        let payload = {
          id: id,
          type: type,
        };
        SendVerification(payload)
          .then((res) => {
            setLoading(false);
            if (res.statusCode == 200) {
              //  GetCommunicationList();
              const updatedItemList = communicationData.map((item) => {
                if (item.id === id) {
                  if (type === "email") {
                    return { ...item, status: 2 };
                  } else {
                    return { ...item, status_sms: 2 };
                  }
                }
                return item;
              });
              setCommunicationData(updatedItemList);
              dispatch(getMsg(res.data));
              dispatch(getSnackAlert("success"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            } else {
              dispatch(getMsg("Something went wrong. Please try again"));
              dispatch(getSnackAlert("error"));
              dispatch(getSnackOpen());
              setTimeout(() => {
                dispatch(getSnackClose());
              }, 3000);
            }
          })
          .catch((err) => {});
      } else {
        dispatch(getMsg("Details incomplete!"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      }
    } else {
      dispatch(getMsg("You do not have permission to send verification."));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
    }
  };

  const verifyCommStatus = (id, status) => {
    let payload = {
      id: id,
      status: status ? status : null,
      status_sms: status ? null : 3,
    };
    verifyStatus(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          //  GetCommunicationList();
          const updatedItemList = communicationData.map((item) => {
            if (item.id === id) {
              return { ...item, status: 3 };
            }
            return item;
          });
          setCommunicationData(updatedItemList);
          console.log("api successfully run");
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const RemoveCommVerification = (id, status) => {
    let payload = {
      id: id,
      status: status ? status : null,
      status_sms: status ? null : 1,
    };
    RemoveVerification(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          //  GetCommunicationList();
          const updatedItemList = communicationData.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                status: status ? status : item.status,
                status_sms: payload.status_sms
                  ? payload.status_sms
                  : item.status_sms,
              };
            }
            return item;
          });
          setCommunicationData(updatedItemList);
          console.log("api successfully run");
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {});
  };
  const deleteCommnication = (deleteId) => {
    DeleteCommnication(deleteId)
      .then((res) => {
        if (res.statusCode === 200) {
          GetCommunicationList();
          dispatch(getMsg("Data deleted successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const GetCommunicationList = () => {
    setLoading(true);
    let payload = {
      filter: filterData,
      searchBy: dataToFilter.searchBy,
      searchValue: dataToFilter.searchValue,
    };
    CommunicationList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          setCommunicationData(res.data);
          setOriginalCommunicationList(res.data);
          setNewRecord(res.data.length);
          setLoading(false);
        } else {
          setLoading(false);
          setCommunicationData([]);
          setOriginalCommunicationList([]);
        }
      })
      .catch((err) => {});
  };
  const handleDeleteModal = (id) => {
    setDeleteId(id); // Store the id in state
    setDeleteModal(true); // Open the modal
  };

  useEffect(() => {
    GetCommunicationList();
  }, [filterData, dataToFilter]);
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          communicationData={communicationData}
          dropDownFilter={dropDownFilter}
          filterRecord={filterRecord}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              filterData={filterData}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <CircularProgress />
                  </TableCell>
                  <TableCell colSpan={10} />
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {communicationData.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell padding="checkbox">
                        <StyledTooltip
                          title={
                            <Card>
                              <CardHeader title="Activate or deactivate record" />
                            </Card>
                          }
                        >
                          <Checkbox
                            id="active"
                            name="active"
                            defaultChecked={row.active == 1 ? true : false}
                            onChange={(event) =>
                              handleFormChange(event, row, index)
                            }
                            role="checkbox"
                          />
                        </StyledTooltip>
                      </TableCell>
                      {filterData == "" && (
                        <TableCell padding="checkbox">
                          <StyledTooltip
                            title={
                              <Card>
                                <CardHeader title="Auto confirm" />
                              </Card>
                            }
                          >
                            <Checkbox
                              id="auto_confirm"
                              name="auto_confirm"
                              defaultChecked={
                                row.auto_confirm == "make_confirm"
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                handleFormChange(event, row, index)
                              }
                              role="checkbox"
                            />
                          </StyledTooltip>
                        </TableCell>
                      )}
                      {filterData == "" && (
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {row.status === 2 ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="Email sent pending verification. Double click to resend" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        verifyCommStatus(row.id, 3)
                                      }
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "verifyStatus"
                                        )
                                      }
                                    >
                                      Verify Now
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                sx={{ border: "2px solid" }}
                                color="warning"
                                onDoubleClick={() =>
                                  handleDoubleClick(
                                    row.id,
                                    "email",
                                    row.email.length
                                  )
                                }
                              >
                                <MailOutlineIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : row.status === 3 ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="Email verified" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      hidden
                                      sx={{ marginLeft: "5px" }}
                                      onClick={() =>
                                        RemoveCommVerification(row.id, 1)
                                      }
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "removeVerification"
                                        )
                                      }
                                    >
                                      Remove Verification
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                color="success"
                                sx={{ border: "2px solid" }}
                              >
                                <MailOutlineIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : row.email !== null && row.email !== "" ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="Email not verified. Click to send" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        verifyCommStatus(row.id, 3)
                                      }
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "verifyStatus"
                                        )
                                      }
                                    >
                                      Verify Now
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                sx={{ border: "2px solid" }}
                                color="error"
                                onClick={() =>
                                  handleDoubleClick(
                                    row.id,
                                    "email",
                                    row.email.length
                                  )
                                }
                              >
                                <MailOutlineIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : null}
                          {row.status_sms === 2 && row.phone.length >= 5 ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="SMS pending verification. Double click to resend" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={() => verifyCommStatus(row.id)}
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "verifyStatus"
                                        )
                                      }
                                    >
                                      Verify Now
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                sx={{ border: "2px solid" }}
                                color="warning"
                                onDoubleClick={() =>
                                  handleDoubleClick(
                                    row.id,
                                    "phone",
                                    row.phone.length
                                  )
                                }
                              >
                                <PhoneEnabledIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : row.status_sms === 3 && row.phone.length >= 5 ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="Mobile number verified" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      hidden // Hidden property does not exist in the Button component
                                      sx={{ marginLeft: "5px" }}
                                      onClick={() =>
                                        RemoveCommVerification(row.id)
                                      }
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "removeVerification"
                                        )
                                      }
                                    >
                                      Remove Verification
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                color="success"
                                sx={{ border: "2px solid" }}
                              >
                                <PhoneEnabledIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : row.phone.length >= 5 ? (
                            <StyledTooltip
                              title={
                                <Card>
                                  <CardHeader title="SMS not verified. Click to send" />
                                  <CardContent>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={() => verifyCommStatus(row.id)}
                                      disabled={
                                        !hasPermission(
                                          "Communication",
                                          "verifyStatus"
                                        )
                                      }
                                    >
                                      Verify Now
                                    </Button>
                                  </CardContent>
                                </Card>
                              }
                            >
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDoubleClick(
                                    row.id,
                                    "phone",
                                    row.phone.length
                                  )
                                }
                              >
                                <PhoneEnabledIcon />
                              </IconButton>
                            </StyledTooltip>
                          ) : null}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap", minWidth: "240px" }}
                      >
                        <FormControl>
                          <TextField
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            fullWidth
                            autoComplete="off"
                            defaultValue={row.email}
                            onChange={(event) =>
                              handleFormChange(event, row, index)
                            }
                            disabled={filterData !== ""}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap", minWidth: "240px" }}
                      >
                        <FormControl>
                          <MuiPhoneNumber
                            name="phone"
                            id="phone"
                            value={row.phone}
                            onChange={(newPhoneNumber) =>
                              handlePhoneChange(newPhoneNumber, row, index)
                            }
                            defaultCountry={"us"}
                            style={{ width: "100%" }}
                            variant="outlined"
                            disableAreaCodes={true}
                            countryCodeEditable={false}
                            disabled={filterData !== ""}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap", minWidth: "240px" }}
                      >
                        <FormControl>
                          <TextField
                            id="first_name"
                            name="first_name"
                            placeholder="First name"
                            fullWidth
                            autoComplete="off"
                            defaultValue={row.first_name}
                            onChange={(event) =>
                              handleFormChange(event, row, index)
                            }
                            disabled={filterData !== ""}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "nowrap", minWidth: "240px" }}
                      >
                        <FormControl>
                          <TextField
                            id="last_name"
                            name="last_name"
                            placeholder="Last name"
                            fullWidth
                            autoComplete="off"
                            defaultValue={row.last_name}
                            onChange={(event) =>
                              handleFormChange(event, row, index)
                            }
                            disabled={filterData !== ""}
                          />
                        </FormControl>
                      </TableCell>
                      {filterData === "" && (
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.listcount ? row.listcount : 0}
                        </TableCell>
                      )}
                      <TableCell align="left" sx={{ minWidth: "100px" }}>
                        <Box sx={{ display: "flex" }}>
                          <IconButton
                            aria-label="Settings"
                            size="large"
                            aria-owns={anchorEl ? "icon-menu" : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleActionClick(event, row)}
                            color="primary"
                          >
                            <SettingsIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            sx={{
                              display:
                                toggleSave === true ? "inline-block" : "none",
                            }}
                            onClick={confirmationModalOpen}
                          >
                            <SaveIcon />
                          </IconButton>
                        </Box>
                        <StyledMenu
                          id="icon-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleActionClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              {
                                // console.log("row data 1497", updatedRow);
                                navigate(
                                  `/settings/property-user/communication/position-select/${updatedRow.id}`
                                );
                              }
                            }}
                          >
                            <ListItemIcon>
                              <ListAltSharpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Position Select" pl={1} />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              {
                                // updatedRow.hasOwnProperty("id") &&
                                subscriptionForTestUserDialogOpen();
                                handleActionClose();
                              }
                            }}
                          >
                            <ListItemIcon>
                              <ListAltSharpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Subscribed lists" pl={1} />
                          </MenuItem>
                          <WithPermissionHide
                            controller="Communication"
                            action="deleteAssignment"
                          >
                            <MenuItem
                              onClick={() => {
                                handleDeleteModal(updatedRow.id);
                                handleActionClose();
                              }}
                            >
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText primary="Delete" pl={1} />
                            </MenuItem>
                          </WithPermissionHide>
                        </StyledMenu>
                        {filterData === "" &&
                          formChangedIndex &&
                          formChangedIndex === row.id && (
                            <Button
                              variant="contained"
                              onClick={confirmationModalOpen}
                            >
                              Save
                            </Button>
                          )}
                      </TableCell>
                    </TableRow>
                  );

                  {
                    emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 6 : 0) * emptyRows }}>
                        <TableCell colSpan={9} />
                      </TableRow>
                    );
                  }
                })}
                {communicationData.length < 1 && (
                  <TableRow
                    style={{
                      height: (dense ? 6 : 0) * communicationData.length,
                    }}
                  >
                    <TableCell colSpan={12} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {filterData === "" && (
          <Card>
            <CardContent sx={{ paddingBottom: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <WithPermissionHide
                    controller="Communication"
                    action="addCommunication"
                  >
                    <StyledTooltip
                      title={
                        <Card>
                          <CardHeader title="Each key adds one new record" />
                        </Card>
                      }
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={addNewRecord}
                      >
                        Add new record
                      </Button>
                    </StyledTooltip>
                  </WithPermissionHide>
                  {saveChange && (
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginLeft: "15px" }}
                      onClick={commDetailsUnverifiedOpen}
                    >
                      Save changes
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Paper>

      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {subscriptionForTestUserDialog && (
        <SubscriptionForTestUser
          GetCommunicationList={GetCommunicationList}
          subscriptionForTestUserDialog={subscriptionForTestUserDialog}
          setSubscriptionForTestUserDialog={setSubscriptionForTestUserDialog}
          subscriptionForTestUserDialogOpen={subscriptionForTestUserDialogOpen}
          subscriptionForTestUserDialogClose={
            subscriptionForTestUserDialogClose
          }
          updatedRow={updatedRow}
        />
      )}
      {commDetailsUnverified && (
        <CommunicationDetailsUnverified
          addCommunication={addCommunication}
          commDetailsUnverified={commDetailsUnverified}
          setCommDetailsUnverified={setCommDetailsUnverified}
          commDetailsUnverifiedOpen={commDetailsUnverifiedOpen}
          commDetailsUnverifiedClose={commDetailsUnverifiedClose}
        />
      )}
      {confirmationModal && (
        <Confirmation
          updateCommRecord={updateCommRecord}
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          confirmationModalOpen={confirmationModalOpen}
          confirmationModalClose={confirmationModalClose}
        />
      )}
      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)}>No</Button>
          <Button
            onClick={() => {
              // Perform the API call using the stored ID
              deleteCommnication(deleteId);
              setDeleteModal(false); // Close the modal after API call
              setDeleteId(null); // Reset the stored ID
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Communication() {
  return (
    <React.Fragment>
      <Helmet title="Communications" />
      <WithPermissionFallback
        controller="Communication"
        action="communicationList"
      >
        <Typography variant="h3" gutterBottom display="inline">
          Communications
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default Communication;
