import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { TriggerEmailVisit } from "../../../api";
import hasPermission from "../../../utils/hasPermission";
const TriggerVisitEmailCheckbox = (props) => {
  const { id, value } = props;
  const [triggerEmailVisit, setTriggerEmailVisit] = useState(
    value == 1 ? true : false
  );
  const handleChange = (status, id) => {
    let payload = {
      visitor_type_id: id,
      visibility_status: status === true ? 0 : 1,
    };
    TriggerEmailVisit(payload)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("success");
        } else {
          console.log("err");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color="success"
            checked={triggerEmailVisit}
            onClick={() => {
              setTriggerEmailVisit(!triggerEmailVisit);
              handleChange(triggerEmailVisit, id);
            }}
            disabled={!hasPermission("Visitor", "triggerEmailVisits")}
          />
        }
        label=""
      />
    </>
  );
};

export default TriggerVisitEmailCheckbox;
