import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddPoiImage from "./inner-component/AddPoiImage";

import {
  Grid,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Select,
  InputLabel as MuiInputLabel,
  Avatar as MuiAvatar,
  TextField,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl as MuiFormControl,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormLabel,
  FormControlLabel,
  TextareaAutosize as MuiTextareaAutosize,
  Tab,
  Box,
  MenuItem,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Tooltip,
  FormHelperText,
  Paper,
  CircularProgress,
  Menu,
  FormGroup,
  ImageList,
  ImageListItem,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Code as CodeIcon,
  FullscreenExit as FullscreenExitIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  Restore as RestoreIcon,
  LocalPrintshop as LocalPrintshopIcon,
  Redo as RedoIcon,
  FormatBold as FormatBoldIcon,
  Undo as UndoIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Square as SquareIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Error as ErrorIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch } from "react-redux";
import { getFileBase64, handleApiResonseErrors } from "../../helper/helper";
import * as Yup from "yup";
import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../redux/slices/mainSlice";
import { UpdatePOI, ViewPoi, ShareLocationsList } from "../../api";
import CustomSelect from "../../components/form-components/CustomSelect";
import { MakeList, ModelList, YearList, DeletePoiAttachment } from "../../api";
import CustomTextField from "../../components/form-components/CustomTextField";
import { ChromePicker } from "react-color";
import states from "../../helper/states.json";
import CheckboxWrapper from "../accounts/components/Checkbox";
import CameraFeeding from "../../helper/camera-feeding";
import ReactQuill from "react-quill";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import WithPermissionHide from "../../utils/withPermissionHide";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -4px;
`;
const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  object-fit: cover;
`;
const BigAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 30px auto 0;
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const Accordion = styled(MuiAccordion)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
`;
//Colo Picker Dropdown
const StyledDropDownColorPicker = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenuItem-root": { padding: 0 },
      "& .MuiMenu-list": {
        padding: "0px",
      },
      "& .MuiMenuItem-root": {
        padding: "0px 0 10px",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);
// Classes Styling
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: 16,
    borderTop: 0,
    "& .MuiAccordion-root": {
      "& .MuiAccordionSummary-root": {
        padding: "0 12px",
        opacity: ".5",
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px 12px 12px",
      },
    },
    "& .MuiAccordion-root::before": {
      display: "none",
    },
    "& .Mui-expanded": {
      minHeight: "auto",
      margin: "7px 0 7px",
    },
  },
  VerticalTab: {
    "& .MuiTabs-flexContainer": {
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "3px",

      "& .MuiTab-root": {
        display: "block",
        width: "105px",
        height: "105px",
        marginLeft: "auto",
        marginBottom: "8px",
        border: "1px solid",
        borderRadius: "10px",
        borderColor:
          theme.palette.mode === "light" ? "#e0e0e0" : theme.palette.grey[700],
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "& .MuiTabs-flexContainer::-webkit-scrollbar-thumb": {
      backgroundColor: "#e0e0e0",
    },
  },
  textAreaControl: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute",
      top: "-24px",
      padding: "0 5px",
      zIndex: "1",
      left: "-5px",
      fontSize: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  fixedLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));
const TextareaAutosize = styled(MuiTextareaAutosize)`
  ${spacing};
  border: 1px solid
    ${(props) => (props.theme.palette.mode === "dark" ? "#555f6f" : "#c4c4c4")};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 120px !important;
  background: transparent;
  padding: 20px 12px 15px;
  font-size: 13px;
  opacity: 0.8;
  font-weight: 200;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  letter-spacing: normal;
`;
const TextAreaControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    "& textarea:focus-visible": {
      outlineColor: "#2f63be",
      border: "0px",
    },
  })
);

function EditPersonOfInterest() {
  const classes = useStyles();
  const { id } = useParams(); //Getting the edit user Id
  const [editData, setEditData] = useState({});
  const [tabValue, setTabValue] = React.useState("1");
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  //States related to autocomplete
  const [of, setOf] = useState([]);
  const [piercingAuto, setPiercingAuto] = useState([]);
  const [tatooAuto, setTatooAuto] = useState([]);
  const [locationAuto, setLocationAuto] = useState([]);
  const [teethAuto, setTeethAuto] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [description, setDescription] = useState(""); //State for text editor
  const [sharedLocList, setSharedLocList] = useState([]);
  //States for color pickers
  //Color picker hat
  const [bagColor, setBagColor] = useState("#000000");
  const [colorPickerB, setColorPickerB] = useState(null);
  const handleClickDropdownB = (event, id) => {
    setColorPickerB(event.currentTarget);
  };

  const [frontFace, setFrontFace] = useState(null);
  const [leftSideFace, setLeftSideFace] = useState(null);
  const [rightSideFace, setRightSideFace] = useState(null);
  const [chest, setChest] = useState(null);
  const [back, setBack] = useState(null);
  const [leftHand, setLeftHand] = useState(null);
  const [rightHand, setRightHand] = useState(null);
  const [leftArm, setLeftArm] = useState(null);
  const [rightArm, setRightArm] = useState(null);
  const [leftLeg, setLeftLeg] = useState(null);
  const [rightLeg, setRightLeg] = useState(null);
  const [other, setOther] = useState(null);
  let navigate = useNavigate();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerB = () => {
    setColorPickerB(null);
    setBagColor(bagColor);
  };
  const saveColorPickerB = () => {
    setColorPickerB(null);
    setBagColor(bagColor);
  };
  const cancelColorPickerB = () => {
    setColorPickerB(null);
    setBagColor("#000000");
  };
  //Color picker hat
  const [hatColor, setHatColor] = useState("#000000");
  const [colorPickerH, setColorPickerH] = useState(null);
  const handleClickDropdown = (event, id) => {
    setColorPickerH(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerH = () => {
    setColorPickerH(null);
    setHatColor(hatColor);
  };
  const saveColorPickerH = () => {
    setColorPickerH(null);
    setHatColor(hatColor);
  };
  const cancelColorPickerH = () => {
    setColorPickerH(null);
    setHatColor("#000000");
  };
  //Color Picker coat
  const [coatColor, setCoatColor] = useState("#000000");
  const [colorPickerC, setColorPickerC] = useState(null);
  const handleClickDropdownC = (event, id) => {
    setColorPickerC(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerC = () => {
    setColorPickerC(null);
    setCoatColor(coatColor);
  };
  const saveColorPickerC = () => {
    setColorPickerC(null);
    setCoatColor(coatColor);
  };
  const cancelColorPickerC = () => {
    setColorPickerC(null);
    setCoatColor("#000000");
  };

  //Color picker for shirt
  const [shirtColor, setShirtColor] = useState("#000000");
  const [colorPickerS, setColorPickerS] = useState(null);
  const handleClickDropdownS = (event, id) => {
    setColorPickerS(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerS = () => {
    setColorPickerS(null);
    setShirtColor(shirtColor);
  };
  const saveColorPickerS = () => {
    setColorPickerS(null);
    setShirtColor(shirtColor);
  };
  const cancelColorPickerS = () => {
    setColorPickerS(null);
    setShirtColor("#000000");
  };

  //Color picker for pant
  const [pantColor, setPantColor] = useState("#000000");
  const [colorPickerP, setColorPickerP] = useState(null);
  const handleClickDropdownP = (event, id) => {
    setColorPickerP(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerP = () => {
    setColorPickerP(null);
    setPantColor(pantColor);
  };
  const saveColorPickerP = () => {
    setColorPickerP(null);
    setPantColor(pantColor);
  };
  const cancelColorPickerP = () => {
    setColorPickerP(null);
    setPantColor("#000000");
  };

  //Color picker for shoes
  const [shoesColor, setShoesColor] = useState("#000000");
  const [colorPickerSh, setColorPickerSh] = useState(null);
  const handleClickDropdownSh = (event, id) => {
    setColorPickerSh(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const closeColorPickerSh = () => {
    setColorPickerSh(null);
    setShoesColor(shoesColor);
  };
  const saveColorPickerSh = () => {
    setColorPickerSh(null);
    setShoesColor(shoesColor);
  };
  const cancelColorPickerSh = () => {
    setColorPickerSh(null);
    setShoesColor("#000000");
  };

  //States related to image upload
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [showWebCam, setShowWebCam] = React.useState(false);
  const uploadedFileRef = useRef();

  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setUploadedImage(result);
    });
  };

  const handleShowWebCam = () => {
    let changeSetShowWebCam = !showWebCam;
    setShowWebCam(changeSetShowWebCam);
  };

  const clearSelectedImage = () => {
    setUploadedImage(null);
    uploadedFileRef.current.value = "";
  };

  const threatType = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
  ];

  const genderType = [
    { key: "Male", value: "M" },
    { key: "Female", value: "F" },
    { key: "Undetermined", value: "U" },
  ];

  const raceType = [
    { key: "White", value: "white" },
    { key: "Black", value: "black" },
    { key: "Asian", value: "asian" },
    { key: "Hispanic", value: "hispanic" },
    { key: "Native American", value: "native_american" },
    { key: "Pacific Islander", value: "pacific_islander" },
    { key: "Unknown", value: "unknown" },
  ];

  const ageType = [
    { key: "18-25", value: "18-25" },
    { key: "26-30", value: "26-30" },
    { key: "31-35", value: "31-35" },
    { key: "36-40", value: "36-40" },
    { key: "41-45", value: "41-45" },
    { key: "46-50", value: "46-50" },
    { key: "51-60", value: "51-60" },
    { key: "Over 60", value: "over 60" },
    { key: "Unknown", value: "Unknown" },
  ];

  const heightType = [
    { key: "Under 5 foot", value: "Under 5 foot" },
    { key: "5'-1' to 5'-6'", value: "5'-1' to 5'-6'" },
    { key: "5'-7' to 6'-0'", value: "5'-7' to 6'-0'" },
    { key: "Over 6 foot", value: "Over 6 foot" },
    { key: "Unknown", value: "Unknown" },
  ];

  const weightType = [
    { key: "Under 120 lbs.", value: "Under 5 foot" },
    { key: "120 - 135 lbs.", value: "120 - 135 lbs." },
    { key: "136 - 150 lbs.", value: "136 - 150 lbs." },
    { key: "151 - 170 lbs.", value: "151 - 170 lbs." },
    { key: "171 - 185 lbs.", value: "171 - 185 lbs." },
    { key: "186 - 200 lbs.", value: "186 - 200 lbs." },
    { key: "201 - 225 lbs.", value: "201 - 225 lbs." },
    { key: "Over 225 lbs", value: "Over 225 lbs" },
    { key: "Unknown", value: "Unknown" },
  ];

  const languageType = [
    { key: "English", value: "english" },
    { key: "Spanish", value: "spanish" },
    { key: "Asian", value: "asian" },
    { key: "German", value: "german" },
    { key: "French", value: "french" },
    { key: "Russian", value: "russian" },
    { key: "Middle Eastern", value: "middle_eastern" },
    { key: "European", value: "european" },
    { key: "Australian", value: "australian" },
    { key: "Unknown", value: "unknown" },
  ];

  const eyeColorType = [
    { key: "Blue", value: "blue" },
    { key: "Brown", value: "brown" },
    { key: "Hazel", value: "hazel" },
    { key: "Green", value: "green" },
  ];

  const teethOption = [
    { id: 1, title: "Straight", value: "Straight" },
    { id: 2, title: "Crocked", value: "Crocked" },
    { id: 3, title: "Braces", value: "Braces" },
    { id: 4, title: "Some missing", value: "Some missing" },
    { id: 5, title: "Stained", value: "Stained" },
    { id: 6, title: "Chipped", value: "Chipped" },
    { id: 7, title: "Gold", value: "Gold" },
    { id: 8, title: "Silver", value: "Silver" },
    { id: 9, title: "Diamonds", value: "Diamonds" },
  ];

  const facialHairType = [
    { key: "None", value: "None" },
    { key: "Scruffy", value: "Scruffy" },
    { key: "Mustache", value: "Mustache" },
    { key: "Sideburns", value: "Sideburns" },
    { key: "Trimmed beard", value: "Trimmed beard" },
    { key: "Full beard", value: "Full beard" },
    { key: "Other", value: "Other" },
  ];

  const otherFacialOption = [
    { id: 1, title: "Torn ear lobe", value: "Torn ear lobe" },
    { id: 2, title: "Pimples", value: "Pimples" },
    { id: 3, title: "Deformity", value: "Deformity" },
    { id: 4, title: "Eyes sunken", value: "Eyes sunken" },
    { id: 5, title: "Nose crocked", value: "Nose crocked" },
    { id: 6, title: "Chin cleft", value: "Chin cleft" },
  ];

  const hairColorType = [
    { key: "Blonde", value: "Blonde" },
    { key: "Brown", value: "Brown" },
    { key: "Red", value: "Red" },
    { key: "Grey", value: "Grey" },
    { key: "Black", value: "Black" },
    { key: "Other", value: "Other" },
  ];

  const hairStyleType = [
    { key: "Straight", value: "Straight" },
    { key: "Slight curls", value: "Slight curls" },
    { key: "Tight curls", value: "Tight curls" },
    { key: "Frizzy", value: "Frizzy" },
    { key: "Afro", value: "Afro" },
  ];

  const hairLengthType = [
    { key: "None", value: "None" },
    { key: "Buzzed", value: "Buzzed" },
    { key: "Short", value: "Short" },
    { key: "Medium", value: "Medium" },
    { key: "Long", value: "Long" },
  ];

  const odorType = [
    { key: "None", value: "None" },
    { key: "Fragrance", value: "Fragrance" },
    { key: "Foul smell", value: "Foul smell" },
  ];

  const piercingOption = [
    { id: 1, title: "None", value: "None" },
    { id: 2, title: "Ear", value: "Ear" },
    { id: 3, title: "Nose", value: "Nose" },
    { id: 4, title: "Eyebrow", value: "Eyebrow" },
    { id: 5, title: "Face", value: "Face" },
    { id: 6, title: "Navel", value: "Navel" },
    { id: 7, title: "Nipple", value: "Nipple" },
    { id: 8, title: "Body", value: "Body" },
  ];

  const tattooOption = [
    { id: 1, title: "None", value: "None" },
    { id: 2, title: "Fingers", value: "Fingers" },
    { id: 3, title: "Hand", value: "Hand" },
    { id: 4, title: "Forearm", value: "Forearm" },
    { id: 5, title: "Upper arm", value: "Upper arm" },
    { id: 6, title: "Shoulder", value: "Shoulder" },
    { id: 7, title: "Ankle", value: "Ankle" },
    { id: 8, title: "Calf", value: "Calf" },
    { id: 9, title: "Thigh", value: "Thigh" },
    { id: 10, title: "Chest", value: "Chest" },
    { id: 11, title: "Back", value: "Back" },
    { id: 12, title: "Neck", value: "Neck" },
    { id: 13, title: "Scalp", value: "Scalp" },
    { id: 14, title: "Face", value: "Face" },
  ];

  const gangAffiliationType = [
    { key: "Unknown", value: "Unknown" },
    { key: "Known", value: "Known" },
  ];

  const locationOption = [
    { id: 1, title: "Straight", value: "Straight" },
    { id: 2, title: "Crocked", value: "Crocked" },
    { id: 3, title: "Braces", value: "Braces" },
    { id: 4, title: "Some missing", value: "Some missing" },
    { id: 5, title: "Stained", value: "Stained" },
    { id: 6, title: "Chipped", value: "Chipped" },
    { id: 7, title: "Gold", value: "Gold" },
    { id: 8, title: "Silver", value: "Silver" },
    { id: 9, title: "Diamonds", value: "Diamonds" },
  ];

  const attachmentType = [
    { key: "Doc", value: "docx" },
    { key: "Image", value: "image" },
    { key: "PDF", value: "pdf" },
  ];

  const initialValues = {
    threat_level: editData
      ? editData.threat_level
        ? editData.threat_level
        : ""
      : "",
    tl_reason: editData ? (editData.tl_reason ? editData.tl_reason : "") : "",
    gender: editData ? (editData.gender ? editData.gender : "") : "",
    race: editData ? (editData.race ? editData.race : "") : "",
    age: editData ? (editData.age ? editData.age : "") : "",
    height: editData ? (editData.height ? editData.height : "") : "",
    weight: editData ? (editData.weight ? editData.weight : "") : "",
    first_name: editData
      ? editData.first_name
        ? editData.first_name
        : ""
      : "",
    last_name: editData ? (editData.last_name ? editData.last_name : "") : "",
    address_1: editData ? (editData.address_1 ? editData.address_1 : "") : "",
    address_2: editData ? (editData.address_2 ? editData.address_2 : "") : "",
    city: editData ? (editData.city ? editData.city : "") : "",
    state: editData ? (editData.state ? editData.state : "") : "",
    home_phone: editData
      ? editData.home_phone
        ? editData.home_phone
        : ""
      : "",
    mobile_phone: editData
      ? editData.mobile_phone
        ? editData.mobile_phone
        : ""
      : "",
    zip: editData ? (editData.zip ? editData.zip : "") : "",
    alias: editData ? (editData.alias ? editData.alias : "") : "",
    lang_accent: editData
      ? editData.lang_accent
        ? editData.lang_accent
        : ""
      : "",
    eye_color: editData ? (editData.eye_color ? editData.eye_color : "") : "",
    teeth: [],
    facial_hair: editData
      ? editData.facial_hair
        ? editData.facial_hair
        : ""
      : "",
    facial_hair_other: editData
      ? editData.facial_hair_other
        ? editData.facial_hair_other
        : ""
      : "",
    // other_facial: of ? of : [],
    other_facial_desc: editData
      ? editData.other_facial_desc
        ? editData.other_facial_desc
        : ""
      : "",
    hair_color: editData
      ? editData.hair_color
        ? editData.hair_color
        : ""
      : "",
    other_hair_color: editData
      ? editData.other_hair_color
        ? editData.other_hair_color
        : ""
      : "",
    hair_style: editData
      ? editData.hair_style
        ? editData.hair_style
        : ""
      : "",
    hair_length: editData
      ? editData.hair_length
        ? editData.hair_length
        : ""
      : "",
    odor: editData ? (editData.odor ? editData.odor : "") : "",
    cleanliness: editData
      ? editData.cleanliness
        ? editData.cleanliness
        : ""
      : "",
    scars: editData ? (editData.scars ? editData.scars : "") : "",
    scar_description: editData
      ? editData.scar_description
        ? editData.scar_description
        : ""
      : "",
    piercings: editData ? (editData.piercings ? editData.piercings : "") : "",
    // piercings_on: [],
    piercings_description: editData
      ? editData.piercings_description
        ? editData.piercings_description
        : ""
      : "",
    tattoos: editData ? (editData.tattoos ? editData.tattoos : "") : "",
    // tattoo_on: [],
    tattoo_description: editData
      ? editData.tattoo_description
        ? editData.tattoo_description
        : ""
      : "",
    glasses: editData ? (editData.glasses ? editData.glasses : "") : "",
    bag: editData ? (editData.bag ? editData.bag : "") : "",
    hat: editData ? (editData.hat ? editData.hat : "") : "",
    coat_description: editData
      ? editData.coat_description
        ? editData.coat_description
        : ""
      : "",
    shirt_description: editData
      ? editData.shirt_description
        ? editData.shirt_description
        : ""
      : "",
    pants_description: editData
      ? editData.pants_description
        ? editData.pants_description
        : ""
      : "",
    shoe_description: editData
      ? editData.shoe_description
        ? editData.shoe_description
        : ""
      : "",
    year: editData ? (editData.vehicle_year ? editData.vehicle_year : "") : "",
    make: editData ? (editData.vehicle_make ? editData.vehicle_make : "") : "",
    model: editData
      ? editData.vehicle_model
        ? editData.vehicle_model
        : ""
      : "",
    vehicle_color: editData
      ? editData.vehicle_color
        ? editData.vehicle_color
        : ""
      : "",
    vehicle_state: editData
      ? editData.vehicle_state
        ? editData.vehicle_state
        : ""
      : "",
    vehicle_plate: editData
      ? editData.vehicle_plate
        ? editData.vehicle_plate
        : ""
      : "",
    gang_affiliation: editData
      ? editData.gang_affiliation
        ? editData.gang_affiliation
        : ""
      : "",
    gang_description: editData
      ? editData.gang_description
        ? editData.gang_description
        : ""
      : "",
    criminal_history: editData
      ? editData.criminal_history
        ? editData.criminal_history
        : ""
      : "",
    criminal_history_description: editData
      ? editData.criminal_history_description
        ? editData.criminal_history_description
        : ""
      : "",
    send_other_location: editData
      ? editData.send_other_location == 1
        ? true
        : false
      : false,
    other_location_id: [],
    record_encounter: editData
      ? editData.record_encounter == 1
        ? true
        : false
      : false,
    poi_attachment: editData
      ? editData.poi_attachment
        ? editData.poi_attachment
        : []
      : [],
    poi_count: 0,
  };

  const validationSchema = Yup.object().shape({
    threat_level: Yup.string().required("Please select threat level"),
    gender: Yup.string().required("Please select gender"),
    race: Yup.string().required("Please select race"),
    age: Yup.string().required("Please select age"),
    height: Yup.string().required("Please select height"),
    weight: Yup.string().required("Please select weight"),
    // name: Yup.string().required("Please enter details"),
    // description: Yup.string().required("Please enter details"),
    // poi_attachment: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       attach_id: Yup.string().required("Please select attachment type"),
    //       attachment_detail: Yup.string().required(
    //         "Please enter attachment details "
    //       ),
    //       attachment_detail: Yup.string().required("Please attach file"),
    //     })
    //   )
    //   .min(1, "Please select one passengers"),
    poi_attachment: Yup.array().when(["poi_count"], (same, schema) => {
      return same > 0
        ? schema
            .of(
              Yup.object().shape({
                attach_id: Yup.string().required(
                  "Please select attachment type"
                ),
                attachment_detail: Yup.string().required(
                  "Please enter attachment details "
                ),
                attachment_file: Yup.mixed().required("Please attach file"),
              })
            )
            .min(1, "Please select one passengers")
        : schema;
    }),
  });
  function isUrl(str) {
    const pattern =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return pattern.test(str);
  }

  const processAtt = async (poiAtt) => {
    const promises = poiAtt.map(async (item) => {
      const result = await new Promise((resolve) => {
        getFileBase64(item.attachment_file, (result) => {
          resolve(result);
        });
      });
      item.attachment_file = result;
      return item;
    });
    return Promise.all(promises);
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let fileData = await processAtt(values.poi_attachment);
      setLoader(true);
      let formData = {
        id: id,
        threat_level: values.threat_level,
        tl_reason: values.tl_reason,
        gender: values.gender,
        race: values.race,
        age: values.age,
        height: values.height,
        weight: values.weight,
        first_name: values.first_name,
        last_name: values.last_name,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        state: values.state,
        home_phone: values.home_phone,
        mobile_phone: values.mobile_phone,
        zip: values.zip,
        alias: values.alias,
        lang_accent: values.lang_accent,
        eye_color: values.eye_color,
        teeth: teethAuto ? teethAuto.map((data) => data.value).toString() : [],
        facial_hair: values.facial_hair,
        facial_hair_other: values.facial_hair_other,
        other_facial: of ? of.map((data) => data.value).toString() : [],
        other_facial_desc: values.other_facial_desc,
        hair_color: values.hair_color,
        other_hair_color: values.other_hair_color,
        hair_style: values.hair_style,
        hair_length: values.hair_length,
        odor: values.odor,
        cleanliness: values.cleanliness,
        scars: values.scars,
        scar_description: values.scar_description,
        piercings: values.piercings,
        piercings_on: piercingAuto
          ? piercingAuto.map((data) => data.value).toString()
          : [],
        piercings_description: values.piercings_description,
        tattoos: values.tattoos,
        tattoo_on: tatooAuto
          ? tatooAuto.map((data) => data.value).toString()
          : [],
        tattoo_description: values.tattoo_description,
        glasses: values.glasses,
        bag: values.bag,
        bag_color: bagColor,
        hat: values.hat,
        hat_color: hatColor,
        coat_description: values.coat_description,
        coat_color: coatColor,
        shirt_description: values.shirt_description,
        shirt_color: shirtColor,
        pants_description: values.pants_description,
        pant_color: pantColor,
        shoe_description: values.shoe_description,
        shoe_color: shoesColor,
        vehicle_year: values.year,
        vehicle_make: values.make,
        vehicle_model: values.model,
        vehicle_color: values.vehicle_color,
        vehicle_state: values.vehicle_state,
        vehicle_plate: values.vehicle_plate,
        gang_affiliation: values.gang_affiliation,
        gang_description: values.gang_description,
        criminal_history: values.criminal_history,
        criminal_history_description: values.criminal_history_description,
        send_other_location: values.send_other_location == true ? 1 : 0,
        other_location_id: locationAuto
          ? locationAuto.map((data) => data.value)
          : [],
        notes: description,
        attachments: fileData,
        record_encounter: values.record_encounter == true ? 1 : 0,
        front_face: isUrl(frontFace) ? null : frontFace,
        left_side_face: isUrl(leftSideFace) ? null : leftSideFace,
        right_side_face: isUrl(rightSideFace) ? null : rightSideFace,
        chest: isUrl(chest) ? null : chest,
        back: isUrl(back) ? null : back,
        left_hand: isUrl(leftHand) ? null : leftHand,
        right_hand: isUrl(rightHand) ? null : rightHand,
        left_arm: isUrl(leftArm) ? null : leftArm,
        right_arm: isUrl(rightArm) ? null : rightArm,
        left_leg: isUrl(leftLeg) ? null : leftLeg,
        right_leg: isUrl(rightLeg) ? null : rightLeg,
        other: isUrl(other) ? null : other,
      };
      UpdatePOI(formData)
        .then((res) => {
          setLoader(false);
          if (res.statusCode === 200) {
            // getPropertyAreaList();
            navigate({
              pathname: "/poi/poi-list",
            });
            dispatch(getMsg("POI Updated successfully"));
            dispatch(getSnackAlert("success"));
            dispatch(getSnackOpen());
            // addPropertyAreaDialogClose();
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          } else {
            dispatch(getMsg("Something went wrong, please try again"));
            dispatch(getSnackAlert("error"));
            dispatch(getSnackOpen());
            setTimeout(() => {
              dispatch(getSnackClose());
            }, 3000);
          }
        })
        .catch((err) => {
          setLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          dispatch(getMsg(error));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        });
    } catch (err) {
      setLoader(false);
      let errors = err.data.errors;
      let error = handleApiResonseErrors(errors);
      dispatch(getMsg(error));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
    }
  };

  const [yearData, setYearData] = useState([]);
  //method for getting year dropdown
  const getYearList = () => {
    setLoader(true);
    YearList()
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setYearData(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const [makeData, setMakeData] = useState([]);
  //Method for getting make from year dropdown
  const getMakeList = (v) => {
    setLoader(true);
    let payload = {
      year: v,
    };
    MakeList(payload)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setMakeData(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const [modelData, setModelData] = useState([]);
  const getModelList = (v) => {
    setLoader(true);
    let payload = {
      make: v,
    };
    ModelList(payload)
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          setModelData(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  const [attachedData, setAttachedData] = useState([]);
  //Method for getting the data
  const getPoiData = () => {
    setLoader(true);
    ViewPoi(id)
      .then((res) => {
        setLoader(false);
        if (res.statusCode === 200) {
          updateImagesData(res.data);
          setEditData(res.data);
          setHatColor(res?.data?.hat_color);
          setCoatColor(res?.data?.coat_color);
          setShirtColor(res?.data?.shirt_color);
          setPantColor(res?.data?.pant_color);
          setShoesColor(res?.data?.shoe_color);
          setDescription(res?.data?.notes);
          setAttachedData(res?.data?.attachment_file);
          let teethOpt = res.data.teeth ? res.data.teeth.split(",") : [];
          let teethUpdatedData = [];
          let otherFaceOption = res.data.other_facial
            ? res.data.other_facial.split(",")
            : [];
          let ofData = [];
          let piercingOpt = res.data.piercings_on
            ? res.data.piercings_on.split(",")
            : [];
          let piercingUpdateData = [];
          let tatooOpt = res.data.tattoo_on
            ? res.data.tattoo_on.split(",")
            : [];
          let tatooUpdateData = [];
          let locationOpt = res.data.other_location_id
            ? res.data.other_location_id.split(",")
            : [];
          let locationUpdatedData = [];
          teethOption.forEach((obj) => {
            teethOpt.forEach((test) => {
              if (test == obj.value) {
                teethUpdatedData.push(obj);
              }
            });
          });
          setTeethAuto(teethUpdatedData);
          otherFacialOption.forEach((obj) => {
            otherFaceOption.forEach((test) => {
              if (test == obj.value) {
                ofData.push(obj);
              }
            });
          });
          setOf(ofData);
          piercingOption.forEach((obj) => {
            piercingOpt.forEach((test) => {
              if (test == obj.value) {
                piercingUpdateData.push(obj);
              }
            });
          });
          setPiercingAuto(piercingUpdateData);
          tattooOption.forEach((obj) => {
            tatooOpt.forEach((val) => {
              if (val == obj.value) {
                tatooUpdateData.push(obj);
              }
            });
          });
          setTatooAuto(tatooUpdateData);
          sharedLocList.forEach((obj) => {
            locationOpt.forEach((val) => {
              if (val == obj.value) {
                locationUpdatedData.push(obj);
              }
            });
          });
          setLocationAuto(locationUpdatedData);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };

  //API call for getting shared location list
  const getMergedLocationList = () => {
    setLoader(true);
    ShareLocationsList()
      .then((res) => {
        setLoader(false);
        if (res.statusCode == 200) {
          console.log(res.data);
          setSharedLocList(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        let errors = err.data.errors;
        let error = handleApiResonseErrors(errors);
        dispatch(getMsg(error));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
      });
  };
  useEffect(() => {
    getYearList();
    getMergedLocationList();
  }, []);

  useEffect(() => {
    if (sharedLocList) {
      getPoiData();
    }
  }, [sharedLocList]);

  useEffect(() => {
    if (editData && editData.vehicle_year && editData.vehicle_make) {
      getMakeList(editData.vehicle_year);
      getModelList(editData.vehicle_make);
    }
  }, [editData]);

  const updateImagesData = (data) => {
    setFrontFace(data?.front_face);
    setLeftSideFace(data?.left_side_face);
    setRightSideFace(data?.right_side_face);
    setChest(data?.chest);
    setBack(data?.back);
    setLeftHand(data?.left_hand);
    setRightHand(data?.right_hand);
    setLeftArm(data?.left_arm);
    setRightArm(data?.right_arm);
    setLeftLeg(data?.left_leg);
    setRightLeg(data?.right_leg);
    setOther(data?.other);
  };

  const deleteAttachment = (value) => {
    DeletePoiAttachment(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setAttachedData((items) =>
            items.filter((item) => item.attachment_id !== value)
          );
        } else {
          console.log("errr");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const download = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = "file.fdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <CustomSelect
                        name="threat_level"
                        fieldLabel="Threat Level *"
                        firstOptionTitle="Select threat level"
                        displayEmpty
                        options={threatType}
                      />
                      <FormHelperText error>
                        {touched.threat_level && errors.threat_level}
                      </FormHelperText>
                    </FormControl>
                    {/* <Grid
                      item
                      xs={12}
                      md={6}
                      // sx={{
                      //   display:
                      //     values.facial_hair === "Other" ? "block" : "none",
                      // }}
                    > */}
                    <FormControl
                      mt={3}
                      sx={{
                        display:
                          values.threat_level == "6" ||
                          values.threat_level == "7" ||
                          values.threat_level == "8" ||
                          values.threat_level == "9" ||
                          values.threat_level == "10"
                            ? "block"
                            : "none",
                      }}
                    >
                      <CustomTextField
                        id="facial-hair-other"
                        name="tl_reason"
                        label="Threat level reason"
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="ex, Person is aggressive, known criminal, etc."
                      />
                      <FormHelperText>
                        Provide a concise reason you chose this threat level for
                        this POI. This should be short, 1-2 sentences.
                      </FormHelperText>
                    </FormControl>

                    {/* </Grid> */}
                    <FormControl mt={6}>
                      <CustomSelect
                        name="gender"
                        fieldLabel="Gender *"
                        firstOptionTitle="Select gender"
                        displayEmpty
                        options={genderType}
                      />
                      <FormHelperText error>
                        {touched.gender && errors.gender}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="race"
                        fieldLabel="Race *"
                        firstOptionTitle="Select race"
                        displayEmpty
                        options={raceType}
                      />
                      <FormHelperText error>
                        {touched.race && errors.race}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="age"
                        fieldLabel="Age (approx) *"
                        firstOptionTitle="Select age"
                        displayEmpty
                        options={ageType}
                      />
                      <FormHelperText error>
                        {touched.age && errors.age}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="height"
                        fieldLabel="Height *"
                        firstOptionTitle="Select height"
                        displayEmpty
                        options={heightType}
                      />
                      <FormHelperText error>
                        {touched.height && errors.height}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <CustomSelect
                        name="weight"
                        fieldLabel="Weight *"
                        firstOptionTitle="Select weight"
                        displayEmpty
                        options={weightType}
                      />
                      <FormHelperText error>
                        {touched.weight && errors.weight}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* Images section */}
                  <Grid item xs={12} md={6}>
                    <AddPoiImage
                      frontFace={frontFace}
                      leftSideFace={leftSideFace}
                      rightSideFace={rightSideFace}
                      chest={chest}
                      back={back}
                      leftHand={leftHand}
                      rightHand={rightHand}
                      leftArm={leftArm}
                      rightArm={rightArm}
                      leftLeg={leftLeg}
                      rightLeg={rightLeg}
                      other={other}
                      setFrontFace={(file) => setFrontFace(file)}
                      setLeftSideFace={(file) => setLeftSideFace(file)}
                      setRightSideFace={(file) => setRightSideFace(file)}
                      setChest={(file) => setChest(file)}
                      setBack={(file) => setBack(file)}
                      setLeftHand={(file) => setLeftHand(file)}
                      setRightHand={(file) => setRightHand(file)}
                      setLeftArm={(file) => setLeftArm(file)}
                      setRightArm={(file) => setRightArm(file)}
                      setLeftLeg={(file) => setLeftLeg(file)}
                      setRightLeg={(file) => setRightLeg(file)}
                      setOther={(file) => setOther(file)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box className={classes.accordion}>
                      {/* POI details */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="contact-details"
                          id="contact-details-header"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            POI Contact Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="first-name"
                                  name="first_name"
                                  label="First name"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="John"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="last-name"
                                  name="last_name"
                                  label="Last name"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Smith"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="addrs-1"
                                  name="address_1"
                                  label="Address 1"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="1234 Main Str."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="addrs-2"
                                  name="address_2"
                                  label="Address 2"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Apt 23"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="city"
                                  name="city"
                                  label="City"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Miami"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="state"
                                  name="state"
                                  label="State"
                                  inputProps={{ maxLength: 20 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="FL"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="home-phone"
                                  name="home_phone"
                                  label="Home phone"
                                  inputProps={{ maxLength: 20 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="xxx-xxx-xxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="mobile-phone"
                                  name="mobile_phone"
                                  label="Mobile phone"
                                  inputProps={{ maxLength: 20 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="xxx-xxx-xxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="zip"
                                  name="zip"
                                  label="ZIP"
                                  inputProps={{ maxLength: 8 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="xxxxx"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="alias"
                                  name="alias"
                                  label="Alias"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Provide if name is not known"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      {/* Other charecteristics section */}
                      <Accordion mt={4}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="other-physical-characteristics"
                          id="other-physical-characteristics-header"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Other Physical Characteristics
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="lang_accent"
                                  fieldLabel="Language & Accent"
                                  firstOptionTitle="Select language and accent"
                                  displayEmpty
                                  options={languageType}
                                />
                                <FormHelperText error>
                                  {touched.lang_accent && errors.lang_accent}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="eye_color"
                                  fieldLabel="Eye Color"
                                  firstOptionTitle="Select eye color"
                                  displayEmpty
                                  options={eyeColorType}
                                />
                                <FormHelperText error>
                                  {touched.eye_color && errors.eye_color}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Autocomplete
                                multiple
                                id="teeth-select"
                                fullWidth
                                limitTags={2}
                                options={teethOption}
                                value={teethAuto}
                                onChange={(e, v) => {
                                  setTeethAuto(v);
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.title}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    name="teeth"
                                    label="Teeth"
                                    placeholder="Select teeth"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="facial_hair"
                                  fieldLabel="Facial Hair"
                                  firstOptionTitle="Select facial hair"
                                  displayEmpty
                                  options={facialHairType}
                                />
                                <FormHelperText error>
                                  {touched.facial_hair && errors.facial_hair}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sx={{
                                display:
                                  values.facial_hair === "Other"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  id="facial-hair-other"
                                  name="facial_hair_other"
                                  label="Other Facial Hair"
                                  inputProps={{ maxLength: 20 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <Autocomplete
                                  multiple
                                  id="other-facial"
                                  fullWidth
                                  limitTags={2}
                                  options={otherFacialOption}
                                  value={of}
                                  onChange={(e, v) => {
                                    setOf(v);
                                  }}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.title}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.title}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // name="other_facial"
                                      label="Other facial"
                                      placeholder="Select other facial"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="other-facial-description"
                                  name="other_facial_desc"
                                  label="Other facial description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Shape, scars, piercings, tattoos, etc"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="hair_color"
                                  fieldLabel="Hair Color"
                                  firstOptionTitle="Select hair color"
                                  displayEmpty
                                  options={hairColorType}
                                />
                                <FormHelperText error>
                                  {touched.hair_color && errors.hair_color}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sx={{
                                display:
                                  values.hair_color === "Other"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  id="other-hair-color"
                                  name="other_hair_color"
                                  label="Other Hair Color"
                                  inputProps={{ maxLength: 20 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="hair_style"
                                  fieldLabel="Hair Style"
                                  firstOptionTitle="Select hair style"
                                  displayEmpty
                                  options={hairStyleType}
                                />
                                <FormHelperText error>
                                  {touched.hair_style && errors.hair_style}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="hair_length"
                                  fieldLabel="Hair Length"
                                  firstOptionTitle="Select hair length"
                                  displayEmpty
                                  options={hairLengthType}
                                />
                                <FormHelperText error>
                                  {touched.hair_length && errors.hair_length}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="odor"
                                  fieldLabel="Odor"
                                  firstOptionTitle="Select odor"
                                  displayEmpty
                                  options={odorType}
                                />
                                <FormHelperText error>
                                  {touched.odor && errors.odor}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Cleanliness</FormLabel>
                                <RadioGroup
                                  name="cleanliness"
                                  aria-labelledby="cleanliness"
                                  value={values.cleanliness.toString()}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "cleanliness",
                                      e.currentTarget.value
                                    )
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Clean"
                                    value="Clean"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Dirty"
                                    value="Dirty"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Scars</FormLabel>
                                <RadioGroup
                                  name="scars"
                                  aria-labelledby="scars"
                                  value={values.scars}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "scars",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("scar_description", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Y"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="N"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Unknown"
                                    value="Unknown"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sx={{
                                display:
                                  values.scars === "Y" ? "block" : "none",
                              }}
                            >
                              <FormControl>
                                <CustomTextField
                                  id="scar-description"
                                  name="scar_description"
                                  label="Scar Description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder=""
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Piercing</FormLabel>
                                <RadioGroup
                                  name="piercings"
                                  aria-labelledby="piercings"
                                  value={values.piercings.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "piercings",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("piercings_on", []);
                                    setFieldValue("piercings_description", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.piercings === "Yes" ? "block" : "none",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <Autocomplete
                                      multiple
                                      id="piercing-select"
                                      fullWidth
                                      limitTags={2}
                                      options={piercingOption}
                                      value={piercingAuto}
                                      onChange={(e, v) => {
                                        setPiercingAuto(v);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.title}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.title}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // name="piercings_on"
                                          label="Piercings On"
                                          placeholder="Select piercing  area"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomTextField
                                      id="piercings-description"
                                      name="piercings_description"
                                      label="Piercings Description"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Tattoos</FormLabel>
                                <RadioGroup
                                  name="tattoos"
                                  aria-labelledby="tattoos"
                                  value={values.tattoos.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "tattoos",
                                      e.currentTarget.value
                                    );
                                    setFieldValue("tattoo_on", []);
                                    setFieldValue("tattoo_description", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Yes"
                                    value="Yes"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="No"
                                    value="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display:
                                  values.tattoos === "Yes" ? "block" : "none",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <Autocomplete
                                      multiple
                                      id="piercing-select"
                                      fullWidth
                                      limitTags={2}
                                      options={tattooOption}
                                      value={tatooAuto}
                                      onChange={(e, v) => {
                                        setTatooAuto(v);
                                      }}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option.title}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option.title}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // name="tattoo_on"
                                          label="Tattoo On"
                                          placeholder="Select tattoo  area"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormControl>
                                    <CustomTextField
                                      id="tattoo-description"
                                      name="tattoo_description"
                                      label="Tattoo Description"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      placeholder=""
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      {/* Clothing worn section */}
                      <Accordion mt={4}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="clothing-worn"
                          id="clothing-worn"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Clothing Worn
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                              <FormControl mt={3}>
                                <FormLabel>Glasses</FormLabel>
                                <RadioGroup
                                  name="glasses"
                                  aria-labelledby="glasses"
                                  value={values.glasses.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "glasses",
                                      e.currentTarget.value
                                    );
                                    // setFieldValue("tattoo_on", []);
                                    // setFieldValue("tattoo_description", "");
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="None"
                                    value="None"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Prescription"
                                    value="Prescription"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Dark tint"
                                    value="Dark tint"
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label="Light tint"
                                    value="Light tint"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="bag"
                                  name="bag"
                                  label="Bag"
                                  inputProps={{ maxLength: 50 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Purse, man-bag, backpack, luggage, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Bag Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdownB}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: bagColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerB}
                                  open={Boolean(colorPickerB)}
                                  onClose={closeColorPickerB}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={bagColor}
                                        onChange={(updatedColor) =>
                                          setBagColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerB}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerB}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="hat"
                                  name="hat"
                                  label="Hat"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Baseball, beenie, du-rag, cowboy, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                {/* <TextField
                                    name="custom_button_text_color"
                                    placeholder={hatColor}
                                    value={hatColor}
                                    onChange={(e) => hatColor(e.target.value)}
                                    sx={{ maxWidth: "180px" }}
                                  /> */}
                                <FormLabel>Hat Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdown}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: hatColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerH}
                                  open={Boolean(colorPickerH)}
                                  onClose={closeColorPickerH}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={hatColor}
                                        onChange={(updatedColor) =>
                                          setHatColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerH}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerH}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                              {/* <FormControl>
                                <FormLabel>Color picker 2</FormLabel>
                              </FormControl> */}
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="coat"
                                  name="coat_description"
                                  label="Coat"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Athletic, winter, leather, sleeveless, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Coat Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdownC}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: coatColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerC}
                                  open={Boolean(colorPickerC)}
                                  onClose={closeColorPickerC}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={coatColor}
                                        onChange={(updatedColor) =>
                                          setCoatColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerC}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerC}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="shirt"
                                  name="shirt_description"
                                  label="Shirt"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Tee, sleeveless, polo, long sleeve, sweater, none, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Shirt Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdownS}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: shirtColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerS}
                                  open={Boolean(colorPickerS)}
                                  onClose={closeColorPickerS}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={shirtColor}
                                        onChange={(updatedColor) =>
                                          setShirtColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerS}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerS}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="pants"
                                  name="pants_description"
                                  label="Pants"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Shorts, skirts, jeans, cargo, dress, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Pant Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdownP}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: pantColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerP}
                                  open={Boolean(colorPickerP)}
                                  onClose={closeColorPickerP}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={pantColor}
                                        onChange={(updatedColor) =>
                                          setPantColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerP}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerP}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="shoes"
                                  name="shoe_description"
                                  label="Shoes"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Sandals, heels, athletic, work, western, casual, etc."
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <FormLabel>Shoes Color</FormLabel>
                                <Button
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      bgcolor: "transparent",
                                    },
                                    display: "inline-flex",
                                    padding: 0,
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                  onClick={handleClickDropdownSh}
                                  aria-label="color-picker"
                                  size="large"
                                  aria-owns={
                                    anchorEl ? "color-picker" : undefined
                                  }
                                  aria-haspopup="true"
                                >
                                  <SquareIcon
                                    sx={{
                                      color: shoesColor,
                                      fontSize: "35px",
                                    }}
                                  />
                                  <ArrowDropDownIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <StyledDropDownColorPicker
                                  id="color-picker"
                                  anchorEl={colorPickerSh}
                                  open={Boolean(colorPickerSh)}
                                  onClose={closeColorPickerSh}
                                  // onClose={() => handleSave(user.id)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <MenuItem>
                                    <Box>
                                      <ChromePicker
                                        color={shoesColor}
                                        onChange={(updatedColor) =>
                                          setShoesColor(updatedColor.hex)
                                        }
                                        className={classes.colorPicker}
                                      />
                                      <Button
                                        sx={{ mt: "10px" }}
                                        onClick={cancelColorPickerSh}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={saveColorPickerSh}
                                        color="primary"
                                        variant="outlined"
                                        autoFocus
                                        sx={{ marginLeft: "5px", mt: "10px" }}
                                      >
                                        save
                                      </Button>
                                    </Box>
                                  </MenuItem>
                                </StyledDropDownColorPicker>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion mt={4}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="vehicle-detail"
                          id="vehicle-detail"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Attachments
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* {attachedData.map((item) => {
                            return <img src={item.attachedment_file} />;
                          })} */}
                          <ImageList
                            sx={{ width: "100%", height: 450 }}
                            cols={5}
                            rowHeight={164}
                          >
                            {attachedData?.map((item) => (
                              <Paper
                                sx={{
                                  "& .MuiBox-root:hover .MuiIconButton-root": {
                                    display: "block",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <WithPermissionHide
                                    controller="Poi"
                                    action="deletePoiAttachment"
                                  >
                                    <IconButton
                                      color="primary"
                                      sx={{
                                        position: "absolute",
                                        right: -2,
                                        top: -2,
                                        zIndex: 1,
                                        display: "none",
                                      }}
                                    >
                                      <CancelIcon
                                        onClick={() =>
                                          deleteAttachment(item.attachment_id)
                                        }
                                      />
                                    </IconButton>
                                  </WithPermissionHide>
                                  <ImageListItem key={item.attachment_id}>
                                    <img
                                      src={item.image}
                                      // srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                      alt={item.attachment_id}
                                      // loading="lazy"
                                      onClick={() =>
                                        download(item.attachedment_file)
                                      }
                                    />
                                  </ImageListItem>
                                </Box>
                              </Paper>
                            ))}
                          </ImageList>
                        </AccordionDetails>
                      </Accordion>
                      {/* Vehicle detail section */}
                      <Accordion mt={4}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="vehicle-detail"
                          id="vehicle-detail"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Vehicle detail
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={4}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="year"
                                  fieldLabel="Year"
                                  options={yearData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  firstOptionTitle="Select year"
                                  displayEmpty
                                  // disabled={vehicleActionStatus}
                                  customCallbackMethod={(evt) => {
                                    if (evt.target.value !== "") {
                                      getMakeList(evt.target.value);
                                      setFieldValue("make", "");
                                      setFieldValue("model", "");
                                      setModelData([]);
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="make"
                                  fieldLabel="Make"
                                  options={makeData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  firstOptionTitle="Select make"
                                  displayEmpty
                                  // disabled={vehicleActionStatus}
                                  customCallbackMethod={(evt) => {
                                    if (evt.target.value !== "") {
                                      getModelList(evt.target.value);
                                      setFieldValue("model", "");
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="model"
                                  fieldLabel="Model"
                                  options={modelData}
                                  firstOptionTitle="Select model"
                                  displayEmpty
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // disabled={vehicleActionStatus}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  id="color"
                                  name="vehicle_color"
                                  label="Color"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Blue"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomSelect
                                  name="vehicle_state"
                                  fieldLabel="State"
                                  options={states}
                                  firstOptionTitle="Select state"
                                  displayEmpty
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // disabled={vehicleActionStatus}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl>
                                <CustomTextField
                                  id="plate-number"
                                  name="vehicle_plate"
                                  label="License plate number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="764TLK"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion mt={4}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="criminal-history"
                          id="criminal-history"
                        >
                          <Typography variant="h6" component="div" gutterBottom>
                            Criminal History / Gangs / Group Affiliations
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                              <FormControl mt={3}>
                                <CustomSelect
                                  name="gang_affiliation"
                                  fieldLabel="Gang Affiliation"
                                  firstOptionTitle="Click to select"
                                  displayEmpty
                                  options={gangAffiliationType}
                                />
                                <FormHelperText error>
                                  {touched.gang_affiliation &&
                                    errors.gang_affiliation}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl mt={3}>
                                <CustomTextField
                                  id="group-name"
                                  name="gang_description"
                                  label="Group name or description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="List facts if known"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomSelect
                                  name="criminal_history"
                                  fieldLabel="Criminal History"
                                  firstOptionTitle="Click to select"
                                  displayEmpty
                                  options={gangAffiliationType}
                                />
                                <FormHelperText error>
                                  {touched.criminal_history &&
                                    errors.criminal_history}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl>
                                <CustomTextField
                                  id="criminal-desc"
                                  name="criminal_history_description"
                                  label="Criminal history description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="List facts if known"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <CheckboxWrapper
                        name="send_other_location"
                        checked={values.send_other_location}
                        disableRipple
                        label="Send POI to other SOVA locations"
                        returnOnChange={(evt) => {
                          if (evt.target.checked) {
                            setFieldValue("other_location_id", []);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display:
                        values.send_other_location === true ? "block" : "none",
                    }}
                  >
                    <FormControl>
                      <Autocomplete
                        multiple
                        id="location-select"
                        fullWidth
                        limitTags={1}
                        options={sharedLocList}
                        value={locationAuto}
                        onChange={(e, v) => {
                          setLocationAuto(v);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.key}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.key}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // name="other_location_id"
                            label="Select location"
                            placeholder="Select location"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormLabel>Notes on POI</FormLabel>
                      <Box
                        className={classes.textEditor}
                        sx={{ marginTop: "15px" }}
                      >
                        <QuillWrapper>
                          <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                            placeholder="Type something.."
                          />
                        </QuillWrapper>
                        {/* <MUIRichTextEditor
                        label={
                          <Typography sx={{ padding: "15px" }}>
                            Type something here...
                          </Typography>
                        }
                        name="notification_text"
                        //   onSave={save}
                        inlineToolbar={true}
                      /> */}
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Tooltip title="Add DOC, Image or PDF">
                      <Button
                        color="primary"
                        onClick={() => {
                          let copy = {
                            attach_id: "",
                            attachment_detail: "",
                            attachment_file: null,
                          };
                          setFieldValue("poi_attachment", [
                            ...values.poi_attachment,
                            copy,
                          ]);
                          setFieldValue("poi_count", values.poi_count + 1);
                        }}
                      >
                        Add Attachment
                        <Tooltip
                          placement="top"
                          title="Each click add one attachment"
                        >
                          <ErrorIcon />
                        </Tooltip>
                      </Button>
                    </Tooltip>

                    {values.poi_attachment.map((item, index) => {
                      return (
                        <Grid spacing={6} container alignItems="center">
                          <Grid item xs={12} md={11}>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={4}>
                                <FormControl mt={6}>
                                  <InputLabel shrink id="attachment-id">
                                    Attachment type
                                  </InputLabel>
                                  <Select
                                    labelId="attachment"
                                    id="attachment-type"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    value={item.attach_id}
                                    onChange={(e) => {
                                      let newAtt = values.poi_attachment;
                                      newAtt[index].attach_id = e.target.value;
                                      setFieldValue("poi_attachment", newAtt);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.poi_attachment &&
                                        touched.poi_attachment[index]
                                          ?.attach_id &&
                                        errors?.poi_attachment &&
                                        errors?.poi_attachment[index]?.attach_id
                                    )}
                                    helperText={
                                      touched.poi_attachment &&
                                      touched.poi_attachment[index]
                                        ?.attach_id &&
                                      errors?.poi_attachment &&
                                      errors?.poi_attachment[index]?.attach_id
                                    }
                                    fullWidth
                                    displayEmpty
                                  >
                                    <MenuItem disabled value="">
                                      Select attachment type
                                    </MenuItem>
                                    {attachmentType.map((data) => {
                                      return (
                                        <MenuItem
                                          value={data.value}
                                          key={data.key}
                                        >
                                          {data.key}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {touched.poi_attachment &&
                                      touched.poi_attachment[index]
                                        ?.attach_id &&
                                      errors?.poi_attachment &&
                                      errors?.poi_attachment[index]?.attach_id}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormControl mt={6}>
                                  <TextField
                                    label="Attachment name"
                                    id="attachment-name"
                                    // name="attachment_name"
                                    InputLabelProps={{ shrink: true }}
                                    value={item.attachment_detail}
                                    fullWidth
                                    onChange={(e) => {
                                      let newAtt = values.poi_attachment;
                                      newAtt[index].attachment_detail =
                                        e.target.value;
                                      setFieldValue("poi_attachment", newAtt);
                                      // handleChangePassengerl_name(index, e);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.poi_attachment &&
                                        touched.poi_attachment[index]
                                          ?.attachment_detail &&
                                        errors?.poi_attachment &&
                                        errors?.poi_attachment[index]
                                          ?.attachment_detail
                                    )}
                                    helperText={
                                      touched.poi_attachment &&
                                      touched.poi_attachment[index]
                                        ?.attachment_detail &&
                                      errors?.poi_attachment &&
                                      errors?.poi_attachment[index]
                                        ?.attachment_detail
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormControl mt={6}>
                                  <TextField
                                    type="file"
                                    label="Attachment"
                                    id="attachment"
                                    // name="attachment"
                                    InputLabelProps={{ shrink: true }}
                                    // value={item.attachment_file}
                                    fullWidth
                                    onChange={(e) => {
                                      let newAtt = values.poi_attachment;
                                      newAtt[index].attachment_file =
                                        e.target.files[0];
                                      setFieldValue("poi_attachment", newAtt);
                                      console.log(errors, values);
                                      console.log(e.target.files[0]);
                                      console.log(newAtt);
                                    }}
                                    // handleChangePassengerl_name(index, e);
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.poi_attachment &&
                                        touched.poi_attachment[index]
                                          ?.attachment_file &&
                                        errors?.poi_attachment &&
                                        errors?.poi_attachment[index]
                                          ?.attachment_file
                                    )}
                                    helperText={
                                      touched.poi_attachment &&
                                      touched.poi_attachment[index]
                                        ?.attachment_file &&
                                      errors?.poi_attachment &&
                                      errors?.poi_attachment[index]
                                        ?.attachment_file
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <IconButton
                              color="error"
                              onClick={() => {
                                // removePassengers(index);
                                let newArr = [];
                                for (
                                  let i = 0;
                                  i < values.poi_attachment.length;
                                  i++
                                ) {
                                  if (i !== index) {
                                    newArr.push(values.poi_attachment[i]);
                                  }
                                }
                                setFieldValue("poi_attachment", newArr);
                                setFieldValue(
                                  "poi_count",
                                  values.poi_count - 1
                                );
                              }}
                              // onClick={removeAttachment}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormGroup row alignItems="center">
                        <CheckboxWrapper
                          name="record_encounter"
                          checked={values.record_encounter}
                          disableRipple
                          label="Add encounter ?"
                          // returnOnChange={(evt) => {
                          //   if (evt.target.checked) {
                          //     setFieldValue("other_location_id", []);
                          //   }
                          // }}
                        />
                        <Tooltip
                          sx={{ marginBottom: "-15px" }}
                          color="primary"
                          title="Select to add a new encounter record for this POI when saving"
                          placement="top"
                        >
                          <ErrorIcon />
                        </Tooltip>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Update POI
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          {loader && (
            <Paper className={classes.fixedLoader}>
              <CircularProgress />
            </Paper>
          )}
          {showWebCam && (
            <CameraFeeding
              showWebCam={showWebCam}
              closeWabCam={() => setShowWebCam(false)}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}
function EditPoi() {
  return (
    <React.Fragment>
      <Helmet title="Edit Person Of Interest" />
      <WithPermissionFallback controller="Poi" action="editPoi">
        <Typography variant="h3" gutterBottom display="inline">
          Edit Person Of Interest
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/poi/poi-list">
            List POI
          </Link>
          <Typography>Edit Person Of Interest</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <EditPersonOfInterest />
      </WithPermissionFallback>
    </React.Fragment>
  );
}
export default EditPoi;
