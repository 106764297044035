import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import PropTypes from "prop-types";
import { GetUserView } from "../../api";
import Infocards from "../dashboards/Task/Infocards";
// import { RecentUserActivity } from "../../api";
import {
  CardContent,
  Avatar as MuiAvatar,
  Box as MuiBox,
  Chip as MuiChip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Button as MuiButton,
  TableRow,
  TableContainer,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 120px;
  width: 120px;
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#17212f" : "rgb(229,246,253)"};

    color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#fff" : "#646464"};
    position: sticky;
    top: 0;
    padding: 14px 15px;
  }
  &.${tableCellClasses.body} {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 15px;
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  }
`;
const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.completed && green[500]};
  background: ${(props) => props.processing && orange[700]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Box = styled(MuiBox)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  padding: 15px;
  border-radius: 10px;
`;
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function UserViewCard({ profile }) {
  return (
    <Paper mt={3} pl={4}>
      <Typography variant="h6" gutterBottom>
        Profile Picture
      </Typography>
      <Typography variant="body2" gutterBottom mt={10}>
        <Avatar
          sx={{ marginLeft: "15px", marginTop: "10px" }}
          alt="Lucy Lavender"
          src={profile.profile_image}
        />
      </Typography>
    </Paper>
  );
}
function ActivityLogOutUser({ user }) {
  return (
    <Paper>
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={4} md={4}>
            <Avatar alt="Lucy Lavender" src={user.profile_image} />
          </Grid>
          <Grid item xs={8} md={8}>
            <Typography component="p" sx={{ fontSize: "16px" }}>
              Phone: {user.phone}
            </Typography>
            <Typography component="p" sx={{ fontSize: "16px" }}>
              Email: {user.email}
            </Typography>
          </Grid>
          {/* <Grid xs={12} align="right">
            <Button
              variant="outlined"
              mt={5}
              sx={{
                borderRadius: "30px",
                padding: "8px 15px",
                minWidth: "120px",
                marginLeft: "auto",
                display: "block",
              }}
            >
              Logout
            </Button>
          </Grid> */}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
          }}
        >
          <Button variant="contained" color="primary" component="span">
            Logout User
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
function ActivityLog() {
  return (
    <Paper>
      <TableContainer
        className="tableContainer"
        sx={{
          maxHeight: "350px",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#F7F9FC",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#f3f3f3",
            borderRadius: 2,
          },
        }}
      >
        <Table
          sx={{ height: "max-content" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomTableCell component="th">Activity Time</CustomTableCell>
              <CustomTableCell component="th">Activity Type</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                03/10/2022 09:24:46
              </CustomTableCell>
              <CustomTableCell>
                IR Writing Started Guest illness-100498847
              </CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
function IncidentReports() {
  return (
    <Paper>
      <TableContainer
        className="tableContainer"
        sx={{
          maxHeight: "350px",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#F7F9FC",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#f3f3f3",
            borderRadius: 2,
          },
        }}
      >
        <Table
          sx={{ height: "max-content" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomTableCell component="th">Incident Type</CustomTableCell>
              <CustomTableCell component="th">Incident Date</CustomTableCell>
              <CustomTableCell component="th">Incident Time</CustomTableCell>
              <CustomTableCell component="th">Incident Status</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>14:45</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>06:50</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>12/29/2021</CustomTableCell>
              <CustomTableCell>20:34</CustomTableCell>
              <CustomTableCell>Returned</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>14:45</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>06:50</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>12/29/2021</CustomTableCell>
              <CustomTableCell>20:34</CustomTableCell>
              <CustomTableCell>Returned</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>14:45</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>06:50</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>12/29/2021</CustomTableCell>
              <CustomTableCell>20:34</CustomTableCell>
              <CustomTableCell>Returned</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>14:45</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>06:50</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>12/29/2021</CustomTableCell>
              <CustomTableCell>20:34</CustomTableCell>
              <CustomTableCell>Returned</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>14:45</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>03/04/2022</CustomTableCell>
              <CustomTableCell>06:50</CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Vehicle Accident
              </CustomTableCell>
              <CustomTableCell>12/29/2021</CustomTableCell>
              <CustomTableCell>20:34</CustomTableCell>
              <CustomTableCell>Returned</CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
function TourActivity() {
  return (
    <Paper>
      <TableContainer
        className="tableContainer"
        sx={{
          maxHeight: "350px",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#F7F9FC",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#f3f3f3",
            borderRadius: 2,
          },
        }}
      >
        <Table
          sx={{ height: "max-content" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomTableCell component="th">Tour Name</CustomTableCell>
              <CustomTableCell component="th">Start Time</CustomTableCell>
              <CustomTableCell component="th">Result</CustomTableCell>
              <CustomTableCell component="th">End Time</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>01/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="2/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>20/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 12:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="1/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>05/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 12:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="2/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>05/20/2021 11:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>01/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="2/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>20/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 12:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="1/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>05/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 12:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="2/3" processing={+true} />
              </CustomTableCell>
              <CustomTableCell>05/20/2021 11:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
            <TableRow>
              <CustomTableCell component="td" scope="row">
                Perimeter Tour
              </CustomTableCell>
              <CustomTableCell>03/04/2022 17:08:41</CustomTableCell>
              <CustomTableCell>
                <Chip size="small" label="3/3" completed={+true} />
              </CustomTableCell>
              <CustomTableCell>11/20/2021 17:07:40</CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
function UserInfo({ user }) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Paper mt={3}>
      <TableWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  padding: "6px 0px 6px",
                  fontWeight: 600,
                  width: "155px",
                }}
              >
                First Name:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.first_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Last Name:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.last_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Email:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Phone:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.phone}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                User Level:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.user_level}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "5px 0 5px",
                  width: "155px",
                }}
              >
                Assigned Corporate:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.assigned_corporate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  borderBottom: "none",
                  fontWeight: 600,
                  padding: "6px 0px 6px",
                  width: "155px",
                }}
              >
                Properties:
              </TableCell>
              <TableCell
                style={{ borderBottom: "none", padding: "6px 0px 6px" }}
              >
                {user.properties}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
      <Button
        variant="contained"
        color="primary"
        component="span"
        mt={6}
        onClick={handleClickOpen}
      >
        Activity Log
      </Button>
      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 30px",
            }}
          >
            <Typography variant="h4">
              Recent User Activity: {user.first_name} {user.last_name}
            </Typography>
            <DialogActions>
              <IconButton onClick={handleClose} sx={{ marginRight: "-10px" }}>
                <CancelRoundedIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                padding: "30px 8px",
              }}
            >
              <Grid container spacing={6}>
                <Grid item xs={12} lg={5} xl={5} pt={0}>
                  <Paper>
                    <ActivityLogOutUser user={user} />
                  </Paper>
                  <Grid container spacing={6} alignItems="center" mt={6} mb={4}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4" color="primary">
                        Activity Log
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="p" align="right" display="block">
                        Today's Date: 01-21-2022
                      </Typography>
                    </Grid>
                  </Grid>
                  <Paper mt={4}>
                    <ActivityLog />
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={7} xl={7}>
                  {/* <Grid container spacing={6}>
                    <Grid item xs={12} sm={3} xl={3}>
                      <ImageWrapper>
                        <Image
                          sx={{ width: "100%" }}
                          component="img"
                          alt="Guage Chart"
                          src="/static/img/unsplash/guage-1.jpg"
                        />
                      </ImageWrapper>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <ImageWrapper>
                        <Image
                          sx={{ width: "100%" }}
                          component="img"
                          alt="Guage Chart"
                          src="/static/img/unsplash/guage-2.jpg"
                        />
                      </ImageWrapper>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <ImageWrapper>
                        <Image
                          sx={{ width: "100%" }}
                          component="img"
                          alt="Guage Chart"
                          src="/static/img/unsplash/guage-3.jpg"
                        />
                      </ImageWrapper>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <ImageWrapper>
                        <Image
                          sx={{ width: "100%" }}
                          component="img"
                          alt="Guage Chart"
                          src="/static/img/unsplash/guage-4.jpg"
                        />
                      </ImageWrapper>
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    spacing={6}
                    mt={1}
                    sx={{
                      "& .MuiTypography-root": {
                        display: "block",
                        textAlign: "center",
                        marginBottom: "20px",
                        color: "black",
                        textDecoration: "none",
                      },
                      "& a": {
                        textDecoration: "none",
                      },
                      "& .dataTitle": {
                        marginBottom: 0,
                        fontSize: "17px",
                        fontWeight: "600",
                      },
                      "& .guage-text-left": {
                        width: "80px",
                        textAlign: "center",
                        marginLeft: "-20px",
                      },
                      "& .guage-text-right": {
                        width: "80px",
                        textAlign: "center",
                        marginRight: "-20px",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl
                      sx={{ paddingTop: "0px !important" }}
                    >
                      <Infocards
                        sx={{ marginBottom: "0px !important" }}
                        title={
                          <Typography variant="body2">
                            Tours Completed Today
                          </Typography>
                        }
                        linkTo="/reports/tour-patrol"
                        revScore={
                          <Paper
                            sx={{
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle"></Typography>
                            <Typography>Tours</Typography>
                            <Box
                              className="guage-numbers"
                              sx={{
                                paddingBottom: "5px !important",
                                paddingTop: "5px !important",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                className="guage-text-left"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                0
                              </Typography>
                              <Typography
                                className="guage-text-right"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                80
                              </Typography>
                            </Box>
                          </Paper>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl
                      sx={{ paddingTop: "0px !important" }}
                    >
                      <Infocards
                        sx={{ marginBottom: "0px !important" }}
                        title={
                          <Typography variant="body2">
                            Monthly Task Count
                          </Typography>
                        }
                        linkTo="/reports/incidents"
                        revScore={
                          <Paper
                            sx={{
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle"></Typography>
                            <Typography>Count</Typography>
                            <Box
                              className="guage-numbers"
                              sx={{
                                paddingBottom: "5px !important",
                                paddingTop: "5px !important",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                className="guage-text-left"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                0
                              </Typography>
                              <Typography
                                className="guage-text-right"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                50
                              </Typography>
                            </Box>
                          </Paper>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl
                      sx={{ paddingTop: "0px !important" }}
                    >
                      <Infocards
                        sx={{ marginBottom: "0px !important" }}
                        title={
                          <Typography variant="body2">
                            Monthly Tour Hours
                          </Typography>
                        }
                        linkTo="/reports/performance"
                        revScore={
                          <Paper
                            sx={{
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle"></Typography>
                            <Typography>Hours</Typography>
                            <Box
                              className="guage-numbers"
                              sx={{
                                paddingBottom: "5px !important",
                                paddingTop: "5px !important",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                className="guage-text-left"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                0
                              </Typography>
                              <Typography
                                className="guage-text-right"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                200
                              </Typography>
                            </Box>
                          </Paper>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      xl
                      sx={{ paddingTop: "0px !important" }}
                    >
                      <Infocards
                        sx={{ marginBottom: "0px !important" }}
                        title={
                          <Typography variant="body2">
                            Monthly Task Hours
                          </Typography>
                        }
                        linkTo="/reports/non-tour-tasks"
                        revScore={
                          <Paper
                            sx={{
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle"></Typography>
                            <Typography>Hours</Typography>
                            <Box
                              className="guage-numbers"
                              sx={{
                                paddingBottom: "5px !important",
                                paddingTop: "5px !important",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                className="guage-text-left"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                0
                              </Typography>
                              <Typography
                                className="guage-text-right"
                                sx={{
                                  marginBottom: "0px !important",
                                }}
                              >
                                140
                              </Typography>
                            </Box>
                          </Paper>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h4" color="primary" mt={10}>
                    Incident Reports
                  </Typography>
                  <Paper mt={4}>
                    <IncidentReports />
                  </Paper>
                  <Typography variant="h4" color="primary" mt={10}>
                    Tour Activity
                  </Typography>
                  <Paper mt={4}>
                    <TourActivity />
                  </Paper>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </Paper>
  );
}
function ViewUser() {
  const [user, setUser] = useState({});

  const { id } = useParams();
  const handleViewUser = () => {
    GetUserView(id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleViewUser();
  }, []);
  return (
    <React.Fragment>
      <Helmet title="View User" />
      <WithPermissionFallback controller="Users" action="getUserView">
        <Typography variant="h3" gutterBottom display="inline">
          View User
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/tables/Users">
            User
          </Link>
          <Typography>View User</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={12} lg={6} xl={6}>
                <UserViewCard profile={user} />
              </Grid>
              <Grid item xs={12} lg={6} xl={6}>
                <UserInfo user={user} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ViewUser;
