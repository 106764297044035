import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";

import { ToastProvider } from "react-toast-notifications";
import { fetchPermissions } from "./redux/slices/permissionSlice";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();
  const locationDetail = localStorage.getItem("location");
  const locationId = JSON.parse(locationDetail)?.LocationID || "";

  const client = new Ably.Realtime({
    key: "FiSX9g.xIo36w:LldqceEFINznqGLI5RnDcfq3isTKQYJoEX8SXbz-Qyg",
    clientId: "me",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    const connection = client.connection;

    connection.on((stateChange) => {
      console.log("Ably connection state:", stateChange.current);
    });

    return () => {
      connection.off();
    };
  }, [client]);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | SOVA" defaultTitle="SaaS Dashboard | SOVA" />
      {/* <Provider store={store}> */}
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <ToastProvider>
                  <AblyProvider client={client}>
                    <AuthProvider>{content}</AuthProvider>
                  </AblyProvider>
                </ToastProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
      {/* </Provider> */}
    </HelmetProvider>
  );
}

export default App;
