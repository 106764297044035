import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { handleApiResonseErrors } from "../../helper/helper";
import {
  getSnackOpen,
  getSnackClose,
  getMsg,
} from "../../redux/slices/equipmentSlice";
import {
  GetAllDepartment,
  GetPositionSL,
  ViewPosition,
  UpdatePosition,
} from "../../api";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormLabel,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormHelperText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  CircularProgress,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Classes Styling
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
function AddPositionForm() {
  // created useStyles instance for style classes
  const classes = useStyles();
  /* Using the useNavigate hook to navigate to a different page. */
  let navigate = useNavigate();

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* Destructuring the id property from the useParams hook. */
  const { id } = useParams();

  /* Using the useState hook to create a state variable called departmentList and a function called
  setDepartmentList. It is also calling the GetAllDepartment function from the API and setting the
  state variable departmentList to the response data. */
  const [pageLoad, setPageLoad] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const communicationType = [
    { key: "AED Expired Battery or Pads", value: "aed_expired_components" },
    { key: "AED Monthly Inspection", value: "aed_monthly_inspection" },
    { key: "Activity Alert", value: "activity_alert" },
    { key: "Attendance Record", value: "attendance_record" },
    { key: "Critical IR", value: "critical_IR" },
    { key: "Critical Task", value: "critical_TASK" },
    { key: "Critical Tour Button Missed", value: "tour_submit" },
    { key: "E-Notify", value: "emergency_alert" },
    { key: "EAR High Severity", value: "sevraty_scale" },
    { key: "Executive Summary", value: "executive_summary" },
    { key: "Expired Daily Task", value: "expired_daily_task" },
    { key: "Expired Visitor", value: "expired_visitor" },
    { key: "IR Reminder", value: "ir_reminder" },
    { key: "IR Status", value: "IR_status" },
    { key: "Iot System", value: "iot_system" },
    { key: "Lost and Found High Value", value: "lf_value" },
    { key: "Lost and Found Payment", value: "lostfound_payment" },
    { key: "Lost and Found Inquiry", value: "lostfound_inquiry" },
    {
      key: "Lost and Found Payment Not Done",
      value: "lf_shipping_payment_notdone",
    },
    { key: "Man Down / Lone Worker Protection", value: "main_down" },
    { key: "Minimum Tour Count Per Day", value: "count_tour_per_day" },
    { key: "Mobile User Panic Alert", value: "panic_alert" },
    { key: "Mobile User No Movement", value: "no_movement" },
    { key: "New IR", value: "new_IR" },
    { key: "No Login", value: "no_login" },
    { key: "No SOVA Activity for 24 Hours", value: "no_activity" },
    { key: "O1 custom", value: "O1" },
    { key: "O2 custom", value: "O2" },
    { key: "O3 custom", value: "O3" },
    { key: "O4 custom", value: "O4" },
    { key: "O5 custom", value: "O5" },
    { key: "Occ Health Manager Notify", value: "send_ohm" },
    { key: "Overdue Keys", value: "overdue_keys" },
    { key: "POI Close Proximity", value: "poi_proximity" },
    { key: "POI High Threat Level", value: "threat_level" },
    { key: "Package Notify - Employee", value: "package_notify_employee" },
    { key: "Package Notify - Guest", value: "package_notify" },
    { key: "Panic Mp3 Email", value: "panic_mp3_email" },
    { key: "SMS Group Notify", value: "self_enroll" },
    { key: "Send DAR", value: "send_dar" },
    { key: "Send EAR", value: "send_ear" },
    { key: "Send IR", value: "send_ir" },
    { key: "Send SR", value: "send_sr" },
    { key: "Submit Tour", value: "submit_tour" },
    { key: "Team Notify", value: "team_notify" },
    { key: "Trigger Word", value: "trigger_word" },
    { key: "Undelivered Package", value: "undelivered_package_notify" },
    { key: "User Blocked", value: "user_blocked" },
    { key: "Visitor Notify", value: "visitor_arrival" },
    { key: "Visitor Overstay", value: "visitor_overstay" },
    { key: "Shift Equipment Report", value: "shift_equipment_report" },
  ];
  const handleDepartment = () => {
    GetAllDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [equipmentCheck, setEquipmentCheck] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [position, setPosition] = useState({});

  /**
   * It takes a value, passes it to a function that returns a promise, and then sets the state of the
   * component
   * @param value - The value of the selected option
   */
  const handleChangeSL = (value) => {
    GetPositionSL(value)
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipmentCheck(res.data);
        } else {
          setEquipmentCheck([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Creating a checkbox that is checked when the user clicks on it. */
  const [modulearray, setmodulearray] = useState([]);
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setmodulearray([...modulearray, value]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
    }
  };

  /* Creating an array with the numbers 1-10. */
  let securityLevel = [];
  for (let i = 1; i <= 10; i++) {
    securityLevel.push(i);
  }

  /**
   * This function is used to view the position of the user
   */
  const viewPosition = () => {
    setPageLoad(true);
    ViewPosition(id)
      .then((res) => {
        if (res.statusCode === 200) {
          setPosition(res.data);
          setmodulearray(res.data.equipment_permission);
          setSelectedTypes(
            res?.data?.communication ? res?.data?.communication : []
          );
          setPageLoad(false);
        } else {
          setPosition([]);
          setPageLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoad(false);
      });
  };

  /* The above code is using the useEffect hook to call the handleDepartment function when the component
 mounts. */
  useEffect(() => {
    handleDepartment();
    viewPosition();
  }, []);

  /* Destructuring the position object. */
  const { position_name, security_level, department_id } = position;

  useEffect(() => {
    GetPositionSL(security_level)
      .then((res) => {
        if (res.statusCode === 200) {
          setEquipmentCheck(res.data);
        } else {
          setEquipmentCheck([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [security_level]);

  /* Creating an object called initialValues. */
  const initialValues = {
    department: department_id ? department_id : "",
    security_level: security_level ? security_level : "",
    position_name: position_name ? position_name : "",
  };

  /* Creating a validation schema for the form. */
  const validationSchema = Yup.object().shape({
    position_name: Yup.string().required("Please enter position name"),
    department: Yup.string().required("Please select department"),
    security_level: Yup.string().required("Please select department"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      setPageLoad(true);
      let formData = {
        department_id: values.department,
        security_level: values.security_level,
        equipment_permission: modulearray,
        position_name: values.position_name,
      };
      UpdatePosition(id, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(getSnackOpen());
            dispatch(getMsg("Position has been updated successfully"));
            navigate({
              pathname: "/equipment/positions-list",
            });
          } else {
            dispatch(getSnackClose());
          }
          setPageLoad(false);
        })
        .catch((err) => {
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setErrMsg(error);
          }
          dispatch(getSnackClose());
          setPageLoad(false);
        });
      console.log({ values });
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="department"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Department*
                      </InputLabel>
                      <Select
                        labelId="department"
                        id="department-select"
                        name="department"
                        label="Department*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.department}
                        onChange={(e) => {
                          setFieldValue("department", e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.department && errors.department)}
                        helperText={touched.department && errors.department}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          Select department
                        </MenuItem>
                        {departmentList.map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>
                              {data.department_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.department && errors.department}
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="position"
                        sx={{
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Select security level*
                      </InputLabel>
                      <Select
                        name="security_level"
                        label="position*"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.security_level}
                        onChange={(e) => {
                          setFieldValue("security_level", e.target.value);
                          handleChangeSL(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.security_level && errors.security_level
                        )}
                        helperText={
                          touched.security_level && errors.security_level
                        }
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Position security level</MenuItem>
                        {securityLevel.map((data) => {
                          return <MenuItem value={data}>{data}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {touched.security_level && errors.security_level}
                      </FormHelperText>
                    </FormControl>

                    <FormControl mt={6}>
                      <TextField
                        fullWidth
                        id="pname"
                        label="Position name*"
                        name="position_name"
                        value={values.position_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.position_name && errors.position_name
                        )}
                        helperText={
                          touched.position_name && errors.position_name
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="No special characters"
                      />
                    </FormControl>

                    <FormControl mt={6}>
                      <InputLabel id="comm-type">Comm Type</InputLabel>
                      <Select
                        labelId="comm-type"
                        id="comm-type-checkbox"
                        multiple
                        value={selectedTypes}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          setSelectedTypes(
                            // On autofill we get a stringified value.
                            typeof value === "string" ? value.split(",") : value
                          );
                        }}
                        input={<OutlinedInput label="Comm Type" />}
                        renderValue={(selected) => {
                          return selected
                            .map((value) => {
                              const found = communicationType.find(
                                (type) => type.value === value
                              );
                              return found ? found.key : "";
                            })
                            .join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        {communicationType.map(({ key, value }) => (
                          <MenuItem key={value} value={value}>
                            <Checkbox
                              checked={selectedTypes?.includes(value)}
                            />
                            <ListItemText primary={key} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={6}>
                      <FormLabel>
                        Equipment permissions (select security level first)
                      </FormLabel>
                      <FormGroup>
                        {equipmentCheck.map((mod, index) => {
                          return (
                            <FormControlLabel
                              key={mod.id}
                              control={
                                <Checkbox
                                  disableRipple
                                  name={mod.name}
                                  value={mod.id}
                                  checked={modulearray.includes(
                                    mod.id.toString()
                                  )}
                                  onChange={(e) => {
                                    handleChecked(e, mod.name);
                                  }}
                                />
                              }
                              label={mod.name}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      mt={6}
                    >
                      Save position
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
    </>
  );
}

function EditPosition() {
  return (
    <React.Fragment>
      <WithPermissionFallback controller="Equipment" action="updatePosition">
        <Helmet title="Edit Position" />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom display="inline">
              Edit Position
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              color="primary"
              variant="contained"
              component={NavLink}
              to="/equipment/positions-list"
            >
              View positions
            </Button>
          </Grid>
        </Grid>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/equipment/positions-list">
            List Position
          </Link>
          <Typography>Edit Position</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <AddPositionForm />
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default EditPosition;
