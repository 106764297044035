import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import styled from "styled-components/macro";
import CustomTextField from "../../../components/form-components/CustomTextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl as MuiFormControl,
  IconButton,
  Button,
  Paper,
  Typography,
  FormHelperText,
} from "@mui/material";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DAREditComment } from "../../../api";
import Toaster from "../../tasks/Toaster";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../../redux/slices/reportSlice";
import CustomDateTimePicker from "../../../components/form-components/CustomDateTimePicker";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const EditActivityDialog = ({
  editCommentDialog,
  editCommentDialogClose,
  darId,
  darLocation,
  darDateTime,
  darRemark,
  getDailyActivityList,
}) => {
  const dispatch = useDispatch();
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");

  const currentUser = useSelector((state) => state.login.user);
  const permissions = useSelector((state) => state.permissions.permissions);

  const loggedInUserGroup = currentUser?.user_group || "";
  const isAdmin = loggedInUserGroup === "Admin";
  const isPropertyAdmin = loggedInUserGroup === "Property Admin";
  const hasPermissionToEditDate = isAdmin || isPropertyAdmin;

  const hasPermissionToEditActivity = permissions.some(
    (perm) =>
      perm.controller === "Reports" &&
      perm.action_name === "editDailyActivity" &&
      perm.user_group.includes(currentUser?.user_group_id)
  );

  //method for close the snackbaruser_group
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };
  console.log("DAR", currentUser);
  console.log("logged in user ", loggedInUserGroup);
  const initialValues = {
    comments: darRemark ? darRemark : "",
    location: darLocation ? darLocation : "",
    activityDateTime: darDateTime ? darDateTime : "",
  };

  const validationSchema = Yup.object().shape({
    location: Yup.string().required("Location is required"),
    activityDateTime: Yup.string()
      .required("Activity Date Time is required")
      .test(
        "is-valid-date",
        "Invalid date format, please use yyyy-mm-dd hh:mm:ss",
        (value) => {
          return moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid();
        }
      )
      .test(
        "is-valid-for-group",
        "Future dates are not allowed for Property Admin",
        (value) => {
          if (isPropertyAdmin) {
            const now = moment();
            return value
              ? moment(value, "YYYY-MM-DD HH:mm:ss").isBefore(now)
              : true;
          }
          return true; // Allow all dates for Admin
        }
      ),
  });

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let payload = {
      location: values.location,
      comments: values.comments,
      activity_date_time: values.activityDateTime,
    };
    DAREditComment(payload, darId)
      .then((res) => {
        if (res.statusCode === 200) {
          dispatch(getSnackMsg("DAR comment has been updated successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          editCommentDialogClose();
          getDailyActivityList();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        } else {
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
          editCommentDialogClose();
          setTimeout(() => {
            dispatch(getSnackClose());
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(getSnackMsg("Something went wrong, please try again"));
        dispatch(getSnackAlert("error"));
        dispatch(getSnackOpen());
        editCommentDialogClose();
        setTimeout(() => {
          dispatch(getSnackClose());
        }, 3000);
      });
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        aria-labelledby="edit-comment-title"
        aria-describedby="edit-comment-description"
        open={editCommentDialog}
        onClose={editCommentDialogClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          id="edit-comment-title"
        >
          <Typography variant="h4">Edit Activity</Typography>
          <DialogActions>
            <IconButton
              onClick={editCommentDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="comments"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Comments"
                          autoComplete="off"
                          multiline={true}
                          rows={4}
                          placeholder="User Log Out"
                          value={values.comments}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.comments && Boolean(errors.comments)}
                          helperText={touched.comments && errors.comments}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl mt={3}>
                        <CustomTextField
                          name="location"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Location"
                          autoComplete="off"
                          multiline={true}
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.location && Boolean(errors.location)}
                          helperText={touched.location && errors.location}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <FormControl>
                        <CustomDateTimePicker
                          name="activityDateTime"
                          label="Activity Date Time"
                          inputFormat="yyyy-MM-dd HH:mm:ss"
                          placeholder="yyyy-mm-dd hh:mm:ss"
                          disabled={!hasPermissionToEditDate}
                          setValue={(value) => {
                            if (!value) return;
                            let date = moment(value);
                            let dateValue = date.isValid()
                              ? date.format("YYYY-MM-DD HH:mm:ss")
                              : "";
                            setFieldValue("activityDateTime", dateValue);
                          }}
                          value={values.activityDateTime}
                          onBlur={handleBlur}
                        />
                        <FormHelperText error>
                          {touched.activityDateTime && errors.activityDateTime}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={editCommentDialogClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!hasPermissionToEditActivity}
                        color="primary"
                        variant="contained"
                        type="submit"
                        sx={{ marginLeft: "10px" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditActivityDialog;
