import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import axios from "axios";
import {
  Grid,
  IconButton,
  /* Link, */
  /* Breadcrumbs as MuiBreadcrumbs, */
  /* Divider as MuiDivider, */
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Button,
  /* Tooltip, */
  Typography,
  FormGroup,
  /* List,
  ListItem, */
  ListItemIcon,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Select,
  Switch,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Settings as SettingsIcon,
  /*  LockOpen as LockOpenIcon, */
  AddBox as AddBoxIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import Corporaterow from "./components/Corporaterow";
import SnackToolbar from "./components/SnackToolbar";
import { useDispatch, useSelector } from "react-redux";
import { CorporateListing, CorporateColumns, DeleteCorporate } from "../../api";
import Toaster from "../tasks/Toaster";
import { getChecked, getSnackClose } from "../../redux/slices/corporateSlice";
import WithPermissionFallback from "../../utils/withPermissionFallback";
import hasPermission from "../../utils/hasPermission";
/* const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing); */
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
                whiteSpace: "initial",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const Paper = styled(MuiPaper)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const [headColumn, setHeadColumn] = useState([]); //My header...
  const checkedValue = useSelector((state) => state.corporate.checked);
  const {
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //  Function for preselected options in site.
  const headResult = () => {
    let result = [];
    checkedValue.forEach((obj) => {
      colArray.forEach((result3) => {
        if (result3.key == obj) {
          result.push(result3);
        }
      });
    });
    result.sort((a, b) => {
      return a.sort_id - b.sort_id;
    });
    // console.log("Final result after sorting -----", finalResult);

    setHeadColumn(result);
  };

  // const [headCellState, setHeadCellState] = useState(headCells);

  // console.log("Stateeeeeeeee----", headCellState.id);
  useEffect(() => {
    headResult();
  }, [checkedValue, colArray]);

  // console.log("My new header", headColumn);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all property" }}
          />
        </TableCell>
        {/* {checkedValue.includes("name") && (
          <TableCell
            component="th"
            scope="row"
            padding="none"
            sx={{ lg: 'whiteSpace: "nowrap"' }}
            // align="left"
          >
            <TableSortLabel onClick={createSortHandler("corporate_name")}>
              Name
            </TableSortLabel>
          </TableCell>
        )}
        {checkedValue.includes("first_name") && (
          <TableCell align="left">First Name</TableCell>
        )}
        {checkedValue.includes("last_name") && (
          <TableCell align="left">Last Name</TableCell>
        )}
        {checkedValue.includes("email") && (
          <TableCell align="left">Email</TableCell>
        )}
        {checkedValue.includes("phone") && (
          <TableCell align="left">Phone</TableCell>
        )}
        {checkedValue.includes("fax") && (
          <TableCell align="left">Fax</TableCell>
        )}
        {checkedValue.includes("title") && (
          <TableCell align="left">Title</TableCell>
        )}
        <TableCell align="left">Action</TableCell> */}
        {headColumn.map((headCell) => (
          <TableCell
            key={headCell.sort_id}
            align="left"
            //align={headCell.key === "corporate_name" ? "left" : "left"}
            padding={headCell.key === "corporate_name" ? "none" : "normal"}
            // padding="none"
            // sortDirection={orderBy === headCell.id ? order : false}
            // style={{
            //   display: headCell.isHidden === true ? "block" : "none",
            // }}
            sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              active={
                headCell.key === "corporate_name"
                  ? orderBy === headCell.key
                  : false
              }
              // active={orderBy === headCell.id}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={
                headCell.key === "corporate_name"
                  ? createSortHandler(headCell.key)
                  : null
              }
              hideSortIcon={headCell.key === "corporate_name" ? false : true}
            >
              {headCell.value}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </TableHead>
  );
};
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const checkedValue = useSelector((state) => state.corporate.checked);
  const dispatch = useDispatch();
  const [actions, setActions] = useState("");
  const {
    handleDeleteOpen,
    totalRecord,
    colArray,
    getColumnList,
    getColumnChecked,
  } = props;
  const handleChange = (event) => {
    setActions(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = React.useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };

  //Handler for checked unchecked
  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(getChecked([...checkedValue, value]));
    } else {
      dispatch(getChecked(checkedValue.filter((e) => e !== value)));
    }
  };
  return (
    <Toolbar>
      <div>
        <Paper mt={3}>
          <FormControl mr={2} sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={actions}
              label="Actions"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      boxShadow:
                        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 10%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
                      margin: "0px 3px 5px 3px",
                      padding: "0",
                    },
                  },
                },
              }}
            >
              <MenuItem
                disabled={!hasPermission("Property", "deleteCorporate")}
                value={10}
                onClick={handleDeleteOpen}
              >
                Delete
              </MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      <div className="totalRecord">
        {totalRecord > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} records found
          </Typography>
        ) : (
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecord} record found
            {/* {numSelected} */}
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        <Paper mt={3}>
          <IconButton
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            <FilterListIcon />
          </IconButton>
          <StyledFilterMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              aria-label="add-corporate"
              component={NavLink}
              to="/accounts/add-corporate"
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText size="large" primary="Add Corporate" pl={0} />
            </MenuItem>
            <MenuItem
              aria-owns={anchorColumn ? "columns-dropdown" : undefined}
              aria-haspopup="true"
              onClick={handleClickColumn}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Columns" pl={0} />
            </MenuItem>
          </StyledFilterMenu>
          <StyledColumnDropdown
            id="columns-dropdown"
            anchorEl={anchorColumn}
            open={Boolean(anchorColumn)}
            onClose={handleCloseColumn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem>
              <Card>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {checkedValue.length} out of {colArray.length} visible
                  </Typography>
                  <Box>
                    <FormGroup>
                      {colArray.map((col) => {
                        return (
                          <FormControlLabel
                            key={col.sort_id}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkedValue.includes(col.key)}
                                onChange={(e) => handleChecked(e)}
                                value={col.key}
                              />
                            }
                            label={col.value}
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                  <Toolbar>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        getColumnList();
                        getColumnChecked();
                      }}
                    >
                      Reset
                    </Button>
                    <Spacer />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCloseColumn}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </CardContent>
              </Card>
            </MenuItem>
          </StyledColumnDropdown>
        </Paper>
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("corporate");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [corporateLists, setcorporateLists] = useState([]);
  const [openDelete, setOpenDelete] = useState(false); //state for delete dialog prompt
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false); //State for loader
  //States for column show and hide section
  const [hideLevel, setHideLevel] = useState(true);
  const [colName, setColName] = useState(true);
  const [colFirst, setColFirst] = useState(true);
  const [colLast, setColLast] = useState(true);
  const [colEmail, setColEmail] = useState(true);
  const [colPhone, setColPhone] = useState(true);
  const [colArray, setColArray] = useState([]); //State for list of colmn list
  const [snackToolbarOpen, setsnackToolbarOpen] = useState(false);
  const [recordLength, setrecordLength] = useState(null);
  const dispatch = useDispatch();
  const checkedValue = useSelector((state) => state.corporate.checked);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //for snackbar
  const snackbarStatus = useSelector((state) => state.corporate.snackStatus);
  const snackbarMsg = useSelector((state) => state.corporate.snackMsg);
  const snackbarAlert = useSelector((state) => state.corporate.snackAlert);
  //method for close the snackbar
  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(getSnackClose());
  };

  //handlers for snackbar...
  const handleSnackToolbarClick = () => {
    setsnackToolbarOpen(true);
  };
  const handleSnackToolbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackToolbarOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = corporateLists.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setrecordLength(newSelected.length);
    console.log(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    // setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleActionClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteId(id);
    setrecordLength(1);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  //handler for opening the delete dialog box
  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    setDeleteId(null);
  };

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };

  const deleteCorporate = () => {
    setAnchorEl(null);
    let delId = selected.length > 0 ? selected : deleteId;
    DeleteCorporate(delId)
      // console.log(delId);
      // axios
      //   .post(`delete-corporate/${delId}`)
      .then((res) => {
        showCorporateList();
        handleDeleteClose();
        handleSnackToolbarClick();
        setSelected([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //API call for corporate list
  const showCorporateList = () => {
    setLoading(true);
    let payload = {
      limit: rowsPerPage,
    };
    CorporateListing(currentPage, { params: payload })
      // axios
      //   .get(`get-corporate?page=${currentPage}`, {
      //     params: { limit: rowsPerPage },-
      //   })
      .then((res) => {
        setLoading(false);
        setcorporateLists(res.data);
        setTotalRecord(res.meta.total);
        setCurrentPage(res.meta.current_page);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //API call for showing list of column checkboxes Items
  const getColumnList = () => {
    CorporateColumns()
      // axios
      // .get(`corporate-column-list`)
      .then((res) => {
        // dispatch(getChecked(res.data.data.default_fields));
        setColArray(res.data.all_fields);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColumnChecked = () => {
    CorporateColumns()
      // axios
      //   .get(`corporate-column-list`)
      .then((res) => {
        dispatch(getChecked(res.data.default_fields));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setSelected([]);
  };

  useEffect(() => {
    showCorporateList();
    getColumnList();
  }, [rowsPerPage, currentPage]);

  //sideeffect for dispatching actions to redux store
  useEffect(() => {
    if (checkedValue.length == 0) {
      getColumnChecked();
    } else {
      console.log("Redux store array is empty");
    }
    // setTimeout(() => {
    //   dispatch(getSnackClose());
    // }, 4000);
  }, []);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <>
      <Paper mt={3}>
        <SnackToolbar
          handleSnackToolbarClose={handleSnackToolbarClose}
          snackToolbarOpen={snackToolbarOpen}
          recordLength={recordLength}
        />
        <div>
          {selected.length || deleteId > 0 ? (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete corporate?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>Cancel</Button>
                <Button
                  onClick={(handleDeleteClose, deleteCorporate)}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"You haven't selected any item!"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeleteClose}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        {/* <h2>Redux state for checked status {checkedValue}</h2> */}
        <EnhancedTableToolbar
          numSelected={selected.length}
          deleteCorporate={deleteCorporate}
          corporateLists={corporateLists}
          setcorporateLists={setcorporateLists}
          handleDeleteOpen={handleDeleteOpen}
          totalRecord={totalRecord}
          setTotalRecord={setTotalRecord}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          hideLevel={hideLevel}
          setHideLevel={setHideLevel}
          colName={colName}
          setColName={setColName}
          colFirst={colFirst}
          setColFirst={setColFirst}
          colLast={colLast}
          setColLast={setColLast}
          colEmail={colEmail}
          setColEmail={setColEmail}
          colPhone={colPhone}
          setColPhone={setColPhone}
          // colAction={colAction}
          // setColAction={setColAction}
          colArray={colArray}
          setColArray={setColArray}
          showCorporateList={showCorporateList}
          getColumnList={getColumnList}
          getColumnChecked={getColumnChecked}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            /*  maxWidth: { md: "900px", lg: "992px", xl: "100%" }, */
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableCell colSpan={13} align="center">
                <CircularProgress />
              </TableCell>
            ) : (
              <>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={corporateLists?.length ? corporateLists?.length : 0}
                  colArray={colArray}
                />
                <TableBody>
                  {stableSort(
                    corporateLists,
                    getComparator(order, orderBy)
                  ).map((corporateList) => {
                    const isItemSelected = isSelected(corporateList.id);
                    const labelId = `enhanced-table-checkbox-${corporateList.id}`;
                    return (
                      <Corporaterow
                        key={corporateList.id}
                        corporateList={corporateList}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        handleActionClick={handleActionClick}
                        deleteId={deleteId}
                        handleActionClose={handleActionClose}
                        anchorEl={anchorEl}
                        StyledMenu={StyledMenu}
                        handleDeleteOpen={handleDeleteOpen}
                      />
                    );
                  })}
                  {corporateLists.length === 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 6 : 0) * corporateLists.length,
                      }}
                    >
                      <TableCell colSpan={11} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={Math.ceil(totalRecord / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalRecord}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <FormControlLabel
        sx={{
          visibility: "hidden",
        }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {snackbarStatus && (
        <Toaster
          openSnack={snackbarStatus}
          handleCloseSnack={handleCloseSnack}
          severity={snackbarAlert}
          message={snackbarMsg}
        />
      )}
    </>
  );
}

function ListCorporate() {
  const [snackopen, setSnackopen] = useState(true);
  const SnackState = useSelector((state) => state.corporate.snackStatus);

  //handlers for snackbar...
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackopen(false);
  };

  // const markRead = (post) => {
  //   if (post.status === 1) {
  //     setMsg("Message already marked as read.");
  //     // showMessageBoard();
  //     handleSnackClick();
  //   } else {
  //     axios.post(`mark-as-read-message/${post.id}`, {
  //       status: "1",
  //     });
  //     setMsg("Message marked as read.");
  //     // showMessageBoard();
  //     handleSnackClick();
  //   }
  // };

  return (
    <React.Fragment>
      <Helmet title="List Corporate" />
      <WithPermissionFallback controller="Property" action="getCorporate">
        <Typography variant="h3" gutterBottom display="inline">
          Corporate List
        </Typography>

        {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Tables
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>

        {/* Snackbar */}
        {SnackState && (
          <Snackbar
            open={snackopen}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              variant="filled"
              sx={{ maxWidth: 600 }}
            >
              Corporate has been successfully updated.
            </Alert>
          </Snackbar>
        )}
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default ListCorporate;
