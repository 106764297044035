import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { GetHeaderLocation } from "../../../api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterActivityDialog = ({
  filterActivityDialog,
  setFilterActivityDialog,
  setActivityPayload,
  setLocationIds,
}) => {
  const [sites, setSites] = useState([]); // Holds all site options
  const [selectedSites, setSelectedSites] = useState([]); // Holds selected options
  const [loading, setLoading] = useState(true); // Loading state

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      // Handle "Select All" logic
      if (selectedSites.length === sites.length) {
        // Deselect all if all are selected
        setSelectedSites([]);
      } else {
        // Select all
        setSelectedSites(sites.map((site) => site.LocationID));
      }
    } else {
      setSelectedSites(typeof value === "string" ? value.split(",") : value);
    }
  };

  // Fetch site data
  const getSites = async () => {
    try {
      setLoading(true);
      const res = await GetHeaderLocation();
      const fetchedSites = res?.data || [];
      setSites(fetchedSites);
      setSelectedSites(fetchedSites.map((site) => site.LocationID));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSites();
  }, []);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
          },
        },
      }}
      open={filterActivityDialog}
      onClose={() => {
        setFilterActivityDialog(false);
      }}
    >
      <DialogTitle
        id="filterActivityDialog"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h5">Location Name</Typography>
        <IconButton
          sx={{ marginRight: "-10px" }}
          onClick={() => {
            setFilterActivityDialog(false);
          }}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="body2" gutterBottom mt={3}>
          Sites
        </Typography>
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="sites-label">Sites</InputLabel>
          <Select
            labelId="sites-label"
            id="sites-multiple-checkbox"
            multiple
            value={selectedSites}
            onChange={handleChange}
            input={<OutlinedInput label="Sites" />}
            renderValue={(selected) => {
              if (selected.length === sites.length) {
                return `All Selected (${sites.length})`;
              }
              const selectedNames = sites
                .filter((site) => selected.includes(site.LocationID))
                .map((site) => site.LocationName)
                .join(", ");
              return selectedNames || "None Selected";
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value="all">
              <Checkbox
                checked={selectedSites.length === sites.length}
                indeterminate={
                  selectedSites.length > 0 &&
                  selectedSites.length < sites.length
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {sites.map((site) => (
              <MenuItem key={site.LocationID} value={site.LocationID}>
                <Checkbox checked={selectedSites.includes(site.LocationID)} />
                <ListItemText primary={site.LocationName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: "20px 24px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setFilterActivityDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setActivityPayload({
              userID: false,
              userstatus: "default",
              loactionID: selectedSites.join(","),
            });
            setLocationIds(selectedSites);
            setFilterActivityDialog(false);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterActivityDialog;
