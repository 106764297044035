import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Incidents from "../../pages/reports/Incidents";
import { DateTimePicker } from "@mui/lab";
import { HeaderSearch } from "../../api";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  ListItemText as MuiListItemText,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Select,
  TextField,
  Box,
  Button,
  Menu,
  MenuItem,
  TextareaAutosize as MuiTextareaAutosize,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { spacing } from "@mui/system";
import WithPermissionFallback from "../../utils/withPermissionFallback";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
function createData(
  datetime,
  ts,
  activity,
  location,
  image,
  remarks,
  so,
  action
) {
  return {
    datetime,
    ts,
    activity,
    location,
    image,
    remarks,
    so,
    action,
  };
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    colArray,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headColumn, setHeadColumn] = useState([
    "Activity",
    "Datetime",
    "Description",
    "Action",
  ]);

  return (
    <TableHead sx={{ marginTop: "20px" }}>
      <TableRow>
        {headColumn.map((headCell, index) => (
          <TableCell key={index} align="left" sx={{ whiteSpace: "nowrap" }}>
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const EnhancedTableToolbar = (props) => {
  const {
    startDateToFilter,
    endDateToFilter,
    setStartDateToFilter,
    setEndDateToFilter,
    searchTerm,
    setSearchTerm,
    onSearchSubmit,
  } = props;

  const [errors, setErrors] = React.useState({
    searchTerm: "",
    startDateToFilter: "",
    endDateToFilter: "",
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, searchTerm: "" }));
  };

  const handleStartDateChange = (newValue) => {
    setStartDateToFilter(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, startDateToFilter: "" }));
  };

  const handleEndDateChange = (newValue) => {
    setEndDateToFilter(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, endDateToFilter: "" }));
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    let newErrors = {};

    // if (!searchTerm) {
    //   newErrors.searchTerm = "Search term is required";
    //   isValid = false;
    // }

    if (!startDateToFilter) {
      newErrors.startDateToFilter = "From date is required";
      isValid = false;
    }

    if (!endDateToFilter) {
      newErrors.endDateToFilter = "To date is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      onSearchSubmit(searchTerm);
    }
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <Grid container spacing={3} sx={{ px: "10px", mt: "10px" }}>
        <Grid item xs={6} md={3}>
          <Item className="search_term_input">
            {" "}
            <Typography
              variant="subtitle1"
              mt={3}
              id="tableTitle"
              style={{
                width: "max-content",
              }}
            >
              <TextField
                sx={{ width: { xs: "152px", md: "217px" } }}
                id="outlined-basic"
                label="Search Term"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                error={!!errors.searchTerm}
                helperText={errors.searchTerm}
              />
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item sx={{ marginTop: "12px" }}>
            {" "}
            <DesktopDatePicker
              label="From Date"
              value={startDateToFilter}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.startDateToFilter}
                  helperText={errors.startDateToFilter}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item sx={{ marginTop: "12px" }}>
            <DesktopDatePicker
              label="To Date"
              value={endDateToFilter}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.endDateToFilter}
                  helperText={errors.endDateToFilter}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "21px" }}
              type="submit"
            >
              Submit
            </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Searched For: </strong> {searchTerm}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

function EnhancedTable(props) {
  const [dense, setDense] = React.useState(true);
  //States related to listing
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = React.useState(false); //State for loader
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const { searchInput } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchInput);
  // Initialize startDate 1 year back from the current date
  const defaultStartDate = moment().subtract(6, "months").toDate();
  const [startDateToFilter, setStartDateToFilter] = useState(defaultStartDate);
  const [endDateToFilter, setEndDateToFilter] = useState(new Date());

  // Define api response array keys and view routes
  const dataConfig = {
    Incident: {
      fields: [null, "start_time", "SummaryDescription"],
      getUrl: (item) => `/reports/view-incident-report/${item.IncidentID}`,
    },
    Poi: {
      fields: [null, "created", "notes"],
      getUrl: (item) => `/poi/view-poi/${item.id}`,
    },
    ShiftReport: {
      fields: [null, "created", "summary"],
      getUrl: (item) => `/logs/view-shift-report/${item.id}`,
    },
    UserActivity: {
      fields: [null, "activity_time", "display_name"],
      getUrl: (item) => `/reports/daily-activity`,
    },
    Lostfound: {
      fields: ["category", "created", "description"],
      getUrl: (item) => `/lostandfound/edit-lost-found/${item.id}`,
    },
    Package: {
      fields: [null, "created", "description_note"],
      getUrl: (item) => `/packages/view-package/${item.id}`,
    },
    VisitorDetail: {
      fields: [null, "createdAt", null],
      getUrl: (item) => `/visitors/edit-visitor/${item.id}`,
    },
  };

  const fetchSearchResults = (currentSearchTerm) => {
    setLoading(true);
    try {
      let payload = {
        search: currentSearchTerm,
        start_date: startDateToFilter
          ? moment(startDateToFilter).format("MM-DD-YYYY")
          : "",
        end_date: endDateToFilter
          ? moment(endDateToFilter).format("MM-DD-YYYY")
          : "",
      };
      HeaderSearch(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            setSearchResults(res.data);
            setSearchTerm(currentSearchTerm);
            setTotalRecord(Object.values(res.data).flat().length); // Update to handle total records dynamically
            setLoading(false);
          } else {
            setSearchTerm("");
            setSearchResults("");
            setTotalRecord(0);
            setLoading(false);
            console.log("something went wrong");
          }
        })
        .catch((err) => {});
    } catch (error) {
      console.error("Error fetching search results:", error);
      setLoading(false);
    }
  };

  const handleSearch = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    fetchSearchResults(newSearchTerm);
  };

  useEffect(() => {
    if (searchInput) {
      fetchSearchResults(searchInput);
    }
  }, []);

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setSelected([]);
  };

  const handleChangeLimit = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
    setSelected([]);
  };
  return (
    <div>
      <Paper mt={3}>
        <EnhancedTableToolbar
          startDateToFilter={startDateToFilter}
          setStartDateToFilter={setStartDateToFilter}
          endDateToFilter={endDateToFilter}
          setEndDateToFilter={setEndDateToFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearchSubmit={handleSearch}
        />
        <TableContainer
          className="tableContainer"
          component={Paper}
          sx={{
            margin: "15px auto auto",
            "&::-webkit-scrollbar": {
              width: 7,
              height: 7,
            },
            "&::-webkit-scrollbar-track": {
              background: "#bed2f5",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#92a6c9",
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="sticky table"
          >
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                  {/* collapse null column to resolve console errors */}
                  {/* <TableCell colSpan={8} /> */}
                </TableRow>
              </TableBody>
            ) : (
              <>
                <EnhancedTableHead />
                <TableBody>
                  {Object.entries(searchResults).map(([key, items]) =>
                    items.map((item, index) => (
                      <TableRow key={index}>
                        {dataConfig[key].fields.map((field, idx) => (
                          <TableCell key={idx}>
                            {field === null
                              ? idx === 0
                                ? key
                                : "" // Show key index name if first field name is null
                              : idx === 1 && item[field]
                              ? moment(item[field]).format("MM/DD/YYYY")
                              : item[field] || ""}
                          </TableCell>
                        ))}
                        <TableCell>
                          <Link to={dataConfig[key].getUrl(item)}>
                            <VisibilityIcon sx={{ color: "gray" }} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Pagination
            count={totalRecord !== 0 ? Math.ceil(totalRecord / rowsPerPage) : 1}
            page={currentPage}
            onChange={handleChangePagination}
            color="primary"
            boundaryCount={3}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>
                Rows per page :
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                // label="Age"
                onChange={handleChangeLimit}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
      </Paper>
    </div>
  );
}

function Searchpage() {
  return (
    <React.Fragment>
      <Helmet title="Search" />
      <WithPermissionFallback controller="Search" action="index">
        <Typography variant="h3" gutterBottom display="inline">
          Search Result(s)
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable />
          </Grid>
        </Grid>
      </WithPermissionFallback>
    </React.Fragment>
  );
}

export default Searchpage;
